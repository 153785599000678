import React from "react";
import { Link } from "react-router-dom";

import { Card, Text } from "@chakra-ui/react";

const Authorization = () => {
  return (
    <Card className="mt-10 mx-10 p-10">
      <div className="gap-6 grid grid-cols-2 md:grid-cols-4 ">
        <Link to={`permissions`}>
          <Card className="min-h-20 min-w-20 hover:scale-110 transition-all cursor-pointer">
            <div className="flex items-center justify-center flex-1">
              <Text>Permissions</Text>
            </div>
          </Card>
        </Link>

        <Link to={`roles`}>
          <Card className="min-h-20 min-w-20 hover:scale-110 transition-all cursor-pointer">
            <div className="flex items-center justify-center flex-1">
              <Text>Roles</Text>
            </div>
          </Card>
        </Link>

        <Link to={`assigned-roles`}>
          <Card className="min-h-20 min-w-20 hover:scale-110 transition-all cursor-pointer">
            <div className="flex items-center justify-center flex-1">
              <Text>Assigned Roles</Text>
            </div>
          </Card>
        </Link>
      </div>
    </Card>
  );
};

export default Authorization;
