/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";

import {
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Textarea,
    useToast,
    chakra,
    Stack,
    Flex,
    HStack,
    Box,
    VStack,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import * as Yup from "yup";

import { callApiWithToken } from "../../utils/utils";

const EditSubAccountModal = (props) => {
    const { t } = useTranslation();
    const { isOpen, onClose, isLoading, refreshGrid, details } = props;
    const { orgId, note } = details;

    const [loading, setLoading] = useState(false)

    const toast = useToast();

    const closeModal = () => {
        formik.resetForm();
        onClose();
        setLoading(false);
    }


    const formik = useFormik({
        initialValues: {
            note: note,
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            note: Yup.string().required("Additional Notes is Manadatory")
        }),
        onSubmit: async (formValues) => {
            try {
                setLoading(true);
                const response = await callApiWithToken("POST", "/api/customers/wpay/updateNotes", {
                    field: "note",
                    value: formValues?.note,
                    orgId
                });
                if (response?.message) {
                    toast({
                        title: 'Success',
                        description: response?.message,
                        status: 'success',
                        duration: 5000,
                        isClosable: true,
                        position: 'top-right'
                    })
                }
                refreshGrid();
                closeModal();
            } catch (err) {
                setLoading(false);
                toast({
                    title: 'Error!',
                    description: err?.message || "Failed to fetch order details.",
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                    position: 'top-right'
                })
            }
        },

    });

    return (
        <chakra.form onSubmit={formik.handleSubmit} display="flex" flexGrow={1}>
            <Modal isOpen={isOpen} onClose={() => closeModal()} size={'xl'}>
                <ModalOverlay />
                <ModalContent
                    minWidth="600px"
                    height="fit-content">
                    <ModalHeader>{t("sub-accounts:additional_note")}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>

                        <Stack direction={"column"} justifyContent="space-between" flexGrow={1}>
                            <FormControl
                                isRequired
                                isInvalid={formik.touched.note && formik.errors.note}>
                                <FormLabel htmlFor="name">{t("sub-accounts:additional_note")}</FormLabel>
                                <Textarea
                                    id="note"
                                    type="text"
                                    value={formik.values.note}
                                    onChange={formik.handleChange}
                                    size='xl'
                                    rows={10}
                                />
                                <FormErrorMessage>{formik.errors.note}</FormErrorMessage>
                            </FormControl>
                        </Stack>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            onClick={() => closeModal()}
                            mr={3}
                        >
                            {t("sub-accounts:cancel")}
                        </Button>
                        <Button
                            isLoading={loading}
                            onClick={formik.handleSubmit}
                            colorScheme='blue'
                        >
                            {t("sub-accounts:save")}
                        </Button>
                    </ModalFooter>

                </ModalContent>
            </Modal>
        </chakra.form>
    )
}

export default EditSubAccountModal;