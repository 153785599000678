import React, { useState, useMemo } from "react";

import { Search, Plus, X, ChevronDown, Trash2, Pencil, Check } from "lucide-react";

import { getOrgId } from "../../..//components/App/useToken";
import { Card, CardContent, CardHeader, CardTitle } from "../../../components/ui/card";
import { callApiWithToken } from "../../../utils/utils";

const RoleManagement = () => {
  const [roles, setRoles] = useState([
    {
      id: 1,
      name: "Super Admin",
      description: "Full system access",
      permissions: ["users.create", "users.read", "users.update", "users.delete", "customers.manage"],
      isCustom: false,
    },
    {
      id: 2,
      name: "Organization Admin",
      description: "Organization level administrator",
      permissions: ["users.read", "users.update", "customers.manage"],
      isCustom: false,
    },
  ]);

  const [permissions] = useState([
    { id: 1, name: "users.create", description: "Create users", resource: "users" },
    { id: 2, name: "users.read", description: "View users", resource: "users" },
    { id: 3, name: "users.update", description: "Update users", resource: "users" },
    { id: 4, name: "users.delete", description: "Delete users", resource: "users" },
    { id: 5, name: "customers.manage", description: "Manage customers", resource: "customers" },
    { id: 6, name: "customers.read", description: "View customers", resource: "customers" },
    { id: 7, name: "reports.generate", description: "Generate reports", resource: "reports" },
    { id: 8, name: "reports.view", description: "View reports", resource: "reports" },
    { id: 9, name: "permissions.create", description: "View reports", resource: "reports" },
  ]);

  const [showRoleModal, setShowRoleModal] = useState(false);
  const [showPermissionSelector, setShowPermissionSelector] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [permissionSearchTerm, setPermissionSearchTerm] = useState("");
  const [selectedResource, setSelectedResource] = useState("");
  const [editingRole, setEditingRole] = useState(null);
  const orgId = getOrgId();
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    permissions: [],
    orgId,
  });
  const [formErrors, setFormErrors] = useState({});

  const resources = useMemo(() => {
    return [...new Set(permissions.map((p) => p.resource))];
  }, [permissions]);

  const filteredPermissions = useMemo(() => {
    return permissions.filter((permission) => {
      const matchesSearch =
        permission.name.toLowerCase().includes(permissionSearchTerm.toLowerCase()) ||
        permission.description.toLowerCase().includes(permissionSearchTerm.toLowerCase());
      const matchesResource = !selectedResource || permission.resource === selectedResource;
      return matchesSearch && matchesResource;
    });
  }, [permissions, permissionSearchTerm, selectedResource]);

  const groupedPermissions = useMemo(() => {
    return filteredPermissions.reduce((groups, permission) => {
      const resource = permission.resource;
      if (!groups[resource]) {
        groups[resource] = [];
      }
      groups[resource].push(permission);
      return groups;
    }, {});
  }, [filteredPermissions]);

  const filteredRoles = useMemo(() => {
    return roles.filter(
      (role) => role.name.toLowerCase().includes(searchTerm.toLowerCase()) || role.description.toLowerCase().includes(searchTerm.toLowerCase()),
    );
  }, [roles, searchTerm]);

  const validateForm = () => {
    const errors = {};
    if (!formData.name.trim()) {
      errors.name = "Role name is required";
    }
    if (!formData.description.trim()) {
      errors.description = "Description is required";
    }
    if (formData.permissions.length === 0) {
      errors.permissions = "At least one permission must be selected";
    }
    return errors;
  };

  const handleRoleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm();

    if (Object.keys(errors).length === 0) {
      if (editingRole) {
        setRoles(roles.map((role) => (role.id === editingRole.id ? { ...role, ...formData } : role)));
      } else {
        const { success, data } = await callApiWithToken("POST", "/api/authorization/roles", formData);

        if (success) {
          setRoles([...roles, data]);
        }
      }
      handleCloseModal();
    } else {
      setFormErrors(errors);
    }
  };

  const handleCloseModal = () => {
    setShowRoleModal(false);
    setEditingRole(null);
    setFormData({
      name: "",
      description: "",
      permissions: [],
    });
    setFormErrors({});
    setPermissionSearchTerm("");
    setSelectedResource("");
    setShowPermissionSelector(false);
  };

  const handleEditRole = (role) => {
    setEditingRole(role);
    setFormData({
      name: role.name,
      description: role.description,
      permissions: role.permissions,
    });
    setShowRoleModal(true);
  };

  const togglePermission = (permissionName) => {
    setFormData((prev) => ({
      ...prev,
      permissions: prev.permissions.includes(permissionName) ? prev.permissions.filter((p) => p !== permissionName) : [...prev.permissions, permissionName],
    }));
    setFormErrors((prev) => ({ ...prev, permissions: undefined }));
  };

  return (
    <div className="w-full max-w-4xl mx-auto p-4 space-y-4">
      {/* Header */}
      <Card>
        <CardHeader className="flex flex-row items-center justify-between">
          <CardTitle>Role Management</CardTitle>
          <button onClick={() => setShowRoleModal(true)} className="flex items-center gap-2 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600">
            <Plus className="h-4 w-4" />
            New Role
          </button>
        </CardHeader>
      </Card>

      {/* Search */}
      <Card>
        <CardContent className="pt-6">
          <div className="relative">
            <Search className="absolute left-3 top-2.5 h-4 w-4 text-gray-400" />
            <input
              type="text"
              placeholder="Search roles..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full pl-10 p-2 border rounded-md"
            />
          </div>
        </CardContent>
      </Card>

      {/* Role List */}
      <Card>
        <CardHeader>
          <CardTitle>Roles ({filteredRoles.length})</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="space-y-4">
            {filteredRoles.map((role) => (
              <div key={role.id} className="border rounded-lg p-4 hover:bg-gray-50">
                <div className="flex items-start justify-between">
                  <div>
                    <h3 className="font-medium flex items-center gap-2">
                      {role.name}
                      {role.isCustom && <span className="text-xs px-2 py-1 bg-blue-100 text-blue-800 rounded">Custom</span>}
                    </h3>
                    <p className="text-sm text-gray-500 mt-1">{role.description}</p>
                  </div>
                  <div className="flex gap-2">
                    <button onClick={() => handleEditRole(role)} className="p-1 hover:bg-gray-200 rounded">
                      <Pencil className="h-4 w-4 text-gray-500" />
                    </button>
                    {role.isCustom && (
                      <button onClick={() => setRoles(roles.filter((r) => r.id !== role.id))} className="p-1 hover:bg-gray-200 rounded">
                        <Trash2 className="h-4 w-4 text-red-500" />
                      </button>
                    )}
                  </div>
                </div>
                <div className="mt-3">
                  <div className="text-sm font-medium mb-2">Permissions ({role.permissions.length})</div>
                  <div className="flex flex-wrap gap-2">
                    {role.permissions.map((permission) => (
                      <span key={permission} className="text-xs px-2 py-1 bg-gray-100 rounded">
                        {permission}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </CardContent>
      </Card>

      {/* Role Modal */}
      {showRoleModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
          <div className="bg-white rounded-lg w-full max-w-2xl mx-4 max-h-[90vh] overflow-hidden">
            <div className="p-4 border-b">
              <h2 className="text-lg font-medium">{editingRole ? "Edit Role" : "Create New Role"}</h2>
            </div>

            <form onSubmit={handleRoleSubmit} className="p-4 space-y-4 overflow-y-auto max-h-[calc(90vh-8rem)]">
              {/* Role Name */}
              <div>
                <label className="block text-sm font-medium mb-1">Role Name</label>
                <input
                  type="text"
                  value={formData.name}
                  onChange={(e) => {
                    setFormData((prev) => ({ ...prev, name: e.target.value }));
                    setFormErrors((prev) => ({ ...prev, name: undefined }));
                  }}
                  className={`w-full p-2 border rounded-md ${formErrors.name ? "border-red-500" : ""}`}
                  placeholder="Enter role name"
                />
                {formErrors.name && <p className="mt-1 text-sm text-red-500">{formErrors.name}</p>}
              </div>

              {/* Description */}
              <div>
                <label className="block text-sm font-medium mb-1">Description</label>
                <input
                  type="text"
                  value={formData.description}
                  onChange={(e) => {
                    setFormData((prev) => ({ ...prev, description: e.target.value }));
                    setFormErrors((prev) => ({ ...prev, description: undefined }));
                  }}
                  className={`w-full p-2 border rounded-md ${formErrors.description ? "border-red-500" : ""}`}
                  placeholder="Enter role description"
                />
                {formErrors.description && <p className="mt-1 text-sm text-red-500">{formErrors.description}</p>}
              </div>

              {/* Permissions */}
              <div>
                <label className="block text-sm font-medium mb-1">Permissions</label>
                <button
                  type="button"
                  onClick={() => setShowPermissionSelector(!showPermissionSelector)}
                  className={`w-full flex items-center justify-between p-2 border rounded-md ${formErrors.permissions ? "border-red-500" : ""}`}
                >
                  <span>
                    {formData.permissions.length} permission{formData.permissions.length !== 1 ? "s" : ""} selected
                  </span>
                  <ChevronDown className="h-4 w-4" />
                </button>
                {formErrors.permissions && <p className="mt-1 text-sm text-red-500">{formErrors.permissions}</p>}

                {showPermissionSelector && (
                  <div className="mt-2 border rounded-md">
                    {/* Permission Search and Filter */}
                    <div className="p-3 border-b space-y-3">
                      <div className="relative">
                        <Search className="absolute left-3 top-2.5 h-4 w-4 text-gray-400" />
                        <input
                          type="text"
                          placeholder="Search permissions..."
                          value={permissionSearchTerm}
                          onChange={(e) => setPermissionSearchTerm(e.target.value)}
                          className="w-full pl-10 p-2 border rounded-md"
                        />
                      </div>
                      <div className="flex gap-2">
                        <select value={selectedResource} onChange={(e) => setSelectedResource(e.target.value)} className="flex-1 p-2 border rounded-md">
                          <option value="">All Resources</option>
                          {resources.map((resource) => (
                            <option key={resource} value={resource}>
                              {resource.charAt(0).toUpperCase() + resource.slice(1)}
                            </option>
                          ))}
                        </select>
                        <button
                          type="button"
                          onClick={() => {
                            setPermissionSearchTerm("");
                            setSelectedResource("");
                          }}
                          className="px-3 py-2 text-sm border rounded-md hover:bg-gray-50"
                        >
                          Clear
                        </button>
                      </div>
                    </div>

                    {/* Permissions List */}
                    <div className="max-h-64 overflow-y-auto">
                      {Object.entries(groupedPermissions).map(([resource, resourcePermissions]) => (
                        <div key={resource} className="border-b last:border-b-0">
                          <div className="p-2 bg-gray-50 font-medium text-sm">
                            {resource.charAt(0).toUpperCase() + resource.slice(1)}
                            <span className="ml-2 text-gray-500">({resourcePermissions.length})</span>
                          </div>
                          <div className="divide-y">
                            {resourcePermissions.map((permission) => (
                              <div
                                key={permission.id}
                                className="flex items-center justify-between p-3 hover:bg-gray-50 cursor-pointer"
                                onClick={() => togglePermission(permission.name)}
                              >
                                <div className="flex-1">
                                  <div className="font-medium text-sm">{permission.name}</div>
                                  <div className="text-sm text-gray-500">{permission.description}</div>
                                </div>
                                <div className="ml-4">
                                  {formData.permissions.includes(permission.name) ? (
                                    <div className="w-6 h-6 bg-blue-500 rounded-md flex items-center justify-center">
                                      <Check className="h-4 w-4 text-white" />
                                    </div>
                                  ) : (
                                    <div className="w-6 h-6 border rounded-md" />
                                  )}
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      ))}
                      {filteredPermissions.length === 0 && (
                        <div className="p-4 text-center text-gray-500">No permissions found matching your search criteria</div>
                      )}
                    </div>
                  </div>
                )}
              </div>

              {/* Form Actions */}
              <div className="flex justify-end gap-2 pt-4 border-t">
                <button type="button" onClick={handleCloseModal} className="px-4 py-2 border rounded-md hover:bg-gray-50">
                  Cancel
                </button>
                <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600">
                  {editingRole ? "Update Role" : "Create Role"}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default RoleManagement;
