import { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";

import { Badge, Stack, Text, Box, HStack, Button } from "@chakra-ui/react";
import { startCase, round, isUndefined } from 'lodash';
import moment from "moment";
import { BiReset } from 'react-icons/bi'

import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme

import { constants } from "../../constants";
import { NavBarContext } from '../../context/NavBarContext';
import Loader from '../../utils/Loader';
import Grid from '../../utils/agTable';
import { callApiWithTokenV2 } from '../../utils/utils';

const PrefundSummary = (props) => {
  const { prefundingStatusMap, colorMap } = constants;
  const [refreshGrid, setRefreshGrid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { selectedOrg } = useContext(NavBarContext);

  useEffect(() => {
    setRefreshGrid(!refreshGrid);
  }, [selectedOrg])

  const USformatter = new Intl.NumberFormat('en-US');

  var filterParams = {
    comparator: (filterLocalDateAtMidnight, cellValue) => {
      var dateAsString = cellValue;
      if (dateAsString == null) return -1;
      var cellDate = new Date(cellValue);
      if (filterLocalDateAtMidnight.toLocaleDateString() === cellDate.toLocaleDateString()) {
        return 0;
      }
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      }
      if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
      return 0;
    },
    minValidYear: 2000,
  };

  const handleReset = () => {
    setRefreshGrid(!refreshGrid);
  };

  const getOrderData = async (filters) => {
    filters["type"] = 'prefund-summary';
    const responseData = await callApiWithTokenV2.post(`/api/orders/tfpay/list?`, { impersonatedOrg: selectedOrg }, {
      params: {
        filters
      }
    })
    return responseData;
  }

  const columns = [
    {
      headerName: "Prefunding Currency", field: "currency", enableRowGroup: true, customerVisibility: true, width: '200', cellRenderer: ({ value }) => (
        <Text>{value ? value : 'N/A'}</Text>
      )
    },
    {
      headerName: "Date",
      field: "date",
      filterParams: filterParams,
      customerVisibility: true,
      enableRowGroup: false,
      cellDataType: 'date',
      width: '220',
      filter: 'agDateColumnFilter',
      cellRenderer: ({ value }) => (
        <Text>{value ? moment(value).format("DD-MMM-YYYY") : "N/A"}</Text>
      ),
    },
    {
      headerName: "Customer Name", field: "name", enableRowGroup: true, customerVisibility: true, width: '200', cellRenderer: ({ value }) => (
        <Text>{!isUndefined(value) ? value : 'N/A'}</Text>
      )
    },
    {
      headerName: "Opening Prefunding", field: "openingBalance", customerVisibility: true, width: '200', cellRenderer: ({ value }) => (
        <Text>{!isUndefined(value) ? value : 'N/A'}</Text>
      )
    },
    {
      headerName: "Additional Prefunding Received", field: "totalWithdrawAmount", customerVisibility: true, width: '200', cellRenderer: ({ value }) => (
        <Text>{!isUndefined(value) ? value : 'N/A'}</Text>
      )
    },
    {
      headerName: "Total prefunding available before txns", field: "totalPrefunding", customerVisibility: true, width: '200', cellRenderer: ({ value }) => (
        <Text>{!isUndefined(value) ? value : 'N/A'}</Text>
      )
    },
    {
      headerName: "Value of  Payout orders successful (fiat)", field: "totalSucessfulFiatPayoutOrderAmount", customerVisibility: true, width: '200', cellRenderer: ({ value }) => (
        <Text>{!isUndefined(value) ? value : 'N/A'}</Text>
      )
    },
    {
      headerName: "Value of  Payout orders successful (stable coins)", field: "totalSucessfulCryptoPayoutOrderAmount", customerVisibility: true, width: '200', cellRenderer: ({ value }) => (
        <Text>{!isUndefined(value) ? value : 'N/A'}</Text>
      )
    },
    {
      headerName: "Value of  Payout orders in transit(fiat)", field: "totalInTransitFiatPayoutOrderAmount", customerVisibility: true, width: '200', cellRenderer: ({ value }) => (
        <Text>{!isUndefined(value) ? value : 'N/A'}</Text>
      )
    },
    {
      headerName: "Value of  Payout orders in transit(stable coins)", field: "totalInTransitCryptoPayoutOrderAmount", customerVisibility: true, width: '200', cellRenderer: ({ value }) => (
        <Text>{!isUndefined(value) ? value : 'N/A'}</Text>
      )
    },
    {
      headerName: "Closing prefunding after txns including in transit", field: "closingBalance", customerVisibility: true, width: '200', cellRenderer: ({ value }) => (
        <Text>{!isUndefined(value) ? value : 'N/A'}</Text>
      )
    },
  ];

  return (
    <Stack
      direction={"column"}
      justifyContent={"space-between"}
      flexGrow={1}
      p={5}
    >

      {!isLoading ? <Box >
        <HStack pb={5} justifyContent={"flex-end"}>
          <Button
            leftIcon={<BiReset style={{ fontSize: "20px" }} />}
            color={"#525C76"}
            variant={"outline"}
            borderColor={"#d2d2d2"}
            _hover={{ bg: "#ffffff" }}
            onClick={handleReset}
          >
            Reset
          </Button>
        </HStack>
        <Grid columns={columns} key={refreshGrid.toString()} getOrderData={getOrderData} />
      </Box> : <Loader />}
    </Stack>
  );
};

export default PrefundSummary;
