import React, { useState, useRef, useEffect } from "react";
import Loader from "react-js-loader";
import { useParams } from "react-router-dom";
import Select from "react-select";

import { EditIcon } from "@chakra-ui/icons";
import { Box, Text, Card, CardBody, Flex, Badge, Button, useDisclosure } from "@chakra-ui/react";
import { round, isEmpty } from "lodash";
import moment from "moment";

import TransfiGrid from "../../../utils/TransfiGrid";
import { callApiWithToken } from "../../../utils/utils";
import { getOrgRoles } from "../../App/useToken";
import NoteModal from "./NoteModal";

export default function RevShareBatch() {
    const orgRoles = getOrgRoles();
    const { batchId } = useParams();
    const [loading, setLoading] = useState(false);
    const [settlementDetails, setSettlementDetails] = useState({});
    const [total, setTotal] = useState({});
    const gridRef = useRef();

    const { isOpen: isOpenNoteModal, onOpen: onOpenNoteModal, onClose: onCloseNoteModal } = useDisclosure({ defaultIsOpen: false });

    // useEffect(() => {
    //     getTotalBalance();
    // }, []);
    const conversionRate = !isEmpty(settlementDetails?.conversionRate) ? settlementDetails?.conversionRate : "USD"

    const getTotalBalance = async () => {
        try {
            // setLoading(true)

            const response = await callApiWithToken("GET", `/api/settlement/getRevShareSettlementDetails?batchId=${batchId}&conversionRate=${conversionRate}`) || {};
            const { settlementDetails = {}, overallTotal = 0 } = response || {};
            setSettlementDetails(settlementDetails);
            setTotal(overallTotal);
            setLoading(false)
        } catch (e) {
            setLoading(false);
        }

    }

    useEffect(() => {
        getTotalBalance();
    }, [total, conversionRate]);

    const refreshGrid = () => {
        gridRef.current.refreshGrid();
        getTotalBalance()
    };

    const columns = [
        {
            Header: "Order Id",
            accessor: "orderId",
            width: "200",
        },
        {
            Header: "Date",
            accessor: "createdAt",
            width: "200",
            Cell: ({ value }) => {
                return moment(value).utc().format("lll");
            },
        },
        {
            Header: "Fiat Currency",
            accessor: "fiatTicker",
            width: "200",
        },
        {
            Header: "Total Fiat Amt(Buy)",
            accessor: "buy.fiatAmount",
            width: "30",
            color: "info",
            Cell: ({ value }) => value ? round(value, 2).toLocaleString("en-US") : "-"
        },
        {
            Header: "Customer Rev Share (Buy)",
            accessor: "buy.revShare",
            width: "20",
            color: "info",
            Cell: ({ value }) => value ? round(value, 2).toLocaleString("en-US") : "-"
        },
        {
            Header: "Total Fiat Amt(Sell)",
            accessor: "sell.fiatAmount",
            width: "30",
            color: "warning",
            Cell: ({ value }) => value ? round(value, 2).toLocaleString("en-US") : "-"
        },
        {
            Header: "Customer Rev Share(Sell)",
            accessor: "sell.revShare",
            width: "30",
            color: "warning",
            Cell: ({ value }) => value ? round(value, 2).toLocaleString("en-US") : "-"
        },
        {
            Header: "Total customer rev share ",
            accessor: "totalAmount",
            width: "30",
            Cell: ({ value }) => value ? round(value, 2).toLocaleString("en-US") : "-"
        },
        {
            Header: `Conversion Rate to ${conversionRate}`,
            accessor: "conversionRateValue",
            width: "30",
            show: orgRoles.includes("transfi_admin") ? true : false,
            Cell: ({ value }) => value ? round(value, 2).toLocaleString("en-US") : "-"
        },
        {
            Header: "TransFi Rate",
            accessor: "markUp",
            width: "30",
            Cell: ({ value }) => value ? round(value, 2).toLocaleString("en-US") : "-"
        },
        {
            Header: "Rev Share to be paid (in settlement currency)",
            accessor: "revShare",
            width: "30",
            Cell: ({ value }) => value ? round(value, 2).toLocaleString("en-US") : "-"
        },

    ];

    const CardText = ({ label, value }) => {
        return <Flex>
            <Box flex="1">
                <Text fontWeight="bold">{label}</Text>
            </Box>
            <Box flex="1" textAlign={'right'}>
                <Text>{value}</Text>
            </Box>
        </Flex>
    }

    const getNoteField = (value) => {
        return <>
            {value}
            {(orgRoles.includes("transfi_admin") && settlementDetails?.settlementStatus !== "settlement_completed") ?
                <Button
                    variant="link"
                    onClick={() => onOpenNoteModal()}
                ><EditIcon /></Button> : <></>}
        </>;
    }

    return !loading ? (

        <Box style={{ background: "white", marginTop: "30px" }} heading="Orders">
            <Flex width={'full'} marginX={6}>
                <Card flex="1" variant={'filled'}>
                    <CardBody>
                        <CardText label="Settlement Date:" value={getNoteField(settlementDetails?.settlementDate)} />
                        <CardText label="Settlement Currency:" value={getNoteField(settlementDetails?.settlementCurrency)} />
                        <CardText label="Total Settlement:" value={round(total, 2)} />
                    </CardBody>
                </Card>
                <Card flex="1" variant={'filled'} marginX={6}>
                    <CardBody>

                       {orgRoles.includes("transfi_admin") ? <CardText
                            label="Settlement Mark Rate(%)"
                            value={getNoteField(round((settlementDetails?.markUpRate - 1)*100) || "")
                            }
                        /> : ""}
                        <CardText
                            label="Settlement Note"
                            value=
                            {getNoteField(settlementDetails?.note)}

                        />
                        <CardText
                            label="Conversion Currency"
                            value=
                            {getNoteField(settlementDetails?.conversionRate)}

                        />
                    </CardBody>
                </Card>
            </Flex>
            <TransfiGrid
                ref={gridRef}
                columns={columns}
                dataUrl={`/api/settlement/revSharelist/${batchId}`}
                filters={{
                    conversionRate: conversionRate
                }}
            ></TransfiGrid>
            {isOpenNoteModal && <NoteModal
                isOpen={isOpenNoteModal}
                onOpen={onOpenNoteModal}
                onClose={onCloseNoteModal}
                refreshGrid={refreshGrid}
                batchId={batchId}
                initialDescription={settlementDetails?.note}
                initialRate={round((settlementDetails?.markUpRate-1)*100) || ""}
                initialDate={settlementDetails?.settlementDate}
                initialCurrency={settlementDetails?.settlementCurrency}
                initialConversionRate={settlementDetails?.conversionRate}
                initialAccount={settlementDetails?.paymentPartner}
                getTotalBalance={getTotalBalance}
            />}
        </Box>
    ) : (
        <Loader type="spinner-cub" bgColor={"#FFFFFF"} title={"Loading..."} color={"cyan.400"} size={100} />
    );
}
