import React, { useEffect, useState } from "react";

import { Box, useToast } from "@chakra-ui/react";

//icons
import png from '../../../icons/background.png';
import Loader from "../../../utils/Loader";
import { callApi } from "../../../utils/utils";
//Custom Conmponents or functions
import { validateEmail } from "../../../utils/validations";
import EmailIcon from "./icons/EmailIcon.png";
import Logo from "./icons/Logo.png";

//css
import "../Login.css"
import "./PasswordLogin.css"

const EmailForNewPassword = (props) => {
    const toast = useToast();

    const { setUserEmail, reset, setMfaStatus, urlEmail = false } = props || {};
    const [email, setEmail] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (urlEmail) {
            sendOtpToEmail(urlEmail);
        }
    }, [urlEmail])

    const handleEmailInputChange = (e) => {
        setEmail(e.target.value);
    }

    const handleToast = (title, type) => {
        toast({
            title,
            status: type,
            duration: 5000,
            isClosable: true
        });
    };

    const sendOtpToEmail = async (userEmail) => {
        if (!validateEmail(userEmail)) {
            handleToast("Please enter valid email", 'error');
            return;
        }
        try {
            setIsLoading(true);
            const data = await callApi("POST", `/api/customers/verifyEmail`, JSON.stringify({ email: userEmail, resetPassword: reset }));
            const { status = false, message, mfaStatus } = data;

            if (status) {
                setUserEmail(userEmail);
                if (mfaStatus) {
                    setMfaStatus(mfaStatus);
                }
                setIsLoading(false);
            } else {
                throw new Error(message);
            }

            if (mfaStatus) {
                handleToast("Email Verified, Please Verify 2FA to reset your password", 'success');
            } else {
                handleToast("OTP sent successfully", 'success');
            }
        } catch (e) {
            handleToast(e?.message || "Something went wrong", 'error');
            setIsLoading(false);
        }
    }

    return (
        isLoading ? <Loader /> :
            <Box className="pa-login-container" backgroundImage={png}>
                <div className="pa-login-card">
                    <div style={{ textAlign: 'left' }}></div>
                    <img className="pa-login-logo-trouble" src={Logo} />
                    <p className="pa-login-heading">{reset ? 'Reset your password' : 'Set new password'}</p>
                    <p className="pa-login-email-heading" style={{ marginTop: '84px' }}>{`Enter your email address to ${reset ? 'reset' : 'set'} your password`}</p>
                    <div style={{ textAlign: 'center' }}>
                        <div className="pa-input-box-div">
                            <span style={{ position: 'absolute' }}>
                                <img src={EmailIcon} className="pa-email-icon" />
                            </span>
                            <input
                                type="email"
                                className="pa-input-email"
                                placeholder="Enter your email address"
                                onChange={(e) => handleEmailInputChange(e)}
                            />
                        </div>
                    </div>
                    <button className="pa-button" style={{ marginTop: '60px' }} onClick={() => sendOtpToEmail(email)}>
                        Continue
                    </button>
                    <div className="pa-transfi-banner" style={{ marginTop: '130px' }}>
                        <p className="pa-powered-by">Powered by</p>
                        <img style={{ width: '24px', height: '17px', marginLeft: '8px', marginRight: '8px' }} src={Logo} />
                        <p className="pa-powered-transfi">TransFi</p>
                    </div>
                </div>
            </Box>
    );
};

export default EmailForNewPassword;
