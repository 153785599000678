import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Box, useToast } from "@chakra-ui/react";
import { FaRegEye } from "react-icons/fa";
import { RxCross2, RxEyeClosed } from "react-icons/rx";
import { TiTick } from "react-icons/ti";

//icons
import png from "../../../icons/background.png";
import Loader from "../../../utils/Loader";
//custom components and fucntions
import { callApi } from "../../../utils/utils";
import KeyIcon from "./icons/KeyIcon.png";
import Logo from "./icons/Logo.png";

//css
import "../Login.css";
import "./PasswordLogin.css";

const SetNewPassword = (props) => {
  const toast = useToast();
  const navigate = useNavigate();

  const { email, reset } = props || {};

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [viewNewPassword, setViewNewPassword] = useState(false);
  const [viewConfirmPassword, setViewConfirmPassword] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState("weak");
  const [smallCase, setSmallCase] = useState(false);
  const [upperCase, setUpperCase] = useState(false);
  const [digit, setDigit] = useState(false);
  const [specialCharacter, setSpecialCharacter] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleToast = (title, type) => {
    toast({
      title,
      status: type,
      duration: 10000,
      isClosable: true,
    });
  };

  const getPasswordStrength = (password) => {
    let score = 0;
    const minLength = 8;
    const maxLength = 12;

    const hasLowerCase = /[a-z]/.test(password);
    const hasUpperCase = /[A-Z]/.test(password);
    const hasDigit = /\d/.test(password);
    const hasSpecialChar = /[!_@#$%^&*]/.test(password);
    const doNotAllowOtherCharacters = /^[a-zA-Z0-9!_@#$%^&]*$/.test(password);

    if (hasLowerCase) setSmallCase(true);
    else setSmallCase(false);
    if (hasUpperCase) setUpperCase(true);
    else setUpperCase(false);
    if (hasDigit) setDigit(true);
    else setDigit(false);
    if (hasSpecialChar) setSpecialCharacter(true);
    else setSpecialCharacter(false);
    if (password !== "" && hasSpecialChar && doNotAllowOtherCharacters) setSpecialCharacter(true);
    else setSpecialCharacter(false);

    if (password.length >= minLength) {
      if (hasLowerCase) score++;
      if (hasUpperCase) score++;
      if (hasDigit) score++;
      if (hasSpecialChar) score++;
      if (password.length >= maxLength) score++;
    }

    return score;
  };

  const setStrengthStatus = (input) => {
    const strength = getPasswordStrength(input);
    switch (strength) {
      case 1:
        setPasswordStrength("weak");
        break;
      case 2:
        setPasswordStrength("weak");
        break;
      case 3:
        setPasswordStrength("normal");
        break;
      case 4:
        setPasswordStrength("strong");
        break;
      case 5:
        setPasswordStrength("very strong");
        break;
      default:
        setPasswordStrength("weak");
        break;
    }
  };

  const handleChangeNewPassword = (e) => {
    setNewPassword(e.target.value);
    setStrengthStatus(e.target.value);

    let confirmColor = "red";
    if (e.target.value === confirmPassword) {
      confirmColor = "green";
    }
    if (confirmPassword == "") {
      confirmColor = "transparent";
    }

    const confirmPasswordElement = document.getElementById("confirm-password-id");
    confirmPasswordElement.style.borderBottomColor = confirmColor;
  };

  const handleChangeConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
    let color = "red";
    if (e.target.value === newPassword && e.target.value !== "") {
      color = "green";
    }
    if (e.target.value == "") {
      color = "transparent";
    }
    const confirmPasswordElement = document.getElementById("confirm-password-id");
    confirmPasswordElement.style.borderBottomColor = color;
  };

  const onSubmitPassword = async () => {
    if (newPassword === "") {
      handleToast("Password enter new password", "error");
      return;
    }
    if (confirmPassword === "") {
      handleToast("Password confirm your new password", "error");
      return;
    }
    if (newPassword !== confirmPassword) {
      handleToast("Passwords are not matching, Please try again", "error");
      return;
    }
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!_@#$%^&])[a-zA-Z\d!_@#$%^&]{8,}$/;
    if (!regex.test(newPassword)) {
      handleToast("Invalid Password, Please enter valid password", "error");
      return;
    }

    try {
      setIsLoading(true);

      const data = await callApi("POST", `/api/customers/updatePaAccountDetails`, JSON.stringify({ email, accountDetails: newPassword }));
      const { status = false, message } = data;

      if (status) {
        navigate("/login");
        setIsLoading(false);
        handleToast("Your password has been successfully set.", "success");
      } else {
        throw new Error(message);
      }
    } catch (e) {
      handleToast(e?.message || "Something went wrong", "error");
      setIsLoading(false);
    }
  };

  const onResetPassword = async () => {
    if (newPassword === "") {
      handleToast("Password enter new password", "error");
      return;
    }
    if (confirmPassword === "") {
      handleToast("Password confirm your new password", "error");
      return;
    }
    if (newPassword !== confirmPassword) {
      handleToast("Passwords are not matching, Please try again", "error");
      return;
    }
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!_@#$%^&])[a-zA-Z\d!_@#$%^&]{8,}$/;
    if (!regex.test(newPassword)) {
      handleToast("Invalid Password, Please enter valid password", "error");
      return;
    }

    try {
      setIsLoading(true);

      const data = await callApi("POST", `/api/customers/resetPaAccountDetails`, JSON.stringify({ email, accountDetails: newPassword }));
      const { status = false, message } = data;

      if (status) {
        navigate("/login");
        setIsLoading(false);
        handleToast("Your password has been successfully reset.", "success");
      } else {
        throw new Error(message);
      }
    } catch (e) {
      handleToast(e?.message || "Something went wrong", "error");
      setIsLoading(false);
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    handleToast("Pasting password is not allowed", "error");
  };

  const handleSubmit = async () => {
    if (reset) {
      await onResetPassword();
    } else {
      await onSubmitPassword();
    }
  };

  return isLoading ? (
    <Loader />
  ) : (
    <Box className="pa-login-container" backgroundImage={png}>
      <div className="pa-login-card">
        <div style={{ textAlign: "left" }}></div>
        <img className="pa-login-logo-trouble" style={{ marginTop: "84px" }} src={Logo} />
        <p className="pa-login-heading">{`${reset ? "Reset" : "Set New"} Password`}</p>
        <p className="pa-login-email-heading" style={{ marginTop: "84px" }}>
          New Password
        </p>
        <div style={{ textAlign: "center" }}>
          <div className="pa-input-box-div">
            <span style={{ position: "absolute" }}>
              <img src={KeyIcon} className="pa-key-icon" />
            </span>
            <input
              id="new-password-id"
              type={viewNewPassword ? "text" : "password"}
              className="pa-input-password"
              placeholder={"Enter new password"}
              value={newPassword}
              onChange={(e) => handleChangeNewPassword(e)}
              onPaste={(e) => handlePaste(e)}
            />
            {!viewNewPassword ? (
              <span style={{ position: "absolute", marginLeft: "-40px", fontSize: "20px", marginTop: "15px" }}>
                <RxEyeClosed
                  onClick={() => {
                    setViewNewPassword(true);
                  }}
                />
              </span>
            ) : (
              <span style={{ position: "absolute", marginLeft: "-40px", fontSize: "20px", marginTop: "18px" }}>
                <FaRegEye
                  onClick={() => {
                    setViewNewPassword(false);
                  }}
                />
              </span>
            )}
          </div>
        </div>
        <div style={{ textAlign: "left", marginLeft: "170px", marginTop: "8px" }}>
          <p className="pa-login-password-strength" style={{ color: `${["weak", "normal"].includes(passwordStrength) ? "#4e4e4e" : "green"}` }}>
            {["weak", "normal"].includes(passwordStrength) ? (
              <RxCross2 style={{ marginTop: "3px", marginRight: "4px" }} />
            ) : (
              <TiTick style={{ marginTop: "3px", marginRight: "4px" }} />
            )}
            Password strength: {passwordStrength}
          </p>
          <p className="pa-login-password-strength" style={{ color: `${!smallCase ? "#4e4e4e" : "green"}` }}>
            {!smallCase ? <RxCross2 style={{ marginTop: "3px", marginRight: "4px" }} /> : <TiTick style={{ marginTop: "3px", marginRight: "4px" }} />}
            Should have atleast 1 small case character
          </p>
          <p className="pa-login-password-strength" style={{ color: `${!upperCase ? "#4e4e4e" : "green"}` }}>
            {!upperCase ? <RxCross2 style={{ marginTop: "3px", marginRight: "4px" }} /> : <TiTick style={{ marginTop: "3px", marginRight: "4px" }} />}
            Should have atleast 1 upper case character
          </p>
          <p className="pa-login-password-strength" style={{ color: `${!digit ? "#4e4e4e" : "green"}` }}>
            {!digit ? <RxCross2 style={{ marginTop: "3px", marginRight: "4px" }} /> : <TiTick style={{ marginTop: "3px", marginRight: "4px" }} />}
            Should have atleast 1 digit
          </p>
          <p className="pa-login-password-strength" style={{ color: `${!specialCharacter ? "#4e4e4e" : "green"}` }}>
            {!specialCharacter ? <RxCross2 style={{ marginTop: "3px", marginRight: "4px" }} /> : <TiTick style={{ marginTop: "3px", marginRight: "4px" }} />}
            Should have atleast 1 special character from !_@#$%^&*
          </p>
        </div>
        <p className="pa-login-password-heading">Confirm New Password</p>
        <div style={{ textAlign: "center" }}>
          <div className="pa-input-box-div">
            <span style={{ position: "absolute" }}>
              <img src={KeyIcon} className="pa-key-icon" />
            </span>
            <input
              id="confirm-password-id"
              type={viewConfirmPassword ? "text" : "password"}
              className="pa-input-password"
              placeholder="Confirm password"
              value={confirmPassword}
              onChange={(e) => handleChangeConfirmPassword(e)}
              onPaste={(e) => handlePaste(e)}
            />
            {!viewConfirmPassword ? (
              <span style={{ position: "absolute", marginLeft: "-40px", fontSize: "20px", marginTop: "15px" }}>
                <RxEyeClosed
                  onClick={() => {
                    setViewConfirmPassword(true);
                  }}
                />
              </span>
            ) : (
              <span style={{ position: "absolute", marginLeft: "-40px", fontSize: "20px", marginTop: "18px" }}>
                <FaRegEye
                  onClick={() => {
                    setViewConfirmPassword(false);
                  }}
                />
              </span>
            )}
          </div>
        </div>
        <button className="pa-button" style={{ marginTop: "60px" }} onClick={() => handleSubmit()}>
          {`${reset ? "Reset" : "New"} Password`}
        </button>
        <div className="pa-transfi-banner" style={{ marginTop: "79px" }}>
          <p className="pa-powered-by">Powered by</p>
          <img style={{ width: "24px", height: "17px", marginLeft: "8px", marginRight: "8px" }} src={Logo} />
          <p className="pa-powered-transfi">TransFi</p>
        </div>
      </div>
    </Box>
  );
};

export default SetNewPassword;
