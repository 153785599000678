import React, { useState, useContext, useRef, useEffect } from "react";
import Loader from "react-js-loader";
import { useParams, Link } from "react-router-dom";

import { DownloadIcon } from "@chakra-ui/icons";
import { Badge, useDisclosure, Button } from "@chakra-ui/react";
import { Box } from "@chakra-ui/react";
import { startCase, uniqBy, isEmpty, round } from "lodash";
import moment from "moment-timezone";
import { BiRefresh, BiReset } from "react-icons/bi";
import { FiFilter } from "react-icons/fi";

import { NavBarContext } from "../../context/NavBarContext";
import { TimezoneContext } from "../../context/TimezoneContext";
import SplitView from "../../utils/SplitView";
import TimezoneFormatter from "../../utils/TimezoneFormatter";
import { statusMap, colorMap } from "../../utils/config";
import { getCountryData } from "../../utils/getCountries";
import { callApiWithToken } from "../../utils/utils";
import { getOrgRoles } from "../App/useToken";
import OrderSpliViewContainer from "./OrderSpliViewContainer";
import OrderFilter from "./helper/OrderFilter";

export default function ComplianceBuyOrders() {
    const { orderId } = useParams();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const defaultDate = { startDate: moment().subtract(2, "months"), endDate: moment().format("YYYY-MM-DD 23:59:59") };

    const [loading, setLoading] = useState(false);
    const [countryOptions, setCountryOptions] = useState([]);
    const [fiatCurrency, setFiatCurrency] = useState([]);
    const [paymentOptions, setPaymentOptions] = useState([]);
    const [formValues, setFormValues] = useState({});
    const [inputData, setInputData] = useState({});
    const [filters, setFilters] = useState({ dateRange: defaultDate, type: ["buy", "payin", "otc_payin"] });
    const [dateRange, setDateRange] = useState(defaultDate);
    const [email, setEmail] = useState("");

    const roles = getOrgRoles();
    const { selectedOrg } = useContext(NavBarContext);
    const { timezone } = useContext(TimezoneContext);
    const gridRef = useRef();

    useEffect(() => {
        getCountryList();
        getPaymentList();
    }, []);

    useEffect(() => {
        refreshGrid();
    }, [timezone]);

    const refreshGrid = () => {
        gridRef.current && gridRef.current.refreshGrid();
    };

    const downloadReport = () => {
        gridRef.current.downloadReport();
    };

    const getCountryList = async () => {
        const { countryList, fiatList } = await getCountryData();
        setCountryOptions(countryList);
        setFiatCurrency(fiatList);
    };

    const getPaymentList = async () => {
        const response = await callApiWithToken("GET", `/api/orders/getPaymentMethods`);
        const data = response.data;
        let list = data.map((country) => {
            return { value: country.name, label: country.name };
        });
        setPaymentOptions(uniqBy(list, (obj) => obj.value));
    };

    const numberFormatter = (value) => {
        const number = round(value, 2).toFixed(2)
        const result = parseFloat(number).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        })
        return result === "NaN" ? "0.00" : result;
    };

    const columns = [
        {
            Header: "Order Id",
            accessor: "orderId",
            width: "200",
            Cell: ({ value, row }) => {
                return (
                    <Link style={{ color: "#4ACDED", fontWeight: "bold" }} to={`/buy-order/${value}`} onClick={() => setEmail(row?.original?.email)}>
                        {value}
                    </Link>
                );
            },
            zIndex: 1,
            position: 'sticky',
            textAlign: 'center',
        },
        {
            Header: "Customer",
            accessor: "customer.formattedName",
            width: "130",
            show: roles.includes("transfi_admin") ? true : false,
            Cell: ({ value }) => {
                return startCase(value) || "NA"
            }
        },
        {
            Header: "Email",
            accessor: "email",
            width: "150",
            Cell: ({ value }) => {
                return value || "NA"
            }
        },
        {
            Header: "Status",
            accessor: "status",
            width: "130",
            Cell: ({ row }) => {
                const { status } = row?.original;
                const newStatus = statusMap["buy"][status] || status;
                const color = colorMap["buy"][status] || "blue";
                return <Badge colorScheme={color}> {newStatus} </Badge>;
            },
        },
        {
            Header: "Date Order Initiated",
            width: "120",
            Cell: ({ row }) => {
                return <TimezoneFormatter date={row?.original.createdAt} format={"MM/DD/YYYY"} />
            },
            color: "info",
        },
        {
            Header: "Time Order Initiated",
            width: "120",
            Cell: ({ row }) => {
                return <TimezoneFormatter date={row?.original.createdAt} format={'LT'} />
            },
            color: "info",
        },
        {
            Header: "Date Completed",
            width: "100",
            Cell: ({ row }) => {
                if (!isEmpty(row?.original?.timestamps)) {
                    if (row?.original?.status === "asset_settled") {
                        return <TimezoneFormatter date={row?.original?.timestamps?.assetSettledAt} format={"MM/DD/YYYY"} />
                    }
                    else {
                        return "NA"
                    }
                } else {
                    return "NA"
                }
            },
            color: "info",
        },
        {
            Header: "Time Completed",
            width: "100",
            Cell: ({ row }) => {
                if (!isEmpty(row?.original?.timestamps)) {
                    if (row?.original?.status === "asset_settled") {
                        return <TimezoneFormatter date={row?.original?.timestamps?.assetSettledAt} format={"LT"} />
                    }
                    else {
                        return "NA"
                    }
                }
                else {
                    return 'NA'
                }
            },
            color: "info",
        },
        {
            Header: "Fiat Payment Partner",
            width: "150",
            show: roles.includes("transfi_admin") ? true : false,
            color: "warning",
            Cell: ({ row }) => {
                if (!isEmpty(row?.original?.fiat?.partner)) {
                    return row?.original?.fiat?.partner
                } else {
                    return row?.original?.paymentGateway || "NA";
                }
            }
        },
        {
            Header: "Fiat Payment Method",
            width: "150",
            color: "warning",
            Cell: ({ row }) => {
                if (!isEmpty(row?.original?.fiat?.paymentName)) {
                    return row?.original?.fiat?.paymentName;
                } else {
                    return row?.original?.paymentName || "NA";
                }
            }
        },
        {
            Header: "Fiat Currency",
            width: "120",
            color: "warning",
            Cell: ({ row }) => {
                if (!isEmpty(row?.original?.fiat?.currency)) {
                    return row?.original?.fiat?.currency;
                } else {

                    return row?.original?.fiatTicker || "NA";
                }
            }
        },
        {
            Header: "Gross Fiat Amount (Received from user)",
            width: "230",
            color: "warning",
            Cell: ({ row }) => {
                if (!isEmpty(row?.original?.fiat?.orderAmount)) {
                    return numberFormatter(row?.original?.fiat?.orderAmount)
                } else {
                    return numberFormatter(row?.original?.fiatAmount) || "NA";
                }
            }
        },
        {
            Header: "Fiat Amount in USD",
            accessor: "metaData.usdAmount",
            width: "150",
            color: "warning",
            Cell: ({ value }) => {
                return value || "NA";
            }
        },
        {
            Header: "Crypto Payment Partner",
            accessor: "crypto.partner",
            width: "170",
            Cell: ({ value }) => startCase(value) || "Okx",
            show: roles.includes("transfi_admin") ? true : false,
            color: "info",
        },
        {
            Header: "Crypto Ticker",
            width: "100",
            Cell: ({ row }) => {
                if (!isEmpty(row?.original?.crypto?.currency)) {
                    return row?.original?.crypto?.currency;
                } else {
                    return row?.original?.cryptoTicker || "NA";
                }
            },
            color: "info",
        },
        {
            Header: "Crypto Units  Delivered",
            width: "150",
            Cell: ({ row }) => {
                if (!isEmpty(row?.original?.crypto?.orderAmount)) {
                    return numberFormatter(row?.original?.crypto?.orderAmount);
                } else {
                    return numberFormatter(row?.original?.cryptoAmount) || "NA";
                }
            },
            color: "info",
        },
        {
            Header: "Txn Hash",
            width: "200",
            accessor: "txnHash",
            color: "info",
            Cell: ({ value }) => {
                return value || "NA"
            }
        },
        {
            Header: "Crypto Wallet Address",
            width: "200",
            color: "info",
            Cell: ({ row }) => {
                if (!isEmpty(row?.original?.walletAddress)) {
                    return row?.original?.walletAddress;
                } else return "NA";
            }
        },
        {
            Header: "Api Key",
            accessor: "apiKey",
            width: "150",
            color: "info",
            Cell: ({ value }) => {
                return value || "NA";
            }
        },
    ];

    const condensedColumns = {
        Header: "Order Id",
        accessor: "orderId",
        Cell: ({ row, value }) => {
            const { status, userId, type } = row || {};
            const newStatus = statusMap?.[type]?.[status] || status;
            const color = colorMap?.[type]?.[status] || "blue";
            return (
                <>
                    <div>
                        <span color="#4ACDED">{value}</span>
                        <Badge style={{ marginLeft: "20px" }} colorScheme={color}>
                            {" "}
                            {startCase(newStatus)}{" "}
                        </Badge>
                    </div>
                    <div style={{ fontSize: "13px" }}>{userId}</div>
                </>
            );
        },
    };

    const handleOnReset = () => {
        setDateRange(defaultDate);
        setFilters({ dateRange: defaultDate, type: ["buy", "payin", "otc_payin"] });
        setFormValues({});
        setInputData({});
    };

    return !loading ? (
        <Box style={{ background: "white" }} heading="Orders">
            {orderId ? (
                <></>
            ) : (
                <div style={{ marginTop: "2%", marginRight: "5%" }}>
                    <div style={{ float: "right" }}>
                        <Button
                            leftIcon={<DownloadIcon />}
                            onClick={() => downloadReport()}
                            variant={"outline"}
                            colorScheme="blue"
                            style={{ margin: "0 8px" }}
                            size="sm"
                        >
                            Download Report
                        </Button>
                        <Button
                            leftIcon={<BiReset style={{ fontSize: "20px" }} />}
                            onClick={() => handleOnReset()}
                            variant={"outline"}
                            colorScheme="blue"
                            style={{ margin: "0 8px" }}
                            size="sm"
                        >
                            Reset
                        </Button>
                        <Button
                            leftIcon={<BiRefresh style={{ fontSize: "20px" }} />}
                            onClick={() => refreshGrid()}
                            variant={"outline"}
                            colorScheme="blue"
                            style={{ margin: "0 0px" }}
                            size="sm"
                        >
                            Refresh
                        </Button>
                        <Button
                            leftIcon={<FiFilter style={{ fontSize: "20px" }} />}
                            onClick={onOpen}
                            variant={"outline"}
                            colorScheme="blue"
                            style={{ margin: "0 8px" }}
                            size="sm"
                        >
                            Filter
                        </Button>
                    </div>
                    <br />
                </div>
            )}
            <SplitView
                ref={gridRef}
                columns={columns}
                condensed={orderId}
                condensedColumns={condensedColumns}
                dataUrl={"/api/compliance/list"}
                condensedUrl={"/compliance/buy-order"}
                filters={{ impersonatedOrg: selectedOrg, filters }}
                downloadReportUrl={"/api/report"}
                collection={"orders"}
                database={"transfi-nucleus"}
                gridFilters={{ filters, impersonatedOrg: selectedOrg }}
                headerDetails={{ types: { "createdAt": "date" } }}
                gridName={"Buy Compliance"}
            >
                <OrderSpliViewContainer orderId={orderId} email={email}/>
            </SplitView>

            <OrderFilter
                onClose={onClose}
                isOpen={isOpen}
                countryOptions={countryOptions}
                fiatCurrency={fiatCurrency}
                paymentOptions={paymentOptions}
                formValues={formValues}
                setFormValues={setFormValues}
                filters={filters}
                setFilters={setFilters}
                dateRange={dateRange}
                setDateRange={setDateRange}
                inputData={inputData}
                setInputData={setInputData}
                handleOnReset={handleOnReset}
                type={"buy"}
            ></OrderFilter>
        </Box>
    ) : (
        <Loader type="spinner-cub" bgColor={"#FFFFFF"} title={"Loading..."} color={"cyan.400"} size={100} />
    );
}
