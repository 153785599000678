/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';
import Loader from "react-js-loader";
import Select from "react-select";

import {
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Input,
    useToast,
    chakra,
    Stack,
    Alert,
    AlertIcon
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import * as Yup from "yup";

import { callApiWithToken } from "../../utils/utils";
import { getOrgId } from '../App/useToken';

const BalanceTransferModal = (props) => {
    const { isOpen, onClose, isLoading, refreshGrid } = props;
    const [prefundLimit, setPrefundLimit] = useState(0);
    const [loading, setLoading] = useState(false);
    const toast = useToast();

    const currencyOptions = [{ label: "PHP", value: "PHP" }, { label: "IDR", value: "IDR" }];

    const getPrefundLimit = async (currency) => {
        try {
            setLoading(true);
            const response = await callApiWithToken("POST", "/api/balance/prefundLimit", { transferCurrency: currency });
            setPrefundLimit(response?.prefundLimit);
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    const formik = useFormik({
        initialValues: {
            transferCurrency: '',
            amount: '',
        },
        validate: values => {
            const errors = {};
            if (values.amount > prefundLimit) errors.amount = `Balance transfer amount should be less than ${prefundLimit}`;
            return errors;
        },
        validationSchema: Yup.object().shape({
            transferCurrency: Yup.object().required("Currency is required"),
            amount: Yup.string().required("Amount is required"),
        }),
        onSubmit: async (formValues, { resetForm }) => {
            try {
                const response = await callApiWithToken("POST", "/api/settlement/createBalanceTransfer", {
                    sendCurrency: formValues?.transferCurrency?.value,
                    sendAmount: formValues?.amount,
                    orgId: getOrgId()
                });
                if (response?.message) {
                    toast({
                        title: 'Success',
                        description: response?.message,
                        status: 'success',
                        duration: 5000,
                        isClosable: true,
                        position: 'top-right'
                    })
                }
                resetForm();
                refreshGrid();
                onClose();
                formik.setValues({ transferCurrency: '', amount: '' });
                setPrefundLimit(0);
            } catch (err) {
                toast({
                    title: 'Error!',
                    description: err?.message || "Failed to fetch order details.",
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                    position: 'top-right'
                })
            }
        },
    });

    return (
        <chakra.form onSubmit={formik.handleSubmit} display="flex" flexGrow={1}>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />

                <ModalContent>
                    <ModalHeader>Balance Transfer</ModalHeader>
                    <ModalCloseButton />
                    {loading ? (
                        <Loader
                            type="spinner-cub"
                            bgColor={"#FFFFFF"}
                            title={"Loading..."}
                            color={"cyan.400"}
                            size={50}
                        />
                    ) :
                        <ModalBody pb={6}>
                            <Stack direction={"column"} justifyContent="space-between" flexGrow={1}>
                                <FormControl
                                    isRequired
                                    isInvalid={formik.touched.transferCurrency && formik.errors.transferCurrency}
                                >
                                    <FormLabel>Currency</FormLabel>
                                    <Select
                                        id="currency"
                                        isSearchable={false}
                                        value={formik.values.transferCurrency}
                                        placeholder='Select Currency'
                                        options={currencyOptions}
                                        onChange={(e) => {
                                            formik.setFieldValue("transferCurrency", e);
                                            getPrefundLimit(e?.value)
                                        }}
                                    />
                                    <FormErrorMessage>{formik.errors.transferCurrency}</FormErrorMessage>
                                </FormControl>
                                <FormControl
                                    isRequired
                                    isInvalid={formik.touched.amount && formik.errors.amount}
                                >
                                    <FormLabel htmlFor="name">Amount</FormLabel>
                                    <Input
                                        id="amount"
                                        type="number"
                                        value={formik.values.amount}
                                        onChange={formik.handleChange}
                                    />
                                    <FormErrorMessage>{formik.errors.amount}</FormErrorMessage>
                                </FormControl>
                                <Alert status='warning'>
                                    <AlertIcon />
                                    {`Available Prefund Balance : ${prefundLimit}`}
                                </Alert>
                            </Stack>
                        </ModalBody>
                    }
                    <ModalFooter>
                        <Button
                            isLoading={isLoading}
                            onClick={formik.handleSubmit}
                            colorScheme='blue' mr={3}
                        >
                            Create
                        </Button>
                        <Button
                            onClick={onClose}
                        >
                            Cancel
                        </Button>
                    </ModalFooter>

                </ModalContent>
            </Modal>
        </chakra.form>
    )
}

export default BalanceTransferModal;