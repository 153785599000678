import { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";

import { Badge, Stack, Text, Box, HStack, Button } from "@chakra-ui/react";
import { startCase, round } from 'lodash';
import moment from "moment";
import { BiReset } from 'react-icons/bi'

import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme

import { constants } from "../../constants";
import { NavBarContext } from '../../context/NavBarContext';
import EditModal from "../../utils/EditModal";
import Loader from '../../utils/Loader';
import TimezoneFormatter from "../../utils/TimezoneFormatter";
import Grid from '../../utils/agTable';
import { callApiWithTokenV2 } from '../../utils/utils';


const CryptoPayoutOrders = (props) => {
  const { fiatPayoutStatusMap, colorMap } = constants;
  const [refreshGrid, setRefreshGrid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [partners, setPartners] = useState([]);

  const { selectedOrg } = useContext(NavBarContext);

  useEffect(() => {
    setRefreshGrid(!refreshGrid);
  }, [selectedOrg]);

  useEffect(()=>{
    getPayoutPartners();
  },[])

  const USformatter = new Intl.NumberFormat('en-US');

  const getPayoutPartners = async () => {
    try{
      const responseData = await callApiWithTokenV2.get(`/api/orders/tfpay/partner`)
      const data = responseData?.data?.paymentPartners;
      const partners = data?.map((d)=>d?.paymentGateway)
      setPartners(partners);
    }catch(error){
      console.log(error);
    }
  }

  var filterParams = {
    comparator: (filterLocalDateAtMidnight, cellValue) => {
      var dateAsString = cellValue;
      if (dateAsString == null) return -1;
      var cellDate = new Date(cellValue);
      if (filterLocalDateAtMidnight.toLocaleDateString() === cellDate.toLocaleDateString()) {
        return 0;
      }
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      }
      if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
      return 0;
    },
    minValidYear: 2000,
  };

  const handleReset = () => {
    setRefreshGrid(!refreshGrid);
  };

  const getOrderData = async (filters) => {
    filters["type"] = "crypto_payout";
    const responseData = await callApiWithTokenV2.post(`/api/orders/tfpay/list?`, { impersonatedOrg: selectedOrg }, {
      params: {
        filters
      }
    })
    return responseData;
  }

  const columns = [
    {
      headerName: "Order ID TransFi",
      field: "orderId",
      getQuickFilterText: params => {
        return params.value.name;
      },
      customerVisibility: true,
      width: '250',
      cellRenderer: ({ value }) => {
        return <Link style={{ color: "#1A4FD6", fontWeight: "bold", fontSize: '14px' }} >{value}</Link>
      },
    },
    //{ headerName: "Order ID Customer", field: "cxId", customerVisibility: false, width: '200' },
    {
      headerName: "Customer Name", field: "customer.formattedName", enableRowGroup: true, customerVisibility: false, width: '200', cellRenderer: ({ value }) => (
        <Text>{value ? startCase(value) : 'N/A'}</Text>
      )
    },
    {
      headerName: "User ID/Email", field: "customer.email", enableRowGroup: true, customerVisibility: true, width: '300', cellRenderer: ({ value }) => (
        <Text>{value ? value : 'N/A'}</Text>
      )
    },
    {
      headerName: "Status",
      field: "status",
      customerVisibility: true,
      enableRowGroup: true,
      filterParams: {
        applyMiniFilterWhileTyping: true,
      },
      editable: ({ data }) => data.status !== 'fund_settled',
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: ['draft', 'initiated', 'fund_processing', 'fund_settled', 'fund_failed', 'asset_deposited', 'asset_deposit_failed', 'partial_asset_settled', 'asset_failed', 'asset_settled', 'asset_settle_failed', 'onHold', 'rejected'],
      },
      cellRenderer: ({ value }) => {
        const newStatus = fiatPayoutStatusMap[value] || value;
        const color = colorMap[value];
        return <Badge width={"100%"} textAlign={"center"} colorScheme={color}>{newStatus}</Badge>;
      },
    },
    { headerName: "Recipient Name", field: "contactName", enableRowGroup: true, customerVisibility: true, width: '200' },
    { headerName: "Recipient Email", field: "recipientEmail", enableRowGroup: true, customerVisibility: true, width: '200' },
    {
      headerName: "Purpose of Transaction", field: "purposeCode", enableRowGroup: true, customerVisibility: true, cellRenderer: ({ value }) => (
        <Text>{value ? startCase(value) : 'N/A'}</Text>
      )
    },
    {
      headerName: "Nature of Sender", field: "", enableRowGroup: true, customerVisibility: false, cellRenderer: ({ value }) => (
        <Text>{value ? startCase(value) : 'Individual'}</Text>
      )
    },
    {
      headerName: "Nature of Recipient", field: "contactList.type", enableRowGroup: true, customerVisibility: false, cellRenderer: ({ value }) => (
        <Text>{value ? startCase(value) : 'N/A'}</Text>
      )
    },
    {
      headerName: "Jurisdiction of Sender", field: "", enableRowGroup: true, customerVisibility: false, width: '200',
      cellRenderer: ({ value }) => (
        <Text>{value ? startCase(value) : 'N/A'}</Text>
      )
    },
    { headerName: "Jurisdiction of Recipient", field: "contactList.country", enableRowGroup: true, customerVisibility: false, width: '200' },
    {
      headerName: "Initiated Date",
      field: "createdAt",
      filterParams: filterParams,
      customerVisibility: true,
      enableRowGroup: false,
      cellDataType: 'date',
      width: '220',
      filter: 'agDateColumnFilter',
      cellRenderer: ({ value }) => (
        <Text>{value ? <TimezoneFormatter date={value} format={"DD-MMM-YYYY @ h:mm A"} /> : "N/A"}</Text>
      ),
    },
    {
      headerName: "Completed Date",
      field: "timestamps.fundSettledAt",
      filter: 'agDateColumnFilter',
      filterParams: filterParams,
      customerVisibility: true,
      enableRowGroup: false,
      cellDataType: 'date',
      width: '220',
      cellRenderer: ({ value, data }) => {return(
        <>
        {data?.status !== 'fund_settled'?
        <EditModal value={value} columnName="Completed Date" data={data} field={'timestamps.fundSettledAt'} type={'crypto_payout'} handleReset={handleReset}/>
        : <Text>{value ? <TimezoneFormatter date={value} format={"DD-MMM-YYYY @ h:mm A"} /> : "N/A"}</Text>
        }</>
        )},
    },
    {
      headerName: "Cut off Time",
      field: "timestamps.cutOffAt",
      filterParams: filterParams,
      customerVisibility: true,
      enableRowGroup: false,
      cellDataType: 'date',
      width: '220',
      filter: 'agDateColumnFilter',
      cellRenderer: ({ value, data }) => {return(
        <>
        {data?.status !== 'fund_settled'?
        <EditModal value={value} columnName="Cut off Time" data={data} field={'timestamps.cutOffAt'} type={'crypto_payout'} handleReset={handleReset}/>
        : <Text>{value ? <TimezoneFormatter date={value} format={"DD-MMM-YYYY @ h:mm A"} /> : "N/A"}</Text>
        }</>
        )},
    },
    {
      headerName: "Batch Settlement Time",
      field: "timestamps.settlementSettledAt",
      filterParams: filterParams,
      customerVisibility: true,
      enableRowGroup: false,
      cellDataType: 'date',
      width: '220',
      filter: 'agDateColumnFilter',
      cellRenderer: ({ value, data }) => {return(
        <>
        {data?.status !== 'fund_settled'?
        <EditModal value={value} columnName="Batch Settlement Time" data={data} field={'timestamps.settlementSettledAt'} type={'crypto_payout'} handleReset={handleReset}/>
        : <Text>{value ? <TimezoneFormatter date={value} format={"DD-MMM-YYYY @ h:mm A"} /> : "N/A"}</Text>
        }</>
        )},
    },
    {
      headerName: "Payout Payment Partner", field: "crypto.partner", editable: ({ data }) => data.status !== 'fund_settled', 
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: { values: partners },
      customerVisibility: false, cellRenderer: ({ value }) => (
        <Text>{value ? startCase(value) : 'N/A'}</Text>
      )
    },
    {
      headerName: "Partner Order ID", field: "crypto.orderId", editable: ({ data }) => data.status !== 'fund_settled', cellEditor: 'agTextCellEditor', customerVisibility: false, cellRenderer: ({ value }) => (
        <Text>{value ? value : 'N/A'}</Text>
      )
    },
    {
      headerName: "Payment Method", field: "paymentFormattedName", editable: ({ data }) => data.status !== 'fund_settled', cellEditor: 'agTextCellEditor', customerVisibility: false, cellRenderer: ({ value }) => (
        <Text>{value ? startCase(value) : 'N/A'}</Text>
      )
    },
    {
      headerName: "Currency", field: "withdrawCurrency", customerVisibility: true, cellRenderer: ({ value }) => (
        <Text>{value ? value : "N/A"}</Text>
      )
    },
    {
      headerName: "Payout Date",
      field: "timestamps.fundSettledAt",
      filter: 'agDateColumnFilter',
      filterParams: filterParams,
      customerVisibility: true,
      enableRowGroup: false,
      cellDataType: 'date',
      width: '220',
      cellRenderer: ({ value, data }) => {return(
        <>
        {data?.status !== 'fund_settled'?
        <EditModal value={value} columnName="Payout Date" data={data} field={'timestamps.fundSettledAt'} type={'crypto_payout'} handleReset={handleReset}/>
        : <Text>{value ? <TimezoneFormatter date={value} format={"DD-MMM-YYYY @ h:mm A"} /> : "N/A"}</Text>
        }</>
        )},
    },
    {
      headerName: "Payout amount", field: "withdrawAmount", aggFunc: 'sum', cellDataType: 'number', filter: 'agNumberColumnFilter', customerVisibility: true, cellRenderer: ({ value }) => (
        <Text>{value ? USformatter.format(value) : '0'}</Text>
      )
    },
    {
      headerName: "Payment Partner Fees", field: "paymentPartnerFees", filter: 'agNumberColumnFilter', cellDataType: 'number', customerVisibility: false, cellRenderer: ({ value }) => (
        <Text>{value ? USformatter.format(value) : '0'}</Text>
      )
    },
    {
      headerName: "Amount Deducted from Partner", field: "amountDeductedFromPartner", filter: 'agNumberColumnFilter', cellDataType: 'number', customerVisibility: false, cellRenderer: ({ value }) => (
        <Text>{value ? USformatter.format(value) : 'N/A'}</Text>
      )
    },
    {
      headerName: "Processing fees %", field: "fiat.tfFee", filter: 'agNumberColumnFilter', cellDataType: 'number', customerVisibility: false, cellRenderer: ({ value }) => (
        <Text>{value ? (value * 100) + '%' : '0'}</Text>
      )
    },
    {
      headerName: "Processing fees Amount", field: "processingFeeAmount", filter: 'agNumberColumnFilter', cellDataType: 'number', customerVisibility: false, cellRenderer: ({ value }) => (
        <Text>{value ? USformatter.format(value) : '0'}</Text>
      )
    },
    {
      headerName: "GTV Payout Currency", field: "gtvPayoutCurrency", filter: 'agNumberColumnFilter', cellDataType: 'number', customerVisibility: false, cellRenderer: ({ value }) => (
        <Text>{value ? USformatter.format(value) : '0'}</Text>
      )
    },
    {
      headerName: "Conversion Rate to USD", field: "conversionRateToUsd", filter: 'agNumberColumnFilter', customerVisibility: false, cellDataType: 'number', cellRenderer: ({ value }) => {
        return <Text>{value ? round(value, 8) : '0'}</Text>
      }
    },
    {
      headerName: "Gross TXN Amount on USD if zero forex markup", field: "grossTxnIfZeroForexMarkup", filter: 'agNumberColumnFilter', customerVisibility: false, cellDataType: 'number', cellRenderer: ({ value }) => (
        <Text>{value ? USformatter.format(value) : '0'}</Text>
      )
    },
    {
      headerName: "FX Markup %", field: "fiat.markupFee", cellDataType: 'number', customerVisibility: false, filter: 'agNumberColumnFilter', cellRenderer: ({ value }) => (
        <Text>{value ? USformatter.format(Math.abs(value) * 100) + "%" : '0'}</Text>
      )
    },
    {
      headerName: "Conversion rate Used by TransFi", field: "conversionRateUsed", filter: 'agNumberColumnFilter', cellDataType: 'number', customerVisibility: false, cellRenderer: ({ value }) => (
        <Text>{value ? round(value, 6) : '0'}</Text>
      )
    },
    {
      headerName: "Gross TXN Amount on USD", field: "grossTxnAmountOnUSD", filter: 'agNumberColumnFilter', aggFunc: 'sum', customerVisibility: true, cellDataType: 'number', cellRenderer: ({ value }) => (
        <Text>{value ? USformatter.format(value) : '0'}</Text>
      )
    },
    {
      headerName: "TransFi Forex gain USD", field: "transfiForexGainUsd", filter: 'agNumberColumnFilter', aggFunc: 'sum', cellDataType: 'number', customerVisibility: false, cellRenderer: ({ value }) => (
        <Text>{value ? USformatter.format(value) : '0'}</Text>
      )
    },
    {
      headerName: "Fiat Delivered To User(In USD)", field: "fiatDeliveredToUserInUsd", filter: 'agNumberColumnFilter', aggFunc: 'sum', customerVisibility: true, cellDataType: 'number', cellRenderer: ({ value }) => (
        <Text>{value ? USformatter.format(value) : '0'}</Text>
      )
    },
    {
      headerName: "Processing Fees Amount(In USD)", field: "processingFeesAmountInUsd", filter: 'agNumberColumnFilter', aggFunc: 'sum', cellDataType: 'number', customerVisibility: false, cellRenderer: ({ value }) => (
        <Text>{value ? USformatter.format(value) : '0'}</Text>
      )
    },
    {
      headerName: "Payment Partner Fees(In USD)", field: "paymentPartnerFeesInUsd", filter: 'agNumberColumnFilter', aggFunc: 'sum', cellDataType: 'number', customerVisibility: false, cellRenderer: ({ value }) => (
        <Text>{value ? USformatter.format(value) : '0'}</Text>
      )
    },
    {
      headerName: "Prefunding Currency", field: "depositCurrency", filter: 'agNumberColumnFilter', aggFunc: 'sum', cellDataType: 'number', customerVisibility: false, cellRenderer: ({ value }) => (
        <Text>{value ? value : 'N/A'}</Text>
      )
    },
    {
      headerName: "Conversion Rate USD to Prefunding Currency", field: "conversionRatePrefundCurrency", filter: 'agNumberColumnFilter', aggFunc: 'sum', cellDataType: 'number', customerVisibility: false, cellRenderer: ({ value }) => (
        <Text>{value ? USformatter.format(value) : 'N/A'}</Text>
      )
    },
    {
      headerName: "Amount Deducted from Prefunding balance", field: "amountDeductedFromPrefundingBalance", filter: 'agNumberColumnFilter', aggFunc: 'sum', cellDataType: 'number', customerVisibility: false, cellRenderer: ({ value }) => (
        <Text>{value ? USformatter.format(value) : 'N/A'}</Text>
      )
    }
  ];

  return (
    <Stack
      direction={"column"}
      justifyContent={"space-between"}
      flexGrow={1}
      p={5}
    >

      {!isLoading ? <Box >
        <HStack pb={5} justifyContent={"flex-end"}>
          <Button
            leftIcon={<BiReset style={{ fontSize: "20px" }} />}
            color={"#525C76"}
            variant={"outline"}
            borderColor={"#d2d2d2"}
            _hover={{ bg: "#ffffff" }}
            onClick={handleReset}
          >
            Reset
          </Button>
        </HStack>
        <Grid columns={columns} key={refreshGrid.toString()} getOrderData={getOrderData} type="crypto_payout" />
      </Box> : <Loader />}
    </Stack>
  );
};

export default CryptoPayoutOrders;