import React, { useState, useEffect } from 'react';
import Loader from "react-js-loader";
import { Link } from "react-router-dom";

import { Badge, Stat, StatLabel, StatNumber } from '@chakra-ui/react';
import { capitalize } from 'lodash';
import moment from 'moment';

import { callApiWithToken } from '../../../utils/utils';

export default function TransactionDetails(props) {
    const { orderId } = props || {};
    const [loading, setLoading] = useState(false);
    const [fiatTransactionData, setFiatTransactionData] = useState({});
    const [cryptoTransactionData, setCryptoTransactionData] = useState({});

    useEffect(() => {
        getTransactionDetails();
    }, [orderId]);

    const getTransactionDetails = async () => {
        setLoading(true);
        const response = await callApiWithToken("GET", `/api/orders/getTransactionDetails?orderId=${orderId}`) || {};
        const { fiatTransaction = {}, cryptoTransaction = {} } = response;
        setFiatTransactionData(fiatTransaction);
        setCryptoTransactionData(cryptoTransaction);
        setLoading(false);
    }

    const colorMap = {
        "pending": "yellow",
        'success': "green",
        "failed": "red",
    }

    const fiatRowComponent = (label, value) => {
        return <>
            <div style={{ display: 'inline-flex', width: '100%' }}>
                <div style={{ float: 'left', width: '50%' }}>
                    <Stat float={'left'}>
                        <StatLabel>{label}</StatLabel>
                    </Stat>
                </div>
                <div style={{ float: 'right', width: '50%', marginRight: '10px' }}>
                    <Stat float={'right'}>
                        <StatLabel><b>{value || "N/A"}</b></StatLabel>
                    </Stat>
                </div>
            </div>
        </>
    }

    const cryptoRowComponent = (label, value) => {
        return <>
            <div style={{ display: 'inline-flex', width: '100%', marginLeft: '10px' }}>
                <div style={{ float: 'left', width: '50%' }}>
                    <Stat float={'left'}>
                        <StatLabel>{label}</StatLabel>
                    </Stat>
                </div>
                <div style={{ float: 'right', width: '50%' }}>
                    <Stat float={'right'}>
                        <StatLabel><b>{value || "N/A"}</b></StatLabel>
                    </Stat>
                </div>
            </div>
        </>
    }

    return !loading ? <>
        <div style={{ width: '100%' }}>
            <div style={{ float: 'left', width: '75%' }}>
                <Stat float={'left'} marginBottom={'20px'}>
                    <StatNumber fontSize={'md'}>Fiat Transaction</StatNumber>
                </Stat>
                {fiatRowComponent('Transaction Id', fiatTransactionData?.txId)}
                {fiatRowComponent('Date', fiatTransactionData?.createdAt ? moment(fiatTransactionData?.createdAt).format('lll') : "N/A")}
                {fiatRowComponent('User Id', fiatTransactionData?.userId ? <Link style={{ color: '#4ACDED', fontWeight: 'bold' }} to={`/users/${fiatTransactionData?.userId}`} >{fiatTransactionData?.userId}</Link> : "N/A")}
                {fiatRowComponent('Status', fiatTransactionData?.status ? <Badge colorScheme={colorMap[fiatTransactionData?.status] || "grey"}> {capitalize(fiatTransactionData?.status)} </Badge> : "N/A")}
                {fiatRowComponent('External Id', fiatTransactionData?.externalId)}
                {fiatRowComponent('PM Id', fiatTransactionData?.pmId)}
                {fiatTransactionData?.metaData?.vaCode && fiatRowComponent('Virtual Account Number', fiatTransactionData?.metaData?.vaCode)}
            </div>
            <div style={{ borderLeft: "solid rgba(0,0,0,0.1)" }}></div>
            <div style={{ float: 'left', width: '75%' }}>
                <Stat float={'left'} marginBottom={'20px'} mt="10">
                    <StatNumber fontSize={'md'}>Crypto Transaction</StatNumber>
                </Stat>
                {cryptoRowComponent('Transaction Id', cryptoTransactionData?.txId)}
                {cryptoRowComponent('Date', cryptoTransactionData?.createdAt ? moment(cryptoTransactionData?.createdAt).format('lll') : "N/A")}
                {cryptoRowComponent('User Id', cryptoTransactionData?.userId ? <Link style={{ color: '#4ACDED', fontWeight: 'bold' }} to={`/users/${cryptoTransactionData?.userId}`} >{cryptoTransactionData?.userId}</Link> : "N/A")}
                {cryptoRowComponent('Status', cryptoTransactionData?.status ? <Badge colorScheme={colorMap[cryptoTransactionData?.status] || "grey"}> {capitalize(cryptoTransactionData?.status)} </Badge> : "N/A")}
                {cryptoRowComponent('External Id', cryptoTransactionData?.externalId)}
            </div>
        </div>
    </> :
        <Loader type="spinner-cub" bgColor={"#FFFFFF"} title={"Loading..."} color={'#FFFFFF'} size={100} />
}
