import React, { useState } from 'react';
import Loader from "react-js-loader";

import { Tab, TabList, Tabs, TabPanels, TabPanel } from '@chakra-ui/react';

import { getCxRoles, getOrgRoles } from "../App/useToken";
import Comment from './helper/Comment';
import CryptoMonitoringDetails from './helper/CryptoMonitoringDetails';
import Errors from './helper/Errors';
import OrderDetails from './helper/OrderDetails';
import TransactionDetails from './helper/TransactionDetails';
import Webhook from "./helper/Webhook";

export default function OrderSpliViewContainer(props) {
    const { orderId } = props || {};
    const [loading, setLoading] = useState(false);
    const [orderData, setOrderData] = useState([]);
    const cxRoles = getCxRoles();
    const orgRoles = getOrgRoles();

    return !loading ? <>
        <Tabs colorScheme='cyan'>
            <TabList p={2} style={{ justifyContent: 'center' }}>
                <Tab>Details</Tab>
                <Tab>Transactions</Tab>
                <Tab>Crypto Monitoring</Tab> 
               {orgRoles.includes("transfi_admin") && <Tab>Webhooks</Tab>  }
               <Tab>Comments</Tab> 
               {orgRoles.includes("transfi_admin") && <Tab>Errors</Tab>  }         
            </TabList>
            <TabPanels>
                <TabPanel>
                    <OrderDetails orderId={orderId} orderData={orderData} setOrderData={setOrderData} />
                </TabPanel>
                <TabPanel>
                    <TransactionDetails orderId={orderId} />
                </TabPanel>
                <TabPanel>
                    <CryptoMonitoringDetails orderId={orderId} />
                </TabPanel>
                {orgRoles.includes("transfi_admin") && 
                <TabPanel>
                    <Webhook orderId={orderId} />
                </TabPanel>}
                <TabPanel>
                    <Comment orderId={orderId} />
                </TabPanel> 
                {orgRoles.includes("transfi_admin") && 
                <TabPanel>
                    <Errors orderId={orderId} />
                </TabPanel>}  
            </TabPanels>
        </Tabs>
    </> :
        <Loader type="spinner-cub" bgColor={"#FFFFFF"} title={"Loading..."} color={'#FFFFFF'} size={100} />
}
