import React, { useEffect, useState } from "react";

import { getDataFromSession, saveDataToSession } from "../utils/session-storing-utils";

export const EnvTypeContext = React.createContext();

const envDict = {
  'live':'Live',
  'sandbox':'Sandbox',
}

export function EnvTypeProvider({ children }) {
    const [env, setEnv] = useState(envDict[getDataFromSession('connectionData','envType')]);

    const onEnvChange = (val)=>{
        if(val==='sandbox'){
            setEnv('Sandbox')
        }else{
            setEnv('Live')
        }
        saveDataToSession('connectionData','envType',val)
    }

  const store = {env, onEnvChange };
  return (
    <EnvTypeContext.Provider value={store}>{children}</EnvTypeContext.Provider>
  );
}
