import React, { useState, useContext, useRef, useEffect } from "react";
import Loader from "react-js-loader";
import { useParams, Link } from "react-router-dom";

import { Badge, useDisclosure, Button, HStack } from "@chakra-ui/react";
import { Box } from "@chakra-ui/react";
import { startCase, uniqBy, isEmpty, get, round } from "lodash";
import moment from "moment";
import { BiRefresh, BiReset } from "react-icons/bi";
import { BiEdit, BiShow } from "react-icons/bi";
import { BsCartPlus } from "react-icons/bs";
import { FiFilter } from "react-icons/fi";

import { NavBarContext } from "../../context/NavBarContext";
import { TimezoneContext } from "../../context/TimezoneContext";
import SplitView from "../../utils/SplitView";
import TimezoneFormatter from "../../utils/TimezoneFormatter";
import { statusMap, colorMap } from "../../utils/config";
import { getCountryData } from "../../utils/getCountries";
import { callApiWithToken } from "../../utils/utils";
import { getCxRoles, getOrgRoles } from "../App/useToken";
import OrderSpliViewContainer from "./OrderSpliViewContainer";
import EditOtcPayinModal from "./helper/EditOtcPayinModal";
import OrderFilter from "./helper/OrderFilter";
import OtcPayinOrdersModal from "./helper/OtcPayinOrdersModal";
import ViewOtcPayinModal from "./helper/ViewOtcPayinModal";

export default function OtcPayinOrders() {
    const { orderId } = useParams();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const { isOpen, onOpen, onClose } = useDisclosure();
    const {
        isOpen: isOpenViewDetailsModal,
        onOpen: onOpenViewDetailsModal,
        onClose: onCloseViewDetailsModal,
    } = useDisclosure({ defaultIsOpen: false });
    const {
        isOpen: isOpenEditModal,
        onOpen: onOpenEditModal,
        onClose: onCloseEditModal,
    } = useDisclosure({ defaultIsOpen: false });
    const { isOpen: isOpenOtcOrders, onOpen: onOpenOtcOrders, onClose: onCloseOtcOrders } = useDisclosure({ defaultIsOpen: false });
    const [countryOptions, setCountryOptions] = useState([]);
    const [fiatCurrency, setFiatCurrency] = useState([]);
    const [paymentOptions, setPaymentOptions] = useState([]);
    const [formValues, setFormValues] = useState({});
    const [inputData, setInputData] = useState({});
    const defaultDate = { startDate: moment().subtract(2, "months"), endDate: moment().format("YYYY-MM-DD 23:59:59") };
    const [filters, setFilters] = useState({ dateRange: defaultDate });
    const [dateRange, setDateRange] = useState(defaultDate);
    const [viewDetails, setViewDetails] = useState("");
    const [details, setDetails] = useState("");

    const roles = getOrgRoles();
    const cxRoles = getCxRoles();

    const { selectedOrg } = useContext(NavBarContext);
    const { timezone } = useContext(TimezoneContext);
    const gridRef = useRef();
    const showColumn = roles.includes("transfi_admin");

    useEffect(() => {
        getCountryList();
        getPaymentList();
    }, []);

    useEffect(() => {
        refreshGrid();
    }, [timezone]);

    const refreshGrid = () => {
        gridRef.current && gridRef.current.refreshGrid();
    };

    const getCountryList = async () => {
        const { countryList, fiatList } = await getCountryData();
        setCountryOptions(countryList);
        setFiatCurrency(fiatList);
    };

    const getPaymentList = async () => {
        const response = await callApiWithToken("GET", `/api/orders/getPaymentMethods`);
        const data = response.data;
        let list = data.map((country) => {
            return { value: country.name, label: country.name };
        });
        setPaymentOptions(uniqBy(list, (obj) => obj.value));
    };

    const numberFormatter = (value) => {
        const number = round(value, 2).toFixed(2)
        const result = parseFloat(number).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        })
        return result === "NaN" ? "0.00" : result;
    };

    const handleOnClickView = (value) => {
        onOpenViewDetailsModal();
        setViewDetails(value);
    };

    const handleOnClickEdit = (value) => {
        onOpenEditModal();
        setDetails(value);
    };

    const columns = [
        {
            Header: "Order Id",
            accessor: "orderId",
            width: "200",
            Cell: ({ value }) => {
                return (
                    <Link style={{ color: "#4ACDED", fontWeight: "bold" }} to={`/otc-payin-orders/${value}`}>
                        {value}
                    </Link>
                );
            },
            zIndex: 1,
            position: 'sticky',
            textAlign: 'center',
        },
        {
            Header: "Customer Name",
            accessor: "customer.formattedName",
            width: "200",
            Cell: ({ value }) => {
                return value || "N/A"
            }
        },
        {
            Header: "Money Received From",
            accessor: "customer.customerName",
            width: "200",
            Cell: ({ value }) => {
                return value || "N/A"
            }
        },
        {
            Header: "Email",
            accessor: "email",
            width: "230",
            Cell: ({ value }) => {
                return value || "N/A"
            }
        },
        {
            Header: "Created By",
            accessor: "createdBy",
            width: "230",
            Cell: ({ value }) => {
                return value || "N/A"
            },
            show: showColumn
        },
        {
            Header: "Status",
            accessor: "status",
            width: "130",
            Cell: ({ row }) => {
                const { status, type } = row?.original;
                const newStatus = statusMap[type][status] || status;
                const color = colorMap[type][status] || "blue";
                return <Badge colorScheme={color}> {newStatus} </Badge>;
            },
        },
        {
            Header: "Date Order Initiated",
            width: "120",
            Cell: ({ row }) => {
                return <TimezoneFormatter date={row?.original.createdAt} format={"MM/DD/YYYY"} />
            },
            color: "info",
        },
        {
            Header: "Time Order Initiated",
            width: "120",
            Cell: ({ row }) => {
                return <TimezoneFormatter date={row?.original.createdAt} format={'LT'} />
            },
            color: "info",
        },
        {
            Header: "Date Completed",
            width: "100",
            Cell: ({ row }) => {
                if (!isEmpty(row?.original?.timestamps)) {
                    if (row?.original?.status === "asset_settled") {
                        return <TimezoneFormatter date={row?.original?.timestamps?.assetSettledAt} format={"MM/DD/YYYY"} />
                    }
                    else {
                        return "N/A"
                    }
                } else {
                    return "N/A"
                }
            },
            show: showColumn,
            color: "info",
        },
        {
            Header: "Time Completed",
            width: "100",
            Cell: ({ row }) => {
                if (!isEmpty(row?.original?.timestamps)) {
                    if (row?.original?.status === "asset_settled") {
                        return <TimezoneFormatter date={row?.original?.timestamps?.assetSettledAt} format={"LT"} />
                    }
                    else {
                        return "N/A"
                    }
                }
                else {
                    return 'N/A'
                }
            },
            show: showColumn,
            color: "info",
        },
        {
            Header: "Fiat Payment Partner",
            width: "150",
            color: "warning",
            Cell: ({ row }) => {
                if (!isEmpty(row?.original?.fiat?.partner)) {
                    return row?.original?.fiat?.partner
                } else {
                    return row?.original?.paymentGateway
                }
            },
            show: showColumn
        },
        {
            Header: "Fiat Partner Order ID",
            accessor: "fiat.orderId",
            width: "150",
            color: "warning",
            Cell: ({ value }) => value || "-",
            show: showColumn
        },
        {
            Header: "Fiat Payment Method",
            width: "150",
            color: "warning",
            Cell: ({ row }) => {
                if (!isEmpty(row?.original?.fiat?.paymentName)) {
                    return row?.original?.fiat?.paymentName;
                } else {

                    return row?.original?.paymentName;
                }
            }
        },
        {
            Header: "Fiat Currency",
            width: "120",
            color: "warning",
            Cell: ({ row }) => {
                if (!isEmpty(row?.original?.fiat?.currency)) {
                    return row?.original?.fiat?.currency;
                } else {

                    return row?.original?.fiatTicker;
                }
            }
        },
        {
            Header: "Gross Fiat Amount (Received from the user)",
            width: "230",
            color: "warning",
            Cell: ({ row }) => {
                if (!isEmpty(row?.original?.fiat?.orderAmount)) {
                    return numberFormatter(row?.original?.fiat?.orderAmount)
                } else {
                    return numberFormatter(row?.original?.fiatAmount)
                }
            }
        },
        {
            Header: "Amt. Received In Sub Account",
            width: "150",
            Cell: ({ row }) => {
                const fiatAmount = row?.original?.fiat?.orderAmount ? Number(row?.original?.fiat?.orderAmount || 0) : Number(row?.original?.fiatAmount || 0);
                const buyRate = Number(get(row.original, "fiat.partnerFee", 0))
                if (row?.original?.fiat?.partnerFee) {
                    if (row?.original?.fiat?.partnerFee === 0 || row?.original?.fiat?.partnerFee === "NaN") {
                        return numberFormatter(fiatAmount);
                    } else {
                        return numberFormatter(fiatAmount - buyRate);
                    }
                } else {
                    return numberFormatter(fiatAmount);
                }
            },
            color: "warning",
            show: showColumn
        },
        {
            Header: "Payment partner fees",
            width: "150",
            color: "warning",
            Cell: ({ row }) => {
                if (row?.original?.fiat?.partnerFee) {
                    if (row?.original?.fiat?.partnerFee === 0 || row?.original?.fiat?.partnerFee === "NaN") {
                        return "0.00";
                    } else {
                        return numberFormatter(row?.original?.fiat?.partnerFee);
                    }
                } else {
                    return "0.00";
                }

            },
            show: showColumn
        },
        {
            Header: "Processing fees %",
            // accessor: "fiat.processingFees",
            width: "120",
            Cell: ({ row }) => {
                const processingRate = get(row?.original, "fiat.processingRate", 0)
                const tfFee = Number(get(row.original, "fiat.tfFee", 0))
                const cxFee = Number(get(row.original, "fiat.cxFee", 0))
                const fiatAmount = row?.original?.fiat?.orderAmount ? Number(row?.original?.fiat?.orderAmount || 0) : row?.original?.fiatAmount;
                if (processingRate > 0) {
                    return numberFormatter(processingRate) + "%";
                }
                if (tfFee >= 0 && cxFee >= 0 && isEmpty(processingRate)) {
                    const totalFees = tfFee + cxFee;
                    return numberFormatter((totalFees / fiatAmount) * 100) + "%";
                }
                else {
                    return "N/A"
                }

            },
            show: showColumn,
            color: "warning",
        },
        {
            Header: "Processing fees amt.",
            width: "180",
            Cell: ({ row }) => {
                if (isEmpty(row?.original?.fiat?.processingFee)) {
                    const tfFee = Number(get(row.original, "fiat.tfFee", 0))
                    const cxFee = Number(get(row.original, "fiat.cxFee", 0))
                    const processingFee = (tfFee + cxFee).toFixed(2)
                    return numberFormatter(processingFee)
                } else {
                    const processingFee = Number(row?.original?.fiat?.processingFee).toFixed(2)
                    return numberFormatter(processingFee)
                }

            },
            show: showColumn,
            color: "warning",
        },
        {
            Header: "Net Amount",
            width: "100",
            color: "warning",
            Cell: ({ row }) => {
                const partnerFee = Number(get(row?.original, "fiat.partnerFee", 0));
                const fiatAmount = Number(get(row?.original, "fiat.orderAmount", 0));
                const processingFee = Number(get(row?.original, "fiat.processingFee", 0))
                const subAcc = Number(fiatAmount) - Number(partnerFee);
                return numberFormatter(subAcc - processingFee);
            },
            show: showColumn
        },
        {
            Header: "Sub Account Receipt Date",
            width: "180",
            color: "warning",
            Cell: ({ row }) => {
                if (!isEmpty(row?.original?.fiat?.settlementTime)) {
                    return <TimezoneFormatter date={row?.original?.fiat?.settlementTime} format={"MM/DD/YYYY"} />

                } else {
                    return "N/A"
                }
            },
            show: showColumn
        },
        {
            Header: "Conversion Rate to USD",
            width: "150",
            color: "warning",
            Cell: ({ row }) => {
                if (isEmpty(row?.original?.fiat?.conversionRate)) {
                    return "0.00";
                } else {
                    return numberFormatter(1 / row?.original?.fiat?.conversionRate);
                }

            },
            show: showColumn
        },
        {
            Header: "GTV USD",
            width: "150",
            color: "warning",
            Cell: ({ row }) => {
                if (isEmpty(row?.original?.fiat?.conversionRate)) {
                    return "0.00";
                } else {
                    const fiatAmount = row?.original?.fiat?.orderAmount ? Number(row?.original?.fiat?.orderAmount || 0) : row?.original?.fiatAmount;
                    const conversionRate = round(Number(row?.original?.fiat?.conversionRate || 0), 6)
                    return numberFormatter(fiatAmount * conversionRate);
                }
            },
            show: showColumn
        },
        {
            Header: "Amount received in Sub account(USD)",
            width: "180",
            color: "warning",
            Cell: ({ row }) => {
                if (isEmpty(row?.original?.fiat?.conversionRate)) {
                    return "0.00";
                } else {
                    const fiatAmount = row?.original?.fiat?.orderAmount ? Number(row?.original?.fiat?.orderAmount || 0) : row?.original?.fiatAmount;
                    const buyRate = Number(row?.original?.fiat?.partnerFee || 0)
                    const subAcc = fiatAmount - buyRate;
                    const conversionRate = Number(row?.original?.fiat?.conversionRate)
                    return numberFormatter(subAcc * conversionRate);
                }
            },
            show: showColumn
        },
        {
            Header: "Crypto Monitoring Status",
            width: "130",
            color: "info",
            Cell: ({ row }) => {
                const { metaData } = row?.original;
                const { monitoringResults } = metaData;
                const reviewAnswer = monitoringResults?.crypto?.reviewAnswer === "GREEN" ? "Approved" : "Rejected"
                console.log(crypto?.reviewAnswer);
                const color = reviewAnswer === "Approved" ? "green" : "red";
                return <Badge colorScheme={color}> {reviewAnswer} </Badge>;
            },
            show: showColumn
        },
        {
            Header: "Crypto Payment Partner",
            accessor: "crypto.partner",
            width: "170",
            Cell: ({ value }) => startCase(value) || "-",
            color: "info",
            show: showColumn
        },
        {
            Header: "Crtypo Payment Order ID",
            accessor: "crypto.orderId",
            width: "170",
            Cell: ({ value }) => value || "NA",
            color: "info",
            show: showColumn
        },
        {
            Header: "USDC / USDT sold",
            accessor: "crypto.conversionAmount",
            width: "130",
            Cell: ({ value }) => numberFormatter(value) || "NA",
            color: "info",
            show: showColumn
        },
        {
            Header: "Crypto Ticker",
            width: "100",
            Cell: ({ row }) => {
                if (!isEmpty(row?.original?.crypto?.currency)) {
                    return row?.original?.crypto?.currency;
                } else {
                    return row?.original?.cryptoTicker;
                }
            },
            color: "info",
            show: showColumn
        },
        {
            Header: "Crypto Purchase Rate",
            width: "150",
            Cell: ({ row }) => {
                if (row?.original?.crypto?.conversionRate) {
                    return numberFormatter(row?.original?.crypto?.conversionRate);
                } else {
                    return numberFormatter(row?.original?.cryptoUnitPrice);
                }
            },
            color: "info",
            show: showColumn
        },
        {
            Header: "Crypto Units  Delivered",
            width: "150",
            Cell: ({ row }) => {
                if (!row?.original?.crypto?.orderAmount) {
                    return numberFormatter(row?.original?.crypto?.orderAmount);
                } else {
                    return numberFormatter(row?.original?.cryptoAmount);
                }
            },
            color: "info",
            show: showColumn
        },
        {
            Header: "Gas fees Actual ",
            width: "120",
            Cell: ({ row }) => { return numberFormatter(row?.original?.fees?.networkFee) || "0" },
            color: "info",
            show: showColumn
        },
        {
            Header: "Net (of gas fees) Crypto paid",
            width: "180",
            Cell: ({ row }) => {
                const cryptoAmount = !row?.original?.crypto?.orderAmount ? Number(row?.original?.crypto?.orderAmount || 0) : Number(row?.original?.cryptoAmount || 0);
                const gasFee = Number(row?.original?.fees?.networkFee || 0);
                const netAmount = cryptoAmount - gasFee;
                return numberFormatter(netAmount) || "0"
            },
            color: "info",
            show: showColumn
        },
        {
            Header: "Txn Hash",
            width: "200",
            accessor: "txnHash",
            color: "info",
            Cell: ({ value }) => {
                return value || "N/A"
            },
            show: showColumn
        },
        {
            Header: "Crypto Wallet Address",
            width: "200",
            color: "info",
            Cell: ({ row }) => {
                if (row?.original?.type === "sell") {
                    if (!isEmpty(row?.original?.metaData?.sendersWalletAddress)) {
                        return row?.original?.metaData?.sendersWalletAddress[0];
                    } else {
                        return "N/A";
                    }
                }
                else {
                    if (!isEmpty(row?.original?.walletAddress)) {
                        return row?.original?.walletAddress;
                    } else return "N/A";
                }

            },
            show: showColumn
        },
        {
            Header: "Gross profit on transaction (In USD)",
            width: "200",
            color: "info",
            Cell: ({ row }) => {
                const fiatAmount = row?.original?.fiat?.orderAmount ? Number(row?.original?.fiat?.orderAmount || 0) : row?.original?.fiatAmount;
                const buyRate = Number(row?.original?.fiat?.partnerFee || 0)
                const subAcc = fiatAmount - buyRate;
                const conversionRate = Number(row?.original?.fiat?.conversionRate);
                const usdAmount = Number(subAcc * conversionRate || 0);
                const usdCryptoAmount = Number(row?.original?.crypto?.conversionAmount || 0);
                const profit = Number(usdAmount - usdCryptoAmount || 0);
                return numberFormatter(profit) || "0";
            },
            show: showColumn
        },
        {
            Header: "Other Charges",
            width: "180",
            color: "info",
            Cell: ({ row }) => {
                return (row?.original?.fiat?.feeCharged ? row?.original?.fiat?.feeCharged : <>
                    <BiEdit
                        style={{
                            fontSize: "22px",
                            color: "#2b6cb0",
                            cursor: "pointer",
                        }}
                        onClick={() => handleOnClickEdit(row?.original)}
                    ></BiEdit>
                </>

                );
            },
            show: showColumn
        },
    ];

    const condensedColumns = {
        Header: "Order Id",
        accessor: "orderId",
        Cell: ({ row, value }) => {
            const { status, userId, type } = row || {};
            const newStatus = statusMap[type][status] || status;
            const color = colorMap[type][status] || "blue";
            return (
                <>
                    <div>
                        <span color="#4ACDED">{value}</span>
                        <Badge style={{ marginLeft: "20px" }} colorScheme={color}>
                            {" "}
                            {startCase(newStatus)}{" "}
                        </Badge>
                    </div>
                    <div style={{ fontSize: "13px" }}>{userId}</div>
                </>
            );
        },
    };

    const handleOnReset = () => {
        setDateRange(defaultDate);
        setFilters({ dateRange: defaultDate });
        setFormValues({});
        setInputData({});
    };

    return !loading ? (
        <Box style={{ background: "white" }} heading="Orders" whiteSpace="nowrap">
            {orderId ? (
                <></>
            ) : (
                <div style={{ marginTop: "2%" }}>
                    <div style={{ float: "right" }}>
                        {roles.includes("transfi_admin") && 
                        <Button
                        leftIcon={<BsCartPlus style={{ fontSize: "20px" }} />}
                        onClick={onOpenOtcOrders}
                        variant={"outline"}
                        colorScheme="blue"
                        style={{ margin: "0 8px" }}
                        size="sm"
                    >
                        Create OTC Orders
                    </Button>
                        }
                        <Button
                            leftIcon={<BiReset style={{ fontSize: "20px" }} />}
                            onClick={() => handleOnReset()}
                            variant={"outline"}
                            colorScheme="blue"
                            style={{ margin: "0 8px" }}
                            size="sm"
                        >
                            Reset
                        </Button>
                        <Button
                            leftIcon={<BiRefresh style={{ fontSize: "20px" }} />}
                            onClick={() => refreshGrid()}
                            variant={"outline"}
                            colorScheme="blue"
                            style={{ margin: "0 0px" }}
                            size="sm"
                        >
                            Refresh
                        </Button>
                        <Button
                            leftIcon={<FiFilter style={{ fontSize: "20px" }} />}
                            onClick={onOpen}
                            variant={"outline"}
                            colorScheme="blue"
                            style={{ margin: "0 8px" }}
                            size="sm"
                        >
                            Filter
                        </Button>
                    </div>
                    <br />
                </div>
            )}
            <SplitView
                ref={gridRef}
                columns={columns}
                condensed={orderId}
                condensedColumns={condensedColumns}
                dataUrl={"/api/orders/list"}
                condensedUrl={"/otc-payin-orders"}
                filters={{ impersonatedOrg: selectedOrg, filters, type: "otc_payin" }}
                selectRow={false}
            // onChangeSelectedRow={selectionCallback}
            // initialState={{ selectedRowIds: selectedRows }}
            >
                <OrderSpliViewContainer orderId={orderId} />
            </SplitView>

            <OrderFilter
                onClose={onClose}
                isOpen={isOpen}
                countryOptions={countryOptions}
                fiatCurrency={fiatCurrency}
                paymentOptions={paymentOptions}
                formValues={formValues}
                setFormValues={setFormValues}
                filters={filters}
                setFilters={setFilters}
                dateRange={dateRange}
                setDateRange={setDateRange}
                inputData={inputData}
                setInputData={setInputData}
                handleOnReset={handleOnReset}
                type={"otc_payin"}
            ></OrderFilter>
            <OtcPayinOrdersModal
                isLoading={loading}
                isOpen={isOpenOtcOrders}
                onOpen={onOpenOtcOrders}
                onClose={onCloseOtcOrders}
                refreshGrid={refreshGrid}
            />
            <ViewOtcPayinModal
                isOpen={isOpenViewDetailsModal}
                onOpen={onOpenViewDetailsModal}
                onClose={onCloseViewDetailsModal}
                selectedRow={viewDetails}
                refreshGrid={refreshGrid}
            />
            {details && <EditOtcPayinModal
                isOpen={isOpenEditModal}
                onOpen={onOpenEditModal}
                onClose={onCloseEditModal}
                selectedRow={details}
                refreshGrid={refreshGrid}
            />}
        </Box>
    ) : (
        <Loader type="spinner-cub" bgColor={"#FFFFFF"} title={"Loading..."} color={"cyan.400"} size={100} />
    );
}