import React, { useState, useRef, useEffect, useContext } from "react";
import Loader from "react-js-loader";

import { ChevronDownIcon } from "@chakra-ui/icons";
import { SettingsIcon } from "@chakra-ui/icons";
import { Box, Button, useDisclosure, Badge, Text } from "@chakra-ui/react";
import { MenuButton, MenuList, MenuItem, Menu } from "@chakra-ui/react";
import { startCase, upperCase } from "lodash";
import moment from "moment";
import { BiRefresh, BiReset } from "react-icons/bi";
import { BiShow } from "react-icons/bi";
import { FiFilter } from "react-icons/fi";
import { HiPlusSm } from "react-icons/hi";

import { EnvTypeContext } from "../../context/EnvTypeContext";
import { NavBarContext } from "../../context/NavBarContext";
import TransfiGrid from "../../utils/TransfiGrid";
import { callApiWithToken } from "../../utils/utils";
import { getOrgRoles, getCxRoles } from "../App/useToken";
import ApproveModal from "./ApproveModal";
import NewPreFundModal from "./NewPreFundModal";
import PrefundFilter from "./PrefundFilter";
import SettlePrefund from "./SettlePrefund";
import ViewDetailModal from "./ViewDetailModal";
import ViewModal from "./ViewModal";

export default function PreFund(props) {
    const [loading, setLoading] = useState(false);
    const [selectedRow, setSelectedRow] = useState("");
    const [viewDetails, setViewDetails] = useState("");
    const [inputData, setInputData] = useState({});
    const [formValues, setFormValues] = useState({});
    const [totalData, setTotalData] = useState({});
    const defaultDate = { startDate: moment(new Date()).subtract(2, "months"), endDate: moment(new Date()) };
    const defaultFilters = {
        dateRange: defaultDate,
        partnerType: "customer",
        partnerName: "binance",
        currency: "PHP",
    }
    const [filters, setFilters] = useState(defaultFilters);
    const [dateRange, setDateRange] = useState(defaultDate);

    const orgRoles = getOrgRoles();
    const cxRole = getCxRoles();
    const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: false });
    const {
        isOpen: isOpenApproveModal,
        onOpen: onOpenApproveModal,
        onClose: onCloseApproveModal,
    } = useDisclosure({ defaultIsOpen: false });
    const {
        isOpen: isOpenViewModal,
        onOpen: onOpenViewModal,
        onClose: onCloseViewModal,
    } = useDisclosure({ defaultIsOpen: false });
    const {
        isOpen: isOpenViewDetailsModal,
        onOpen: onOpenViewDetailsModal,
        onClose: onCloseViewDetailsModal,
    } = useDisclosure({ defaultIsOpen: false });

    const {
        isOpen: isOpenFilterModal,
        onOpen: onOpenFilterModal,
        onClose: onCloseFilterModal,
    } = useDisclosure({ defaultIsOpen: false });
    const {
        isOpen: isOpenSettleModal,
        onOpen: onOpenSettleModal,
        onClose: onCloseSettleModal,
    } = useDisclosure({ defaultIsOpen: false });

    const { env } = useContext(EnvTypeContext);
    const { selectedOrg } = useContext(NavBarContext);

    const gridRef = useRef();

    useEffect(() => {
		getOverview();
	}, [env]);

    const handleOnClick = (value) => {
        onOpenViewDetailsModal();
        setViewDetails(value);
    };

    const refreshGrid = () => {
        gridRef.current.refreshGrid();
    };

    const getOverview = async () => {
		try {
			setLoading(true);
			const response = await callApiWithToken("POST", `/api/prefund/aggregrateBalance`) || {};
			const { status = false, message, data } = response;
            console.log(response);

			if (status) {
				const { total } = data || {};
				setTotalData(total);
			} else {
				throw new Error(message);
			}
			setLoading(false);
		} catch (e) {
			setLoading(false);
		}
	}

    const columns = [
        {
            Header: "Date",
            accessor: "createdAt",
            width: "170",
            Cell: ({ value }) => {
                return moment(value).format("lll");
            },
        },
        {
            Header: "Prefund ID",
            accessor: "pfId",
            width: "170",
        },
        {
            Header: "Status",
            accessor: "status",
            width: "170",
            Cell: ({ value }) => {
                const color = value === "in_progress" ? "yellow" : "green";
                return <Badge colorScheme={color}> {startCase(value)} </Badge>;
            },
        },
        {
            Header: "Transfer Currency",
            accessor: "sendCurrency",
            width: "120",
        },
        {
            Header: "Amount",
            width: "120",
            Cell: (cellProps) => {
                const { row = {}, data = {} } = cellProps;
                const { index } = row;
                const record = data?.[index];
                if( ["GHS","ZMW","TZS","UGX"].includes(record?.sendCurrency)) {
                    return record?.sendAmountUsdt
                } else {
                    return record?.sendAmount   
                }
            },
        },
        {
            Header: "Deposit Currency",
            Cell: (cellProps) => {
                const { row = {}, data = {} } = cellProps;
                const { index } = row;
                const record = data?.[index];
                if( ["GHS","ZMW","TZS","UGX"].includes(record?.sendCurrency)) {
                    return record?.sendCurrencyUsdt
                } else {
                    return record?.convertedCurrency   
                }
            },
        },
        {
            Header: "Type",
            Cell: (cellProps) => {
                const { row = {}, data = {} } = cellProps;
                const { index } = row;
                const record = data?.[index];
                return ["PHP", "IDR","GHS","ZMW","TZS","UGX"].includes(record.convertedCurrency) ? (
                    <Text color="green"> {upperCase("Balance Prefund")} </Text>
                ) : (
                    <Text color="red"> {upperCase("Settle Prefund")} </Text>
                )
            },
        },
    ];
    if (orgRoles.includes("transfi_admin")) {
        columns.push({
            Header: "Actions",
            Cell: (cellProps) => {
                const { row = {}, data = [] } = cellProps;

                const { index } = row;
                const record = data?.[index];
                const status = row?.original?.status;
                return (
                    <Menu>
                        <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                            <SettingsIcon />
                        </MenuButton>
                        <MenuList>
                            <MenuItem
                                onClick={() => handleOnClick(row?.original)}
                            >
                                View
                            </MenuItem>
                            {status !== "completed" && (
                                <MenuItem
                                    onClick={() => {
                                        setSelectedRow(record);
                                        onOpenApproveModal();
                                    }}
                                >
                                    Approve
                                </MenuItem>
                            )}
                        </MenuList>
                    </Menu>
                );
            },
        });
    }
    if (!orgRoles.includes("transfi_admin")) {
        columns.push({
            Header: "View Details",
            accessor: "id",
            width: "130",
            Cell: ({ row }) => {
                return (
                    <BiShow
                        style={{
                            fontSize: "22px",
                            color: "#2b6cb0",
                            cursor: "pointer",
                            margin: "0 20%",
                        }}
                        onClick={() => handleOnClick(row?.original)}
                    ></BiShow>
                );
            },
        });
    }

    const Card = (data) => {
        const { currency, currencyDetails } = data || {}
        return <>
            <div className="balance-card-zoom" style={{ width: '250px', padding: '15px 20px', boxShadow: '0 4px 10px 2px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)', borderRadius: '4px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                        <h3 style={{ fontSize: '14px', fontWeight: '600', }}>{`${currency} Balance`}</h3>
                        <div style={{ fontSize: '26px', marginTop: '8px', fontWeight: '400' }}> <b>{currency} {Number(currencyDetails?.prefundingBalance).toLocaleString()}</b></div>
                    </div>
                </div>
            </div>
        </>
    }

    const renderCards = () => {
        const cards = Object.keys(totalData).sort();
        return <div style={{ display: 'flex', justifyContent: 'left', padding: '20px 10px', flexWrap: 'wrap', gap: '4.45%' }}>
            {cards.map(value => {
                return <div ><Card currency={value} currencyDetails={totalData[value]} /></div>
            })}
        </div>
    }

    const handleOnReset = () => {
        setDateRange(defaultDate);
        setFilters(defaultFilters);
        setFormValues({});
        setInputData({});
    };

    return !loading ? (
        <Box style={{ background: "white" }}>
            <div style={{ float: "right", marginTop: "2%" }}>
            <Button
                    leftIcon={<HiPlusSm />}
                    colorScheme="blue"
                    style={{ margin: "0 8px" }}
                    size="sm"
                    onClick={onOpenSettleModal}
                    isDisabled={!orgRoles.includes("transfi_admin")  ? true : false}
                >
                    Settle Prefund
                </Button>
                <Button
                    leftIcon={<HiPlusSm />}
                    colorScheme="blue"
                    style={{ margin: "0 8px" }}
                    size="sm"
                    onClick={onOpen}
                    // isDisabled={cxRole != "admin" ? true : false}
                >
                    Initiate Prefund
                </Button>
                <Button
                    leftIcon={<BiReset style={{ fontSize: "20px" }} />}
                    variant={"outline"}
                    colorScheme="blue"
                    onClick={() => handleOnReset()}
                    style={{ margin: "0 8px" }}
                    size="sm"
                >
                    Reset
                </Button>
                <Button
                    leftIcon={<BiRefresh style={{ fontSize: "20px" }} />}
                    onClick={() => refreshGrid()}
                    variant={"outline"}
                    colorScheme="blue"
                    style={{ margin: "0 0px" }}
                    size="sm"
                >
                    Refresh
                </Button>
                <Button
                    leftIcon={<FiFilter style={{ fontSize: "20px" }} />}
                    onClick={onOpenFilterModal}
                    variant={"outline"}
                    colorScheme="blue"
                    style={{ margin: "0 8px" }}
                    size="sm"
                >
                    Filter
                </Button>
            </div>
            {renderCards()}
            <TransfiGrid
                ref={gridRef}
                columns={columns}
                dataUrl={"/api/prefund/list"}
                filters={{ filters, impersonatedOrg: selectedOrg }}
            ></TransfiGrid>
            <SettlePrefund 
             isLoading={loading}
             isOpen={isOpenSettleModal}
             onOpen={onOpenSettleModal}
             onClose={onCloseSettleModal}
             refreshGrid={refreshGrid}
            />
            <NewPreFundModal
                isLoading={loading}
                isOpen={isOpen}
                onOpen={onOpen}
                onClose={onClose}
                refreshGrid={refreshGrid}
            />
            <ViewDetailModal
                isOpen={isOpenViewDetailsModal}
                onOpen={onOpenViewDetailsModal}
                onClose={onCloseViewDetailsModal}
                selectedRow={viewDetails}
            />
            <ApproveModal
                isLoading={loading}
                isOpen={isOpenApproveModal}
                onOpen={onOpenApproveModal}
                onClose={onCloseApproveModal}
                selectedRow={selectedRow}
                refreshGrid={refreshGrid}
            />
            <ViewModal
                isLoading={loading}
                isOpen={isOpenViewModal}
                onOpen={onOpenViewModal}
                onClose={onCloseViewModal}
                selectedRow={selectedRow}
                refreshGrid={refreshGrid}
            />
            <PrefundFilter
                isOpen={isOpenFilterModal}
                onClose={onCloseFilterModal}
                inputData={inputData}
                setInputData={setInputData}
                dateRange={dateRange}
                setDateRange={setDateRange}
                filters={filters}
                setFilters={setFilters}
                formValues={formValues}
                setFormValues={setFormValues}
                handleOnReset={handleOnReset}
            />
        </Box >
    ) : (
        <Loader
            type="spinner-cub"
            bgColor={"#FFFFFF"}
            title={"Loading..."}
            color={"cyan.400"}
            size={100}
        />
    );
}
