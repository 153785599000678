import React, { createContext, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';

import axios from 'axios';
import { isEmpty } from "lodash";

import { saveDataToSession, getDataFromSession } from "../utils/session-storing-utils";

export const LanguageContext = createContext();

export function LanguageProvider({ children }) {
    const { i18n } = useTranslation();
    const currentLang = i18n.language;
    const [defaultLanguage, setDefaultLanguage] = useState(getDataFromSession("connectionData", "language") || currentLang);
    const [language, setLanguage] = useState(defaultLanguage);
    const grpOrgId = window.sessionStorage.getItem("grpOrgId");

    useEffect(() => {
        const fetchData = async () => {
            const ipResponse = await axios.get('https://api.ipify.org?format=json');
            const ip = ipResponse.data.ip;
            const country = !isEmpty(ip) && await getUserCountry({ ip });
            if (country === "China" && grpOrgId === "OG-220923101501950") {
                onLanguageChange("zh")
            } else {
                onLanguageChange("en")
            }
        };

        fetchData();
    }, []);

    const getUserCountry = async ({ ip }) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_TF_SERVER_BASE_URL}/exchanges/common/getUserCountry?ip=${ip}`);

            return response?.data?.data?.country;
        } catch (err) {
            return err;
        }
    };

    const onLanguageChange = (val) => {
        setLanguage(val);
        saveDataToSession("connectionData", "language", val);
        i18n.changeLanguage(val);
    }
    const store = { language, onLanguageChange, defaultLanguage, setDefaultLanguage };
    return (
        <LanguageContext.Provider value={store}>{children}</LanguageContext.Provider>
    );
}
