import { AgGridReact } from "ag-grid-react";

import "ag-grid-community/styles/ag-grid.min.css"; // Core CSS
import "ag-grid-community/styles/ag-theme-quartz.min.css"; // Theme
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { getOrgId } from "../components/App/useToken";
import { callApiWithTokenV2 } from "./utils";

import './style.css';

export default function Grid(props) {
    const { columns, getOrderData, setSelectedData, select, type, selectedData } = props;
    const quickFilterText = '';
    const gridRef = useRef();

    const [gridApi, setGridApi] = useState(null);

    // Apply settings across all columns
    const defaultColDef = useMemo(() => ({
        filter: true, // Enable filtering on all columns
        floatingFilter: true,
    }));

    const quickFilterParser = useCallback((quickFilter) => {
        let quickFilterParts = [];
        let lastSpaceIndex = -1;
        const isQuote = (index) => quickFilter[index] === '"';
        const getQuickFilterPart = (lastSpaceIndex, currentIndex) => {
            const startsWithQuote = isQuote(lastSpaceIndex + 1);
            const endsWithQuote = isQuote(currentIndex - 1);
            const startIndex =
                startsWithQuote && endsWithQuote
                    ? lastSpaceIndex + 2
                    : lastSpaceIndex + 1;
            const endIndex =
                startsWithQuote && endsWithQuote ? currentIndex - 1 : currentIndex;
            return quickFilter.slice(startIndex, endIndex);
        };
        for (let i = 0; i < quickFilter.length; i++) {
            const char = quickFilter[i];
            if (char === ' ') {
                if (!isQuote(lastSpaceIndex + 1) || isQuote(i - 1)) {
                    quickFilterParts.push(getQuickFilterPart(lastSpaceIndex, i));
                    lastSpaceIndex = i;
                }
            }
        }
        if (lastSpaceIndex !== quickFilter.length - 1) {
            quickFilterParts.push(
                getQuickFilterPart(lastSpaceIndex, quickFilter.length)
            );
        }
        return quickFilterParts;
    }, []);

    const onGridReady = (params) => {
        gridRef.current = params.api;
        setGridApi(params);
        // register datasource with the grid
        const datasource = {
            async getRows(params) {
                try {
                    const { startRow, endRow, filterModel, sortModel, valueCols, pivotMode, pivotCols, groupKeys, rowGroupCols } = params.request;
                    const filters = {
                        startRow, endRow, filterModel, sortModel,
                        valueCols, pivotMode, pivotCols, groupKeys, rowGroupCols, type
                    };
                    const { data } = await getOrderData(filters);
                    params.success({
                        rowData: data.list,
                        pivotResultFields: data.pivotFields,
                    });
                }
                catch (err) {
                    console.error(err);
                    params.fail();
                }
            }
        };
        params.api.setServerSideDatasource(datasource);
    }
    // ...

    const handleCellValueChanged = async (event) => {
        const { data, colDef, newValue } = event;
        try {
            const updatedData = {
                field: colDef.field,
                newValue,
                type,
                prefundType: data.type,
                customerEmail: data?.customer?.email
            };

            await callApiWithTokenV2.post(`/api/orders/tfpay/update?orderId=${data.orderId}`, updatedData);

        } catch (error) {
            console.error('Error updating settlement time:', error);
        }
    };

    const onRowSelected = useCallback(
        (event) => {
            const selectedRowData = event.node.data;
            setSelectedData((prevSelectedData) => {
                const isDuplicate = prevSelectedData.some(
                    (data) => data._id === selectedRowData._id
                );
                if (event.node.isSelected() && !isDuplicate) {
                    return [...prevSelectedData, selectedRowData];
                }
                if (!event.node.isSelected() && isDuplicate) {
                    return prevSelectedData.filter(
                        (data) => data._id !== selectedRowData._id
                    );
                }
                return prevSelectedData;
            });
        },
        [setSelectedData]
    );

    return (
        // Container
        <div className="ag-theme-quartz" style={{ height: 650 }} >
            {/* The AG Grid component */}
            <AgGridReact
                onGridReady={onGridReady}
                ref={gridRef}
                rowModelType='serverSide'
                serverSideStoreType='partial'
                columnDefs={columns}
                defaultColDef={defaultColDef}
                pagination={true}
                onCellValueChanged={handleCellValueChanged}
                quickFilterText={quickFilterText}
                quickFilterParser={quickFilterParser}
                sideBar={false}
                suppressMenuHide={true}
                enableAdvancedFilter={true}
                suppressMultiRangeSelection={true}
                enableRangeSelection={true}
                rowGroupPanelShow={'always'}
                groupIncludeFooter={true}
                rowSelection={'multiple'}
                paginationPageSize={20}
                cacheBlockSize={20}
                onRowSelected={onRowSelected}
            />
        </div>
    )
}