import React, { useContext, useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Loader from "react-js-loader";
import { useParams, Link } from "react-router-dom";

import { DownloadIcon } from "@chakra-ui/icons";
import { Badge, Button, useDisclosure, Box } from "@chakra-ui/react";
import { startCase, find, get, uniqBy, upperFirst } from "lodash";
import moment from "moment";
import { BiRefresh, BiReset } from "react-icons/bi";
import { FiFilter } from "react-icons/fi";

import { NavBarContext } from "../../context/NavBarContext";
import { TimezoneContext } from "../../context/TimezoneContext";
import SplitView from "../../utils/SplitView";
import TimezoneFormatter from "../../utils/TimezoneFormatter";
import { callApiWithToken } from "../../utils/utils";
import { getOrgRoles } from "../App/useToken";
import AddComments from "./AddComments";
import UpdateSource from "./UpdateSource";
import UserSpliViewContainer from "./UserSpliViewContainer";
import ViewComments from "./ViewComments";
import UserFilter from "./helper/UserFilter";

export default function Users() {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [commentsLoading, setCommentsLoading] = useState(false);
    const [selectedRow, setselectedRow] = useState({});
    const [comments, setComments] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [bulkUpdate, setBulkUpdate] = useState(false);
    const [fiatList, setFiatList] = useState([]);
    const [countryOptions, setCountryOptions] = useState([]);
    const [inputData, setInputData] = useState({});
    const [formValues, setFormValues] = useState({});
    const defaultDate = { startDate: moment(new Date()).subtract(2, "months"), endDate: moment(new Date()) };
    const [filters, setFilters] = useState({ dateRange: defaultDate });
    const [dateRange, setDateRange] = useState(defaultDate);

    const { selectedOrg } = useContext(NavBarContext);
    const { timezone } = useContext(TimezoneContext);

    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isOpenViewComments, onOpen: onOpenViewComments, onClose: onCloseViewComments } = useDisclosure();
    const { isOpen: isOpenAddComments, onOpen: onOpenAddComments, onClose: onCloseAddComments } = useDisclosure();
    const { isOpen: isOpenUpdateSource, onOpen: onOpenUpdateSource, onClose: onCloseUpdateSource } = useDisclosure();

    const gridRef = useRef();

    const { userId } = useParams();
    const roles = getOrgRoles();
    const grpOrgId = sessionStorage.getItem('grpOrgId');
    const orgId = sessionStorage.getItem('orgId');
    const showColumn = roles.includes("transfi_admin");

    useEffect(() => {
        getFiatList();
    }, []);

    useEffect(() => {
        refreshGrid();
    }, [timezone]);

    const colorMap = {
        trial_user: "orange",
        kyc_initiated: "purple",
        kyc_blocked: "red",
        kyc_expired: "red",
        kyc_rejected: "red",
        address_pending: "orange",
        sof_pending: "orange",
        kyc_pending: "orange",
        kyc_manual_review: "orange",
        kyc_pep_review: "orange",
        kyc_success: "green",
        active: "green",
        inactive: "red",
        kyc_failed: "red",
    };

    const getFiatList = async () => {
        const response = await callApiWithToken("GET", `/api/widget/configList?name=fiat&module=buy_crypto`);
        const data = response?.data[0]?.config;
        const list = data.map((country) => {
            return { value: country.countryCode2Digit, label: country.countryCode2Digit };
        });
        setFiatList(data);
        setCountryOptions(uniqBy(list, (obj) => obj.value));
    };

    const refreshGrid = () => {
        gridRef.current.refreshGrid();
    };

    const columns = [
        {
            Header: t("users:owner"),
            accessor: "customer.email",
            show: (orgId === "OG-220923101501950" || grpOrgId === "OG-220923101501950") ? true : false,
            width: "150",
            color: "warning",
            Cell: ({ value }) => {
                return value || "NA"
            },
        },
        {
            Header: t("users:created_at"),
            accessor: "createdAt",
            width: "200",
            color: "warning",
            Cell: ({ value }) => {
                return <TimezoneFormatter date={value} format={"lll"} />
            },
        },
        {
            Header: t("users:user_id"),
            accessor: "userId",
            width: "210",
            color: "warning",
            Cell: ({ value }) => {
                return (
                    <Link style={{ color: "#4ACDED", fontWeight: "bold" }} to={`/users/${value}`}>
                        {value}
                    </Link>
                );
            },
        },
        {
            Header: t("users:name"),
            accessor: "name",
            width: "200",
            color: "warning",
            Cell: ({ row }) => {
                const { name, firstName, lastName } = row?.original;
                return upperFirst(name) || firstName && upperFirst(firstName + "" + lastName) || "NA";
            },
        },
        {
            Header: t("users:email"),
            accessor: "email",
            width: "230",
            color: "warning",
            Cell: ({ value }) => {
                return value || "N/A"
            }
        },
        {
            Header: t("users:country"),
            accessor: "country",
            color: "warning",
            Cell: ({ value }) => {
                const response = find(fiatList, { countryCode2Digit: value });
                return <strong>{response?.symbol + " " + response?.countryCode2Digit}</strong>;
            },
        },
        {
            Header: "Coinify Vendor",
            accessor: "partners",
            color: "warning",
            show: (orgId === "OG-220923101501950" || grpOrgId === "OG-220923101501950") ? false : true,
            Cell: ({ row }) => {
                const { partners = [] } = row?.original;
                const status = get(find(partners, { vendor: "Coinify" }), "status", "na");
                const color = colorMap[status] || "grey";
                return <Badge colorScheme={color}> {startCase(status)} </Badge>;
            },
        },
        {
            Header: "Sumsub Vendor",
            accessor: "vendors",
            color: "warning",
            show: (orgId === "OG-220923101501950" || grpOrgId === "OG-220923101501950") ? false : true,
            Cell: ({ row }) => {
                const { values = {} } = row;
                const { partners = [] } = values;
                const status = get(find(partners, { vendor: "Sumsub" }), "status", "na");
                const color = colorMap[status] || "grey";
                return <Badge colorScheme={color}> {startCase(status)} </Badge>;
            },
        },
        {
            Header: t("users:status"),
            accessor: "status",
            color: "warning",
            Cell: ({ value }) => {
                const color = colorMap[value] || "purple";
                return <Badge colorScheme={color}> {startCase(value)} </Badge>;
            },
        },
        {
            Header: t("users:kyc_failure_message"),
            accessor: "failureMessage",
            color: "warning",
            Cell: ({ value }) => {
                return value || "N/A";
            },
        }
    ];

    const handleOnReset = () => {
        setDateRange(defaultDate);
        setFilters({ dateRange: defaultDate });
        setFormValues({});
        setInputData({});
    };

    const downloadReport = () => {
        gridRef.current.downloadReport();
    };

    const condensedColumns = {
        Header: "User Id",
        accessor: "userId",
        Cell: ({ row, value }) => {
            const { status, email } = row || {};
            const color = colorMap[status] || "grey";
            return (
                <>
                    <div>
                        <span color="#4ACDED">{value}</span>
                        <Badge style={{ marginLeft: "20px" }} colorScheme={color}>
                            {" "}
                            {startCase(status)}{" "}
                        </Badge>
                    </div>
                    <div>{email}</div>
                </>
            );
        },
    };

    const selectionCallback = React.useCallback((ids) => setSelectedRows(ids), []);

    return !loading ? (
        <Box>
            {userId ? (
                <></>
            ) : (
                <>
                    <div style={{ margin: "2% 0" }}>
                        <div style={{ float: "right", marginRight: "5%" }}>
                            {/* {orgRoles.includes("transfi_admin") ? (
                                <Menu>
                                    <MenuButton
                                        as={Button}
                                        rightIcon={<ChevronDownIcon />}
                                        disabled={!selectedRows.length} >
                                        Actions
                                    </MenuButton>
                                    <MenuList>
                                        <MenuItem onClick={() => openBulkAddComments()}>Add Comment</MenuItem>
                                        <MenuItem onClick={() => openBulkUpdateSource()}>Update Source</MenuItem>
                                    </MenuList>
                                </Menu>
                            ) : ( <></>)} */}

                            {showColumn && <Button
                                leftIcon={<DownloadIcon />}
                                onClick={() => downloadReport()}
                                variant={"outline"}
                                colorScheme="blue"
                                style={{ margin: "0 8px" }}
                                size="sm"
                            >
                                Download Report
                            </Button>}
                            <Button
                                leftIcon={<BiReset style={{ fontSize: "20px" }} />}
                                variant={"outline"}
                                colorScheme="blue"
                                onClick={() => handleOnReset()}
                                style={{ margin: "0 8px" }}
                                size="sm"
                            >
                                {t("default:reset")}
                            </Button>
                            <Button
                                leftIcon={<BiRefresh style={{ fontSize: "20px" }} />}
                                onClick={() => refreshGrid()}
                                variant={"outline"}
                                colorScheme="blue"
                                style={{ margin: "0 0px" }}
                                size="sm"
                            >
                                {t("default:refresh")}
                            </Button>
                            <Button
                                leftIcon={<FiFilter style={{ fontSize: "20px" }} />}
                                onClick={onOpen}
                                variant={"outline"}
                                colorScheme="blue"
                                style={{ margin: "0 8px" }}
                                size="sm"
                            >
                                {t("default:filter")}
                            </Button>
                        </div>
                    </div>
                    <br />
                </>
            )}
            <SplitView
                ref={gridRef}
                columns={columns}
                condensed={userId}
                condensedColumns={condensedColumns}
                dataUrl={"/api/users/list"}
                condensedUrl={"/users"}
                filters={{ impersonatedOrg: selectedOrg, filters }}
                onChangeSelectedRow={selectionCallback}
                initialState={{ selectedRowIds: selectedRows }}
                downloadReportUrl={"/api/report"}
                collection={"draft_users"}
                database={"transfi-nucleus"}
                gridFilters={{ filters, impersonatedOrg: selectedOrg }}
                headerDetails={{ types: { "createdAt": "date" } }}
                gridName={"users"}
            >
                <UserSpliViewContainer userId={userId} />
            </SplitView>

            <UserFilter
                isOpen={isOpen}
                onClose={onClose}
                countryOptions={countryOptions}
                inputData={inputData}
                setInputData={setInputData}
                dateRange={dateRange}
                setDateRange={setDateRange}
                filters={filters}
                setFilters={setFilters}
                formValues={formValues}
                setFormValues={setFormValues}
                handleOnReset={handleOnReset}
            ></UserFilter>

            <ViewComments
                isLoading={commentsLoading}
                comments={comments}
                isOpen={isOpenViewComments}
                onOpen={onOpenViewComments}
                onClose={onCloseViewComments}
            />
            <AddComments
                entityType={"users"}
                refreshData={refreshGrid}
                id={selectedRow.userId}
                selectedRows={selectedRows}
                isOpen={isOpenAddComments}
                onOpen={onOpenAddComments}
                bulkUpdate={bulkUpdate}
                onClose={() => {
                    setBulkUpdate(false);
                    onCloseAddComments();
                }}
            />
            <UpdateSource
                entityType={"users"}
                refreshData={refreshGrid}
                id={selectedRow.userId}
                selectedRows={selectedRows}
                isOpen={isOpenUpdateSource}
                onOpen={onOpenUpdateSource}
                bulkUpdate={bulkUpdate}
                onClose={() => {
                    setBulkUpdate(false);
                    onCloseUpdateSource();
                }}
            />
        </Box>
    ) : (
        <Loader type="spinner-cub" bgColor={"#FFFFFF"} title={"Loading..."} color={"#FFFFFF"} size={100} />
    );
}
