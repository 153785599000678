import React from "react";

import { Box, Center, Stack, Button } from "@chakra-ui/react";
import { join, startCase } from "lodash";
import { BiRefresh } from "react-icons/bi";

import TransfiGrid from "../../../utils/TransfiGrid";

const RoutingMasterView = () => {
  const gridRef = React.useRef();
  const refreshGrid = () => {
    gridRef.current && gridRef.current.refreshGrid();
  };

  const columns = [
    {
      Header: "Product",
      accessor: "product",
      width: 100,
      Cell: ({ value }) => startCase(value),
    },
    {
      Header: "Currency",
      accessor: "currency",
      width: 100,
      Cell: ({ value }) => startCase(value),
    },
    {
      Header: "Payment Gateway",
      accessor: "paymentGateway",
      width: 100,
      Cell: ({ value }) => startCase(value),
    },
    {
      Header: "Reference Name",
      accessor: "referenceName",
      width: 100,
      Cell: ({ value }) =>
        value
          ? join(
              value.map((b) => startCase(b)),
              ", ",
            )
          : "-",
    },
    {
      Header: "Payment Type",
      accessor: "paymentType",
      width: 100,
      Cell: ({ value }) =>
        value
          ? join(
              value.map((b) => startCase(b)),
              ", ",
            )
          : "-",
    },
  ];

  return (
    <Center py={5} bg="white.100">
      <Stack spacing={2} w="90%">
        <Box textAlign="right" mb={4}>
          <Button
            onClick={() => refreshGrid()}
            variant={"outline"}
            colorScheme="blue"
            style={{ margin: "0 4px" }}
            leftIcon={<BiRefresh style={{ fontSize: "20px" }} />}
            size="sm"
          >
            Refresh
          </Button>
        </Box>
        <TransfiGrid
          showPagination={false}
          ref={gridRef}
          columns={columns}
          dataUrl={"/api/admin/payment-routes-master"}
          selectRow={false}
        ></TransfiGrid>
      </Stack>
    </Center>
  );
};

export default RoutingMasterView;
