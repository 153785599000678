/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import Select from "react-select";

import {
    Button,
    FormControl,
    FormLabel,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Input,
    useToast,
    Stack,
    Grid,
    Divider,
    Heading,
    chakra,
    FormErrorMessage,Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel,
    Box, VStack, HStack, Text
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { capitalize, isEmpty } from "lodash";
import * as Yup from "yup";

import { callApiWithToken } from '../../../utils/utils';

const OtcPayinOrdersModal = (props) => {
    const { isOpen, onClose, refreshGrid } = props;

    const [currencyList, setCurrencyList] = useState("");
    const [paymentPartnerList, setPaymentPartnerList] = useState("");
    const [emailList, setEmailList] = useState("");
    const [paymentMethodList, setPaymentMethodList] = useState("");
    const [validation, setValidation] = useState({});
    const [cryptoList, setCryptoList] = useState("");
    const [orgList, setOrgList] = useState("");
    const [loading, setLoading] = useState(false)
    const [quotes, setQuotes] = useState(true);
    const [quotesData, setQuotesData] = useState("");
    const [quotesLoading, setQuotesLoading] = useState(false);
    const toast = useToast();

    useEffect(() => {
        getCurrencyList();
        getCryptoList();
        getIndividualList();
        getOrganizationList();
    }, []);

    const getCurrencyList = async (search) => {
        const response = await callApiWithToken(
            "GET",
            `/api/orders/configList?name=supported_otc_currencies&module=orders`
        );
        const coList = response?.data?.supportedOtcCurrencies;
        const countryList = coList.map((ls) => {
            return { value: ls, label: ls };
        });
        setCurrencyList(countryList);
    };

    const getCryptoList = async (search) => {
        const response = await callApiWithToken(
            "GET",
            `/api/orders/configList?name=crypto&module=otc`
        );
        const coList = response?.data?.crypto;
        const countryList = coList.map((ls) => {
            return { value: ls.formattedSymbol, label: ls.symbol + "-" + ls.description + (!isEmpty(ls.network) ? (`(${ls.network})`) : ""), address: ls.symbol };
        });
        setCryptoList(countryList);
    };

    const getPaymentPartnerList = async (value) => {
        const currency = value.value
        const response = await callApiWithToken(
            "GET",
            `/api/orders/paymentPartner?currency=${currency}&product=otc`
        );
        const { data = [] } = response;
        let paymentOptions = data.map((pm) => { return { value: pm, label: capitalize(pm) } })
        setPaymentPartnerList(paymentOptions);
    };

    const getPaymentMethodList = async (value) => {
        const paymentGateway = value?.value;
        const currencyOptions =formik?.values?.currency?.value;
        const response = await callApiWithToken(
            "GET",
            `/api/orders/paymentMethod?currency=${currencyOptions}&product=otc&paymentGateway=${paymentGateway}`
        );
        const { data = [] } = response;
        let paymentOptions = data.map((pm) => { return { value: pm.name, label: pm.name } })
        setPaymentMethodList(paymentOptions);
    };

    const getIndividualList = async (search) => {
        const response = await callApiWithToken(
            "GET",
            `/api/orders/usersList`
        );
        const coList = response?.data;
        const emails = coList.map((ls) => {
            return { value: ls.email, label: ls.email, address:ls.walletAddress };
        });
        setEmailList(emails);
    };

    const getOrganizationList = async (search) => {
        const response = await callApiWithToken(
            "GET",
            `/api/orders/orgList`
        );
        const coList = response?.data;
        const organization = coList.map((ls) => {
            return { value: ls.orgId, label: ls.name, address: ls.walletAddress };
        });
        setOrgList(organization);
    };

    const getQuotes = async() =>{
        try {
            setQuotesLoading(true);
            const response = await callApiWithToken("POST", "/api/orders/otc/quotes", {
                fiatTicker: formik.values.currency.value,
                amount: formik.values.fiatAmount,
                cryptoTicker: formik.values.cryptoTicker.value,
                processingFeeAmount: formik.values.processingFeeAmount
                });
                setQuotesData(response.message.data)
                setQuotes(false);
        } catch (error) {
            setQuotesLoading(false);
            toast({
                title: 'Error!',
                description: error?.message || "Failed to fetch order details.",
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: 'top-right'
            })
        }
    }

    const typeList = [
        { label: "Individual", value: "individual" },
        { label: "Business-Self", value: "business-self" },
        { label: "Business-Customer", value: "business-customer" }
    ]

    const closeModal = () => {
        formik.resetForm();
        onClose();
        setLoading(false);
        setQuotes(true);
        setQuotesData("");
        setQuotesLoading(false);
    }

    const formik = useFormik({
        initialValues: {
            date:'',
            paymentPartner: '',
            currency:'',
            partnerOrderId:'',
            email: '',
            paymentMethod: '',
            fiatAmount:'',
            processingFee:'',
            cryptoTicker: '',
            walletAddress:'',
            type: '',
            org: '',
            cxOrg: '',
            receivedDate:'',
            processingFeeAmount:'',
        },
        validationSchema: Yup.object().shape({
            date:Yup.string().required("Date is required"),
            paymentPartner: Yup.object().required("Payment Partner is required"),
            currency:Yup.object().required("Currency is required"),
            partnerOrderId:Yup.string().required("Partner Order Id is required"),
            paymentMethod: Yup.object().required("Payment Method is required"),
            fiatAmount:Yup.string().required("Fiat Amount is required"),
            processingFeeAmount:Yup.string().required("Processing Amount is required"),
            processingFee:Yup.string().required("Processing Fee is required"),
            cryptoTicker: Yup.object().required("Crypto Currency is required"),
            walletAddress:Yup.string().required("Wallet Address is required"),
            type: Yup.object().required("Type is required"),
            receivedDate:Yup.string().required("Sub Account recieved date is required"),
            ...validation
        }),
        onSubmit: async (formValues) => {
            try {
                setLoading(true);
                const response = await callApiWithToken("POST", "/api/orders/otc/buy/create", {
                date:formValues?.date,
                paymentPartner: formValues?.paymentPartner?.value,
                currency: formValues?.currency?.value,
                partnerOrderId:formValues?.partnerOrderId,
                email: formValues?.email?.value,
                paymentMethod: formValues?.paymentMethod?.value,
                fiatAmount: formValues?.fiatAmount,
                processingFee: formValues?.processingFee,
                cryptoTicker: formValues?.cryptoTicker?.value,
                walletAddress: formValues?.walletAddress,
                type: formValues?.type?.value,
                org: formValues?.org?.value,
                customerOrg: formValues?.cxOrg?.value,
                receivedDate:formValues?.receivedDate,
                processingFeeAmount: formValues?.processingFeeAmount,
                cryptoAmount: quotesData?.receiveAmount,
                networkFee: quotesData?.networkFee,
                totalFee: quotesData?.totalFee
                });
                if (response?.message) {
                    toast({
                        title: 'Success',
                        description: response?.message,
                        status: 'success',
                        duration: 5000,
                        isClosable: true,
                        position: 'top-right'
                    })
                }
                refreshGrid();
                closeModal();
            } catch (err) {
                setLoading(false);
                toast({
                    title: 'Error!',
                    description: err?.message || "Failed to fetch order details.",
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                    position: 'top-right'
                })
            }
        },
    });

    return (
        <chakra.form onSubmit={formik.handleSubmit} display="flex" flexGrow={1}>
        <Modal isOpen={isOpen} onClose={() =>  closeModal()} size={'5xl'}>
            <ModalOverlay />

            <ModalContent>
                <ModalHeader>OTC Orders</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={12}>
                    <Stack direction={"column"} justifyContent="space-between" flexGrow={1}>
                        <Grid templateColumns='repeat(3, 1fr)' gap={5}>
                            <FormControl isRequired  isInvalid={formik.touched.type && formik.errors.type}>
                                <FormLabel>Type</FormLabel>
                                <Select
                                    id="type"
                                    isSearchable={true}
                                    options={typeList}
                                    value={formik.values.type}
                                    placeholder='Select Type'
                                    onChange={(value) => {
                                        formik.setFieldValue("type", value);
                                        if(value.value === "individual"){
                                            setValidation({email:Yup.object().required("Email is required")})
                                        }
                                        if(value.value === "business-self"){
                                            setValidation({org:Yup.object().required("Organization is required")})
                                        }
                                        if(value.value === "business-customer"){
                                            setValidation({org:Yup.object().required("Organization is required"),
                                        cxOrg:Yup.object().required("Initiator's Organization is required")})
                                        }
                                    }}
                                />
                                 <FormErrorMessage>{formik.errors.type}</FormErrorMessage>
                            </FormControl>
                            { formik?.values?.type?.value === "individual" ? <>
                                <FormControl isRequired isInvalid={formik.touched.email && formik.errors.email}>
                                    <FormLabel>Email</FormLabel>
                                    <Select
                                        id="email"
                                        isSearchable={true}
                                        options={emailList}
                                        value={formik.values.email}
                                        placeholder='Select Email'
                                        onChange={(value) => {
                                            formik.setFieldValue("email", value); 
                                            formik.setFieldValue("walletAddress", value?.address);                                          
                                        }}
                                    />
                                     <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
                                </FormControl>
                            </> : <></>}
                            {formik?.values?.type?.value === "business-self" ? <>
                                <FormControl isRequired isInvalid={formik.touched.org && formik.errors.org}>
                                    <FormLabel>Organization</FormLabel>
                                    <Select
                                        id="org"
                                        isSearchable={true}
                                        options={orgList}
                                        value={formik.values.org}
                                        placeholder='Select Organization'
                                        onChange={(value) => {
                                            formik.setFieldValue("org", value);
                                            formik.setFieldValue("walletAddress", value?.address);    
                                        }}
                                    />
                                     <FormErrorMessage>{formik.errors.org}</FormErrorMessage>
                                </FormControl>
                            </> : <></>}
                            {formik?.values?.type?.value === "business-customer" ? <>
                                <FormControl isRequired isInvalid={formik.touched.org && formik.errors.org}>
                                    <FormLabel>Organization</FormLabel>
                                    <Select
                                        id="org"
                                        isSearchable={true}
                                        options={orgList}
                                        value={formik.values.org}
                                        placeholder='Select Organization'
                                        onChange={(value) => {
                                            formik.setFieldValue("org", value);
                                        }}
                                    />
                                     <FormErrorMessage>{formik.errors.org}</FormErrorMessage>
                                </FormControl>
                                <FormControl isRequired isInvalid={formik.touched.cxOrg && formik.errors.cxOrg}>
                                    <FormLabel>Initiator's Organization</FormLabel>
                                    <Select
                                        id="cxOrg"
                                        isSearchable={true}
                                        options={orgList}
                                        value={formik.values.cxOrg}
                                        placeholder='Select Initiators Organization'
                                        onChange={(value) => {
                                            formik.setFieldValue("cxOrg", value);
                                            formik.setFieldValue("walletAddress", value?.address);    
                                        }}
                                    />
                                     <FormErrorMessage>{formik.errors.cxOrg}</FormErrorMessage>
                                </FormControl>
                            </> : <></>}
                        </Grid>
                        <Divider opacity={1} />
                        <Grid templateColumns='repeat(4, 1fr)' gap={3}>
                            <FormControl isRequired isInvalid={formik.touched.date && formik.errors.date}>
                                <FormLabel htmlFor="name">Date</FormLabel>
                                <Input
                                    id="date"
                                    type="datetime-local"
                                    value={formik.values.date}
                                    onChange={ formik.handleChange }
                                />
                                 <FormErrorMessage>{formik.errors.date}</FormErrorMessage>
                            </FormControl>
                            <FormControl isRequired isInvalid={formik.touched.currency && formik.errors.currency}>
                                <FormLabel>Fiat Currency</FormLabel>
                                <Select
                                    id="currency"
                                    isSearchable={true}
                                    options={currencyList}
                                    value={formik.values.currency}
                                    placeholder='Select Currency'
                                    onChange={(value) => {
                                        formik.setFieldValue("currency", value);
                                        getPaymentPartnerList(value);
                                        // setCurrency(value.value)
                                    }}
                                />
                                 <FormErrorMessage>{formik.errors.currency}</FormErrorMessage>
                            </FormControl>
                            <FormControl isRequired isInvalid={formik.touched.fiatAmount && formik.errors.fiatAmount}>
                                <FormLabel>Fiat Amount</FormLabel>
                                <Input
                                    id="fiatAmount"
                                    type="number"
                                    value={formik.values.fiatAmount}
                                    onChange={formik.handleChange}
                                    // {...formik.values.fiatAmount && setFiatAmount(formik.values.fiatAmount)}
                                />
                                 <FormErrorMessage>{formik.errors.fiatAmount}</FormErrorMessage>
                            </FormControl>
                            <FormControl isRequired isInvalid={formik.touched.processingFee && formik.errors.processingFee}>
                                <FormLabel>Processing Fee(%)</FormLabel>
                                <Input
                                    id="processingFee"
                                    type="text"
                                    value={formik.values.processingFee}
                                    onChange={formik.handleChange}
                                />
                                 <FormErrorMessage>{formik.errors.processingFee}</FormErrorMessage>
                            </FormControl>
                            <FormControl isRequired isInvalid={formik.touched.processingFeeAmount && formik.errors.processingFeeAmount}>
                                <FormLabel>Processing Fee Amount</FormLabel>
                                <Input
                                    id="processingFeeAmount"
                                    type="text"
                                    value={formik.values.processingFeeAmount}
                                    onChange={formik.handleChange}
                                    // {...formik.values.processingFeeAmount && setProcessingFeeAmount(formik.values.processingFeeAmount)}
                                />
                                 <FormErrorMessage>{formik.errors.processingFeeAmount}</FormErrorMessage>
                            </FormControl>
                            <FormControl isRequired isInvalid={formik.touched.cryptoTicker && formik.errors.cryptoTicker}>
                                <FormLabel>Crypto Currency</FormLabel>
                                <Select
                                    id="cryptoTicker"
                                    isSearchable={true}
                                    value={formik.values.cryptoTicker}
                                    placeholder='Select Crypto Ticker'
                                    options={cryptoList}
                                    onChange={(value) => {
                                        formik.setFieldValue("cryptoTicker", value);
                                        // setCryptoTicker(value.value)
                                    }}
                                />
                                 <FormErrorMessage>{formik.errors.cryptoTicker}</FormErrorMessage>
                            </FormControl>
                            <FormControl isRequired isInvalid={formik.touched.walletAddress && formik.errors.walletAddress}>
                                <FormLabel>Crypto Wallet Address</FormLabel>
                                <Input
                                    id="walletAddress"
                                    type="text"
                                    value={formik.values.walletAddress}
                                    onChange={formik.handleChange}
                                />
                                 <FormErrorMessage>{formik.errors.walletAddress}</FormErrorMessage>
                            </FormControl>
                        </Grid>
                        <Divider opacity={1} />
                        {/* <Heading size={"sm"} marginTop="6" >Fiat Details:</Heading> */}
                        <Grid templateColumns='repeat(4, 1fr)' gap={3}>
                            <FormControl isRequired isInvalid={formik.touched.paymentPartner && formik.errors.paymentPartner}>
                                <FormLabel>Payment Partner</FormLabel>
                                <Select
                                    id="paymentPartner"
                                    isSearchable={true}
                                    value={formik.values.paymentPartner}
                                    placeholder='Select Payment Partner'
                                    options={paymentPartnerList}
                                    onChange={(value) => {
                                        formik.setFieldValue("paymentPartner", value);
                                        getPaymentMethodList(value);
                                    }}
                                />
                                 <FormErrorMessage>{formik.errors.paymentPartner}</FormErrorMessage>
                            </FormControl>
                            <FormControl isRequired isInvalid={formik.touched.paymentMethod && formik.errors.paymentMethod}>
                                <FormLabel>Payment Method</FormLabel>
                                <Select
                                    id="paymentMethod"
                                    isSearchable={true}
                                    value={formik.values.paymentMethod}
                                    placeholder='Select Method'
                                    options={paymentMethodList}
                                    onChange={(value) => {
                                        formik.setFieldValue("paymentMethod", value);
                                    }}
                                />
                                 <FormErrorMessage>{formik.errors.paymentMethod}</FormErrorMessage>
                            </FormControl>
                            <FormControl isRequired isInvalid={formik.touched.partnerOrderId && formik.errors.partnerOrderId}>
                                <FormLabel>Paymnet Partner Order Id</FormLabel>
                                <Input
                                    id="partnerOrderId"
                                    type="text"
                                    value={formik.values.partnerOrderId}
                                    onChange={formik.handleChange}
                                />
                                 <FormErrorMessage>{formik.errors.partnerOrderId}</FormErrorMessage>
                            </FormControl>
                            <FormControl isRequired isInvalid={formik.touched.receivedDate && formik.errors.receivedDate}>
                            <FormLabel htmlFor="name">Sub Partner Received Date</FormLabel>
                                <Input
                                    id="receivedDate"
                                    type="datetime-local"
                                    value={formik.values.receivedDate}
                                    onChange={ formik.handleChange }
                                />
                                 <FormErrorMessage>{formik.errors.receivedDate}</FormErrorMessage>
                            </FormControl>
                        </Grid>
                        {
                            quotesData ? <>
                            <Divider opacity={1} />
                        <Grid templateColumns='repeat(2, 1fr)' gap={6}>
                            <FormControl>
                                <FormLabel> Quotes </FormLabel>
                                <Accordion borderRadius={12} borderColor="transparent" size={'2xl'} boxShadow="0 0 5px 0 #1F29371F" allowToggle>
            <AccordionItem >
                <AccordionButton _focus={{ outline: "none" }} _hover={{ bg: 'none' }} fontSize='large'>
                    <Box flex='1' textAlign='left' fontSize={'large'}>
                        {`You get ~ ${quotesData.receiveAmount.toFixed(5)} ${formik.values.cryptoTicker.address} for ${formik.values.fiatAmount} ${formik.values.currency.value}`}
                    </Box>
                    <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                    <VStack>
                        <HStack justifyContent="space-between" width="full">
                            <Text fontSize="sm" color="black">
                                {quotesData?.receiveAmount} {formik.values.cryptoTicker.address} @ {quotesData?.cryptoPrice} {formik.values.currency.value}
                            </Text>
                            <Text color="black" fontSize="sm" textAlign="right">
                                {Number(formik.values.fiatAmount).toLocaleString()} {formik.values.currency.value}
                            </Text>
                        </HStack>
                        <HStack justifyContent="space-between" width="full">
                            <Text fontSize="sm" color="black">
                              Network Fee
                            </Text>
                            <Text color="black" fontSize="sm">
                                {(Number(quotesData?.networkFee).toLocaleString())} {formik.values.currency.value}
                            </Text>
                        </HStack>
                        <HStack justifyContent="space-between" width="full">
                            <Text fontSize="sm" color="black">
                             Processing Fee
                            </Text>
                            <HStack><Text color={'#5E5E5E'} fontSize="sm">
                               as low as
                            </Text> <Text color="black" fontSize="sm">{Number(quotesData?.processingFee).toLocaleString()} {formik.values.currency.value}</Text></HStack>
                        </HStack>
                    </VStack>
                </AccordionPanel>
            </AccordionItem>
        </Accordion>
                            </FormControl>
                           
       
        <FormControl >
                                <FormLabel>Crypto Amount</FormLabel>
                                <Input
                                    id="cryptoAmount"
                                    type="number"
                                    value={quotesData?.receiveAmount}
                                    onChange={formik.handleChange}
                                    isDisabled background='gray.200' color={'black'}
                                    // {...formik.values.fiatAmount && setFiatAmount(formik.values.fiatAmount)}
                                />
                                 
                            </FormControl>
                        </Grid>
                            </> : ""
                        }
                        
                    </Stack>
                </ModalBody>
                <ModalFooter>
                { quotes ?  <Button
                        isLoading={quotesLoading}
                        onClick={() => getQuotes()}
                        colorScheme='blue' mr={3}
                    >
                        Get quotes
                    </Button>  : <Button
                        isLoading={loading}
                        onClick={formik.handleSubmit}
                        colorScheme='blue' mr={3}
                    >
                        Create
                    </Button>}
                    <Button
                        onClick={() =>  closeModal()}
                    >
                        Cancel
                    </Button>
                </ModalFooter>

            </ModalContent>
        </Modal>
        </chakra.form>
    )
}

export default OtcPayinOrdersModal;