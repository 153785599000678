/* eslint-disable no-unused-expressions */
import React, { useState } from "react";

import {
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useToast,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Alert, AlertIcon
} from "@chakra-ui/react";
import { isEmpty } from "lodash";

import { callApiWithToken } from "../../utils/utils";

export default function ConfirmationModal(props) {
    const { isOpen, onClose, isLoading, selectedRows, refreshGrid, summaryDetails = [], error } = props;

    const toast = useToast();
    const [ loading, setLoading ] = useState(false);

    const onCloseHandler = () => {
        onClose();
    };

    const onClickHandler = async () => {
        try {
            setLoading(true);
            const selectedDetails = selectedRows.map((data) => data.orderId);
            const response = await callApiWithToken("POST", "/api/settlement/createBatch", {
                batches: summaryDetails,
                data: selectedDetails
            });
            if (response?.message) {
                toast({
                    title: 'Success',
                    description: response?.message,
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                    position: 'top-right'
                })
            } else {
                throw { error: response?.error };
            }
            setLoading(false)
            refreshGrid();
            onClose();
        } catch (err) {

            toast({
                title: 'Error!',
                description: err?.error || "Failed to fetch order details.",
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: 'top-right'
            })
            setLoading(false)
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onCloseHandler} size={'4xl'}>
            <ModalOverlay />

            <ModalContent>
                <ModalHeader>Confirm Settlement </ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={2}>
                    {/* <Text as='b' style={{ float: 'right' }}>{`Final Settlement Amount: ${round(summaryDetails?.settlementAmount)}`}</Text> */}
                    <br />
                    {isEmpty(error) ? <TableContainer alignContent="center">
                        <Table size='sm' >
                            <Thead>
                                <Tr>
                                    <Th>Date</Th>
                                    <Th>Fiat Currency</Th>
                                    <Th>Total Buy Amount</Th>
                                    <Th>Total Sell Amount</Th>
                                    <Th>Settlement Amount</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {summaryDetails.map(summary => {
                                    return <Tr>
                                        <Td>{summary?._id?.date || "N/A"}</Td>
                                        <Td>{summary?._id?.fiatTicker || "N/A"}</Td>
                                        <Td color="green">{`+${summary?.totalBuyNetAmount} (${summary?._id?.fiatTicker})`}</Td>
                                        <Td color="red">{`-${Math.abs(summary?.totalSellNetAmount)} (${summary?._id?.fiatTicker})`}</Td>
                                        <Td color="green">{`${summary?.settlementAmount} (${summary?._id?.fiatTicker})`}</Td>
                                    </Tr>
                                })}
                            </Tbody>
                        </Table>
                    </TableContainer>
                        : <><br />
                            <Alert status='error'>
                                <AlertIcon />
                                {error}
                            </Alert></>}

                </ModalBody>
                <ModalFooter>
                    <Button isLoading={loading} onClick={onClickHandler} colorScheme="blue" mr={3} isDisabled={!isEmpty(error) ? true : false}>
                        Create Batch
                    </Button>
                    <Button onClick={onCloseHandler}>Cancel</Button>

                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}