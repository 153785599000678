/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import {
    Button,
    FormControl,
    FormLabel,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useToast,
    Select,
} from "@chakra-ui/react";

import { callApiWithToken } from "../../utils/utils";

export default function EditModal(props) {
    const { t } = useTranslation();
    const { isOpen, onClose, isLoading, refreshGrid, selectedDetails } = props;

    const [loading, setLoading] = useState(false);
    const [role, setRole] = useState("");
    const [status, setStatus] = useState("");
    const [options, setOptions] = useState("");

    useEffect(() => {
        setRole("");
        setStatus("");
    }, [options]);

    const toast = useToast();

    const { email } = selectedDetails;

    const onCloseHandler = () => {
        onClose();
        setOptions("");
    };

    const onClickHandler = async () => {
        try {
            setLoading(true);
            await callApiWithToken("POST", "/api/customers/updateDetails", {
                email,
                role,
                status,
            });

            toast({
                title: "Updated Successfully",
                status: "success",
                isClosable: true,
                position: "bottom",
                duration: 5000,
            });
            setLoading(false);
            await refreshGrid();
            onCloseHandler();
        } catch (err) {
            toast({
                title: `Updation Failed :  ${err.message}`,
                status: "failed",
                isClosable: true,
                position: "bottom",
                duration: 5000,
            });
            setLoading(false);
            onCloseHandler();
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onCloseHandler}>
            <ModalOverlay />

            <ModalContent>
                <ModalHeader>{t("users:edit_details")}</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    <FormControl mt={4}>
                        <FormLabel>{t("users:select_fields")}</FormLabel>
                        <Select
                            placeholder={t("users:select_fields")}
                            onChange={(event) => setOptions(event.target.value)}
                        >
                            <option value="status">Status</option>
                            <option value="role">Role</option>
                        </Select>
                    </FormControl>
                    {options === "role" && (
                        <FormControl mt={4}>
                            <FormLabel>{t("users:select_role")}</FormLabel>
                            <Select
                                placeholder={t("users:select_role")}
                                onChange={(e) => setRole(e.target.value)}
                            >
                                <option value="developer">Developer</option>
                                <option value="admin">Admin</option>
                                <option value="operations">Operations</option>
                            </Select>
                        </FormControl>
                    )}
                    {options === "status" && (
                        <FormControl>
                            <FormLabel>{t("users:select_status")}</FormLabel>
                            <Select
                                placeholder={t("users:select_status")}
                                onChange={(e) => setStatus(e.target.value)}
                            >
                                <option value="active">Active</option>
                                <option value="inactive">Inactive</option>
                            </Select>
                        </FormControl>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button
                        isLoading={isLoading}
                        onClick={onClickHandler}
                        colorScheme="blue"
                        mr={3}
                    >
                        {t("users:update")}
                    </Button>
                    <Button onClick={onCloseHandler}>{t("sub-accounts:cancel")}</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}
