import { uniqBy, sortBy, startCase } from "lodash";

import { callApiWithToken } from "../utils/utils";

export const getCountryData = async () => {
    const response = await callApiWithToken("GET", `/api/orders/countryList`);
    const data = response.data;
    const countryList = data.map((country) => {
        return { value: country.twoDigitCode, label: country.twoDigitCode };
    });
    const fiatList = data.map((fiat) => {
        return { value: fiat.currency, label: fiat.currency };
    });
    const countryNameList = data.map((country) => {
        return { value: country.formattedName, label: startCase(country.formattedName) };
    });
    return {
        countryList: sortBy(uniqBy(countryList, (obj) => obj.value), "value"),
        fiatList: sortBy(uniqBy(fiatList, (obj) => obj.value), "value"),
        countryNameList: sortBy(uniqBy(countryNameList, (obj) => obj.value), "value")
    };
}
