import React, { useState, useEffect, useMemo } from 'react';

import { Search, Plus, X, Check, Filter, AlertCircle } from 'lucide-react';

import { Card, CardContent, CardHeader, CardTitle } from '../../../components/ui/card';

const AssignedRoles = () => {
  // Sample data - replace with API calls
  const [assignedRoles, setAssignedRoles] = useState([
    { 
      id: 1, 
      entityId: '1',
      entityType: 'User',
      entityName: 'John Doe',
      entityEmail: 'john@example.com',
      roleId: '1',
      roleName: 'Admin',
      status: 'active',
      assignedAt: '2024-03-15',
      assignedBy: 'System Admin'
    },
    {
      id: 2,
      entityId: '2',
      entityType: 'User',
      entityName: 'Jane Smith',
      entityEmail: 'jane@example.com',
      roleId: '2',
      roleName: 'Staff',
      status: 'active',
      assignedAt: '2024-03-14',
      assignedBy: 'System Admin'
    }
  ]);

  const [roles] = useState([
    { id: '1', name: 'Admin', description: 'Full access' },
    { id: '2', name: 'Staff', description: 'Limited access' },
    { id: '3', name: 'Manager', description: 'Department manager' }
  ]);

  const [users] = useState([
    { id: '1', name: 'John Doe', email: 'john@example.com', department: 'IT' },
    { id: '2', name: 'Jane Smith', email: 'jane@example.com', department: 'HR' },
    { id: '3', name: 'Mike Johnson', email: 'mike@example.com', department: 'Sales' }
  ]);

  const [showModal, setShowModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [roleFilter, setRoleFilter] = useState('');
  const [formData, setFormData] = useState({
    entityId: '',
    roleId: '',
    expiresAt: ''
  });
  const [formErrors, setFormErrors] = useState({});
  const [selectedAssignment, setSelectedAssignment] = useState(null);

  // Filter assigned roles
  const filteredAssignments = useMemo(() => {
    return assignedRoles.filter(assignment => {
      const matchesSearch = 
        assignment.entityName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        assignment.entityEmail.toLowerCase().includes(searchTerm.toLowerCase()) ||
        assignment.roleName.toLowerCase().includes(searchTerm.toLowerCase());
      
      const matchesStatus = !statusFilter || assignment.status === statusFilter;
      const matchesRole = !roleFilter || assignment.roleId === roleFilter;

      return matchesSearch && matchesStatus && matchesRole;
    });
  }, [assignedRoles, searchTerm, statusFilter, roleFilter]);

  // Get unassigned users
  const availableUsers = useMemo(() => {
    const assignedUserIds = assignedRoles.map(a => a.entityId);
    return users.filter(user => !assignedUserIds.includes(user.id));
  }, [users, assignedRoles]);

  const validateForm = () => {
    const errors = {};
    if (!formData.entityId) errors.entityId = 'User is required';
    if (!formData.roleId) errors.roleId = 'Role is required';
    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validateForm();

    if (Object.keys(errors).length === 0) {
      const selectedUser = users.find(u => u.id === formData.entityId);
      const selectedRole = roles.find(r => r.id === formData.roleId);

      const newAssignment = {
        id: assignedRoles.length + 1,
        entityId: selectedUser.id,
        entityType: 'User',
        entityName: selectedUser.name,
        entityEmail: selectedUser.email,
        roleId: selectedRole.id,
        roleName: selectedRole.name,
        status: 'active',
        assignedAt: new Date().toISOString().split('T')[0],
        assignedBy: 'Current User',
        expiresAt: formData.expiresAt || null
      };

      setAssignedRoles([...assignedRoles, newAssignment]);
      handleCloseModal();
    } else {
      setFormErrors(errors);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedAssignment(null);
    setFormData({
      entityId: '',
      roleId: '',
      expiresAt: ''
    });
    setFormErrors({});
  };

  const handleStatusChange = async (assignmentId, newStatus) => {
    setAssignedRoles(prevAssignments =>
      prevAssignments.map(assignment =>
        assignment.id === assignmentId
          ? { ...assignment, status: newStatus }
          : assignment
      )
    );
  };

  return (
    <div className="w-full max-w-4xl mx-auto p-4 space-y-4">
      {/* Header */}
      <Card>
        <CardHeader className="flex flex-row items-center justify-between">
          <CardTitle>Role Assignments</CardTitle>
          <button
            onClick={() => setShowModal(true)}
            className="flex items-center gap-2 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
          >
            <Plus className="h-4 w-4" />
            Assign Role
          </button>
        </CardHeader>
      </Card>

      {/* Filters */}
      <Card>
        <CardContent className="pt-6">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div className="relative">
              <Search className="absolute left-3 top-2.5 h-4 w-4 text-gray-400" />
              <input
                type="text"
                placeholder="Search users or roles..."
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
                className="w-full pl-10 p-2 border rounded-md"
              />
            </div>
            <select
              value={roleFilter}
              onChange={e => setRoleFilter(e.target.value)}
              className="p-2 border rounded-md"
            >
              <option value="">All Roles</option>
              {roles.map(role => (
                <option key={role.id} value={role.id}>
                  {role.name}
                </option>
              ))}
            </select>
            <select
              value={statusFilter}
              onChange={e => setStatusFilter(e.target.value)}
              className="p-2 border rounded-md"
            >
              <option value="">All Status</option>
              <option value="active">Active</option>
              <option value="suspended">Suspended</option>
              <option value="expired">Expired</option>
            </select>
          </div>
        </CardContent>
      </Card>

      {/* Assignments List */}
      <Card>
        <CardHeader>
          <CardTitle>Assigned Roles ({filteredAssignments.length})</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="space-y-4">
            {filteredAssignments.map(assignment => (
              <div
                key={assignment.id}
                className="border rounded-lg p-4 hover:bg-gray-50"
              >
                <div className="flex items-start justify-between">
                  <div>
                    <h3 className="font-medium">{assignment.entityName}</h3>
                    <p className="text-sm text-gray-500">{assignment.entityEmail}</p>
                  </div>
                  <div className="flex items-center gap-2">
                    <span className={`text-sm px-2 py-1 rounded ${
                      assignment.status === 'active' ? 'bg-green-100 text-green-800' :
                      assignment.status === 'suspended' ? 'bg-red-100 text-red-800' :
                      'bg-gray-100 text-gray-800'
                    }`}>
                      {assignment.status.charAt(0).toUpperCase() + assignment.status.slice(1)}
                    </span>
                    <select
                      value={assignment.status}
                      onChange={e => handleStatusChange(assignment.id, e.target.value)}
                      className="text-sm border rounded-md p-1"
                    >
                      <option value="active">Active</option>
                      <option value="suspended">Suspend</option>
                    </select>
                  </div>
                </div>
                <div className="mt-2">
                  <span className="text-sm bg-blue-100 text-blue-800 px-2 py-1 rounded">
                    {assignment.roleName}
                  </span>
                </div>
                <div className="mt-2 text-sm text-gray-500">
                  <span>Assigned: {assignment.assignedAt}</span>
                  {assignment.expiresAt && (
                    <span className="ml-4">Expires: {assignment.expiresAt}</span>
                  )}
                  <span className="ml-4">By: {assignment.assignedBy}</span>
                </div>
              </div>
            ))}
          </div>
        </CardContent>
      </Card>

      {/* Assignment Modal */}
      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
          <div className="bg-white rounded-lg w-full max-w-md mx-4">
            <div className="p-4 border-b">
              <h2 className="text-lg font-medium">
                Assign Role
              </h2>
            </div>
            
            <form onSubmit={handleSubmit} className="p-4 space-y-4">
              {/* User Selection */}
              <div>
                <label className="block text-sm font-medium mb-1">User</label>
                <select
                  value={formData.entityId}
                  onChange={e => {
                    setFormData(prev => ({ ...prev, entityId: e.target.value }));
                    setFormErrors(prev => ({ ...prev, entityId: undefined }));
                  }}
                  className={`w-full p-2 border rounded-md ${
                    formErrors.entityId ? 'border-red-500' : ''
                  }`}
                >
                  <option value="">Select User</option>
                  {availableUsers.map(user => (
                    <option key={user.id} value={user.id}>
                      {user.name} ({user.email})
                    </option>
                  ))}
                </select>
                {formErrors.entityId && (
                  <p className="mt-1 text-sm text-red-500">{formErrors.entityId}</p>
                )}
              </div>

              {/* Role Selection */}
              <div>
                <label className="block text-sm font-medium mb-1">Role</label>
                <select
                  value={formData.roleId}
                  onChange={e => {
                    setFormData(prev => ({ ...prev, roleId: e.target.value }));
                    setFormErrors(prev => ({ ...prev, roleId: undefined }));
                  }}
                  className={`w-full p-2 border rounded-md ${
                    formErrors.roleId ? 'border-red-500' : ''
                  }`}
                >
                  <option value="">Select Role</option>
                  {roles.map(role => (
                    <option key={role.id} value={role.id}>
                      {role.name} - {role.description}
                    </option>
                  ))}
                </select>
                {formErrors.roleId && (
                  <p className="mt-1 text-sm text-red-500">{formErrors.roleId}</p>
                )}
              </div>

              {/* Expiration Date */}
              <div>
                <label className="block text-sm font-medium mb-1">
                  Expiration Date (Optional)
                </label>
                <input
                  type="date"
                  value={formData.expiresAt}
                  onChange={e => setFormData(prev => ({
                    ...prev,
                    expiresAt: e.target.value
                  }))}
                  className="w-full p-2 border rounded-md"
                  min={new Date().toISOString().split('T')[0]}
                />
              </div>

              {/* Actions */}
              <div className="flex justify-end gap-2 pt-4">
                <button
                  type="button"
                  onClick={handleCloseModal}
                  className="px-4 py-2 border rounded-md hover:bg-gray-50"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                >
                  Assign Role
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default AssignedRoles;