import React, { useState } from "react";

import { Box, useToast } from "@chakra-ui/react";

//icons
import png from "../../../icons/background.png";
//custom components and fucntions
import Loader from "../../../utils/Loader";
import { saveDataToSession } from "../../../utils/session-storing-utils";
import { callApi } from "../../../utils/utils";
import OTPInputBoxes from "./OTPInputBoxes";
import Logo from "./icons/Logo.png";

//css
import "../Login.css";
import "./PasswordLogin.css";

const VerifyMFA = (props) => {
  const toast = useToast();

  const { email, setOTPVerified, redirectLogin = false, setToken } = props || {};
  const [otp, setOtp] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleToast = (title, type) => {
    toast({
      title,
      status: type,
      duration: 5000,
      isClosable: true,
    });
  };

  const verifyOtp = async () => {
    if (otp.length < 6) {
      handleToast("Please Enter Valid OTP", "error");
      return;
    }
    try {
      setIsLoading(true);
      const data = await callApi("POST", `/api/customers/verify2FA`, JSON.stringify({ email, otp, needLogin: true }));
      const { otpVerified = false, message, tokenData } = data;

      if (otpVerified) {
        if (redirectLogin) {
          localStorage.setItem("token", tokenData.token);
          localStorage.setItem("refreshToken", tokenData.refreshToken);
          window.location.href = "/dashboard";
        } else {
          setOTPVerified(otpVerified);
        }
      } else {
        setIsLoading(false);
        throw new Error(message);
      }

      handleToast("MFA Successfully Verified", "success");
    } catch (e) {
      handleToast(e?.message || "Something went wrong", "error");
      setIsLoading(false);
    }
  };

  return isLoading ? (
    <Loader />
  ) : (
    <Box className="pa-login-container" backgroundImage={png}>
      <div className="pa-login-card">
        <img className="pa-login-logo-trouble" style={{ marginTop: "84px" }} src={Logo} />
        <p className="pa-login-heading">Verify 2FA</p>
        <p className="pa-login-sub-heading" style={{ marginTop: "84px" }}>
          Enter Code from your two factor authenticator APP
        </p>
        <OTPInputBoxes setOtp={setOtp} />
        <p className="pa-login-sub-heading" style={{ marginTop: "32px", color: "#1A4FD6" }}>
          To reset 2FA please contact TransFi support
        </p>
        <button className="pa-button" style={{ marginTop: "86px" }} onClick={() => verifyOtp()}>
          Continue
        </button>
        <div className="pa-transfi-banner" style={{ marginTop: "96px" }}>
          <p className="pa-powered-by">Powered by</p>
          <img style={{ width: "24px", height: "17px", marginLeft: "8px", marginRight: "8px" }} src={Logo} />
          <p className="pa-powered-transfi">TransFi</p>
        </div>
      </div>
    </Box>
  );
};

export default VerifyMFA;
