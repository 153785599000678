import { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";

import Admin from "../src/components/Admin";
import Header from "../src/components/Nav/Header";
import Configurations from "./components/Admin/Configurations";
import AddConfigurations from "./components/Admin/Configurations/AddConfig";
import EditConfigurations from "./components/Admin/Configurations/EditConifg";
import CounterPartyStatus from "./components/Admin/CounterPartyStatus/counterPartyStatus";
import FinanceBalance from "./components/Admin/FinanceBalance";
import LiveRates from "./components/Admin/LiveRates";
import PartnerOverview from "./components/Admin/PartnersOverview";
import AddPaymentMethods from "./components/Admin/Payment/AddPaymentMethods";
import PaymentMethodsV2 from "./components/Admin/Payment/PaymentMethodsV2";
import PaymentGateway from "./components/Admin/PaymentGateway/index";
import PaymentRoutes from "./components/Admin/PaymentRoutes";
import Pricing from "./components/Admin/Pricing";
import AddNewCurrency from "./components/Admin/Pricing/AddNewCurrency";
import RoutingMasterView from "./components/Admin/RoutingMasterView";
import { getOrgRoles, getOrgName, getOrgId } from "./components/App/useToken";
import ComplianceBuyOrders from "./components/Compliance/BuyOrders";
import ComplianceCards from "./components/Compliance/CardView";
import ChainalysisPreScreening from "./components/Compliance/ChainalysisPreScreening";
import ComplianceSellOrders from "./components/Compliance/SellOrders";
import CxRevshareSettlement from "./components/CxRevshareSettlement";
import RevShareBatch from "./components/CxRevshareSettlement/RevShareBatch/index";
import Finance from "./components/Finance";
import KYBForm from "./components/KYBForm";
import BuyOrders from "./components/Orders/BuyOrders";
import CollectionsOrders from "./components/Orders/CollectionsOrders";
import CryptoPayoutOrders from "./components/Orders/CryptoPayoutOrders";
import FiatPayoutOrders from "./components/Orders/FiatPayoutOrders";
import Orders from "./components/Orders/OrdersCardView";
import OtcPayinOrders from "./components/Orders/OtcPayinOrders";
import OtcPayoutOrders from "./components/Orders/OtcPayoutOrders";
import PayOrders from "./components/Orders/PayOrders";
import PayinOrders from "./components/Orders/PayinOrders";
import PayoutOrders from "./components/Orders/PayoutOrders";
import PrefundOrders from "./components/Orders/PrefundOrders";
import PrefundSummary from "./components/Orders/PrefundSummary";
import SellOrders from "./components/Orders/SellOrders";
import WpayOrders from "./components/Orders/WpayOrders";
import PreFund from "./components/PreFund";
import Refund from "./components/Refund";
import Settlement from "./components/Settlement";
import CollectionsBalance from "./components/Settlement/CollectionsBalance";
import CollectionsSettlement from "./components/Settlement/CollectionsSettlement";
import CreatePayinSettlement from "./components/Settlement/CreatePayinSettlement";
import WpaySettlement from "./components/Settlement/OceanGamingSettlement";
import SettlementDetails from "./components/Settlement/SettlementDetails";
import SettlementBatch from "./components/SettlementBatch";
import CryptoSettlementMaster from "./components/SettlementMaster/CryptoSettlementMaster";
import FiatSettlementMaster from "./components/SettlementMaster/FiatSettlementMaster";
import SubAccount from "./components/SubAccount";
import Teams from "./components/Team/Team";
import Transactions from "./components/Transactions";
import Users from "./components/Users";
import Authorization from "./pages/authorization";
import AssignedRoles from "./pages/authorization/assigned-roles/index";
import Permissions from "./pages/authorization/permissions";
import Roles from "./pages/authorization/roles/index";
import Loader from "./utils/Loader";

import "./Styles.css";

const Dashboard = lazy(() => import("../src/components/Dashboard/Dashboard"));
const Rules = lazy(() => import("./components/Admin/Rules"));
const RuleSetConfig = lazy(() => import("./components/Admin/Rules/RuleConfig"));

export default function Router(props) {
  const orgRoles = getOrgRoles();
  const orgName = getOrgName();
  const orgId = getOrgId();
  const grpOrgId = sessionStorage.getItem("grpOrgId");
  const oceanGaming = orgName === "ocean_gaming" || grpOrgId === "OG-220923101501950";
  const allowableBinanceOrgs = ["binance", "binance_demo", "binance_qa", "binance_prod", "binance_africa", "openpayd"];
  const allowablePayinOrgs = ["roshi", "ptnarrativemediabaru", "ptklikteknologiindonesia"].includes(orgName);

  return (
    <>
      <div className="header">
        <Header />
      </div>

      <div className="router">
        <Routes>
          {/* Default Routes */}
          {allowableBinanceOrgs.includes(orgName) && <Route path="*" element={<Transactions />} />}
          {oceanGaming && <Route path="*" element={<SubAccount />} />}
          {!allowableBinanceOrgs.includes(orgName) && !oceanGaming && (
            <Route
              path="*"
              element={
                <Suspense fallback={<Loader />}>
                  <Dashboard />
                </Suspense>
              }
            />
          )}

          {orgRoles.includes("transfi_admin") && <Route path="admin" element={<Admin />}></Route>}
          {orgRoles.includes("transfi_admin") && <Route path="orders" element={<Orders />}></Route>}
          {orgRoles.includes("transfi_admin") && <Route path="compliance" element={<ComplianceCards />}></Route>}
          {orgRoles.includes("transfi_admin") && <Route path="compliance/buy-order" element={<ComplianceBuyOrders />}></Route>}
          {orgRoles.includes("transfi_admin") && <Route path="buy-order/:orderId" element={<ComplianceBuyOrders />}></Route>}
          {orgRoles.includes("transfi_admin") && <Route path="compliance/sell-order" element={<ComplianceSellOrders />}></Route>}
          {orgRoles.includes("transfi_admin") && <Route path="sell-order/:orderId" element={<ComplianceSellOrders />}></Route>}
          {orgRoles.includes("transfi_admin") && <Route path="compliance/chainalysis-pre-screening" element={<ChainalysisPreScreening />}></Route>}

          <Route
            path="dashboard"
            element={
              <Suspense fallback={<Loader />}>
                <Dashboard />
              </Suspense>
            }
          />
          <Route path="buy-orders" element={<BuyOrders />}></Route>
          <Route path="sell-orders" element={<SellOrders />}></Route>
          {oceanGaming && <Route path="transactions" element={<WpayOrders />}></Route>}

          {orgRoles.includes("transfi_admin") && <Route path="admin/routing-master-view" element={<RoutingMasterView />}></Route>}
          {(orgRoles.includes("transfi_admin") || allowablePayinOrgs) && <Route path="otc-payin-orders" element={<OtcPayinOrders />}></Route>}
          {(orgRoles.includes("transfi_admin") || allowablePayinOrgs) && <Route path="payin-orders" element={<PayinOrders />}></Route>}
          {(orgRoles.includes("transfi_admin") || allowablePayinOrgs) && <Route path="collections-orders" element={<CollectionsOrders />}></Route>}
          {(orgRoles.includes("transfi_admin") || allowablePayinOrgs) && <Route path="payout-orders" element={<PayoutOrders />}></Route>}
          {(orgRoles.includes("transfi_admin") || allowablePayinOrgs) && <Route path="pay-orders" element={<PayOrders />}></Route>}
          {(orgRoles.includes("transfi_admin") || allowablePayinOrgs) && <Route path="fiat-payout-orders" element={<FiatPayoutOrders />}></Route>}
          {(orgRoles.includes("transfi_admin") || allowablePayinOrgs) && <Route path="crypto-payout-orders" element={<CryptoPayoutOrders />}></Route>}
          {(orgRoles.includes("transfi_admin") || allowablePayinOrgs) && <Route path="prefund-orders" element={<PrefundOrders />}></Route>}
          {(orgRoles.includes("transfi_admin") || allowablePayinOrgs) && <Route path="prefund-summary" element={<PrefundSummary />}></Route>}
          {orgRoles.includes("transfi_admin") && <Route path="otc-payout-orders" element={<OtcPayoutOrders />}></Route>}
          <Route path="finance" element={<Finance />}></Route>
          <Route path="transactions" element={<Transactions />}></Route>
          {(allowableBinanceOrgs.includes(orgName) || orgRoles.includes("transfi_admin")) && <Route path="prefunds" element={<PreFund />}></Route>}
          {(allowableBinanceOrgs.includes(orgName) || orgRoles.includes("transfi_admin")) && <Route path="settlements" element={<Settlement />}></Route>}
          {(allowableBinanceOrgs.includes(orgName) || orgRoles.includes("transfi_admin")) && (
            <Route path="settlements/collections" element={<CollectionsSettlement />}></Route>
          )}
          {(allowableBinanceOrgs.includes(orgName) || orgRoles.includes("transfi_admin")) && (
            <Route path="settlements/collections/:id" element={<SettlementDetails />}></Route>
          )}
          {(allowableBinanceOrgs.includes(orgName) || orgRoles.includes("transfi_admin")) && <Route path="refunds" element={<Refund />}></Route>}
          {(allowableBinanceOrgs.includes(orgName) || orgRoles.includes("transfi_admin")) && (
            <Route path="settlements/batch/:batchId" element={<SettlementBatch />}></Route>
          )}
          {!allowableBinanceOrgs.includes(orgName) && <Route path="cx-revshare-settlements" element={<CxRevshareSettlement />}></Route>}
          {orgRoles.includes("transfi_admin") && <Route path="balance-overview" element={<PartnerOverview />}></Route>}
          {orgRoles.includes("transfi_admin") && <Route path="balance-dashboard" element={<FinanceBalance />}></Route>}
          {orgRoles.includes("transfi_admin") && <Route path="crypto-settlement-master" element={<CryptoSettlementMaster />}></Route>}
          {orgRoles.includes("transfi_admin") && <Route path="fiat-settlement-master" element={<FiatSettlementMaster />}></Route>}
          {/* {!allowableBinanceOrgs.includes(orgName) && !orgRoles.includes("transfi_admin") && <Route path="my-settlements" element={<MySettlement />}></Route>} */}
          {!allowableBinanceOrgs.includes(orgName) && <Route path="cx-revshare-settlements/batch/:batchId" element={<RevShareBatch />}></Route>}
          <Route path="buy-orders/:orderId" element={<BuyOrders />}></Route>
          <Route path="sell-orders/:orderId" element={<SellOrders />}></Route>
          {(orgRoles.includes("transfi_admin") || allowablePayinOrgs) && <Route path="otc-payin-orders/:orderId" element={<OtcPayinOrders />}></Route>}
          {orgRoles.includes("transfi_admin") && <Route path="otc-payout-orders/:orderId" element={<OtcPayoutOrders />}></Route>}
          <Route path="users" element={<Users />}></Route>
          <Route path="users/:userId" element={<Users />}></Route>

          <Route path="developers/kyb-form" element={<KYBForm />}></Route>
          <Route path="teams" element={<Teams />}></Route>
          {orgRoles.includes("transfi_admin") && <Route path="admin/pricing" element={<Pricing />}></Route>}
          {orgRoles.includes("transfi_admin") && <Route path="admin/payment-methods" element={<PaymentMethodsV2 />}></Route>}
          {orgRoles.includes("transfi_admin") && <Route path="admin/add-new-currency" element={<AddNewCurrency />}></Route>}
          {orgRoles.includes("transfi_admin") && <Route path="admin/add-payment-methods" element={<AddPaymentMethods />}></Route>}
          {orgRoles.includes("transfi_admin") && <Route path="admin/payment-routes" element={<PaymentRoutes />}></Route>}
          {orgRoles.includes("transfi_admin") && <Route path="admin/configs" element={<Configurations />}></Route>}
          {orgRoles.includes("transfi_admin") && <Route path="admin/configs/edit" element={<EditConfigurations />}></Route>}
          {orgRoles.includes("transfi_admin") && <Route path="admin/configs/add" element={<AddConfigurations />}></Route>}
          {orgRoles.includes("transfi_admin") && <Route path="admin/payment-gateways" element={<PaymentGateway />}></Route>}
          {orgRoles.includes("transfi_admin") && <Route path="admin/rules" element={<Rules />}></Route>}
          {orgRoles.includes("transfi_admin") && <Route path="admin/rules/:ruleSetId" element={<RuleSetConfig />}></Route>}
          {orgRoles.includes("transfi_admin") && <Route path="admin/live-rates" element={<LiveRates />}></Route>}
          {orgId === "OG-220923101501950" && <Route path="sub-accounts" element={<SubAccount />}></Route>}
          {(orgId === "OG-220923101501950" || grpOrgId === "OG-220923101501950") && <Route path="wpay-settlement" element={<WpaySettlement />}></Route>}
          {orgRoles.includes("transfi_admin") && <Route path="admin/counter-party-status" element={<CounterPartyStatus />}></Route>}
          {orgRoles.includes("transfi_admin") && <Route path="/collections-balance" element={<CollectionsBalance />}></Route>}
          {(allowableBinanceOrgs.includes(orgName) || orgRoles.includes("transfi_admin")) && (
            <Route path="payin-orders/settlements/create" element={<CreatePayinSettlement />}></Route>
          )}

          <Route
            path="authorization"
            element={
              <Suspense fallback={<Loader />}>
                <Authorization />
              </Suspense>
            }
          />
          <Route
            path="authorization/permissions"
            element={
              <Suspense fallback={<Loader />}>
                <Permissions />
              </Suspense>
            }
          />
          <Route
            path="authorization/roles"
            element={
              <Suspense fallback={<Loader />}>
                <Roles />
              </Suspense>
            }
          />
          <Route
            path="authorization/assigned-roles"
            element={
              <Suspense fallback={<Loader />}>
                <AssignedRoles />
              </Suspense>
            }
          />
        </Routes>
      </div>
    </>
  );
}
