import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { Box, Button, HStack, Stack, Text } from '@chakra-ui/react';
import moment from 'moment';
import { BiReset } from 'react-icons/bi';

import { constants } from '../../constants';
import { NavBarContext } from '../../context/NavBarContext';
import Loader from '../../utils/Loader';
import Grid from '../../utils/agTable';
import { callApiWithTokenV2 } from '../../utils/utils';

const CollectionsSettlement = () => {
    const { colorMap, statusMap } = constants;

    const [isLoading, setIsLoading] = useState(false);
    const [selectedData, setSelectedData] = useState([])
    const [select, setSelect] = useState(false);
    const [refreshGrid, setRefreshGrid] = useState(false);

    const { selectedOrg } = useContext(NavBarContext);

    useEffect(() => {
        setRefreshGrid(!refreshGrid);
    }, [selectedOrg])

    const navigate = useNavigate();

    const USformatter = new Intl.NumberFormat('en-US');

    const getSettlementsData = async (filters) => {
        const responseData = await callApiWithTokenV2.post(`/api/orders/tfpay/settlement`, { impersonatedOrg: selectedOrg }, {
            params: {
                filters
            }
        })
        return responseData;
    }
    const handleReset = () => {
        setRefreshGrid(!refreshGrid);
    };

    const handleClick = (val) => {
        navigate(`/settlements/collections/${val}`)
    }

    const columns = [
        {
            headerName: "Date",
            field: "dateObj",
            cellDataType: 'date',
            getQuickFilterText: params => {
                return params.value.name;
            },
            filter: 'agDateColumnFilter',
            customerVisibility: true,
            width: '250',
            cellRenderer: ({ value }) => (
                moment(value).format("DD-MMM-YYYY")
            ),
        },
        {
            headerName: "Fiat Currency",
            field: "fiatTicker",
            getQuickFilterText: params => {
                return params.value.name;
            },
            customerVisibility: true,
            width: '250',
        },
        {
            headerName: "Opening Unsettled Balance",
            field: "openingBalance",
            cellDataType: 'number',
            getQuickFilterText: params => {
                return params.value.name;
            },
            aggFunc: 'sum',
            customerVisibility: true,
            width: '250',
            cellRenderer: ({ value }) => (
                <Text>{value ? USformatter.format(value) : '0'}</Text>
            )
        },
        {
            headerName: "Number of Successful Transactions",
            field: "successfulTxn",
            cellDataType: 'number',
            aggFunc: 'sum',
            getQuickFilterText: params => {
                return params.value.name;
            },
            customerVisibility: true,
            width: '250',
            cellRenderer: ({ value }) => (
                <Text>{value ? USformatter.format(value) : '0'}</Text>
            )
        },
        {
            headerName: "Amount Received",
            field: "totalFiatAmount",
            cellDataType: 'number',
            getQuickFilterText: params => {
                return params.value.name;
            },
            aggFunc: 'sum',
            customerVisibility: true,
            width: '250',
            cellRenderer: ({ value }) => (
                <Text>{value ? USformatter.format(value) : '0'}</Text>
            )
        },
        {
            headerName: "Processing Fees",
            field: "totalTFFee",
            cellDataType: 'number',
            aggFunc: 'sum',
            getQuickFilterText: params => {
                return params.value.name;
            },
            customerVisibility: true,
            width: '250',
            cellRenderer: ({ value }) => (
                <Text>{value ? USformatter.format(value) : '0'}</Text>
            )
        },
        {
            headerName: "Other Fees",
            field: "totalOtherFee",
            cellDataType: 'number',
            aggFunc: 'sum',
            getQuickFilterText: params => {
                return params.value.name;
            },
            customerVisibility: true,
            width: '250',
            cellRenderer: ({ value }) => (
                <Text>{value ? USformatter.format(value) : '0'}</Text>
            )
        },
        {
            headerName: "Net Balance For the Day",
            field: "netBalance",
            cellDataType: 'number',
            aggFunc: 'sum',
            getQuickFilterText: params => {
                return params.value.name;
            },
            customerVisibility: true,
            width: '250',
            cellRenderer: ({ value }) => (
                <Text>{value ? USformatter.format(value) : '0'}</Text>
            )
        },
        {
            headerName: "Amount Settled ( Fiat )",
            field: "fiatAmountSettled",
            cellDataType: 'number',
            getQuickFilterText: params => {
                return params.value.name;
            },
            aggFunc: 'sum',
            customerVisibility: true,
            width: '250',
            cellRenderer: ({ value }) => (
                <Text>{value ? USformatter.format(value) : '0'}</Text>
            )
        },
        {
            headerName: "Amount transferred to prefund balance",
            field: "",
            cellDataType: 'number',
            getQuickFilterText: params => {
                return params.value.name;
            },
            aggFunc: 'sum',
            customerVisibility: true,
            width: '250',
            cellRenderer: ({ value }) => (
                <Text>{value ? USformatter.format(value) : '0'}</Text>
            )
        },
        {
            headerName: "Closing Unsettled Balance",
            field: "closingBalance",
            cellDataType: 'number',
            aggFunc: 'sum',
            getQuickFilterText: params => {
                return params.value.name;
            },
            customerVisibility: true,
            width: '250',
            cellRenderer: ({ value }) => (
                <Text>{value ? USformatter.format(value) : '0'}</Text>
            )
        },
        {
            headerName: "Settlement IDs",
            field: "settlements",
            getQuickFilterText: params => {
                return params.value.name;
            },
            width: '500',
            customerVisibility: true,
            cellRenderer: ({ value }) => (
                value?.map((val, i) => <Text key={i} onClick={() => handleClick(val)} style={{ color: "#1A4FD6", fontWeight: "bold", fontSize: '14px', marginRight: '2px' }} >{val}, </Text>)

            ),
        },
        {
            headerName: "Settlement Currency",
            field: "settlementCurrency",
            getQuickFilterText: params => {
                return params.value.name;
            },
            customerVisibility: true,
            width: '250',
            cellRenderer: ({ value }) => (
                value?.map((val, i) => <Text key={i}>{val}, </Text>)
            ),
        },
        {
            headerName: "Average Conversion Rate",
            field: "avgConversionRate",
            getQuickFilterText: params => {
                return params.value.name;
            },
            customerVisibility: true,
            width: '250',
            cellRenderer: ({ value }) => (
                <Text>{value ? USformatter.format(value) : '0'}</Text>
            ),
        },
        {
            headerName: "Settlement Amount in Settlement Currency",
            field: "cryptoAmountSettled",
            cellDataType: 'number',
            getQuickFilterText: params => {
                return params.value.name;
            },
            aggFunc: 'sum',
            customerVisibility: true,
            width: '250',
            cellRenderer: ({ value }) => (
                <Text>{value ? USformatter.format(value) : '0'}</Text>
            )
        },
        {
            headerName: "Transaction Hash",
            field: "transactionHash",
            getQuickFilterText: params => {
                return params.value.name;
            },
            customerVisibility: true,
            width: '250',
            cellRenderer: ({ value }) => (
                value?.map((val, i) => <Text key={i}>{val}, </Text>)
            ),
        },
    ];


    var filterParams = {
        // provide comparator function
        comparator: (filterLocalDateAtMidnight, cellValue) => {
            if (cellValue === null) return -1;
            let cellDate = new Date(cellValue);
            if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                return 0;
            }
            if (cellDate < filterLocalDateAtMidnight) {
                return -1;
            }
            if (cellDate > filterLocalDateAtMidnight) {
                return 1;
            }
            return 0;
        },
        browserDatePicker: true
    }


    return (
        <Stack
            direction={"column"}
            justifyContent={"space-between"}
            flexGrow={1}
            p={5}
        >
            <Box >
                <HStack pb={5} justifyContent={"flex-end"}>
                    <Button
                        leftIcon={<BiReset style={{ fontSize: "20px" }} />}
                        color={"#525C76"}
                        variant={"outline"}
                        borderColor={"#d2d2d2"}
                        _hover={{ bg: "#ffffff" }}
                        onClick={handleReset}
                    >
                        Reset
                    </Button>

                </HStack>
                {!isLoading ?
                    <Grid columns={columns} key={refreshGrid.toString()} getOrderData={getSettlementsData} type="collections" />
                    : <Loader />}
            </Box>

        </Stack>
    );
};

export default CollectionsSettlement;