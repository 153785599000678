import React, { useState, useContext, useRef, useEffect } from "react";
import Loader from "react-js-loader";
import { useParams, Link } from "react-router-dom";

import { DownloadIcon } from "@chakra-ui/icons";
import { Badge, useDisclosure, Button, useToast } from "@chakra-ui/react";
import { Box } from "@chakra-ui/react";
import { startCase, uniqBy, isEmpty, get, round } from "lodash";
import moment from "moment-timezone";
import { BiRefresh, BiReset } from "react-icons/bi";
import { BsCartPlus } from "react-icons/bs";
import { FiFilter } from "react-icons/fi";

import { NavBarContext } from "../../context/NavBarContext";
import { TimezoneContext } from "../../context/TimezoneContext";
import SplitView from "../../utils/SplitView";
import TimezoneFormatter from "../../utils/TimezoneFormatter";
import { statusMap, colorMap } from "../../utils/config";
import { getCountryData } from "../../utils/getCountries";
import { callApiWithToken } from "../../utils/utils";
import { getCxRoles, getOrgRoles } from "../App/useToken";
import OrderSpliViewContainer from "./OrderSpliViewContainer";
import BuyConfirmationModal from "./helper/BuyConfirmationModal";
import OrderFilter from "./helper/OrderFilter";
import SelectedConfirmModal from "./helper/SelectedConfirmModal";

export default function BuyOrders() {
    const { orderId } = useParams();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isOpenSelectedModal, onOpen: onOpenSelectedModal, onClose: onCloseSelectedModal } = useDisclosure();
    const { isOpen: isOpenConfirmationModal, onOpen: onOpenConfirmationModal, onClose: onCloseConfirmationModal } = useDisclosure();
    const { isOpen: isOpenOtcOrders, onOpen: onOpenOtcOrders, onClose: onCloseOtcOrders } = useDisclosure({ defaultIsOpen: false });
    const [countryOptions, setCountryOptions] = useState([]);
    const [fiatCurrency, setFiatCurrency] = useState([]);
    const [paymentOptions, setPaymentOptions] = useState([]);
    const [formValues, setFormValues] = useState({});
    const [inputData, setInputData] = useState({});
    const defaultDate = { startDate: moment().subtract(2, "months"), endDate: moment().format("YYYY-MM-DD 23:59:59") };
    const [filters, setFilters] = useState({ dateRange: defaultDate });
    const [dateRange, setDateRange] = useState(defaultDate);
    const [selectedRows, setSelectedRows] = useState([]);
    const [summaryDetails, setSummaryDetails] = useState([]);
    const [selectedModalMessage, setSelectedModalMessage] = useState("");
    const toast = useToast();

    const roles = getOrgRoles();
    const { selectedOrg } = useContext(NavBarContext);
    const { timezone } = useContext(TimezoneContext);
    const gridRef = useRef();

    const showColumn = roles.includes("transfi_admin");

    useEffect(() => {
        getCountryList();
        getPaymentList();
    }, []);

    useEffect(() => {
        refreshGrid();
    }, [timezone]);

    const refreshGrid = () => {
        gridRef.current && gridRef.current.refreshGrid();
    };

    const downloadReport = () => {
        gridRef.current.downloadReport();
    };

    const getCountryList = async () => {
        const { countryList, fiatList } = await getCountryData();
        setCountryOptions(countryList);
        setFiatCurrency(fiatList);
    };

    const getPaymentList = async () => {
        const response = await callApiWithToken("GET", `/api/orders/getPaymentMethods`);
        const data = response.data;
        let list = data.map((country) => {
            return { value: country.name, label: country.name };
        });
        setPaymentOptions(uniqBy(list, (obj) => obj.value));
    };

    const numberFormatter = (value) => {
        const number = round(value, 2).toFixed(2)
        const result = parseFloat(number).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        })
        return result === "NaN" ? "0.00" : result;
    };

    const selectionCallback = React.useCallback((ids) => setSelectedRows(ids), []);

    const createBatch = () => {
        if (selectedRows.length) {
            setSelectedModalMessage("Settlement will be created on selected transactions on current screen.");
        } else {
            setSelectedModalMessage("Settlements will be created on filtered date");
        }
        onOpenSelectedModal();
    }

    const summaryView = async () => {
        setLoading(true);
        const selectedDetails = selectedRows.map((data) => data.orderId);
        const response = await callApiWithToken("POST", "/api/settlement/revShareBatch/overview", {
            data: selectedDetails,
            filters: { impersonatedOrg: selectedOrg, filters, type: 'buy' }
        });
        setSummaryDetails(response?.message);
        onOpenConfirmationModal();
        setError(response?.error);
        setLoading(false);
    }

    const handleSettlement = () => {
        summaryView();
    }

    const columns = [
        {
            Header: "Order Id",
            accessor: "orderId",
            width: "200",
            Cell: ({ value }) => {
                return (
                    <Link style={{ color: "#4ACDED", fontWeight: "bold" }} to={`/buy-orders/${value}`}>
                        {value}
                    </Link>
                );
            },
            zIndex: 1,
            position: 'sticky',
            textAlign: 'center',
        },
        {
            Header: "Customer Order Id",
            accessor: "customer.orderId",
            width: "150",
            show: roles.includes("transfi_admin") ? true : false,
            Cell: ({ value }) => {
                return value || "N/A"
            },
        },
        {
            Header: "Customer",
            accessor: "customer.formattedName",
            width: "100",
            show: roles.includes("transfi_admin") ? true : false,
            Cell: ({ value }) => {
                return value || "N/A"
            }
        },
        {
            Header: "Email",
            accessor: "email",
            width: "230",
            Cell: ({ value }) => {
                return value || "N/A"
            }
        },
        {
            Header: "Status",
            accessor: "status",
            width: "130",
            Cell: ({ row }) => {
                const { status, type } = row?.original;
                const newStatus = statusMap[type][status] || status;
                const color = colorMap[type][status] || "blue";
                return <Badge colorScheme={color}> {newStatus} </Badge>;
            },
        },
        {
            Header: "User Country",
            width: "150",
            Cell: ({ row }) => {
                return row?.original?.userCountry || "N/A";
            }
        },
        {
            Header: "Date Order Initiated",
            width: "120",
            Cell: ({ row }) => {
                return <TimezoneFormatter date={row?.original.createdAt} format={"MM/DD/YYYY"} />
            },
            color: "info",
        },
        {
            Header: "Time Order Initiated",
            width: "120",
            Cell: ({ row }) => {
                return <TimezoneFormatter date={row?.original.createdAt} format={'LT'} />
            },
            color: "info",
        },
        {
            Header: "Date Completed",
            width: "100",
            Cell: ({ row }) => {
                if (!isEmpty(row?.original?.timestamps)) {
                    if (row?.original?.status === "asset_settled") {
                        return <TimezoneFormatter date={row?.original?.timestamps?.assetSettledAt} format={"MM/DD/YYYY"} />
                    }
                    else {
                        return "N/A"
                    }
                } else {
                    return "N/A"
                }
            },
            color: "info",
        },
        {
            Header: "Time Completed",
            width: "100",
            Cell: ({ row }) => {
                if (!isEmpty(row?.original?.timestamps)) {
                    if (row?.original?.status === "asset_settled") {
                        return <TimezoneFormatter date={row?.original?.timestamps?.assetSettledAt} format={"LT"} />
                    }
                    else {
                        return "N/A"
                    }
                }
                else {
                    return 'N/A'
                }
            },
            color: "info",
        },
        {
            Header: "Fiat Payment Partner",
            width: "150",
            show: roles.includes("transfi_admin") ? true : false,
            color: "warning",
            Cell: ({ row }) => {
                if (!isEmpty(row?.original?.fiat?.partner)) {
                    return row?.original?.fiat?.partner
                } else {
                    return row?.original?.paymentGateway
                }
            }
        },
        {
            Header: "Fiat Partner Order ID",
            accessor: "fiat.orderId",
            width: "150",
            show: roles.includes("transfi_admin") ? true : false,
            color: "warning",
            Cell: ({ value }) => value || "-",
        },
        {
            Header: "Fiat Payment Method",
            width: "150",
            color: "warning",
            Cell: ({ row }) => {
                if (!isEmpty(row?.original?.fiat?.paymentName)) {
                    return row?.original?.fiat?.paymentName;
                } else {

                    return row?.original?.paymentName;
                }
            }
        },
        {
            Header: "Fiat Currency",
            width: "120",
            color: "warning",
            Cell: ({ row }) => {
                if (!isEmpty(row?.original?.fiat?.currency)) {
                    return row?.original?.fiat?.currency;
                } else {

                    return row?.original?.fiatTicker;
                }
            }
        },
        {
            Header: "Gross Fiat Amount (Received from the user)",
            width: "230",
            color: "warning",
            Cell: ({ row }) => {
                if (!isEmpty(row?.original?.fiat?.orderAmount)) {
                    return numberFormatter(row?.original?.fiat?.orderAmount)
                } else {
                    return numberFormatter(row?.original?.fiatAmount)
                }
            }
        },
        {
            Header: "Network fees / Gas fees (Estimated)",
            width: "180",
            show: roles.includes("transfi_admin") ? true : false,
            Cell: ({ row }) => { return numberFormatter(row?.original?.fees?.networkFee) || "0" },
            color: "warning",
        },
        {
            Header: "Processing fees %",
            // accessor: "fiat.processingFees",
            width: "120",
            show: roles.includes("transfi_admin") ? true : false,
            Cell: ({ row }) => {
                const processingRate = get(row?.original, "fiat.processingRate", 0)
                const tfFee = Number(get(row.original, "fiat.tfFee", 0))
                const cxFee = Number(get(row.original, "fiat.cxFee", 0))
                const fiatAmount = row?.original?.fiat?.orderAmount ? Number(row?.original?.fiat?.orderAmount || 0) : row?.original?.fiatAmount;
                if(row?.original?.fiat?.processingFeeMode === 'hybrid'){
                    return processingRate;
                }
                if (processingRate > 0) {
                    return numberFormatter(processingRate) + "%";
                }
                if (tfFee >= 0 && cxFee >= 0 && isEmpty(processingRate)) {
                    const totalFees = tfFee + cxFee;
                    return numberFormatter((totalFees / fiatAmount) * 100) + "%";
                }
                else {
                    return "N/A"
                }

            },
            color: "warning",
        },
        {
            Header: "Processing fees amt.",
            width: "180",
            Cell: ({ row }) => {
                if (isEmpty(row?.original?.fiat?.processingFee)) {
                    const tfFee = Number(get(row.original, "fiat.tfFee", 0))
                    const cxFee = Number(get(row.original, "fiat.cxFee", 0))
                    const processingFee = (tfFee + cxFee).toFixed(2)
                    return numberFormatter(processingFee)
                } else {
                    const processingFee = Number(row?.original?.fiat?.processingFee).toFixed(2)
                    return numberFormatter(processingFee)
                }

            },
            color: "warning",
        },
        {
            Header: "Discount %",
            width: "180",
            show: roles.includes("transfi_admin") ? true : false,
            Cell: ({ row }) => {
                const discountRate = Number(get(row.original, "fiat.discountRate", 0))
                return numberFormatter(discountRate)

            },
            color: "warning",
        },
        {
            Header: "Discount amt.",
            width: "180",
            Cell: ({ row }) => {
                const discountRate = Number(get(row.original, "fiat.discount", 0))
                return numberFormatter(discountRate)

            },
            color: "warning",
        },
        {
            Header: "Net Amount",
            width: "150",
            color: "warning",
            show: roles.includes("transfi_admin") ? true : false,
            Cell: ({ row }) => {
                if (isEmpty(row?.original?.fiat?.processingFee)) {
                    const tfFee = Number(get(row.original, "fiat.tfFee", 0))
                    const cxFee = Number(get(row.original, "fiat.cxFee", 0))
                    const gasFee = Number(get(row.original, "fees.networkFee", 0))
                    const discountRate = Number(get(row.original, "fiat.discount", 0))
                    const fiatAmount = row?.original?.fiat?.orderAmount ? Number(row?.original?.fiat?.orderAmount || 0) : row?.original?.fiatAmount;
                    return numberFormatter(fiatAmount - ((tfFee + cxFee) - discountRate ) - gasFee);
                } else {
                    const fiatAmount = row?.original?.fiat?.orderAmount ? Number(row?.original?.fiat?.orderAmount || 0) : row?.original?.fiatAmount;
                    const processFees = Number(get(row.original, "fiat.processingFee", 0))
                    const gasFee = Number(get(row.original, "fees.networkFee", 0))
                    const discountRate = Number(get(row.original, "fiat.discount", 0))
                    return numberFormatter(fiatAmount - (processFees - discountRate) - gasFee);
                }
            }

        },
        // {
        //     Header: "Transaction mointioring fees (Sumsub)",
        //     width: "230",
        //     accessor: "fiat.kytFee",
        //     color: "warning",
        //     Cell: ({ value }) => "0.00",
        //     show: roles.includes("transfi_admin") ? true : false,
        // },
        {
            Header: "Amt. Received In Sub Account - API",
            width: "150",
            show: roles.includes("transfi_admin") ? true : false,
            Cell: ({ row }) => {
                if (row?.original?.fiat?.feeSource === "partner"){
                const fiatAmount = row?.original?.fiat?.orderAmount ? Number(row?.original?.fiat?.orderAmount || 0) : Number(row?.original?.fiatAmount || 0);
                const buyRate = Number(get(row.original, "fiat.partnerFee", 0))
                if (row?.original?.fiat?.partnerFee) {
                    if (row?.original?.fiat?.partnerFee === 0 || row?.original?.fiat?.partnerFee === "NaN") {
                        return numberFormatter(fiatAmount);
                    } else {
                        return numberFormatter(fiatAmount - buyRate);
                    }
                } else {
                    return numberFormatter(fiatAmount);
                }
                }
                else{
                    return ("-");
                }
                
            },
            color: "warning",
        },
        {
            Header: "Amt. Received In Sub Account - Calculated",
            width: "150",
            show: roles.includes("transfi_admin") ? true : false,
            Cell: ({ row }) => {
                if (isEmpty(row?.original?.fiat?.feeSource)){
                    const fiatAmount = row?.original?.fiat?.orderAmount ? Number(row?.original?.fiat?.orderAmount || 0) : Number(row?.original?.fiatAmount || 0);
                    const buyRate = Number(get(row.original, "fiat.partnerFee", 0))
                    if (row?.original?.fiat?.partnerFee) {
                        if (row?.original?.fiat?.partnerFee === 0 || row?.original?.fiat?.partnerFee === "NaN") {
                            return numberFormatter(fiatAmount);
                        } else {
                            return numberFormatter(fiatAmount - buyRate);
                        }
                    } else {
                        return numberFormatter(fiatAmount);
                    }
                }else{
                    return ("-");
                }
            
            },
            color: "warning",
        },
        {
            Header: "Payment partner fees",
            width: "150",
            show: roles.includes("transfi_admin") ? true : false,
            color: "warning",
            Cell: ({ row }) => {
                if (row?.original?.fiat?.partnerFee) {
                    if (row?.original?.fiat?.partnerFee === 0 || row?.original?.fiat?.partnerFee === "NaN") {
                        return "0.00";
                    } else {
                        return numberFormatter(row?.original?.fiat?.partnerFee);
                    }
                } else {
                    return "0.00";
                }

            }
        },
        // {
        //     Header: "Sub Account Receipt Date",
        //     width: "180",
        //     color: "warning",
        //     // Cell: ({ row }) => {
        //     //     if (isEmpty(row?.original?.fiat?.cxFee) || row?.original?.fiat?.cxFee === "NaN") {
        //     //         return "0.00";
        //     //     } else {
        //     //         return row?.original?.fiat?.cxFee;
        //     //     }

        //     // },
        // },
        {
            Header: "TransFi Gross Fee",
            width: "150",
            color: "warning",
            show: roles.includes("transfi_admin") ? true : false,
            Cell: ({ row }) => {
                if (isEmpty(row?.original?.fiat?.tfFee) || row?.original?.fiat?.tfFee === "NaN") {
                    return "0.00";
                } else {
                    return numberFormatter(row?.original?.fiat?.tfFee);
                }

            },
        },
        {
            Header: "Customer Revenue Share",
            width: "150",
            color: "warning",
            Cell: ({ row }) => {
                if (isEmpty(row?.original?.fiat?.cxFee) || row?.original?.fiat?.cxFee === "NaN") {
                    return "0.00";
                } else {
                    return numberFormatter(row?.original?.fiat?.cxFee);
                }

            },
        },
        {
            Header: "XE Conversion Rate to USD",
            width: "150",
            color: "warning",
            show: roles.includes("transfi_admin") ? true : false,
            Cell: ({ row }) => {
                if (isEmpty(row?.original?.fiat?.conversionRateWithoutMarkup)) {
                    return numberFormatter(1 / row?.original?.fiat?.conversionRate);
                } else {
                    return numberFormatter(1 / row?.original?.fiat?.conversionRateWithoutMarkup);
                }

            },
        },
        {
            Header: "Conversion Rate to USD",
            width: "150",
            color: "warning",
            show: roles.includes("transfi_admin") ? true : false,
            Cell: ({ row }) => {
                if (isEmpty(row?.original?.fiat?.conversionRate)) {
                    return "0.00";
                } else {
                    return numberFormatter(1 / row?.original?.fiat?.conversionRate);
                }

            },
        },
        {
            Header: "Conversion Rate to EUR",
            width: "150",
            color: "warning",
            show: roles.includes("transfi_admin") ? true : false,
            Cell: ({ row }) => {
                if (isEmpty(row?.original?.fiat?.conversionRateInEur)) {
                    return "0.00";
                } else {
                    return numberFormatter(1 / row?.original?.fiat?.conversionRateInEur);
                }

            },
        },
        {
            Header: "GTV USD",
            width: "150",
            color: "warning",
            show: roles.includes("transfi_admin") ? true : false,
            Cell: ({ row }) => {
                if (isEmpty(row?.original?.fiat?.conversionRate)) {
                    return "0.00";
                } else {
                    const fiatAmount = row?.original?.fiat?.orderAmount ? Number(row?.original?.fiat?.orderAmount || 0) : row?.original?.fiatAmount;
                    const conversionRate = round(Number(row?.original?.fiat?.conversionRate || 0), 6)
                    return numberFormatter(fiatAmount * conversionRate);
                }
            },
        },
        {
            Header: "Amount received in Sub account(USD)",
            width: "180",
            color: "warning",
            show: roles.includes("transfi_admin") ? true : false,
            Cell: ({ row }) => {
                if (isEmpty(row?.original?.fiat?.conversionRate)) {
                    return "0.00";
                } else {
                    const fiatAmount = row?.original?.fiat?.orderAmount ? Number(row?.original?.fiat?.orderAmount || 0) : row?.original?.fiatAmount;
                    const buyRate = Number(row?.original?.fiat?.partnerFee || 0)
                    const subAcc = fiatAmount - buyRate;
                    const conversionRate = Number(row?.original?.fiat?.conversionRate)
                    return numberFormatter(subAcc * conversionRate);
                }
            },
        },
        {
            Header: "Processing fees amt. (USD)",
            width: "180",
            show: roles.includes("transfi_admin") ? true : false,
            Cell: ({ row }) => {
                if (isEmpty(row?.original?.fiat?.conversionRate)) {
                    return "0.00";
                } else {
                    const conversionRate = Number(row?.original?.fiat?.conversionRate)
                    if (isEmpty(row?.original?.fiat?.processingFee) && !isEmpty(conversionRate)) {
                        const tfFee = Number(get(row.original, "fiat.tfFee", 0))
                        const cxFee = Number(get(row.original, "fiat.cxFee", 0))
                        return numberFormatter((tfFee + cxFee) * conversionRate);
                    } else {
                        return numberFormatter(row?.original?.fiat?.processingFee * conversionRate);
                    }
                }

            },
            color: "warning",
        },
        {
            Header: "Discount Amt. (USD)",
            width: "170",
            show: roles.includes("transfi_admin") ? true : false,
            color: "warning",
            Cell: ({ row }) => {
                if (isEmpty(row?.original?.fiat?.conversionRate) && row?.original?.fiat?.discount === 0 || row?.original?.fiat?.discount === "NaN") {
                    return "0.00";
                } else {
                    const conversionRate = Number(row?.original?.fiat?.conversionRate)
                    const discountRate = Number(row?.original?.fiat?.discount || 0);
                    return numberFormatter(conversionRate * discountRate);
                }
            }
        },
        {
            Header: "Payment partner fees (USD)",
            width: "170",
            show: roles.includes("transfi_admin") ? true : false,
            color: "warning",
            Cell: ({ row }) => {
                if (isEmpty(row?.original?.fiat?.conversionRate) && row?.original?.fiat?.partnerFee === 0 || row?.original?.fiat?.partnerFee === "NaN") {
                    return "0.00";
                } else {
                    const conversionRate = Number(row?.original?.fiat?.conversionRate)
                    const partnerFee = Number(row?.original?.fiat?.partnerFee || 0);
                    return numberFormatter(conversionRate * partnerFee);
                }
            }
        },
        {
            Header: "Customer Revenue Share (USD)",
            width: "200",
            show: roles.includes("transfi_admin") ? true : false,
            color: "warning",
            Cell: ({ row }) => {
                if (isEmpty(row?.original?.fiat?.conversionRate || row?.original?.fiat?.cxFee) || row?.original?.fiat?.cxFee === "NaN") {
                    return "0.00";
                } else {
                    const conversionRate = Number(row?.original?.fiat?.conversionRate)
                    const customerFee = Number(row?.original?.fiat?.cxFee || 0);
                    return numberFormatter(conversionRate * customerFee);
                }
            }
        },
        {
            Header: "TransFi Fees (USD)",
            width: "170",
            show: roles.includes("transfi_admin") ? true : false,
            color: "warning",
            Cell: ({ row }) => {
                if (isEmpty(row?.original?.fiat?.conversionRate || row?.original?.fiat?.cxFee) || row?.original?.fiat?.cxFee === "NaN") {
                    return "0.00";
                } else {
                    const conversionRate = Number(row?.original?.fiat?.conversionRate)
                    const customerFee = Number(row?.original?.fiat?.cxFee || 0);
                    const partnerFee = Number(row?.original?.fiat?.partnerFee || 0);
                    let processingFee;
                    if (isEmpty(row?.original?.fiat?.processingFee) && !isEmpty(conversionRate)) {
                        const tfFee = Number(get(row.original, "fiat.tfFee", 0))
                        const cxFee = Number(get(row.original, "fiat.cxFee", 0))
                        processingFee = tfFee + cxFee
                    } else {
                        processingFee = row?.original?.fiat?.processingFee;
                    }
                    const discountRate = Number(get(row.original, "fiat.discount", 0))
                    const customerFeeUsd = (customerFee * conversionRate).toFixed(3);
                    const partnerFeeUsd = (partnerFee * conversionRate).toFixed(3);
                    const processingFeeUsd = (processingFee * conversionRate).toFixed(3);
                    const discountUsd = (discountRate * conversionRate).toFixed(3);

                    return numberFormatter(processingFeeUsd - partnerFeeUsd - customerFeeUsd - discountUsd);
                }
            }
        },
        {
            Header: "Crypto To Be Sold If Zero Forex Markup",
            width: "200",
            show: roles.includes("transfi_admin") ? true : false,
            color: "warning",
            Cell: ({ row }) => {
                const conversionsRate = Number(row?.original?.fiat?.conversionRate)
                const conversionRate = numberFormatter(1/conversionsRate)
                if (isEmpty(row?.original?.fiat?.processingFee)) {
                    const tfFee = Number(get(row.original, "fiat.tfFee", 0))
                    const cxFee = Number(get(row.original, "fiat.cxFee", 0))
                    const discountRate = Number(get(row.original, "fiat.discount", 0))
                    const fiatAmount = row?.original?.fiat?.orderAmount ? Number(row?.original?.fiat?.orderAmount || 0) : row?.original?.fiatAmount;
                    return numberFormatter((fiatAmount - (tfFee + cxFee) + discountRate) / conversionRate);
                } else {
                    const fiatAmount = row?.original?.fiat?.orderAmount ? Number(row?.original?.fiat?.orderAmount || 0) : row?.original?.fiatAmount;
                    const processFees = Number(get(row.original, "fiat.processingFee", 0))
                    const discountRate = Number(get(row.original, "fiat.discount", 0))
                    return numberFormatter((fiatAmount - processFees + discountRate) / conversionRate);
                }
            }
        },
        {
            Header: "Conversion rate charged by TransFi",
            width: "200",
            show: roles.includes("transfi_admin") ? true : false,
            color: "warning",
            Cell: ({ row }) => {
                const conversionRate = Number(get(row.original, "fiat.conversionRate", 0));
                const markupRate = Number(get(row.original, "fiat.markupFee", 0));
                let conversionAmount = 0;
                if (conversionRate !== 0) {
                    conversionAmount = Number(1 / conversionRate);
                }
                if (markupRate !== 0 && conversionAmount !== 0) {
                    const markupPercent = conversionAmount * markupRate;
                    return numberFormatter(conversionAmount + markupPercent);
                } else {
                    return numberFormatter(conversionAmount);
                }

            }
        },
        {
            Header: "Crypto to be Sold (USD)",
            width: "170",
            show: roles.includes("transfi_admin") ? true : false,
            color: "warning",
            Cell: ({ row }) => {
                const conversionRate = Number(get(row.original, "fiat.conversionRate", 0));
                const markupRate = Number(get(row.original, "fiat.markupFee", 0));
                let conversionAmount = 0;
                let markupAmount = 0;
                if (conversionRate !== 0) {
                    conversionAmount = Number(1 / conversionRate,2).toFixed(2)
                }
                if (markupRate !== 0 && conversionAmount !== 0) {
                    markupAmount = Number(conversionAmount * markupRate).toFixed(4);
                }
                const conversionRateCharged = Number(Number(conversionAmount) + Number(markupAmount)).toFixed(4);
                
                if (isEmpty(row?.original?.fiat?.processingFee)) {
                    const tfFee = Number(get(row.original, "fiat.tfFee", 0))
                    const cxFee = Number(get(row.original, "fiat.cxFee", 0))
                    const discountRate = Number(get(row.original, "fiat.discount", 0))
                    const fiatAmount = row?.original?.fiat?.orderAmount ? Number(row?.original?.fiat?.orderAmount || 0) : row?.original?.fiatAmount;
                    return numberFormatter((fiatAmount - (tfFee + cxFee) + discountRate) / conversionRateCharged);
                } else {
                    const fiatAmount = row?.original?.fiat?.orderAmount ? Number(row?.original?.fiat?.orderAmount || 0) : row?.original?.fiatAmount;
                    const processFees = Number(get(row.original, "fiat.processingFee", 0))
                    const discountRate = Number(get(row.original, "fiat.discount", 0))
                    return numberFormatter((fiatAmount - processFees + discountRate) / conversionRateCharged);
                }
            }
        },
        {
            Header: "Revenue from forex",
            width: "170",
            show: roles.includes("transfi_admin") ? true : false,
            color: "warning",
            Cell: ({ row }) => {
                const fiatAmount = row?.original?.fiat?.orderAmount ? Number(row?.original?.fiat?.orderAmount || 0) : row?.original?.fiatAmount;
                const conversionRate = round(Number(row?.original?.fiat?.conversionRate || 0), 6)
                let gtv = Number((fiatAmount * conversionRate).toFixed(2));
                const markupRate = Number(get(row.original, "fiat.markupFee", 0));
                return numberFormatter(gtv * markupRate); 
            }
        },
        {
            Header: "Crypto Payment Partner",
            accessor: "crypto.partner",
            width: "170",
            Cell: ({ value }) => startCase(value) || "NA",
            show: roles.includes("transfi_admin") ? true : false,
            color: "info",
        },
        {
            Header: "Crtypo Payment Order ID",
            accessor: "crypto.orderId",
            width: "170",
            Cell: ({ value }) => value || "NA",
            show: roles.includes("transfi_admin") ? true : false,
            color: "info",
        },
        {
            Header: "USDC / USDT sold",
            accessor: "crypto.conversionAmount",
            width: "130",
            Cell: ({ value }) => numberFormatter(value) || "NA",
            show: roles.includes("transfi_admin") ? true : false,
            color: "info",
        },
        {
            Header: "Crypto Ticker",
            width: "100",
            Cell: ({ row }) => {
                if (!isEmpty(row?.original?.crypto?.currency)) {
                    return row?.original?.crypto?.currency;
                } else {
                    return row?.original?.cryptoTicker;
                }
            },
            color: "info",
        },
        {
            Header: "Crypto Purchase Rate",
            width: "150",
            Cell: ({ row }) => {
                if (row?.original?.crypto?.conversionRate) {
                    return numberFormatter(row?.original?.crypto?.conversionRate);
                } else {
                    return numberFormatter(row?.original?.cryptoUnitPrice);
                }
            },
            show: roles.includes("transfi_admin") ? true : false,
            color: "info",
        },
        {
            Header: "Crypto Units  Delivered",
            width: "150",
            Cell: ({ row }) => {
                if (!isEmpty(row?.original?.crypto?.orderAmount)) {
                    return numberFormatter(row?.original?.crypto?.orderAmount);
                } else {
                    return numberFormatter(row?.original?.cryptoAmount);
                }
            },
            color: "info",
        },
        {
            Header: "Gas fees Actual ",
            width: "120",
            Cell: ({ row }) => {
                if(!isEmpty(row?.original?.crypto?.gasFee)){
                    return round(row?.original?.crypto?.gasFee ,3);
                } else {
                    const networkFeeValue = Number(get(row?.original, "fees.networkFee", 0));
                    const conversionRate = round(Number(row?.original?.fiat?.conversionRate || 0), 6);
                    let cryptoRate
                    if (row?.original?.crypto?.conversionRate) {
                        cryptoRate = Number(get(row?.original, "crypto.conversionRate", 0));
                    } else {
                        cryptoRate = Number(get(row?.original, "cryptoUnitPrice", 0));
                    }
                    const gasFees = Number((networkFeeValue * conversionRate)/cryptoRate || 0)
                    return round(gasFees, 3);
                }
             },
             show: roles.includes("transfi_admin") ? true : false,
            color: "info",
        },
        {
            Header: "Gas fees in USD (calculated) ",
            width: "200",
            color: "info",
            Cell: ({ row }) => {
                let conversionRate
                if(!isEmpty(row?.original?.crypto?.gasFee)){
                    if (row?.original?.crypto?.conversionRate) {
                        conversionRate = Number(get(row?.original, "crypto.conversionRate", 0));
                    } else {
                        conversionRate = Number(get(row?.original, "cryptoUnitPrice", 0));
                    }
                    const gasFee = Number(get(row?.original, "crypto.gasFee", 0));
                    return numberFormatter(gasFee * conversionRate)
                } else {
                    conversionRate = Number(get(row?.original, "fiat.conversionRate", 0));
                    const gasFee = Number(get(row?.original, "fees.networkFee", 0));
                    return numberFormatter(gasFee * conversionRate)
                }  
            },
            show: roles.includes("transfi_admin") ? true : false,
        },

        // {
        //     Header: "Net (of gas fees) Crypto paid",
        //     width: "180",
        //     Cell: ({ value }) => startCase(value) || "NA",
        //     color: "info",
        // },
        {
            Header: "Txn Hash",
            width: "200",
            accessor: "txnHash",
            color: "info",
            Cell: ({ value }) => {
                return value || "N/A"
            }
        },
        {
            Header: "Crypto Wallet Address",
            width: "200",
            color: "info",
            Cell: ({ row }) => {
                if (row?.original?.type === "sell") {
                    if (!isEmpty(row?.original?.metaData?.sendersWalletAddress)) {
                        return row?.original?.metaData?.sendersWalletAddress[0];
                    } else {
                        return "N/A";
                    }
                }
                else {
                    if (!isEmpty(row?.original?.walletAddress)) {
                        return row?.original?.walletAddress;
                    } else return "N/A";
                }

            }
        },
        {
            Header: "Api Key",
            accessor: "apiKey",
            width: "150",
            color: "info",
            Cell: ({ value }) => {
                return value || "N/A"
            },
        },
        {
            Header: "Settlement Batch ID",
            accessor: "batchId",
            width: "30",
            Cell: ({ value }) => {
                return (
                    <Link style={{ color: "#4ACDED", fontWeight: "bold" }} to={`/cx-revshare-settlements/batch/${value}`}>
                        {value}
                    </Link> || "NA"
                );
            },
        },
        {
            Header: "User Device",
            width: "150",
            color: "info",
            Cell: ({ row }) => {
                const userAgent = row?.original?.deviceDetails?.userAgent?.toLowerCase();
                if(!userAgent){
                    return "Others";
                }
                if (userAgent.includes('ipad')) {
                    return 'iPad';
                } else if (userAgent.includes('android')) {
                    if (userAgent.includes('mobile')) {
                        return 'Android Phone';
                    } else {
                        return 'Android Tablet';
                    }
                } else if (userAgent.includes('iphone')) {
                    return 'iPhone';
                } else if (userAgent.includes('windows')) {
                    return 'Windows';
                } else if (userAgent.includes('macintosh') || userAgent.includes('mac os x')) {
                    return 'Macbook';
                } else {
                    return 'Others';
                }
            },
        },
    ];

    const condensedColumns = {
        Header: "Order Id",
        accessor: "orderId",
        Cell: ({ row, value }) => {
            const { status, userId, type } = row || {};
            const newStatus = statusMap[type][status] || status;
            const color = colorMap[type][status] || "blue";
            return (
                <>
                    <div>
                        <span color="#4ACDED">{value}</span>
                        <Badge style={{ marginLeft: "20px" }} colorScheme={color}>
                            {" "}
                            {startCase(newStatus)}{" "}
                        </Badge>
                    </div>
                    <div style={{ fontSize: "13px" }}>{userId}</div>
                </>
            );
        },
    };

    const handleOnReset = () => {
        setDateRange(defaultDate);
        setFilters({ dateRange: defaultDate });
        setFormValues({});
        setInputData({});
    };

    return !loading ? (
        <Box style={{ background: "white" }} heading="Orders">
            {orderId ? (
                <></>
            ) : (
                <div style={{ marginTop: "2%" }}>
                    <div style={{ float: "right" }}>
                        <Button
                            leftIcon={<DownloadIcon />}
                            onClick={() => downloadReport()}
                            variant={"outline"}
                            colorScheme="blue"
                            style={{ margin: "0 8px" }}
                            size="sm"
                        >
                            Download Report
                        </Button>
                        {showColumn ? <Button
                            leftIcon={<BsCartPlus style={{ fontSize: "20px" }} />}
                            onClick={() => createBatch()}
                            variant={"outline"}
                            colorScheme="blue"
                            style={{ margin: "0 8px" }}
                            size="sm"
                        >
                            Create Settlement
                        </Button> : <></>}
                        <Button
                            leftIcon={<BiReset style={{ fontSize: "20px" }} />}
                            onClick={() => handleOnReset()}
                            variant={"outline"}
                            colorScheme="blue"
                            style={{ margin: "0 8px" }}
                            size="sm"
                        >
                            Reset
                        </Button>
                        <Button
                            leftIcon={<BiRefresh style={{ fontSize: "20px" }} />}
                            onClick={() => refreshGrid()}
                            variant={"outline"}
                            colorScheme="blue"
                            style={{ margin: "0 0px" }}
                            size="sm"
                        >
                            Refresh
                        </Button>
                        <Button
                            leftIcon={<FiFilter style={{ fontSize: "20px" }} />}
                            onClick={onOpen}
                            variant={"outline"}
                            colorScheme="blue"
                            style={{ margin: "0 8px" }}
                            size="sm"
                        >
                            Filter
                        </Button>
                    </div>
                    <br />
                </div>
            )}
            <SplitView
                ref={gridRef}
                columns={columns}
                condensed={orderId}
                condensedColumns={condensedColumns}
                dataUrl={"/api/orders/list"}
                condensedUrl={"/buy-orders"}
                filters={{ impersonatedOrg: selectedOrg, filters, type: "buy" }}
                selectRow={showColumn}
                onChangeSelectedRow={selectionCallback}
                initialState={{ selectedRowIds: selectedRows }}
                downloadReportUrl={"/api/report"}
                collection={"orders"}
                database={"transfi-nucleus"}
                gridFilters={{ "type": "buy", filters, impersonatedOrg: selectedOrg }}
                headerDetails={{ types: { "createdAt": "date" } }}
                gridName={"Buy Orders"}
            >
                <OrderSpliViewContainer orderId={orderId} />
            </SplitView>

            <OrderFilter
                onClose={onClose}
                isOpen={isOpen}
                countryOptions={countryOptions}
                fiatCurrency={fiatCurrency}
                paymentOptions={paymentOptions}
                formValues={formValues}
                setFormValues={setFormValues}
                filters={filters}
                setFilters={setFilters}
                dateRange={dateRange}
                setDateRange={setDateRange}
                inputData={inputData}
                setInputData={setInputData}
                handleOnReset={handleOnReset}
                type={"buy"}
            ></OrderFilter>
            <SelectedConfirmModal
                isOpen={isOpenSelectedModal}
                onOpen={onOpenSelectedModal}
                onClose={onCloseSelectedModal}
                message={selectedModalMessage}
                handleSettlement={handleSettlement}
                loading={loading}
            />
            <BuyConfirmationModal
                isLoading={loading}
                isOpen={isOpenConfirmationModal}
                onOpen={onOpenConfirmationModal}
                onClose={onCloseConfirmationModal}
                selectedRows={selectedRows}
                refreshGrid={refreshGrid}
                summaryDetails={summaryDetails}
                error={error}
                filters={filters}
            />
        </Box>
    ) : (
        <Loader type="spinner-cub" bgColor={"#FFFFFF"} title={"Loading..."} color={"cyan.400"} size={100} />
    );
}