import { Route, Routes, Navigate } from "react-router-dom";

import LoginFlow from "./components/Login/PasswordLogin/LoginFlow";
import NewPassword from "./components/Login/PasswordLogin/NewPassword";

export default function UnauthorizedRoutes(props) {
    const { setToken } = props || {};
    return (
        <>
            <div className="router">
                <Routes>
                    <Route path="*" element={<Navigate to="account/login" />} />
                    <Route path="account/login" element={<LoginFlow setToken={setToken}/>}></Route>
                    <Route path="account/login-pwd/new" element={<NewPassword />}></Route>
                    <Route path="account/login-pwd/reset" element={<NewPassword reset={true} />}></Route>
                </Routes>
            </div>
        </>
    );
}
