import { useState } from 'react';

import { startCase, split } from 'lodash';

const useToken = () => {

  const tokenString = localStorage.getItem('token');
  const [token, setToken] = useState(tokenString);

  const saveToken = user => {
    sessionStorage.setItem('name', user.name);
    sessionStorage.setItem('userEmail', user.userEmail);
    sessionStorage.setItem('env', user.env);
    sessionStorage.setItem('cxId', user.cxId);
    sessionStorage.setItem('orgId', user.orgId);
    sessionStorage.setItem('apiKey', user.apiKey);
    sessionStorage.setItem('orgRoles', user.orgRoles);
    sessionStorage.setItem('cxRoles', user.cxRoles);
    sessionStorage.setItem('orgName', user.orgName);
    sessionStorage.setItem('grpOrgId', user.grpOrgId);
    sessionStorage.setItem('defaultTimezone', user?.defaultTimezone);
  };

  return {
    setToken: saveToken,
    token
  }
}

const getUserName = () => {
  const email = sessionStorage.getItem('userEmail') === null ? "" : sessionStorage.getItem('userEmail');
  const name = startCase(email.split('@')[0]);
  return name;
}

const getUserEmail = () => {
  const userEmail = sessionStorage.getItem('userEmail') === null ? "" : sessionStorage.getItem('userEmail');
  return userEmail;
}

const getEnv = () => {
  const env = sessionStorage.getItem('env') === null ? "local" : sessionStorage.getItem('env');
  return env;
}

const getCustomerId = () => {
  const cxId = sessionStorage.getItem('cxId') === null ? "" : sessionStorage.getItem('cxId');
  return cxId;
}

const getOrgId = () => {
  const cxId = sessionStorage.getItem('orgId') === null ? "" : sessionStorage.getItem('orgId');
  return cxId;
}

const getOrgName = () => {
  const orgName = sessionStorage.getItem('orgName') === null ? "" : sessionStorage.getItem('orgName');
  return orgName;
}

const getOrgRoles = () => {
  const roles = sessionStorage.getItem('orgRoles') === null ? "" : sessionStorage.getItem('orgRoles');
  return split(roles, ",");
}

const getCxRoles = () => {
  const roles = sessionStorage.getItem('cxRoles') === null ? "" : sessionStorage.getItem('cxRoles');
  return split(roles, ",");
};

const getGrpOrgId = () =>{
  const grpOrgId = sessionStorage.getItem('grpOrgId') === null ? "" : sessionStorage.getItem('grpOrgId');
  return grpOrgId;
}

export {
  useToken,
  getUserName,
  getEnv,
  getCustomerId,
  getOrgId,
  getOrgName,
  getUserEmail,
  getOrgRoles,
  getCxRoles,
  getGrpOrgId
}