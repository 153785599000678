/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import Select from "react-select";

import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Input,
  useToast,
  chakra,
  Stack,
  Flex
} from '@chakra-ui/react';
import { useClipboard } from '@chakra-ui/react'
import { useFormik } from 'formik';
import * as Yup from "yup";

import { getOrgId } from '../../components/App/useToken';
import { callApiWithToken } from "../../utils/utils";

const ViewModal = (props) => {
  const { isOpen, onClose, isLoading, refreshGrid } = props;

  const [currency, setCurrency] = useState("");

  const { value, setValue, hasCopied: hasCopiedWalletAddress, onCopy: onCopyWalletAddress } = useClipboard("");
  const toast = useToast();
  const currencyOptions = [{ label: "USDT", value: "USDT" }];
  const walletNameOptions = [
    { label: "TransFi USDT", value: "usdt_wallet", address: "0x2d244022359f432f2c1b6b64f90b5f318ad9f817" },
  ];

  const formik = useFormik({
    initialValues: {
      amount: '',
      walletName: '',
      walletAddress: '',
    },
    validationSchema: Yup.object({
      amount: Yup.string().required("Please enter the amount"),
    }),
    onSubmit: async (formValues) => {
      try {
        const response = await callApiWithToken("POST", "/api/prefund/create", {
          sendCurrency: "USDT",
          sendAmount: formValues?.amount,
          walletName: formValues?.walletName?.value,
          walletAddress: formValues?.walletAddress,
          orgId: getOrgId()
        });
        if (response?.message) {
          toast({
            title: 'Success',
            description: response?.message,
            status: 'success',
            duration: 5000,
            isClosable: true,
            position: 'top-right'
          })
        }
        refreshGrid();
        onClose();
      } catch (err) {
        toast({
          title: 'Error!',
          description: err?.message || "Failed to fetch order details.",
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right'
        })
      }
    },
  });

  const onWalletNameChange = (event) => {
    formik.setFieldValue("walletName", event);
    formik.setFieldValue("walletAddress", event?.address);
  };

  useEffect(() => {
    setValue(formik.values.walletAddress)
  }, [formik.values.walletAddress])

  return (
    <chakra.form onSubmit={formik.handleSubmit} display="flex" flexGrow={1}>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />

        <ModalContent>
          <ModalHeader>Initiate Prefund</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>

            <Stack direction={"column"} justifyContent="space-between" flexGrow={1}>
              <FormControl>
                <FormLabel>Currency</FormLabel>
                <Select
                  id="currency"
                  isSearchable={false}
                  value={{ label: "USDT", value: "USDT" }}
                  isDisabled
                  placeholder='Select Currency'
                  options={currencyOptions}
                  onChange={(value) => {
                    setCurrency(value);
                  }}
                />
              </FormControl>
              <FormControl
                isRequired
                isInvalid={formik.touched.amount && formik.errors.amount}
              >
                <FormLabel htmlFor="name">Amount</FormLabel>
                <Input
                  id="amount"
                  type="number"
                  value={formik.values.amount}
                  onChange={formik.handleChange}
                />
                <FormErrorMessage>{formik.errors.amount}</FormErrorMessage>
              </FormControl>

              <FormControl
                isRequired
                isInvalid={formik.touched.walletName && formik.errors.walletName}
              >
                <FormLabel>TransFi Wallet</FormLabel>
                <Select
                  id="walletName"
                  type="text"
                  isSearchable={false}
                  placeholder='Select Destination Wallet'
                  options={walletNameOptions}
                  onChange={(e) => onWalletNameChange(e)}
                  value={formik.values.walletName}
                />
                <FormErrorMessage>{formik.errors.walletName}</FormErrorMessage>
              </FormControl>

              <FormControl>
                <FormLabel htmlFor="name">TransFi Wallet Address</FormLabel>
                <Flex>
                  <Input
                    id="walletAddress"
                    type="text"
                    value={formik.values.walletAddress}
                    disabled
                  />
                  <Button marginLeft={1} onClick={onCopyWalletAddress}>{hasCopiedWalletAddress ? "Copied!" : "Copy"}</Button>
                </Flex>
              </FormControl>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button
              isLoading={isLoading}
              onClick={formik.handleSubmit}
              colorScheme='blue' mr={3}
            >
              Create
            </Button>
            <Button
              onClick={onClose}
            >
              Cancel
            </Button>
          </ModalFooter>

        </ModalContent>
      </Modal>
    </chakra.form>
  )
}

export default ViewModal;