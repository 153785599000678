import React, { useState, useRef, useEffect, useContext } from "react";
import Loader from "react-js-loader";
import { useParams } from "react-router-dom";

import { EditIcon } from "@chakra-ui/icons";
import { Box, Text, Card, CardBody, Flex, Badge, Button, useDisclosure, useToast } from "@chakra-ui/react";
import { round, isEmpty } from "lodash";
import moment from "moment";

import { NavBarContext } from "../../context/NavBarContext";
import TransfiGrid from "../../utils/TransfiGrid";
import { callApiWithToken } from "../../utils/utils";
import { getOrgRoles, getOrgName } from "../App/useToken";
import AddPrefundModal from "./AddPrefundModal";
import ApproveModal from "./ApproveModal";
import NoteModal from "./NoteModal";
import PartialPrefundModal from "./PartialPrefundModal";

export default function SettlementBatch() {
    const orgRoles = getOrgRoles();
    const { selectedOrg: orgName } = useContext(NavBarContext);
    const userOrgName = getOrgName();
    const { batchId } = useParams();
    const [loading, setLoading] = useState(false);
    const [settlementDetails, setSettlementDetails] = useState({});
    const gridRef = useRef();
    const toast = useToast();

    const { isOpen: isOpenApproveModal, onOpen: onOpenApproveModal, onClose: onCloseApproveModal } = useDisclosure({ defaultIsOpen: false });
    const { isOpen: isOpenNoteModal, onOpen: onOpenNoteModal, onClose: onCloseNoteModal } = useDisclosure({ defaultIsOpen: false });
    const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: false });
    const { isOpen: isOpenPartialPrefund, onOpen: onOpenPartialPrefund, onClose: onClosePartialPrefund } = useDisclosure({ defaultIsOpen: false });

    useEffect(() => {
        getTotalBalance();
    }, []);

    const getTotalBalance = async () => {
        const response = await callApiWithToken("GET", `/api/settlement/getSettlementDetails?batchId=${batchId}`) || {};
        const { settlementDetails = {} } = response || {};
        setSettlementDetails(settlementDetails);
    }

    const downloadReport = async () => {
        let body = {
            gridFilters: {  batchId },
            reportName : "Detailed Settlement Report",
            formattedReportName: "detailedSettlementReport"
        };

        const response = await callApiWithToken("POST", '/api/balance/report', body);
        if (response?.message) {
            toast({
                title: 'Unable to schedule report',
                status: 'error',
                isClosable: true,
                position: 'top-right',
                duration: 5000,
            });
        } else {
            toast({
                title: 'Report Scheduled Successfully, You will get email shortly.',
                status: 'success',
                isClosable: true,
                position: 'top-right',
                duration: 5000,
            });
        }
    }

    const settlementCurrency = (orgName.includes("openpayd") || userOrgName === "openpayd")? "EUR" : "USDT"

    const refreshGrid = () => {
        getTotalBalance();
        gridRef.current.refreshGrid();
    };

    const columns = [
        {
            Header: "Order Id",
            accessor: "orderId",
            width: "200",
        },
        {
            Header: "Date",
            accessor: "createdAt",
            width: "200",
            Cell: ({ value }) => {
                return moment(value).utc().format("lll");
            },
        },
        {
            Header: "Fiat Payment Type",
            accessor: "paymentType",
            width: "100",
            Cell: ({ value }) => ["bank_transfer", "netbanking"].includes(value) ? "Bank Transfer" : "E-Wallet",
        },
        {
            Header: "Total Fiat Amt(Buy)",
            accessor: "buy.fiatAmount",
            width: "30",
            color: "info",
            Cell: ({ value }) => value ? round(value,2).toLocaleString("en-US") : "-"
        },
        {
            Header: "Transfi Fee(Buy)",
            accessor: "buy.fees",
            width: "20",
            color: "info",
            Cell: ({ value }) => value ? round(value,2).toLocaleString("en-US") : "-"
        },
        {
            Header: "Net Fiat Amt(Buy)",
            accessor: "buy.netAmount",
            width: "30",
            color: "info",
            Cell: ({ value }) => value ? round(value,2).toLocaleString("en-US") : "-"
        },
        {
            Header: "Total Fiat Amt(Sell)",
            accessor: "sell.fiatAmount",
            width: "30",
            color: "warning",
            Cell: ({ value }) => value ? round(value,2).toLocaleString("en-US") : "-"
        },
        {
            Header: "Transfi Fee(Sell)",
            accessor: "sell.fees",
            width: "30",
            color: "warning",
            Cell: ({ value }) => value ? round(value,2).toLocaleString("en-US") : "-"
        },

        {
            Header: "Net Fiat Amt(Sell)",
            accessor: "sell.netAmount",
            width: "30",
            color: "warning",
            Cell: ({ value }) => value ? round(value,2).toLocaleString("en-US") : "-"
        },
        {
            Header: "Conversion Rate",
            accessor: "conversionRate",
            width: "30",
            Cell: ({ value }) => value ? round(value,2).toLocaleString("en-US") : "-"
        },
        {
            Header: "Total In Fiat",
            width: "30",
            Cell: ({ row }) => {
                if(row?.original?.type === "buy" || row?.original?.type === "payin"){
                    return row?.original?.totalAmount ? `+${round(row?.original?.totalAmount,2).toLocaleString("en-US")}`: "-"
                }
                if(row?.original?.type === "sell"){
                    return row?.original?.totalAmount ? `-${round(row?.original?.totalAmount,2).toLocaleString("en-US")}`: "-"
                }
            }
        },
        {
            Header: "Transfi Live Rate",
            accessor: "liveRate",
            width: "30",
            Cell: ({ value }) => value ? round(value,2).toLocaleString("en-US") : "-"
        },
        {
            Header: "Total",
            width: "30",
            Cell: ({ row }) => {
                if(row?.original?.type === "buy" || row?.original?.type === "payin"){
                    return row?.original?.totalUsdAmount ? `+${round(row?.original?.totalUsdAmount,2).toLocaleString("en-US")}`: "-"
                }
                if(row?.original?.type === "sell"){
                    return row?.original?.totalUsdAmount ? `-${round(row?.original?.totalUsdAmount,2).toLocaleString("en-US")}`: "-"
                }
            }
        },
        {
            Header: `Total Amount (${settlementCurrency})`,
            width: "30",
            Cell: ({ row }) => {
                if(row?.original?.type === "buy" || row?.original?.type === "payin"){
                    return row?.original?.totalAmountUsdt ? `+${round(row?.original?.totalAmountUsdt,2).toLocaleString("en-US")}`: "-"
                }
                if(row?.original.type === "sell" || row?.original?.type === "payout"){
                    return row?.original?.totalAmountUsdt ? `-${round(row?.original?.totalAmountUsdt,2).toLocaleString("en-US")}`: "-"
                }
            }
        },
        {
            Header: `Processing Fees (${settlementCurrency})`,
            accessor: "processingFeeUsdt",
            width: "30",
            Cell: ({ value }) => value ? round(value,2).toLocaleString("en-US") : "-"
        },
        {
            Header: "Aggregrate Total Balance(Fiat)",
            width: "30", 
            Cell: ({ row }) => {
                if(row?.original?.type === "buy"|| row?.original?.type === "payin"){
                    return row?.original?.totalAggregateBalance ? round(row?.original?.totalAggregateBalance,2).toLocaleString("en-US"): "0"
                }
                if(row?.original?.type === "sell" ||row?.original?.type === "payout"){
                    return row?.original?.totalAggregateBalance ? round(row?.original?.totalAggregateBalance,2).toLocaleString("en-US"): "0"
                }
            }
        },
        {
            Header: "Aggregrate Total Balance",
            width: "30", 
            Cell: ({ row }) => {
                if(row?.original?.type === "buy"|| row?.original?.type === "payin"){
                    return row?.original?.totalAggregateBalanceUsd ? round(row?.original?.totalAggregateBalanceUsd,2).toLocaleString("en-US"): "0"
                }
                if(row?.original?.type === "sell" ||row?.original?.type === "payout"){
                    return row?.original?.totalAggregateBalanceUsd ? round(row?.original?.totalAggregateBalanceUsd,2).toLocaleString("en-US"): "0"
                }
            }
        },
        {
            Header: `Aggregrate Total Balance (${settlementCurrency})`,
            width: "30",
            Cell: ({ row }) => {
                if(row?.original?.type === "buy" || row?.original?.type === "payin"){
                    return row?.original?.totalAggregateBalanceUsdt ? round(row?.original?.totalAggregateBalanceUsdt,2).toLocaleString("en-US"): "0"
                }
                if(row?.original.type === "sell" || row?.original?.type === "payout"){
                    return row?.original?.totalAggregateBalanceUsdt ? round(row?.original?.totalAggregateBalanceUsdt,2).toLocaleString("en-US"): "0"
                }
            }
        },

    ];

    const CardText = ({ label, value }) => {
        return <Flex>
            <Box flex="1">
                <Text fontWeight="bold">{label}</Text>
            </Box>
            <Box flex="1" textAlign={'right'}>
                <Text>{value}</Text>
            </Box>
        </Flex>
    }

    const getStatusBadge = (value) => {
        const color = {
            "settlement_created": "yellow",
            "settlement_completed": "green",
            "settlement_fee_requested": "yellow",
            "settlement_fee_confirmed": "green"
        }

        const valueMap = {
            "settlement_created": "Created",
            "settlement_completed": "Completed",
            "settlement_fee_requested": "Fee Requested",
            "settlement_fee_confirmed": "Fee Confirmed"
        }
        return <>
            <Badge colorScheme={color[value]}> {valueMap[value]} </Badge>
            {orgRoles.includes("transfi_admin") && value === "settlement_created" ?
                <Button
                    variant="link"
                    onClick={() => onOpenApproveModal()}
                ><EditIcon /></Button> : <></>}
        </>;
    }

    const getNoteField = (value) => {
        return <>
            {value}
            {orgRoles.includes("transfi_admin") ?
                <Button
                    variant="link"
                    onClick={() => onOpenNoteModal()}
                ><EditIcon /></Button> : <></>}
        </>;
    }

    return !loading ? (
        <Box style={{ background: "white", marginTop: "30px" }} heading="Orders">
            <Flex width={'full'} marginX={6}>
                <Card flex="1" variant={'filled'}>
                    <CardBody>
                        <CardText label="Settlement Date:" value={isEmpty(settlementDetails) || settlementDetails?.date} />
                        <CardText label="Status:" value={getStatusBadge(settlementDetails?.status)} />
                        <CardText label="Total Buy Txns:" value={settlementDetails?.metaData?.totalBuyOrders.toLocaleString("en-US")} />
                        <CardText label="Total Sell Txns:" value={settlementDetails?.metaData?.totalSellOrders.toLocaleString("en-US")} />
                        <CardText label="Total Settlement Amt:" value={["PHP", "IDR"].includes(settlementDetails?.fiatCurrency) ? `${settlementDetails?.fiatCurrency} ${round(settlementDetails?.amount, 2).toLocaleString("en-US")}` : `USDT ${round(settlementDetails?.usdAmount, 2).toLocaleString("en-US")}`} />
                    </CardBody>
                </Card>
                <Card flex="1" variant={'filled'} marginX={6}>
                    <CardBody>
                        <CardText label="Settlement Currency:" value={settlementCurrency} />
                        <CardText label="Conversion Rate:" value={settlementDetails?.conversionRate
                            ? settlementDetails?.conversionRate.toLocaleString("en-US")
                            : getStatusBadge(settlementDetails?.status)}
                        />
                        <CardText
                            label={`Settlement in ${settlementCurrency}:`}
                            value={settlementDetails?.convertedAmount
                                ? `${settlementDetails?.convertedAmount.toLocaleString("en-US")} ${settlementCurrency}`
                                : getStatusBadge(settlementDetails?.status)}
                        />
                        <CardText
                            label="Settlement Note"
                            value={settlementDetails?.description
                                ? getNoteField(settlementDetails?.description)
                                : getStatusBadge(settlementDetails?.status)}
                        />
                       <Text as='u' color='blue' style={{cursor:'pointer', marginRight:'2%'}} onClick={() => downloadReport()}>Download Report</Text> 
                      {orgRoles.includes("transfi_admin") && <Text as='u' color='blue' style={{cursor:'pointer', marginRight:'2%'}} onClick={() => onOpen()}>Add To Prefund Balance</Text>}
                      {orgRoles.includes("transfi_admin") && <Text as='u' color='blue' style={{cursor:'pointer', marginRight:'2%'}} onClick={() => onOpenPartialPrefund()}>Partial Prefund</Text>}
                    </CardBody>
                </Card>
            </Flex>
            <TransfiGrid
                ref={gridRef}
                columns={columns}
                dataUrl={`/api/settlement/list/${batchId}`}
            ></TransfiGrid>
            <ApproveModal
                isLoading={loading}
                isOpen={isOpenApproveModal}
                onOpen={onOpenApproveModal}
                onClose={onCloseApproveModal}
                refreshGrid={refreshGrid}
                batchId={batchId}
                settlementCurrency={settlementCurrency}
            />
            {isOpenNoteModal && <NoteModal
                isOpen={isOpenNoteModal}
                onOpen={onOpenNoteModal}
                onClose={onCloseNoteModal}
                refreshGrid={refreshGrid}
                batchId={batchId}
                initialDescription={settlementDetails?.description}
                getTotalBalance={getTotalBalance}
            />}
            {isOpen && <AddPrefundModal
                isOpen={isOpen}
                onOpen={onOpen}
                onClose={onClose}
                refreshGrid={refreshGrid}
                batchId={batchId}
                data={settlementDetails}
            />}
            {isOpenPartialPrefund && <PartialPrefundModal
                isOpen={isOpenPartialPrefund}
                onOpen={onOpenPartialPrefund}
                onClose={onClosePartialPrefund}
                refreshGrid={refreshGrid}
                batchId={batchId}
                data={settlementDetails}
            />}
        </Box>
    ) : (
        <Loader type="spinner-cub" bgColor={"#FFFFFF"} title={"Loading..."} color={"cyan.400"} size={100} />
    );
}
