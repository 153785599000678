import React, { useState } from "react";

import { Pencil, Trash2, Plus, X, Filter } from "lucide-react";

import { Card, CardContent, CardHeader, CardTitle } from "../../../components/ui/card";
import { callApiWithToken } from "../../../utils/utils";

const Permissions = () => {
  const [permissions, setPermissions] = useState([
    { id: 1, name: "users.create", description: "Create new users", resource: "users", action: "create" },
    { id: 2, name: "users.read", description: "View user details", resource: "users", action: "read" },
    { id: 3, name: "customers.manage", description: "Manage customers", resource: "customers", action: "manage" },
    { id: 4, name: "reports.generate", description: "Generate reports", resource: "reports", action: "create" },
  ]);

  const [showForm, setShowForm] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterResource, setFilterResource] = useState("");
  const [newPermission, setNewPermission] = useState({
    name: "",
    description: "",
    resource: "",
    action: "read",
  });

  const resources = [...new Set(permissions.map((p) => p.resource))];
  const actions = ["create", "read", "update", "delete", "manage"];

  const filteredPermissions = permissions.filter((permission) => {
    const matchesSearch =
      permission.name.toLowerCase().includes(searchTerm.toLowerCase()) || permission.description.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesResource = !filterResource || permission.resource === filterResource;
    return matchesSearch && matchesResource;
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { success } = await callApiWithToken("POST", "/api/authorization/permissions", newPermission);
    if (success) {
      setPermissions([...permissions, { ...newPermission, id: permissions.length + 1 }]);
      setNewPermission({ name: "", description: "", resource: "", action: "read" });
      setShowForm(false);
    }
  };

  const handleDelete = (id) => {
    setPermissions(permissions.filter((p) => p.id !== id));
  };

  return (
    <div className="w-full mt-10 max-w-4xl mx-auto p-4 space-y-4">
      {/* Header Card */}
      <Card>
        <CardHeader className="flex flex-row items-center justify-between">
          <CardTitle>Permission Management</CardTitle>
          <button onClick={() => setShowForm(!showForm)} className="flex items-center gap-2 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600">
            {showForm ? <X className="h-4 w-4" /> : <Plus className="h-4 w-4" />}
            {showForm ? "Cancel" : "New Permission"}
          </button>
        </CardHeader>
      </Card>

      {/* Add Permission Form */}
      {showForm && (
        <Card>
          <CardHeader>
            <CardTitle>Add New Permission</CardTitle>
          </CardHeader>
          <CardContent>
            <form onSubmit={handleSubmit} className="space-y-4">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium mb-1">Name</label>
                  <input
                    type="text"
                    value={newPermission.name}
                    onChange={(e) => setNewPermission({ ...newPermission, name: e.target.value })}
                    className="w-full p-2 border rounded-md"
                    required
                    placeholder="e.g., users.create"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium mb-1">Resource</label>
                  <input
                    type="text"
                    value={newPermission.resource}
                    onChange={(e) => setNewPermission({ ...newPermission, resource: e.target.value })}
                    className="w-full p-2 border rounded-md"
                    required
                    placeholder="e.g., users"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium mb-1">Action</label>
                  <select
                    value={newPermission.action}
                    onChange={(e) => setNewPermission({ ...newPermission, action: e.target.value })}
                    className="w-full p-2 border rounded-md"
                    required
                  >
                    {actions.map((action) => (
                      <option key={action} value={action}>
                        {action.charAt(0).toUpperCase() + action.slice(1)}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label className="block text-sm font-medium mb-1">Description</label>
                  <input
                    type="text"
                    value={newPermission.description}
                    onChange={(e) => setNewPermission({ ...newPermission, description: e.target.value })}
                    className="w-full p-2 border rounded-md"
                    required
                    placeholder="Description of the permission"
                  />
                </div>
              </div>
              <div className="flex justify-end gap-2">
                <button type="button" onClick={() => setShowForm(false)} className="px-4 py-2 border rounded-md hover:bg-gray-100">
                  Cancel
                </button>
                <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600">
                  Add Permission
                </button>
              </div>
            </form>
          </CardContent>
        </Card>
      )}

      {/* Filters */}
      <Card>
        <CardContent className="pt-6">
          <div className="flex flex-col md:flex-row gap-4">
            <div className="flex-1">
              <div className="relative">
                <input
                  type="text"
                  placeholder="Search permissions..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="w-full p-2 pl-8 border rounded-md"
                />
                <Filter className="h-4 w-4 absolute left-2 top-3 text-gray-400" />
              </div>
            </div>
            <div className="w-full md:w-48">
              <select value={filterResource} onChange={(e) => setFilterResource(e.target.value)} className="w-full p-2 border rounded-md">
                <option value="">All Resources</option>
                {resources.map((resource) => (
                  <option key={resource} value={resource}>
                    {resource.charAt(0).toUpperCase() + resource.slice(1)}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </CardContent>
      </Card>

      {/* Permissions List */}
      <Card>
        <CardHeader>
          <CardTitle>Permissions List</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="space-y-2">
            {filteredPermissions.map((permission) => (
              <div key={permission.id} className="flex items-center justify-between p-3 bg-gray-50 rounded-md hover:bg-gray-100">
                <div className="flex-1">
                  <h3 className="font-medium">{permission.name}</h3>
                  <p className="text-sm text-gray-500">{permission.description}</p>
                  <div className="flex gap-2 mt-1">
                    <span className="text-xs px-2 py-1 bg-blue-100 text-blue-800 rounded">{permission.resource}</span>
                    <span className="text-xs px-2 py-1 bg-green-100 text-green-800 rounded">{permission.action}</span>
                  </div>
                </div>
                <div className="flex gap-2">
                  <button className="p-1 hover:bg-gray-200 rounded">
                    <Pencil className="h-4 w-4 text-gray-500" />
                  </button>
                  <button onClick={() => handleDelete(permission.id)} className="p-1 hover:bg-gray-200 rounded">
                    <Trash2 className="h-4 w-4 text-red-500" />
                  </button>
                </div>
              </div>
            ))}
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default Permissions;
