import React, { useEffect, useState } from "react";

import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Input,
  useToast,
  chakra,
  Stack,
} from "@chakra-ui/react";

import { callApiWithToken } from "../../utils/utils";

const CryptoRefundModal = ({
  isOpen,
  onClose,
  selectedRowData,
  refreshGrid,
}) => {
  const toast = useToast();
  const [formValues, setFormValues] = useState({
    walletAddress: "",
    refundAmount: "",
  });

  const [errors, setErrors] = useState({});

  useEffect(() => {
    const amount =
      selectedRowData?.original?.type === "fiat"
        ? selectedRowData?.original?.fiatRefund?.amount
        : selectedRowData?.original?.cryptoRefund?.amount;
    setFormValues((prev) => ({ ...prev, refundAmount: amount }));
  }, [selectedRowData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const validate = () => {
    const newErrors = {};
    if (!formValues.walletAddress)
      newErrors.walletAddress = "Wallet address is required";
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length > 0) return;

    try {
      const response = await callApiWithToken(
        "POST",
        "/api/refund/editAccountDetails",
        {
          refundAmount: formValues?.refundAmount,
          walletAddress: formValues?.walletAddress,
          userName: formValues.userName,
          currency: selectedRowData?.original?.cryptoTicker,
          orderId: selectedRowData?.original?.orderId,
          userId: selectedRowData?.original?.userId,
          partner: selectedRowData?.original?.crypto?.partner,
        }
      );

      if (response?.message) {
        toast({
          title: "Success",
          description: response.message,
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
        refreshGrid();
        onClose();
      } else if (response?.error) {
        toast({
          title: "Error!",
          description: response.error,
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
      }
    } catch (err) {
      toast({
        title: "Error!",
        description: err.message || "Failed to fetch order details.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }

    setFormValues({
      walletAddress: "",
      refundAmount: "",
    });
  };

  const handleClose = () => {
    onClose();
    setErrors({});
  };

  return (
    <chakra.form>
      <Modal isOpen={isOpen} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign="center">Account Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={4}>
              <FormControl isInvalid={Boolean(errors.refundAmount)}>
                <FormLabel htmlFor="refundAmount">Refund Amount</FormLabel>
                <Input
                  id="refundAmount"
                  name="refundAmount"
                  type="text"
                  placeholder="Refund Amount"
                  value={formValues.refundAmount}
                  isDisabled
                />
                <FormErrorMessage>{errors.refundAmount}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={Boolean(errors.walletAddress)}>
                <FormLabel htmlFor="walletAddress">Wallet Address</FormLabel>
                <Input
                  id="walletAddress"
                  name="walletAddress"
                  type="text"
                  placeholder="Enter the Wallet Address"
                  value={formValues.walletAddress}
                  onChange={handleChange}
                />
                <FormErrorMessage>{errors.walletAddress}</FormErrorMessage>
              </FormControl>
            </Stack>
          </ModalBody>
          <ModalFooter justifyContent="center">
            <Button
              onClick={handleSubmit}
              colorScheme="blue"
              mr={3}
              type="submit"
            >
              Initiate
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </chakra.form>
  );
};

export default CryptoRefundModal;
