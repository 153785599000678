/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';

import {
    Button,
    FormControl,
    FormLabel,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Select,
    Textarea,
    Input
} from '@chakra-ui/react';
import moment from "moment";

import { callApiWithToken } from '../../utils/utils';

export default function AddComments(props) {
    const { isOpen, onClose, refreshData, entityType, id, bulkUpdate = false, selectedRows = [] } = props;
    const finalRef = React.useRef(null);

    const [status, setStatus] = useState('');
    const [description, setDescription] = useState('');
    const [followUpDate, setFollowUpDate] = useState(moment().format("YYYY-MM-DD"));
    const [isLoading, setIsLoading] = useState(false);

    const saveComment = async () => {
        setIsLoading(true);
        if (bulkUpdate) {
            let userIdsList = selectedRows.map(val => val?.userId);
            await callApiWithToken(
                'POST',
                '/api/comments/addBulk', { status, description, followUpDate, entityIds: userIdsList, entityType }) || {};
        } else {
            await callApiWithToken(
                'POST',
                '/api/comments/add', { status, description, followUpDate, entityId: id, entityType }) || {};
        }
        setIsLoading(false);
        refreshData();
        onClose();
    }

    return <Modal finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
            <ModalHeader>Add Comment</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
                <FormControl>
                    <FormLabel>Status</FormLabel>
                    <Select placeholder='Select status' onChange={(e) => setStatus(e.target.value)}>
                        <option value='open'>Open</option>
                        <option value='awaiting_tech'>Awaiting Tech</option>
                        <option value='awaiting_partner'>Awaiting Partner</option>
                        <option value='awaiting_user'>Awaiting User</option>
                        <option value='resolved'>Resolved</option>
                        <option value='cancelled'>Cancelled</option>
                    </Select>
                </FormControl>
                <FormControl mt={4}>
                    <FormLabel>Description</FormLabel>
                    <Textarea placeholder='Description' onChange={(e) => setDescription(e.target.value)} />
                </FormControl>
                {["awaiting_tech", "awaiting_partner", "awaiting_user"].includes(status) &&
                    <FormControl mt={4}>
                        <FormLabel>Next Follow Up Date</FormLabel>
                        <Input
                            placeholder="Select Date"
                            size="md"
                            type="date"
                            value={followUpDate}
                            onChange={(e) => setFollowUpDate(e.target.value)}
                        />
                    </FormControl>}
            </ModalBody>
            <ModalFooter>
                <Button isLoading={isLoading} isDisabled={!status || !description} onClick={saveComment} colorScheme='blue' mr={3}>
                    Add
                </Button>
                <Button onClick={onClose}>Cancel</Button>
            </ModalFooter>
        </ModalContent>
    </Modal>
}