/* eslint-disable no-unused-expressions */
import React from 'react';

import {
  Button,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Input,
  Stack,

} from '@chakra-ui/react';

const ViewDetailModal = (props) => {
  const { isOpen, onClose, selectedRow } = props;
  const { status, walletName, walletAddress, txnId, conversionRate, convertedAmount, sendCurrency, sendAmount, convertedCurrency, description } = selectedRow

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />

      <ModalContent>
        <ModalHeader>View Details</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>

          <Stack direction={"column"} justifyContent="space-between" flexGrow={1}>
            {["PHP", "IDR"].includes(sendCurrency) ? (<>
              <FormLabel htmlFor="name">Currency</FormLabel>
              <Input

                value={sendCurrency}
                isDisabled
              />
              <FormLabel htmlFor="name">Amount</FormLabel>
              <Input

                value={sendAmount}
                isDisabled
              />
            </>) : (<>
              <FormLabel htmlFor="name">Wallet Name</FormLabel>
              <Input

                value={walletName}
                isDisabled
              />
              <FormLabel htmlFor="name">Wallet Address</FormLabel>
              <Input

                value={walletAddress}
                isDisabled
              />
            </>
            )}

            {status === "completed" && <>
              {/* <FormLabel htmlFor="name">Txn Id</FormLabel>
                <Input
                  value={txnId}
                  isDisabled
                /> */}
              <FormLabel htmlFor="name">Conversion Rate</FormLabel>
              <Input
                value={conversionRate}
                isDisabled
              />
              <FormLabel htmlFor="name">{`Conversion Amount (${convertedCurrency})`}</FormLabel>
              <Input
                value={`${convertedAmount} `}
                isDisabled
              />
              <FormLabel htmlFor="name">Note</FormLabel>
              <Input
                value={description}
                isDisabled
              />
            </>}
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={onClose}
          >
            Close
          </Button>
        </ModalFooter>

      </ModalContent>
    </Modal>

  )
}

export default ViewDetailModal;