import React, { useContext, useEffect, useRef, useState } from "react";
import NotificationBadge from "react-notification-badge";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import TimezoneSelect, { allTimezones } from "react-timezone-select";

import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  IconButton,
  Avatar,
  Box,
  Flex,
  HStack,
  VStack,
  useColorModeValue,
  Text,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Button,
  Spacer,
  Divider,
  Heading,
  Image,
} from "@chakra-ui/react";
import { sortBy, remove } from "lodash";
import moment from "moment-timezone";
import { FiBell, FiChevronDown } from "react-icons/fi";

import { LanguageContext } from "../../context/LanguageContext";
import { NavBarContext } from "../../context/NavBarContext";
import { TimezoneContext } from "../../context/TimezoneContext";
import enIcon from "../../icons/en.svg";
import zhIcon from "../../icons/zh.svg";
import { getDataFromSession } from "../../utils/session-storing-utils";
import { callApiWithToken } from "../../utils/utils";
import { getUserName, getOrgRoles, getOrgName, getEnv } from "../App/useToken";
import { EnvTypeContext } from "./../../context/EnvTypeContext";
import NotificationDropdown from "./NotificationDropdown";
import { routesTitleMap, routesDescriptioneMap } from "./routesMap";

const Header = () => {
  const name = getUserName();
  const longPollInterval = useRef();
  const orgName = getOrgName();
  const [notificationsData, setNotificationsData] = useState([]);
  const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0);
  const [organizations, setOrganizations] = useState([]);

  const { env, onEnvChange } = useContext(EnvTypeContext);
  const { onOrgChange } = useContext(NavBarContext);
  const { timezone, onTimezoneChange } = useContext(TimezoneContext);
  const { language, onLanguageChange } = useContext(LanguageContext);

  const location = useLocation();
  const route = (location?.pathname || "").toLowerCase();
  const title = routesTitleMap[route] || "";
  const envs = getEnv();
  const description = routesDescriptioneMap[route] || "";
  const orgRoles = getOrgRoles();
  const grpOrgId = window.sessionStorage.getItem("grpOrgId");

  const iconsMapping = {
    en: { label: "English", icon: enIcon },
    zh: { label: "中文", icon: zhIcon },
  };

  useEffect(() => {
    getNotificationList();
    getOrganizationList();
    longPollInterval.current = setInterval(async () => getNotificationList(), 5 * 60000);
    return () => {
      clearInterval(longPollInterval.current);
    };
  }, [env]);

  const getOrganizationList = async () => {
    const response = (await callApiWithToken("GET", "/api/organization", {})) || {};
    const { data = [] } = response;
    const options = data.map((org, idx) => {
      if (idx === 1) return { value: "all", label: "All" };
      return { value: org.formattedName, label: org.name };
    });
    remove(options, (obj) => obj.value === "");
    setOrganizations(sortBy(options, (data) => data.value));
  };

  const getNotificationList = async () => {
    const response = (await callApiWithToken("POST", "/api/notifications/list", {})) || {};
    const { data = [], unreadNotifications = 0 } = response;
    setNotificationsData(data);
    setUnreadNotificationsCount(unreadNotifications);
  };

  function SignOutButton() {
    window.localStorage.clear();
    window.location.replace("/");
    window.sessionStorage.clear();
  }

  const isCustomerLive = getDataFromSession("userData", "status") === "sandbox" ? false : true;

  const allowableBinanceOrgs = ["binance", "binance_demo", "binance_qa", "binance_prod", "binance_africa"];

  return (
    <HStack spacing={5} borderBottom="2px Solid #FFBF33" ml={5} mr={5} p={5} justifyContent="space-between" bg={"#ffffff"}>
      <Box>
        <Flex dir="row" gap="5" alignItems={"center"}>
          <div className="flex flex-row items-center flex-wrap">
            <Text fontSize="1xl">Timezone : </Text>{" "}
            <TimezoneSelect
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  width: "250px",
                  height: "20px",
                  fontSize: "14px",
                  borderColor: "grey",
                }),
                option: (provided, state) => ({
                  ...provided,
                  fontSize: "14px",
                }),
              }}
              value={timezone}
              onChange={(value) => onTimezoneChange(value?.value)}
              timezones={{ ...allTimezones }}
            />
          </div>
          {orgRoles.includes("transfi_admin") && (
            <div className="flex flex-row items-center flex-wrap">
              <Text fontSize="1xl">Organizations : </Text>
              <Select
                isMulti
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    width: "400px",
                  }),
                }}
                options={organizations}
                defaultValue={{ value: "all", label: "All" }}
                onChange={(selectedOptions) => onOrgChange(selectedOptions.map((option) => option.value))}
                // onChange={(selectedOptions) => onOrgChange(selectedOptions.map(option => option.value))}
              />
            </div>
          )}
          <div className="flex flex-row items-center flex-wrap">
            <Text fontSize="1xl">Environment: </Text>
            <Menu style={{ margin: "0" }}>
              <MenuButton as={Button} rightIcon={<ChevronDownIcon />} width="150px">
                {env}
              </MenuButton>
              <MenuList>
                <MenuItem isDisabled={!isCustomerLive} onClick={() => onEnvChange("live")}>
                  Live
                </MenuItem>
                <MenuItem onClick={() => onEnvChange("sandbox")}>Sandbox</MenuItem>
              </MenuList>
            </Menu>
          </div>
          {!allowableBinanceOrgs.includes(orgName) && (
            <Menu closeOnSelect={false}>
              <MenuButton
                as={IconButton}
                icon={
                  <div>
                    <FiBell size={"25px"} />
                    <NotificationBadge style={{ top: "-35px", right: "-15px" }} count={unreadNotificationsCount} />
                  </div>
                }
                isDisabled={!notificationsData.length}
              />
              <MenuList maxHeight={"80vh"} overflow={"scroll"}>
                {notificationsData.map((notification) => (
                  <>
                    <MenuItem>
                      <NotificationDropdown notificationData={notification} setUnreadNotificationsCount={setUnreadNotificationsCount} />
                    </MenuItem>

                    <Divider />
                  </>
                ))}
              </MenuList>
            </Menu>
          )}
          <Menu>
            <MenuButton py={2} transition="all 0.3s" _focus={{ boxShadow: "none" }}>
              <HStack>
                <Avatar size={"sm"} src={""} />
                <VStack display={{ base: "none", md: "flex" }} alignItems="flex-start" spacing="1px" ml="2">
                  <Text fontSize="sm">{name}</Text>
                </VStack>
                <Box display={{ base: "none", md: "flex" }}>
                  <FiChevronDown />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList bg={useColorModeValue("white", "gray.900")} borderColor={useColorModeValue("gray.200", "gray.700")}>
              <MenuItem onClick={SignOutButton}>Sign out</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Box>
    </HStack>
  );
};

export default Header;
