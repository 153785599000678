import React from 'react';
import { useRowSelect, useTable, useMountedLayoutEffect } from "react-table";

import { InfoIcon } from '@chakra-ui/icons';
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Box,
    Flex,
    useToast,
    Tooltip,
    IconButton,
    Stack,
} from '@chakra-ui/react';

const RefundGrid = (props) => {
    const toast = useToast();
    const { columns,data , selectRow = false, compactView, initialState } = props;

    const headerColors = {
        info: "#b3e5fc",
        warning: "#ffcc80",
        highlight: "#0BC5EA"
    }

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows,
        selectedFlatRows,
        state: { selectedRowIds }
    } = useTable(
        {
            columns,
            data,
            manualPagination: true,
            initialState
        },
        useRowSelect,
    );

    return (
        <Box bg='white' w='100%' p={4} color='black' overflowX="auto" maxWidth='80vw' overflowY="auto" h='100%'>
            <Box
                    bg={compactView ? '#e3e4e6' : 'white'}
                    w='100%'
                    pr={compactView ? 0 : 4}
                    pt={4} pl={4}
                    pb={compactView ? 4 : 0}
                    maxWidth='80vw' borderRadius={compactView ? 4 : 0}
                    height={compactView ? '64vh' : '70vh'} overflow={compactView ? 'scroll' : 'auto'}>
                    {
                        !compactView ?
                            <Box bg='white' w='100%' p={4} color='black' overflowX="auto" maxWidth='80vw' overflowY="auto" h='100%' >
                                <Table {...getTableProps()} size="sm" borderCollapse={"collapse"} width={'100%'} >
                                    <Thead position={'sticky'} zIndex={2} top={-5}>
                                        {headerGroups.map((headerGroup) => (
                                            <Tr {...headerGroup.getHeaderGroupProps()}>
                                                {headerGroup.headers.map((column) => (
                                                    <Th
                                                        bgColor={headerColors[column.color]}
                                                        minWidth={column.width}
                                                        zIndex={column.zIndex}
                                                        position={column.position}
                                                        left={column.position ? -5 : 0}
                                                        textAlign={column.textAlign}
                                                        background={headerColors[column.color] ? headerColors[column.color] : 'white'}
                                                        top={column.position ? -20 : -5}
                                                        {...column.getHeaderProps()}
                                                    >
                                                        <Flex>
                                                            {column.render("Header")}
                                                            {column?.info && (
                                                                <Tooltip label={column?.info} >
                                                                    <InfoIcon display={"inline-flex"} ml={1} fontSize={"17px"} />
                                                                </Tooltip>)}
                                                        </Flex>
                                                    </Th>
                                                ))}
                                            </Tr>
                                        ))}
                                    </Thead>
                                    <Tbody {...getTableBodyProps()}>
                                        {rows.map((row, i) => {
                                            prepareRow(row);
                                            return (
                                                <Tr {...row.getRowProps()} >
                                                    {row.cells.map((cell) => {
                                                        return (
                                                            <Td {...cell.getCellProps()} style={{
                                                                backgroundColor: cell?.column?.color === "highlight" ? '#0BC5EA' : 'inherit', zIndex: cell?.column?.zIndex,
                                                                position: cell?.column?.position,
                                                                left: cell?.column?.position ? -20 : 0,
                                                                background: 'white',
                                                                textAlign: cell?.column?.textAlign
                                                            }}>{cell.render("Cell")}</Td>
                                                        );
                                                    })}
                                                </Tr>
                                            );
                                        })}
                                    </Tbody>
                                </Table>
                            </Box>
                            : <Stack spacing='6'>
                              </Stack>
                    }
                </Box>
        </Box>
    );
}

export default RefundGrid;
