import React, { useEffect, useState, useContext, useRef } from "react";
import { Link } from "react-router-dom";

import { DownloadIcon, AddIcon, CloseIcon } from "@chakra-ui/icons";
import { Box, Badge, useDisclosure, Heading, Icon, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, FormControl, FormLabel, Input, Button , Select, HStack,Spinner, Stack, IconButton } from "@chakra-ui/react";
import { AgGridReact } from 'ag-grid-react';
import { isEmpty, result, startCase, uniqBy } from "lodash";
import moment from "moment";
import { BiRefresh } from "react-icons/bi";
import { BsUpload } from 'react-icons/bs';
import * as XLSX from 'xlsx';

import { EnvTypeContext } from "../../../context/EnvTypeContext";
import { NavBarContext } from "../../../context/NavBarContext";
import { callApiWithToken } from "../../../utils/utils";
import { getOrgRoles } from "../../App/useToken";
import PaymentFilter from "./helper/PaymentMethodFilter"; // AG Grid Component
import { validateStringNotEmpty, validateIsNegativeNumber } from "../../../utils/validations";

import './Style.css'
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";


export default function PaymentMethodsV2 (){
    const EditButton = ({ data }) => (
      
      <HStack spacing={2}>
        <Button onClick={() => handleEditClick(data)} size="sm">
          Edit
        </Button>
        <Button onClick={() => handleDeleteClick(data)} size="sm" colorScheme="red">
          Delete
        </Button>
      </HStack>

    );
    const renderLeftAlignedCell = (value) => (
      <div style={{ textAlign: 'left' }}>{value}</div>
    );
    const [selectedRowData, setSelectedRowData] = useState(null);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isDeleteModalOpen, onOpen: openDeleteModal, onClose: closeDeleteModal } = useDisclosure();
    const [rowData, setRowData] = useState([]);
    const [api, setApi] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isRefreshing, setIsRefreshing] = useState(true);
    const [currencies, setCurrencies] = useState([]);
    const [colDefs, setColDefs]= useState([
      { headerName: "Payment Gateway", field: "paymentGateway", filter: true , pinned:"left", cellRenderer: ({ value }) => startCase(value || "-"),headerClass: 'center-align-header', cellClass: 'right-align-cell'},
      { headerName: "Payment Method", field: "name", filter: true , pinned:"left", headerClass: 'center-align-header', cellClass: 'right-align-cell' },
      // { headerName: "Countries", field: "countries" },
      { headerName: "Currencies", field: "currencies", width: 110,headerClass: 'center-align-header', cellClass: 'right-align-cell',filter:true },
      { headerName: "Fee Type", field: "feeType", width: 120, headerClass: 'center-align-header', cellClass: 'right-align-cell', filter: true, valueGetter: ({data})=> data.feeType || "percentage", cellRenderer: ({ value }) => startCase(value) },
      { headerName: "Fees", field: "fees", width : 100 ,headerClass: 'center-align-header', cellClass: 'right-align-cell',filter:true },
      { headerName: "Type", field: "type", cellRenderer: ({ value }) => startCase(value || "-"), width: 170, headerClass: 'center-align-header', cellClass: 'right-align-cell',filter:true },
      { headerName: "Status", field: "status", cellRenderer: ({ value }) => startCase(value || "-"), width: 110,headerClass: 'center-align-header', cellClass: 'right-align-cell', sort: 'asc',filter:true },
      { headerName: "Product", field: "product", cellRenderer: ({ value }) => startCase(value || "-"), width: 110,headerClass: 'center-align-header', cellClass: 'right-align-cell', filter: true },
      { headerName: "Created At", field: "createdAt",width: 210,headerClass: 'center-align-header', cellClass: 'right-align-cell' },
      { headerName: "Updated At", field: "updatedAt", width: 210,headerClass: 'center-align-header', cellClass: 'right-align-cell' },
      { headerName: "Actions", cellRenderer: EditButton, headerComponentParams: { textAlign: 'center' } },
    ]);
    const [metaData, setMetaData] = useState([]);
    const onGridReady = (params) => {
      console.log('Grid is ready!');
      setApi(params.api);
      console.log(api)
    };
  
  const downloadAsXLSX = () => {
    setLoading(true);
    const timestamp = moment().format('DD-MM-YYYY');
    const filename = `payment_methods_dump_${timestamp}.xlsx`;

    const headers = [
      "Payment Gateway",
      "Payment Method",
      "Countries",
      "Currencies",
      "Fee Type",
      "Fees",
      "Fixed Currency",
      "Fixed Fee",
      "Type",
      "Status",
      "Product",
      "Created At",
      "Updated At",
      "Comments"
    ];

    const excelData = rowData.map(row => ({
      "Payment Gateway": startCase(row.paymentGateway || "NA"),
      "Payment Method": row.name || "NA",
      "Countries": (row.countries || []).join(", ") || "-",
      "Currencies": (row.currencies || []).join(", ") || "NA",
      "Fee Type": startCase(row.feeType || "percentage"),
      "Fees": row.fees || "NA",
      "Fixed Currency": ["hybrid", "fixed"].includes(row.feeType) ? (row.fixedCurrency || "NA") : "NA",
      "Fixed Fee": ["hybrid", "fixed"].includes(row.feeType) ? (row.fixedAmount || "NA") : "NA",
      "Type": startCase(row.type || "NA"),
      "Status": startCase(row.status || "NA"),
      "Product": startCase(row.product || "NA"),
      "Created At": row.createdAt || "NA",
      "Updated At": row.updatedAt || "NA",
      "Comments": row.metaData && row.metaData.comments ? row.metaData.comments : "NA"
    }));

    const worksheet = XLSX.utils.json_to_sheet(excelData, { header: headers });
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Payment Methods");

    XLSX.writeFile(workbook, filename);
    setLoading(false);
  };
  
    const refreshGrid = async () => {
      try {
        setIsRefreshing(true);
        const response = await callApiWithToken('POST', '/api/admin/listPaymentMethods');
        setRowData(response.data);
      } catch (error) {
        console.error('Error refreshing payment methods:', error);
      }
      setIsRefreshing(false);
    };
    useEffect(() => {
      const fetchData = async () => {
        try {
          const [paymentMethodResponse, currenciesResponse] = await Promise.all([
            callApiWithToken('POST', '/api/admin/listPaymentMethods'),
            callApiWithToken("GET", `/api/admin/getCountries`)
          ]);
          setRowData(paymentMethodResponse.data);
          setCurrencies(currenciesResponse.data.currencies);
        } catch (error) {
          console.error('Error fetching payment methods:', error);
        }
        setIsRefreshing(false);
      };
      fetchData();
    }, []);
    
  
    const handleEditClick = (rowData) => {
      setSelectedRowData(rowData);
      const metadata = Object.entries(rowData?.metaData || {}).map(([key, value]) => ({ key, value }));
      setMetaData(metadata);
      onOpen ();
    };
    
    const handleDeleteClick = (rowData) => {
      setSelectedRowData(rowData);
      openDeleteModal();
    }

    const handleConfirmDelete = async (rowData) => {
      // Perform delete action
      setLoading(true);
      try{
      console.log(selectedRowData,"test");
      // console.log(rowData)
      const data = {_id:selectedRowData?._id};
      const response = await callApiWithToken('POST','/api/admin/deletePaymentMethod',data);
      }
      catch(error){
        console.log("Error while deleting the method: ",error);
      }
      setLoading(false);
      closeDeleteModal();
      refreshGrid();
    };


    const closeModal = () => {
      setSelectedRowData(null);
      onClose();
    };
    const saveChanges = async () => {
      setLoading(true);
      try{
        const updatedData = {
          _id: selectedRowData?._id,
          status: selectedRowData?.status,
          paymentGateway:selectedRowData?.paymentGateway,
          name:selectedRowData?.name,
          externalImageUrl:selectedRowData?.externalImageUrl,
          maxLimit:selectedRowData?.maxLimit,
          minLimit:selectedRowData?.minLimit,
          ...(selectedRowData?.feeType!=="fixed" && { fees: selectedRowData?.fees }),
          supportedDevice:selectedRowData?.supportedDevice,
          product:selectedRowData?.product,
          feeType: selectedRowData?.feeType,
          ...(["hybrid", "fixed"].includes(selectedRowData?.feeType) && { fixedAmount: selectedRowData?.fixedAmount, fixedCurrency: selectedRowData?.fixedCurrency }),
          metaData: {}
        };
        if(metaData.length){
          // convert the metadata back to objects from array of objects
          metaData.forEach(({ key, value }) => updatedData.metaData[key] = value)
          selectedRowData.metaData = updatedData.metaData
        }
        
        
        const response = await callApiWithToken('POST','/api/admin/updatePaymentMethods',updatedData);
        setSelectedRowData(selectedRowData);
        const updatedRowData = rowData.map(row => {
          if (row._id === selectedRowData._id) {
            return { ...row, ...selectedRowData };
          }
          return row;
        });
        setRowData(updatedRowData);
        // setLoading(false);
      }
      catch(error){
        console.log("error",error)
      }
      // Implement your logic to save changes here
      // You can use the selectedRowData to get the edited values
      console.log("changes made",selectedRowData);
      // refreshGrid();
      setLoading(false);
      closeModal();
    };
    
    const disableSaveChangesButton = () => {
      if (!selectedRowData) {
        return true;
      }
      const stringFieldsToValidate = ["externalImageUrl", "product", "paymentGateway", "name"];
      const numberFieldsToValidate = ["minLimit", "maxLimit"];
      if(["hybrid", "fixed"].includes(selectedRowData?.feeType)){
        numberFieldsToValidate.push("fixedAmount");
        stringFieldsToValidate.push("fixedCurrency");
      }
      if(selectedRowData.feeType!=="fixed"){
        numberFieldsToValidate.push("fees");
      }
  
      const isAnyStringEmpty = stringFieldsToValidate.some(row => validateStringNotEmpty(selectedRowData[row]));
      const isAnyNumberNegative = numberFieldsToValidate.some(row => validateIsNegativeNumber(selectedRowData[row]));
      return isAnyStringEmpty || isAnyNumberNegative;
    };

    const feeTypes = [
      { value: 'percentage', label: 'Percentage' },
      { value: 'fixed', label: 'Fixed' },
      { value: 'hybrid', label: 'Hybrid' }
  ];
    
      return(
        <Box  heading="Payment Method">
          <Stack w="100%">
          <div style={{ marginTop: "2%", marginRight: "5%" }}>
                <div style={{ float: "right" }}>
                    <Button
                        leftIcon={<BiRefresh style={{ fontSize: "20px" }} />}
                        onClick={() => refreshGrid()}
                        variant={"outline"}
                        colorScheme="blue"
                        style={{ margin: "0 4px" }}
                        size="sm"
                    >
                        Refresh
                    </Button>
                    <Button
                      leftIcon={<DownloadIcon />}
                      isLoading={loading}
                      onClick={downloadAsXLSX}
                      variant={"outline"}
                      colorScheme="blue"
                      style={{ margin: "0 4px" }}
                      size="sm"
                    >
                      Download XLSX
                    </Button>
                    <Button
                      leftIcon={<Icon as={BsUpload} />}
                      // onClick = { ()=> uploadBulk()}
                      variant ={"outline"}
                      colorScheme="blue"
                      style = {{ margin: "0 4px" }}
                      size = "sm"
                    >
                      Bulk Upload
                    </Button>
                    <Link to="/admin/add-payment-methods">
                      <Button
                        leftIcon={<AddIcon style={{ fontSize: "20px" }} />}
                        variant={"outline"}
                        colorScheme="blue"
                        style={{ margin: "0 4px" }}
                        size="sm"
                      >
                        Add
                      </Button>
                    </Link>
                </div>
                <br />
            </div>
            </Stack>
            {isRefreshing ? (
              <div  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 700, width: '100%', marginTop: '20px' }}>
  <Spinner size="xl" />
  </div>
) : (
  <div className="ag-theme-quartz" style={{ height: 700, width: '100%', marginTop: '20px' }}>
    <AgGridReact
      rowData={rowData}
      columnDefs={colDefs}
      pagination={true}
      paginationPageSize ={500}
      paginationPageSizeSelector ={[50,100,200,500]}
      onGridReady={onGridReady}
    />
  </div>
)}
      <Modal isOpen={isOpen} onClose={closeModal} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Payment Method</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
          <HStack spacing={4} marginTop={4}>
            <FormControl>
              <FormLabel>Payment Gateway</FormLabel>
              <Input 
                value={selectedRowData?.paymentGateway}
                onChange={(e) => setSelectedRowData({ ...selectedRowData, paymentGateway: e.target.value })}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input 
                value={selectedRowData?.name}
                onChange={(e) => setSelectedRowData({ ...selectedRowData, name: e.target.value })}
              />
            </FormControl>
            </HStack>
            <HStack spacing={4} marginTop={4}>
            <FormControl>
              <FormLabel>Type</FormLabel>
              <Input 
                value={selectedRowData?.type}
                onChange={(e) => setSelectedRowData({ ...selectedRowData, type: e.target.value })}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Product</FormLabel>
              <Input 
                value={selectedRowData?.product}
                onChange={(e) => setSelectedRowData({ ...selectedRowData, product: e.target.value })}
              />
            </FormControl>
            </HStack>
            <HStack spacing={4} marginTop={4}>
            <FormControl>
              <FormLabel>Min Limit</FormLabel>
              <Input 
                value={selectedRowData?.minLimit}
                type="number"
                onChange={(e) => setSelectedRowData({ ...selectedRowData, minLimit: e.target.value === "" ? e.target.value : Number(e.target.value) })}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Max Limit</FormLabel>
              <Input 
                value={selectedRowData?.maxLimit}
                type="number"
                onChange={(e) => setSelectedRowData({ ...selectedRowData, maxLimit: e.target.value === "" ? e.target.value : Number(e.target.value) })}
              />
            </FormControl>
            </HStack>
            <HStack spacing={4} marginTop={4}>
            <FormControl>
              <FormLabel>Fees Type</FormLabel>
              <Select value={selectedRowData?.feeType || "percentage"} onChange={(e)=> 
                setSelectedRowData({ ...selectedRowData, feeType: e.target.value, 
                  ...(e.target.value!=="percentage" && !selectedRowData?.fixedCurrency && { fixedCurrency: currencies[0] })
                 })
              }>
              {feeTypes.map(({value, label}) =><option value={value} key={value} >{label}</option> )}
              </Select>
            </FormControl>
            </HStack>

            {selectedRowData?.feeType !== "fixed" &&
              <HStack spacing={4} marginTop={4}>
                <FormControl>
                  <FormLabel>Fees %</FormLabel>
                  <Input
                    value={selectedRowData?.fees}
                    type="number"
                    onChange={(e) => setSelectedRowData({ ...selectedRowData, fees: e.target.value === "" ? e.target.value : Number(e.target.value) })}
                  />
                </FormControl>
              </HStack>
            }
            {["fixed", "hybrid"].includes(selectedRowData?.feeType) &&
              <HStack spacing={4} marginTop={4}>
                <FormControl>
                  <FormLabel>Fixed Fee's Currency</FormLabel>
                  <Select value={selectedRowData?.fixedCurrency} onChange={(e) => setSelectedRowData({ ...selectedRowData, fixedCurrency: e.target.value })}>
                    {currencies.map((currency) => <option value={currency} key={currency} >{currency}</option>)}
                  </Select>
                </FormControl>
                <FormControl>
                  <FormLabel>Fixed Amount</FormLabel>
                  <Input
                    value={selectedRowData?.fixedAmount}
                    type="number"
                    onChange={(e) => setSelectedRowData({ ...selectedRowData, fixedAmount: e.target.value === "" ? e.target.value : Number(e.target.value) })}
                  />
                </FormControl>
              </HStack>
            }
            
            
            <HStack spacing={4} marginTop={4}>
            <FormControl>
              <FormLabel>Image Url</FormLabel>
              <Input 
                value={selectedRowData?.externalImageUrl}
                onChange={(e) => setSelectedRowData({ ...selectedRowData, externalImageUrl: e.target.value })}
              />
            </FormControl>
            </HStack>
            <HStack spacing={4} marginTop={4}>
            <FormControl>
              <FormLabel>Supported Device</FormLabel>
              <Select
                value={selectedRowData?.supportedDevice || ''}
                onChange={(e) => setSelectedRowData({ ...selectedRowData, supportedDevice: e.target.value })}
              >
                <option value="mobile_only">Mobile Only</option>
                <option value="desktop_only">Desktop Only</option>
                <option value="both">Both</option>
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>Status</FormLabel>
              <Select
                value={selectedRowData?.status || ''}
                onChange={(e) => setSelectedRowData({ ...selectedRowData, status: e.target.value })}
              >
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </Select>
            </FormControl>
            </HStack>
            <MetaDataComponent metaData={metaData} setMetaData={setMetaData}/>
            {/* Add more form fields for other properties */}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={saveChanges} isLoading={loading} isDisabled = {disableSaveChangesButton()} >
              Save
            </Button>
            <Button onClick={closeModal}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
        </Modal>
        <Modal isOpen={isDeleteModalOpen} onClose={closeDeleteModal}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Delete Confirmation</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              Are you sure you want to delete this payment method?
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="red" mr={3} onClick={handleConfirmDelete} isLoading={loading}>
                Delete
              </Button>
              <Button onClick={closeDeleteModal}>Cancel</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
    </Box>
      );

}


const MetaDataComponent = ({ metaData, setMetaData }) => {

  const handleKeyChange = (e, index) => {
    const { value } = e.target;
    const updatedMetaData = [...metaData];
    updatedMetaData[index] = { ...updatedMetaData[index], key: value };
    setMetaData(updatedMetaData);
  };
  
  const handleValueChange = (e, index) => {
    const { value } = e.target;
    const updatedMetaData = [...metaData];
    updatedMetaData[index] = { ...updatedMetaData[index], value: value };
    setMetaData(updatedMetaData);
  };

  const handleAddMetadata = () => {
    setMetaData([...metaData, { key: '', value: '' }]);
  };

  const handleRemoveMetadata = (index) => {
    const updatedMetaData = metaData.filter((_, i) => i !== index);
    console.log(updatedMetaData);
    setMetaData(updatedMetaData);
  };

  return (
      <HStack gap="5" marginBottom={"10px"} marginTop={"20px"}>
          <FormControl>
              <FormLabel>
                  Add Metadata
              </FormLabel>
              <Stack>
                  {metaData.map(({key, value}, index) => (
                      <HStack key={index}>
                          <FormControl>
                              <FormLabel>Key</FormLabel>
                              <Input name={`metaData.${index}.key`} value={key} onChange={(e) => handleKeyChange(e, index)} />
                          </FormControl>
                          <FormControl>
                              <FormLabel>Value</FormLabel>
                              <Input name={`metaData.${index}.value`} value={value} onChange={(e) => handleValueChange(e, index)} />
                          </FormControl>
                          <div style={{ marginTop: "auto" }}>
                              <IconButton
                                  aria-label="Remove"
                                  icon={<CloseIcon />}
                                  onClick={() => handleRemoveMetadata(index)}
                              />
                          </div>
                      </HStack>
                  ))}
                  <Button leftIcon={<AddIcon />} onClick={handleAddMetadata}>
                      Add More Metadata
                  </Button>
              </Stack>
          </FormControl>
      </HStack>
  );
};
