import React, { useEffect, useState, useContext, useRef } from "react";
import Loader from "react-js-loader";

import { Box } from "@chakra-ui/react";
import { round } from "lodash";
import { capitalize } from "lodash";

import TransfiGrid from "../../utils/TransfiGrid";

const FiatSettlementMaster = (props) => {
  const [loading, setLoading] = useState(false);

  const gridRef = useRef();

  useEffect(() => {
    refreshGrid;
  }, []);

  const refreshGrid = () => {
    gridRef.current.refreshGrid();
  };

  const numberFormatter = (value) => {
    const number = round(value, 2).toFixed(2)
    const result = parseFloat(number).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    })
    return result === "NaN" ? "0.00" : result;
};

  const columns = [
    {
      Header: "Date",
      accessor: "date",
      width: "150",
    },
    {
      Header: "Account Name",
      accessor: "cryptoAccount",
      width: "150",
      Cell: ({ value }) => <>{capitalize(value) || "-"}</>,
    },
    {
      Header: "Customer Name",
      accessor: "customerName",
      width: "150",
      Cell: ({ value }) => <>{capitalize(value) || "-"}</>,
    },
    {
      Header: "Customer Type",
      accessor: "customerType",
      width: "150",
    },
    {
      Header: "Settlement Currency",
      accessor: "settlementCurrency",
      width: "150",
      color: "info",
    },
    {
      Header: "Amt. Received In Sub Account",
      accessor: "totalBuyUsdcAmount",
      width: "150",
      color: "info",
      Cell: ({ value }) => <>{numberFormatter(value) || "-"}</>,
    },
    {
      Header: "Amt. Paid From Sub Account",
      accessor: "totalSellUsdcAmount",
      width: "220",
      color: "info",
      Cell: ({ value }) => <>{numberFormatter(value) || "-"}</>,
    },
    {
      Header: "Customer rev share paid",
      accessor: "revsharePaid",
      width: "220",
      color: "info",
      Cell: ({ value }) => <>{numberFormatter(value) || "-"}</>,
    },
    {
        Header: "Prefunding Received",
        accessor: "prefundReceived",
        width: "150",
        color: "info",
        Cell: ({ value }) => <>{numberFormatter(value) || "-"}</>,
      },
  ];

  return !loading ? (
    <Box style={{ background: "white" }} heading="Fiat Settlement Master">
      <TransfiGrid
        ref={gridRef}
        columns={columns}
        dataUrl={"/api/settlement/list"}
        filters={{
         type:"fiat"
        }}
        selectRow={false}
      ></TransfiGrid>
    </Box>
  ) : (
    <Loader
      type="spinner-cub"
      bgColor={"#FFFFFF"}
      title={"Loading..."}
      color={"cyan.400"}
      size={100}
    />
  );
};

export default FiatSettlementMaster;
