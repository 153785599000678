/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';

import {
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Input,
    useToast,
    Stack,
    Select
} from '@chakra-ui/react';
// import Select from "react-select";

import { callApiWithToken } from "../../../utils/utils";

const NoteModal = (props) => {
    const { isOpen, onClose, refreshGrid, batchId, initialDescription, initialRate, initialDate, initialCurrency, initialConversionRate,initialAccount } = props;

    const toast = useToast();
    const [description, setDescription] = useState(initialDescription);
    const [markUp, setMarkUp] = useState(initialRate);
    const [date, setdate] = useState(initialDate);
    const [currency, setCurrency] = useState(initialCurrency);
    const [conversionRate, setConversionRate] = useState(initialCurrency);
    const [paymentAccount, setPaymentAccount] = useState(initialAccount);
    const [isLoading, setIsLoading] = useState(false)

    const onSubmit = async () => {
        try {
            setIsLoading(true);
            const response = await callApiWithToken("POST", "/api/settlement/updateRevShareSettlement", {
                batchId,
                note: description,
                markUpRate: markUp,
                settlementDate: date,
                settlementCurrency: currency,
                conversionRate,
                paymentAccount
            });
            if (response?.message) {
                toast({
                    title: 'Success',
                    description: "Settlement Note updated successfully",
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                    position: 'top-right'
                })
                setIsLoading(false);
                refreshGrid();
                onClose();
            } else {
                setIsLoading(false);
                throw Error("Failed to update note.")
            }
        } catch (err) {
            setIsLoading(false);
            toast({
                title: 'Error!',
                description: "Failed to update note.",
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: 'top-right'
            })
        }
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />

            <ModalContent>
                <ModalHeader>Update Settlement Records for {batchId}</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>

                    <Stack direction={"column"} justifyContent="space-between" flexGrow={1}>
                        <FormControl
                            isRequired
                        >
                            <FormLabel htmlFor="name">Notes</FormLabel>
                            <Input
                                id="description"
                                type="text"
                                required
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </FormControl>
                        <FormControl
                            isRequired
                        >
                            <FormLabel htmlFor="name">Markup(%)</FormLabel>
                            <Input
                                id="description"
                                type="text"
                                required
                                value={markUp}
                                onChange={(e) => setMarkUp(e.target.value)}
                            />
                        </FormControl>
                        <FormControl
                            isRequired
                        >
                            <FormLabel htmlFor="name">Settlement Date</FormLabel>
                            <Input
                                id="description"
                                type="date"
                                required
                                value={date}
                                onChange={(e) => setdate(e.target.value)}
                            />
                        </FormControl>
                        <FormControl
                            isRequired
                        >
                            <FormLabel htmlFor="name">Settlement Currency</FormLabel>
                            <Input
                                id="description"
                                type="text"
                                required
                                value={currency}
                                onChange={(e) => setCurrency(e.target.value)}
                            />
                        </FormControl>
                        <FormControl
                            isRequired
                        >
                            <FormLabel>Conversion Rate</FormLabel>
                            <Select
                                // id="conversionRate"
                                // isSearchable={false}
                                onChange={(e) => setConversionRate(e.target.value)}
                                placeholder='Select Conversion Rate'
                            >
                                <option value='USD'>USD</option>
                                <option value='EUR'>EUR</option>

                            </Select>
                        </FormControl>
                        <FormControl
                            isRequired
                        >
                            <FormLabel htmlFor="name">Account Name</FormLabel>
                            <Input
                                id="description"
                                type="text"
                                required
                                value={paymentAccount}
                                onChange={(e) => setPaymentAccount(e.target.value)}
                            />
                        </FormControl>

                    </Stack>
                </ModalBody>
                <ModalFooter>
                    <Button
                        isLoading={isLoading}
                        onClick={() => onSubmit()}
                        colorScheme='blue' mr={3}
                    >
                        Done
                    </Button>
                    <Button
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                </ModalFooter>

            </ModalContent>
        </Modal>
    )
}

export default NoteModal;