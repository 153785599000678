/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from "react";
import Select from "react-select";

import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Input,
  useToast,
  chakra,
  Stack,
} from "@chakra-ui/react";
import { useClipboard } from "@chakra-ui/react";
import { useFormik } from "formik";
import * as Yup from "yup";

import { getOrgId } from "../../components/App/useToken";
import { callApiWithToken } from "../../utils/utils";

const RefundModalD24 = ({ isOpen, onClose, selectedRowData, isLoading, refreshGrid }) => {

  const [documentTypeOptions, setDocumentTypeOptions] = useState([]);

  const toast = useToast();

  useEffect(()=>{
   if(selectedRowData?.original?.fiatRefund?.partner === 'D24'){
    getConfig();
   } 
  },[selectedRowData])

  const getConfig = async (search) => {
    const response = await callApiWithToken(
        "GET",
        `/api/widget/configList?name=D24&module=refund_dashboard`
    );

    if (response?.data) {
        const currency = selectedRowData?.original?.fiatTicker;
        const configList = response?.data[0]?.config || [];
        
        const currencyConfig = configList.find(config => config[currency]);
        
        if (currencyConfig) {
          const documentTypes = currencyConfig[currency] || [];
          // Transform the array into the format expected by Select
          const optionsArray = documentTypes.map(option => ({
            label: option,
            value: option
          }));
          setDocumentTypeOptions(optionsArray);
        }
      }
};

  const formik = useFormik({
    initialValues: {
      accountType: "",
      beneficiaryName : "",
      documentType: "",
      bankName: "",
      accountNumber: "",
      bankBranchNumber: "",
    },
    onSubmit: async (formValues) => {
        try {
            const response = await callApiWithToken("POST", "/api/refund/editAccountDetails", {
                accountType: formValues?.accountType,
                beneficiaryName : formValues?.beneficiaryName,
                documentType: formValues?.documentType?.value,
                bankName: formValues?.bankName,
                accountNumber: formValues?.accountNumber,
                bankBranchNumber: formValues?.bankBranchNumber,
                orderId: selectedRowData?.original?.orderId,
                userId: selectedRowData?.original?.userId,
                partner: selectedRowData?.original?.fiat?.partner
            });
            if (response?.message) {
                toast({
                    title: 'Success',
                    description: response?.message,
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                    position: 'top-right'
                })
            }
            if (response?.error) {
                toast({
                    title: 'Error!',
                    description: response?.error,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                    position: 'top-right'
                })
            }
            refreshGrid();
            onClose();
        } catch (err) {
            toast({
                title: 'Error!',
                description: err?.message || "Failed to fetch order details.",
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: 'top-right'
            })
        }
        formik.setValues({
            accountType: "",
            beneficiaryName : "",
            documentType: "",
            bankName: "",
            accountNumber: "",
            bankBranchNumber: "",
        });
    },
  });

  return (
    <chakra.form display="flex" flexGrow={1} onSubmit={formik.handleSubmit}>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign="center">Account Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack
              direction={"column"}
              justifyContent="space-between"
              flexGrow={1}
            >

                <FormControl
                isInvalid={formik.touched.accountType && formik.errors.accountType}
                >
                <FormLabel htmlFor="accountType">Account Type</FormLabel>
                <Input
                    id="accountType"
                    type="text"
                    placeholder="Enter the account type"
                    value={formik.values.accountType}
                    onChange={formik.handleChange}
                />
                <FormErrorMessage>{formik.errors.accountType}</FormErrorMessage>
                </FormControl>

                <FormControl
                isInvalid={
                    formik.touched.beneficiaryName && formik.errors.beneficiaryName
                }
                >
                <FormLabel htmlFor="beneficiaryName">
                    Beneficiary Name / Account Holder Name
                </FormLabel>
                <Input
                    id="beneficiaryName"
                    type="text"
                    placeholder="Enter the beneficiary name"
                    value={formik.values.beneficiaryName}
                    onChange={formik.handleChange}
                />
                <FormErrorMessage>{formik.errors.beneficiaryName}</FormErrorMessage>
                </FormControl>

                <FormControl
                isInvalid={formik.touched.documentType && formik.errors.documentType}
                >
                <FormLabel htmlFor="documentType">Document Type</FormLabel>
                <Select
                 id="currency"
                 isSearchable={true}
                 value={formik.values.currency}
                 placeholder='Select Document Type'
                 options={documentTypeOptions}
                 onChange={(value) => {
                     formik.setFieldValue("documentType", value);
                 }}
               />                   
                <FormErrorMessage>{formik.errors.documentType}</FormErrorMessage>
                </FormControl>

                <FormControl
                isInvalid={formik.touched.bankName && formik.errors.bankName}
                >
                <FormLabel htmlFor="bankName">Bank Name</FormLabel>
                <Input
                    id="bankName"
                    type="text"
                    placeholder="Enter the bank name"
                    value={formik.values.bankName}
                    onChange={formik.handleChange}
                />
                <FormErrorMessage>{formik.errors.bankName}</FormErrorMessage>
                </FormControl>

                <FormControl
                isInvalid={formik.touched.accountNumber && formik.errors.accountNumber}
                >
                <FormLabel htmlFor="accountNumber">Account Number</FormLabel>
                <Input
                    id="accountNumber"
                    type="text"
                    placeholder="Enter the account number"
                    value={formik.values.accountNumber}
                    onChange={formik.handleChange}
                />
                <FormErrorMessage>{formik.errors.accountNumber}</FormErrorMessage>
                </FormControl>

                <FormControl
                isInvalid={formik.touched.bankBranchNumber && formik.errors.bankBranchNumber}
                >
                <FormLabel htmlFor="bankBranchNumber">Bank Branch Number</FormLabel>
                <Input
                    id="bankBranchNumber"
                    type="text"
                    placeholder="Enter the bank branch number"
                    value={formik.values.bankBranchNumber}
                    onChange={formik.handleChange}
                />
                <FormErrorMessage>{formik.errors.bankBranchNumber}</FormErrorMessage>
                </FormControl>
            </Stack>
          </ModalBody>
          <ModalFooter justifyContent="center">
            {/* <Button colorScheme="blue" mr={3} type="submit" isLoading={formik.isSubmitting}> */}
            <Button colorScheme="blue" mr={3} onClick={formik.handleSubmit}>
              Initiate
            </Button>
            <Button
              variant="ghost"
              onClick={onClose}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </chakra.form>
  );
};

export default RefundModalD24;
