/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';

import {
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Input,
    useToast,
    Select
} from '@chakra-ui/react';

import { callApiWithToken } from '../../../utils/utils';

const StatusEditModal = (props) => {
    const { isOpen, onClose, isLoading, refreshGrid, orderId } = props;

    const [loading, setLoading] = useState(false);
    const [orderStatus, setOrderStatus] = useState("");

    const toast = useToast();

    const onClickHandler = async () => {
        try {
            setLoading(true);
            const response = await callApiWithToken(
                "POST",
                '/api/orders/updateStatus',
                { orderId, status: orderStatus }
            );

            toast({
                title: 'Status updated successfully!',
                status: 'success',
                isClosable: true,
                position: 'bottom',
                duration: 5000,
            });
            setLoading(false);
            // await refreshGrid();
            onClose();
        }
        catch (err) {
            toast({
                title: `Update Failed:  ${err.message}`,
                status: 'success',
                isClosable: true,
                position: 'bottom',
                duration: 5000,
            });
            setLoading(false);
            onClose();
        }
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />

            <ModalContent>
                <ModalHeader>Update Order: {orderId}</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    <FormControl mt={4}>
                        <FormLabel>Select Status</FormLabel>
                        <Select
                            placeholder='Select Status'
                            onChange={(e) => setOrderStatus(e.target.value)}
                        >
                            <option value='initiated'>Initiated</option>
                            <option value='expired'>Expired</option>
                            <option value='fund_processing'>Fund Processing</option>
                            <option value='fund_settled'>Fund Received</option>
                            <option value='fund_failed'>Fund Not Received</option>
                            <option value='asset_deposited'>Crypto Received</option>
                            <option value='asset_deposit_failed'>Crypto Not Received</option>
                            <option value='asset_settled'>Crypto Delivered</option>
                            <option value='asset_settle_failed'>Crypto Not Delivered</option>
                        </Select>
                    </FormControl>
                </ModalBody>
                <ModalFooter>
                    <Button
                        isLoading={isLoading}
                        isDisabled={!orderStatus}
                        onClick={onClickHandler}
                        colorScheme='blue' mr={3}
                    >
                        Update
                    </Button>
                    <Button
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default StatusEditModal;