import React, { useState, useEffect } from "react";
import Loader from "react-js-loader";

import { Badge, Avatar, Center, Stack, Heading, CardBody } from "@chakra-ui/react";
import { Table, Thead, Tbody, Tr, Text, Th, Td, Link, HStack, CardHeader, Card, Box } from "@chakra-ui/react";
import { Steps } from "antd";
import { find, startCase, get, isEmpty } from "lodash";
import { HiOutlineExternalLink } from "react-icons/hi";

import { constants } from "../../../constants";
import TimezoneFormatter from "../../../utils/TimezoneFormatter";
import { urlMap } from "../../../utils/config";
import { callApiWithToken } from "../../../utils/utils";
import { getCxRoles, getOrgRoles } from "../../App/useToken";

const { COUNTRY_CODE_MAPPING } = constants;

export default function UserDetails(props) {
    const { userId, userDetails, setUserDetails } = props || {};
    const timestamps = userDetails?.timestamps || {};
    const [loading, setLoading] = useState(false);
    const [userName, setUserName] = useState("");
    const [orgDetails, setOrgDetails] = useState([]);

    const roles = getCxRoles();
    const orgRoles = getOrgRoles();

    const { orgId, name, website, status, kybStatus, createdAt } = orgDetails || {};
    const location = urlMap?.[window.location.host];
    const show = location.env === "local" || location.env === "development" ? true : false;

    useEffect(() => {
        getUserDetails();
    }, [userId]);

    const getUserDetails = async () => {
        setLoading(true);
        const response = await callApiWithToken("GET", `/api/users/getUserDetails?userId=${userId}`) || {};
        const { data = [] } = response;
        setUserDetails(data);
        setUserName(data?.firstName ? `${data?.firstName} ${data?.lastName}` : data?.name);
        const orgData = (await callApiWithToken("POST", `/api/organization/details`, { orgId: data.orgId[0] })) || {};
        setOrgDetails(orgData.orgResponse);
        setLoading(false);
    };

    const timestampsItems =
        timestamps &&
        Object.keys(timestamps).map((key) => {
            const newKey = (key.slice(0, -2));
            return {
                title: `${startCase(newKey)}` || startCase(key),
                description: <TimezoneFormatter date={timestamps[key]} format={"MMMM DD YYYY, h:mm:ss A"} />
            };
        });

    const colorMap = {
        kyc_initiated: "purple",
        address_pending: "orange",
        sof_pending: "orange",
        kyc_pending: "orange",
        kyc_manual_review: "orange",
        kyc_success: "green",
        active: "green",
        blocked: "red",
        inactive: 'red',
        kyc_failed: "red"
    };

    return !loading ?
        <>
            <Center>
                <Stack
                    borderWidth="1px"
                    borderRadius="lg"
                    width={'100%'}
                    direction={'column'}
                    bg={'gray.100'}
                    boxShadow={'md'}
                >
                    <Stack
                        width={'100%'}
                        height={'20rem'}
                        direction={'row'}
                        bg={'gray.100'}
                    >
                        <Stack
                            flex={1}
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center">
                            <Avatar
                                size={'xl'}
                                name={userName}
                                alt={'Avatar Alt'}
                                mb={4}
                                pos={'relative'}
                            />
                            <Heading fontSize={'2xl'} fontFamily={'body'}>
                                {userName}
                            </Heading>
                            <Text fontWeight={600} color={'gray.500'} mb={4}>
                                {userDetails?.email}
                            </Text>
                            <HStack>
                                <Badge colorScheme={colorMap[userDetails?.status || 'N/A'] || "grey"}>
                                    {startCase(userDetails?.status || 'N/A')}
                                </Badge>
                                <Text></Text>
                                {orgRoles.includes("transfi_admin")
                                    && userDetails?.vendors?.userAccount === "Sumsub"
                                    && <Link
                                        href={`https://cockpit.sumsub.com/checkus#/applicant/${get(find(userDetails?.partners, { vendor: "Sumsub" }), "id")}`}
                                        isExternal
                                        color="#0078FF"
                                    >
                                        View Details
                                    </Link>
                                }
                            </HStack>


                        </Stack>
                        <div style={{ borderLeft: "solid rgba(0,0,0,0.1)", marginTop: '50px', marginBottom: '60px' }}></div>
                        <Stack
                            flex={1}
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                            p={1}>
                            <Heading fontSize={'2xl'} fontFamily={'body'}>
                                {userId}
                            </Heading>
                            <Text fontWeight={600} color={'gray.600'} size="sm" mb={4}>
                                Country: {userDetails?.country ? find(COUNTRY_CODE_MAPPING, { symbol: userDetails?.country })?.country : 'N/A'}
                            </Text>
                            <Text fontWeight={600} color={'gray.600'} size="sm" mb={4}>
                                State: {userDetails?.state || 'N/A'}
                            </Text>
                            <Text fontWeight={600} color={'gray.600'} size="sm" mb={4}>
                                Created At: <TimezoneFormatter date={userDetails?.createdAt} format={"lll"} />
                            </Text>
                        </Stack>
                    </Stack>
                </Stack>
            </Center>

            {orgRoles.includes("transfi_admin") || show && (
                <Card
                    style={{
                        borderRadius: "20px",
                        marginTop: "25px",
                        boxShadow:
                            "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                    }}
                >
                    <CardHeader>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <Heading size="sm">Organizations</Heading>
                            <Heading size="sm">{orgId}</Heading>
                        </div>
                    </CardHeader>
                    <hr />
                    <CardBody>
                        <Table
                            variant="unstyled"
                            size="sm"
                            responsive={true}
                            style={{}}
                        >
                            <Thead>
                                <Tr>
                                    <Th>Name</Th>
                                    <Th>Website</Th>
                                    <Th>KYB Status</Th>
                                    <Th>Status</Th>
                                    <Th>Created At</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                <Tr style={{ fontWeight: "400" }}>
                                    <Td>{name}</Td>
                                    <Td>
                                        <Link
                                            href={`https://${website}`}
                                            isExternal
                                            color="#0078FF"
                                        >
                                            <HStack>
                                                <span>{website}</span>
                                                <HiOutlineExternalLink mx="2px" />
                                            </HStack>
                                        </Link>
                                    </Td>
                                    <Td>
                                        <Badge
                                            colorScheme={
                                                colorMap[kybStatus] || "purple"
                                            }
                                        >
                                            {kybStatus || "NA"}
                                        </Badge>
                                    </Td>
                                    <Td>{startCase(status)}</Td>
                                    <Td><TimezoneFormatter date={createdAt} format={"lll"} /></Td>
                                </Tr>
                            </Tbody>
                        </Table>
                    </CardBody>
                </Card>
            )}
            <br />
            <br />
            <Box>
                <Text fontSize={"20px"} fontWeight={700} color="#38B2AC" >Timestamps</Text><br />
                {!isEmpty(timestamps) ? (
                    <Steps
                        style={{ fontWeight: 500 }}
                        progressDot
                        current={10}
                        items={timestampsItems}
                    />
                ) : (
                    <Text>No timestamps found.</Text>
                )}
            </Box>
            {/* </Stack> 
             <hr style={{marginTop: '-2rem', marginBottom: '2.3rem', marginLeft: '50px', marginRight: '50px', height: '1px', backgroundColor: 'rgba(0,0,0,0.1)'}}/>
                <Stack
                    width={'100%'}
                    height={'12.3rem'}
                    direction={'row'}
                    bg={'gray.100'}
                >
                    <Stack
                        flex={1}
                        flexDirection="column"
                        alignItems="center"
                    >
                        <Heading fontSize={'l'} fontFamily={'body'} color={'black'}>
                            KYC ATTEMPT 1
                        </Heading>
                        <Text
                            fontSize={'sm'}
                            fontWeight={500}
                            bg={'red.300'}
                            p={2}
                            px={3}
                            color={'red.900'}
                            rounded={'full'}>
                            {'Rejected'}
                        </Text>
                        <Stack direction={'column'} align={'center'} justify={'center'}>
                            <Text fontWeight={600} color={'gray.600'} size="xs">
                                Attempted At: {moment(new Date()).format('lll')}
                            </Text>
                            <Text fontWeight={600} color={'gray.600'} size="xs">
                                Rejected At: {moment(new Date()).format('lll')}
                            </Text>
                            <Text fontWeight={600} color={'gray.600'} size="xs">
                                Rejected Reason: {'Invalid Document'}
                            </Text>
                        </Stack>
                    </Stack>
                    <div style={{ borderLeft: "solid rgba(0,0,0,0.1)", marginBottom: '50px', marginLeft: '-1.5px' }}></div>
                    <Stack
                        flex={1}
                        flexDirection="column"
                        alignItems="center">
                        <Heading fontSize={'l'} fontFamily={'body'} color={'black'}>
                            KYC ATTEMPT 2
                        </Heading>
                        <Text
                            fontSize={'sm'}
                            fontWeight={500}
                            bg={'green.300'}
                            p={2}
                            px={3}
                            color={'green.900'}
                            rounded={'full'}>
                            {'Approved'}
                        </Text>
                        <Stack direction={'column'} align={'center'} justify={'center'}>
                            <Text fontWeight={600} color={'gray.600'} size="xs">
                                Attempted At: {moment(new Date()).format('lll')}
                            </Text>
                            <Text fontWeight={600} color={'gray.600'} size="xs">
                                Approved At: {moment(new Date()).format('lll')}
                            </Text>
                        </Stack>
                    </Stack>
                </Stack> */}
            {/* <div style={{ display: 'inline-flex' }}>
            <KYCAttempt kycStatus="rejected" index="1" statusText="Rejected" />
            <KYCAttempt kycStatus="success" index="2" statusText="Approved" />
        </div> */}
        </> :
        <Loader type="spinner-cub" bgColor={"#FFFFFF"} title={"Loading..."} color={"#FFFFFF"} size={100} />
}
