import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Box, Text, VStack } from '@chakra-ui/react';
import {
    Table,
    Tbody,
    Tr,
    Td,
    TableContainer,
} from '@chakra-ui/react'
import moment from 'moment';

import Loader from '../../utils/Loader';
import { callApiWithTokenV2 } from '../../utils/utils';

const SettlementDetails = () => {
    const { id } = useParams();
    const [data, setData] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const USformatter = new Intl.NumberFormat('en-US');

    const getSettlementsDetails = async () => {
        setIsLoading(true);
        const { data } = await callApiWithTokenV2.get(`/api/settlement/tfpaySettlement/collections/details?settlementId=${id}`);
        setData(data?.data);
        setIsLoading(false);
        return data?.data;
    }

    useEffect(() => {
        getSettlementsDetails();
    }, [])

    return (
        <>
            {isLoading ?
                <Loader
                    type="spinner-cub"
                    bgColor={"#FFFFFF"}
                    title={"Loading..."}
                    color={"cyan.400"}
                    size={100}
                />
                : <Box w="100%" p={4}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '20px', padding: '0 10px', marginBottom: '5px' }}>
                        <Text fontWeight={500}>Settlement Details</Text>

                    </div>
                    <hr style={{ margin: '0 8px', marginBottom: '16px' }} />
                    <TableContainer>
                        <Table variant='striped' colorScheme='gray'>
                            <Tbody>
                                <Tr>
                                    <Td>SettlementId</Td>
                                    <Td>{data?.settlementId}</Td>

                                </Tr>
                                <Tr>
                                    <Td>Created At</Td>
                                    <Td>{moment(data?.createdAt).format("DD-MMM-YYYY @ h:mm A")}</Td>

                                </Tr>
                                <Tr>
                                    <Td>Fiat Amount</Td>
                                    <Td>{USformatter.format(data?.totalAmount)} {data?.fiatTicker}</Td>

                                </Tr>
                                <Tr>
                                    <Td>Crypto Amount</Td>
                                    <Td>{USformatter.format(data?.cryptoAmount)} {data?.cryptoTicker}</Td>

                                </Tr>
                                <Tr>
                                    <Td>Exchange Rate</Td>
                                    <Td> {data?.exchangeRate}</Td>
                                </Tr>
                                <Tr>
                                    <Td>TransFi Exchange Rate</Td>
                                    <Td> {data?.exchangeRateWithMarkup}</Td>
                                </Tr>
                                <Tr>
                                    <Td>Crypto Partner</Td>
                                    <Td> {data?.cryptoPaymentPartner}</Td>
                                </Tr>
                                <Tr>
                                    <Td>Partner Order Id</Td>
                                    <Td> {data?.partnerOrderId}</Td>
                                </Tr>
                                <Tr>
                                    <Td>Transaction Hash</Td>
                                    <Td> {data?.txHash}</Td>
                                </Tr>
                                <Tr>
                                    <Td>Order Ids</Td>
                                    <Td>

                                        {data?.orderIds?.map(orderId => <Text key={orderId}>{orderId}</Text>)}

                                    </Td>
                                </Tr>
                            </Tbody>
                        </Table>
                    </TableContainer>
                </Box>}
        </>
    );
};

export default SettlementDetails;