/* eslint-disable no-unused-expressions */
import React from "react";

import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Input,
  useToast,
  chakra,
  Stack,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import * as Yup from "yup";

import { callApiWithToken } from "../../utils/utils";

const ApproveModal = (props) => {
  const { isOpen, onClose, isLoading, refreshGrid, batchId, settlementCurrency } = props;

  const toast = useToast();

  const formik = useFormik({
    initialValues: {
      conversionRate: "",
      description: "",
    },
    validationSchema: Yup.object({
      conversionRate: Yup.string().required("Please enter the Conversion Rate"),
    }),
    onSubmit: async (formValues) => {
      try {
        const response = await callApiWithToken("POST", "/api/settlement/approveSettlement", {
          settlementId: batchId,
          conversionRate: formValues?.conversionRate,
          description: formValues?.description,
          convertedCurrency: settlementCurrency,
        });
        if (response?.message) {
          toast({
            title: "Success",
            description: response?.message,
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top-right",
          });
        }
        refreshGrid();
        onClose();
      } catch (err) {
        toast({
          title: "Error!",
          description: err?.message || "Failed to fetch order details.",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
      }
    },
  });

  const conversionStatement = settlementCurrency;

  return (
    <chakra.form onSubmit={formik.handleSubmit} display="flex" flexGrow={1}>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />

        <ModalContent>
          <ModalHeader>Approve {batchId}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Stack direction={"column"} justifyContent="space-between" flexGrow={1}>
              <FormControl isRequired isInvalid={formik.touched.conversionRate && formik.errors.conversionRate}>
                <FormLabel htmlFor="name">{`Conversion Rate (1 ${conversionStatement})`}</FormLabel>
                <Input id="conversionRate" type="number" value={formik.values.conversionRate} onChange={formik.handleChange} />
                <FormErrorMessage>{formik.errors.conversionRate}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={formik.touched.description && formik.errors.description}>
                <FormLabel htmlFor="name">Notes</FormLabel>
                <Input id="description" type="text" value={formik.values.description} onChange={formik.handleChange} />
                <FormErrorMessage>{formik.errors.description}</FormErrorMessage>
              </FormControl>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button isLoading={isLoading} onClick={formik.handleSubmit} colorScheme="blue" mr={3}>
              Approve
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </chakra.form>
  );
};

export default ApproveModal;
