import React from 'react';

import { Button, Center, HStack, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalOverlay, Stack, Text } from '@chakra-ui/react';

const SettlementConfirmModal = ({ isOpen, onClose, settlementAmount, settlementCurrency, handleCreateSettlement, loading, setLoading }) => {
    const USformatter = new Intl.NumberFormat('en-US');
    const handleCloseModal = () => {
        setLoading(false);
        onClose()
    }
    return (
        <Modal isOpen={isOpen} onClose={handleCloseModal} motionPreset='slideInBottom' size={'3xl'} >
            <ModalOverlay />
            <ModalContent bg={'#FAFAFA'} p={5} >
                <ModalCloseButton />
                <ModalBody>
                    <Center>
                        <Text style={{ fontFamily: "arial" }} my={2}>Are you sure you want to make settlement of <Text as={'span'} fontWeight={'semibold'}>{USformatter.format(settlementAmount)} {settlementCurrency}</Text></Text>
                    </Center>

                </ModalBody>
                <ModalFooter justifyContent={'center'}>
                    <HStack mt={2} justify={'center'}>
                        <Button
                            w={"150px"}
                            bg="#ffffff"
                            color="#525C76"
                            border={"1px"}
                            borderColor={"#525C76"}
                            variant={"outline"}
                            onClick={onClose}
                            _hover={{ bg: '#ffffff' }}
                        >
                            Cancel
                        </Button>
                        <Button
                            isLoading={loading}
                            bg="#1A4FD6"
                            w={"150px"}
                            color={"#ffffff"}
                            _hover={{ bg: "#232d95" }}
                            onClick={handleCreateSettlement}
                        >
                            Confirm
                        </Button>
                    </HStack>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default SettlementConfirmModal;