import React, { useEffect } from "react";
import Select from "react-select";

import { Drawer, DrawerBody, DrawerHeader, DrawerOverlay, DrawerContent } from "@chakra-ui/react";
import { DrawerCloseButton, Input, Button } from "@chakra-ui/react";
import { isEmpty, unset } from "lodash";

import DateRangePicker from "../Common/DateRangePicker";

const RefundFilter = (props) => {
    const { isOpen, onClose, setFilters, setDateRange, dateRange, handleOnReset } = props;
    const { inputData, setInputData, setFormValues, formValues, filters = {}, } = props;

    useEffect(() => {
        setFormValues(filters);
    }, [filters]);

    useEffect(() => {
        setFormValues(filters);
    }, []);

    const handleOnChanges = (key, op, value) => {
        setInputData({
            ...inputData,
            [key]: value,
        });
        let newValue;
        if (Array.isArray(value)) newValue = value.map((data) => data.value);
        else newValue = value.trim();
        setFormValues({
            ...formValues,
            [key]: { [`$${op}`]: newValue },
        });
    };

    const onChangeDateRange = (start, end) => {
        setDateRange({ startDate: start, endDate: end });
        setFormValues({
            ...formValues,
            ["dateRange"]: { startDate: start, endDate: end },
        });
    };

    const handleOnClear = () => {
        onClose();
        handleOnReset();
    };

    const handleOnFilter = () => {
        Object.keys(formValues).map((key) => {
            if (isEmpty(formValues[key])) unset(formValues, key);
        });
        setFilters(formValues);
        onClose();
    };

    const statusOptions = [
        { label: " Refund Initiated", value: "initiated" },
        { label: " Refund Processing", value: "refund_processing" },
        { label: "Refund Completed", value: "refund_completed" },
        { label: "Refund Failed", value: "refund_failed" },
        { label: "Refund Approved", value: "approved" }
    ];

    return (
        <>
            <Drawer onClose={onClose} isOpen={isOpen} size={"sm"}>
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader style={{ color: "#3182ce", fontFamily: "arial" }}>Filters</DrawerHeader>
                    <DrawerBody>
                        <div style={{ margin: "12px 0" }}>
                            <DateRangePicker
                                width={"100%"}
                                onChangeDateRange={onChangeDateRange}
                                start={dateRange?.startDate}
                                end={dateRange?.endDate}
                            />
                        </div>
                        <div style={{ margin: "12px 0" }}>
                            <Input
                                placeholder="Refund ID"
                                style={{ border: "1px solid hsl(0, 0%, 80%)" }}
                                type="text"
                                value={formValues?.refundId && Object.values(formValues?.refundId)[0]}
                                onChange={(e) =>
                                    handleOnChanges("rfId", "eq", e.target.value)
                                }
                            />
                        </div>

                        <div style={{ margin: "12px 0" }}>
                            <Select
                                isMulti
                                isSearchable={false}
                                placeholder="Status"
                                options={statusOptions}
                                value={inputData?.status}
                                onChange={(e) => handleOnChanges("status", "in", e)

                                }
                            />
                        </div>

                        <div style={{ marginTop: "30px" }}>
                            <Button onClick={() => handleOnFilter()} variant={"outline"} colorScheme="blue">
                                Filter
                            </Button>
                            <Button
                                onClick={() => handleOnClear()}
                                variant={"outline"}
                                colorScheme="red"
                                style={{ marginLeft: "30px" }}
                            >
                                Clear
                            </Button>
                        </div>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    );
};

export default RefundFilter;
