import React, { useState, useContext, useRef, useEffect } from "react";
import Loader from "react-js-loader";
import { useParams, Link } from "react-router-dom";

import { DownloadIcon } from "@chakra-ui/icons";
import { Badge, useDisclosure, Button, useToast } from "@chakra-ui/react";
import { Box } from "@chakra-ui/react";
import { startCase, uniqBy, round, unset, get, isArray, isEmpty } from "lodash";
import moment from "moment";
import { BiRefresh, BiReset, BiShow } from "react-icons/bi";
import { BsCartPlus } from "react-icons/bs";
import { FiFilter } from "react-icons/fi";

import { NavBarContext } from "../../context/NavBarContext";
import TimezoneFormatter from "../../utils/TimezoneFormatter";
import TransfiGrid from "../../utils/TransfiGrid";
import { urlMap } from "../../utils/config";
import { getCountryData } from "../../utils/getCountries";
import { callApiWithToken } from "../../utils/utils";
import { getOrgRoles } from "../App/useToken";
import ConfirmationModal from "./ConfirmationModal";
import SelectedConfirmModal from "./helper/SelectedConfirmModal";
import TransactionFilter from "./helper/TransactionFilter";
import ViewModal from "./helper/ViewModal";

export default function Transactions() {
    const toast = useToast();
    const { orderId } = useParams();
    const [loading, setLoading] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isOpenConfirmationModal, onOpen: onOpenConfirmationModal, onClose: onCloseConfirmationModal } = useDisclosure();
    const { isOpen: isOpenSelectedModal, onOpen: onOpenSelectedModal, onClose: onCloseSelectedModal } = useDisclosure();

    const {
        isOpen: isOpenViewModal,
        onOpen: onOpenViewModal,
        onClose: onCloseViewModal,
    } = useDisclosure({ defaultIsOpen: false });

    const [viewRecord, setViewRecord] = useState({});
    const [countryOptions, setCountryOptions] = useState([]);
    const [paymentOptions, setPaymentOptions] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [summaryDetails, setSummaryDetails] = useState([]);
    const [error, setError] = useState("");
    const [inputData, setInputData] = useState({ settlementStatus:{ label: "All", value: "all" },refundStatus:{ label: "All", value: "all" } });
    const defaultDate = { startDate: moment().subtract(2, "months"), endDate: moment().format("YYYY-MM-DD 23:59:59") };
    const [filters, setFilters] = useState({ dateRange: defaultDate, settlementStatus: 'all', refundStatus: "all" });
    const [formValues, setFormValues] = useState({ ["dateRange"]: defaultDate });
    const [dateRange, setDateRange] = useState(defaultDate);
    const [selectedModalMessage, setSelectedModalMessage] = useState("");

    const location = urlMap?.[window.location.host];
    const show = location?.env !== "production" ? true : false;

    const { selectedOrg } = useContext(NavBarContext);
    const gridRef = useRef();
    const orgRoles = getOrgRoles();
    const showColumn = orgRoles.includes("transfi_admin");

    useEffect(() => {
        getCountryList();
        getPaymentList();
    }, []);

    const refreshGrid = () => {
        gridRef.current.refreshGrid();
    };

    const getCountryList = async () => {
        const { countryList } = await getCountryData();
        setCountryOptions(countryList);
    };

    const getPaymentList = async () => {
        const response = await callApiWithToken("GET", `/api/orders/getPaymentMethods`);
        const data = response.data;
        let list = data.map((country) => {
            return { value: country.name, label: country.name };
        });
        setPaymentOptions(uniqBy(list, (obj) => obj.value));
    };

    const downloadReport = async () => {
        const { startDate, endDate } = filters?.dateRange;
        const convertedStartDate = moment(startDate).format("YYYY-MM-DDT")
        const convertedEndDate = moment(endDate).format("YYYY-MM-DDT")
        unset(filters, "dateRange");
        const updatedFilters = {
            dateRange: { startDate: convertedStartDate, endDate: convertedEndDate },
            ...filters
        };
      
        const org = !isArray(selectedOrg) ? [selectedOrg] : selectedOrg;
        let body = {

            gridFilters: { impersonatedOrg: org, filters: updatedFilters }
        };

        const response = await callApiWithToken("POST", '/api/settlement/transactionReport', body);
        if (response?.message) {
            toast({
                title: 'Unable to schedule report',
                status: 'error',
                isClosable: true,
                position: 'top-right',
                duration: 5000,
            });
        } else {
            toast({
                title: 'Report Scheduled Successfully, You will get email shortly.',
                status: 'success',
                isClosable: true,
                position: 'top-right',
                duration: 5000,
            });
        }
    }

    const selectionCallback = React.useCallback((ids) => setSelectedRows(ids), []);

    const colorMap = {
        "expired": "red",
        "fund_settled": "green",
        "initiated": "purple",
        "fund_processing": "orange",
        "fund_failed": "red"
    };

    const summaryView = async () => {
        setLoading(true);
        const selectedDetails = selectedRows.map((data) => data.orderId);
        const response = await callApiWithToken("POST", "/api/settlement/batch/overview", {
            data: selectedDetails,
            filters: { impersonatedOrg: selectedOrg, filters }
        });
        setSummaryDetails(response?.message);
        onOpenConfirmationModal();
        setError(response?.error);
        setLoading(false);
    }

    const handleViewRecord = (record) => {
        setViewRecord(record);
        onOpenViewModal();
    }

    const columns = [
        {
            Header: "Date Order Initiated",
            accessor: "createdAt",
            width: "150",
            Cell: ({ row }) => {
                return <TimezoneFormatter date={row?.original.createdAt} format={"D/M/YYYY"} />
            },
            type: "date"
        },
        {
            Header: "Time Order Initiated",
            accessor: "",
            width: "150",
            Cell: ({ row }) => {
                const { createdAt } = row?.original;
                return <TimezoneFormatter date={createdAt} format={'LT'} />
            },
            type: "date"
        },
        {
            Header: "Date Order Completed",
            accessor: "timestamps",
            width: "150",
            Cell: ({ row }) => {
                return row?.original.timestamps.fundSettledAt ? <TimezoneFormatter date={row?.original.timestamps.fundSettledAt} format={"D/M/YYYY"} /> : '-'
            },
            type: "date"
        },
        {
            Header: "Time Order Completed",
            accessor: "",
            width: "150",
            Cell: ({ row }) => {
                return row?.original.timestamps.fundSettledAt ? <TimezoneFormatter date={row?.original.timestamps.fundSettledAt} format={"LT"} /> : '-'
            },
            type: "date"
        },
        {
            Header: "Binance Id",
            accessor: "customer.orderId",
            width: "150",
        },
        {
            Header: "Order Id Transfi",
            accessor: "orderId",
            width: "200",
        },
        {
            Header: "User Name",
            accessor: "customer",
            width: "200",
            Cell: ({ value }) => {
                const userName = get(value, "firstName", "") + " " + get(value, "lastName", "");
                return userName;
            },
        },
        {
            Header: "Type",
            accessor: "type",
            width: "35",
            Cell: ({ value }) => {
                const color = value === "buy" ? "blue" : "green";
                return <Badge colorScheme={color}> {startCase(value)} </Badge>;
            },
        },
        {
            Header: "Fiat Payment Type",
            accessor: "paymentType",
            width: "130",
            Cell: ({ value }) => ["bank_transfer", "netbanking"].includes(value) ? "Bank Transfer" : "E-Wallet",
        },
        {
            Header: "Fiat Payment Method",
            accessor: "paymentName",
            width: "130",
            Cell: ({ value }) => startCase(value) || "NA",
        },
        {
            Header: "Gross Fiat Amount",
            accessor: "fiatAmount",
            width: "115",
            Cell: ({ value }) => value ? round(value, 2).toLocaleString("en-US") : "-"
        },
        {
            Header: "Fiat Currency",
            accessor: "fiatTicker",
            width: "30",
        },
        {
            Header: "Processing Fee Amt.",
            accessor: "fees.processingFee",
            width: "30",
            Cell: ({ value }) => value ? round(value, 2).toLocaleString("en-US") : "-"
        },
        {
            Header: "Conversion rate",
            accessor: "",
            width: "100",
            Cell: ({ row }) => {
                const { fiat, paymentType = "", fiatTicker } = row?.original;
                if (fiatTicker !== "IDR") return "NA";
                const { conversionRate } = fiat || {};
                const usdValue = ["netbanking", "bank_transfer"].includes(paymentType)
                    ? Number(conversionRate).toFixed(8).toLocaleString("en-US")
                    : "NA";
                return usdValue;
            }
        },
        {
            Header: "Processing Fee Amt. (USD)",
            accessor: "",
            width: "50",
            Cell: ({ row }) => {
                const { fiat, paymentType = "", fiatTicker } = row?.original;
                if (fiatTicker !== "IDR") return "NA";
                const { conversionRate, processingFee } = fiat || {};
                const usdValue =
                    ["netbanking", "bank_transfer"].includes(paymentType)
                        ? round(conversionRate * processingFee, 2).toLocaleString("en-US")
                        : "NA";
                return usdValue;
            }
        },
        {
            Header: "XE Rate",
            accessor: "",
            width: "100",
            show: showColumn,
            Cell: ({ row }) => {
                if (isEmpty(row?.original?.fiat?.conversionRateWithoutMarkup)) {
                    const conversionRate = Number(1 / row?.original?.fiat?.conversionRate) || 0
                    return round(conversionRate, 2).toLocaleString("en-US");
                } else {
                    const conversionRate = Number(1 / row?.original?.fiat?.conversionRateWithoutMarkup) || 0
                    return round(conversionRate, 2).toLocaleString("en-US");
                }
            },
        },
        {
            Header: "Transfi Live Rate",
            accessor: "",
            width: "100",
            Cell: ({ row }) => {
                if (isEmpty(row?.original?.fiat?.conversionRate)) {
                    return "0.00";
                } else {
                    const conversionRate = Number(1 / row?.original?.fiat?.conversionRate) || 0
                    return round(conversionRate, 2).toLocaleString("en-US");
                }
            },
        },
        {
            Header: "Transfi Live Rate",
            accessor: "",
            width: "100",
            Cell: ({ row }) => {
                if (isEmpty(row?.original?.fiat?.conversionRate)) {
                    return "0.00";
                } else {
                    const conversionRate = Number(1 / row?.original?.fiat?.conversionRate) || 0
                    return round(conversionRate, 2).toLocaleString("en-US");
                }
            },
        },
        {
            Header: "Crypto Amount",
            accessor: "cryptoAmount",
            width: "30",
            Cell: ({ value }) => value ? round(value, 2).toLocaleString("en-US") : "-"
        },
        {
            Header: "Crypto Ticker",
            accessor: "cryptoTicker",
            width: "30",
        },
        {
            Header: "Transaction Status",
            accessor: "status",
            width: "50",
            Cell: ({ row }) => {
                const { status } = row?.original;
                const color = colorMap[status] || "grey";
                return <Badge colorScheme={color}> {startCase(status)} </Badge>;
            },
        },
        {
            Header: "Processing Fee (USDT)",
            width: "30",
            Cell: ({ row }) => {
                const processingFee = Number(row?.original?.fees?.processingFee || 0);
                const conversionRate = Number(row?.original?.settlement?.conversionRate || 0);
                if (conversionRate === 0) {
                    return "0";
                }
                const processingFeeUsdt = Number(processingFee / conversionRate || 0);
                return processingFeeUsdt ? round(processingFeeUsdt, 2).toLocaleString("en-US") : "0"
            }
        },
        {
            Header: "Settlement Amount (USDT)",
            width: "30",
            Cell: ({ row }) => {
                if (row?.original?.type === "buy") {
                    const fiatAmount = Number(row?.original?.fiatAmount || 0);
                    const processingFee = Number(row?.original?.fees?.processingFee || 0);
                    const conversionRate = Number(row?.original?.settlement?.conversionRate || 0);
                    if (conversionRate === 0) {
                        return "0";
                    }
                    const settlementAmount = (fiatAmount - processingFee) / conversionRate;
                    return settlementAmount ? `+${round(settlementAmount, 2).toLocaleString("en-US")}` : "0"
                }
                if (row?.original?.type === "sell") {
                    const fiatAmount = Number(row?.original?.fiatAmount || 0);
                    const processingFee = Number(row?.original?.fees?.processingFee || 0);
                    const conversionRate = Number(row?.original?.settlement?.conversionRate || 0);
                    if (conversionRate === 0) {
                        return "0";
                    }
                    const settlementAmount = (fiatAmount + processingFee) / conversionRate;
                    return settlementAmount ? `-${round(settlementAmount, 2).toLocaleString("en-US")}` : "0"
                }
            }
        },
        {
            Header: "Settlement Batch ID",
            accessor: "batchId",
            width: "30",
            Cell: ({ value }) => {
                return (
                    value ? <Link style={{ color: "#4ACDED", fontWeight: "bold" }} to={`/settlements/batch/${value}`}>
                        {value}
                    </Link> : "N/A"
                );
            },
        },
        {
            Header: "View Bank Details",
            accessor: "id",
            width: "130",
            Cell: ({ row }) => {
                return (
                    <BiShow
                        style={{
                            fontSize: "22px",
                            color: "#2b6cb0",
                            cursor: "pointer",
                            margin: "0 20%",
                        }}
                        onClick={() => handleViewRecord(row?.original)}
                    ></BiShow>
                );
            },
            show: showColumn
        },
        {
            Header: "Failure Code",
            accessor: "error.externalCode",
            width: "30",
        },
        {
            Header: "Refund Status",
            accessor: "refund.refundStatus",
            width: "50",
            Cell: ({ value }) => {
                let status = "Not Applicable";
                if (value === true) {
                    status = "Completed";
                } else if (value === false) {
                    status = "Pending";
                }
                return status;
            },
        },
    ];

    const handleOnReset = () => {
        setDateRange(defaultDate);
        setFilters({ dateRange: defaultDate, settlementStatus: "all", refundStatus: "all" });
        setFormValues({});
        setInputData({ settlementStatus: { label: "All", value: "all" }, refundStatus: { label: "All", value: "all" } });
    };

    const handleSettlement = () => {
        summaryView();
    }

    const createBatch = () => {
        if (selectedRows.length) {
            setSelectedModalMessage("Settlement will be created on selected transactions on current screen.");
        } else {
            setSelectedModalMessage("Settlements will be created on filtered date and currency");
        }
        onOpenSelectedModal();
    }

    return !loading ? (
        <Box style={{ background: "white" }} heading="Orders">
            {orderId ? (
                <></>
            ) : (
                <div style={{ marginTop: "2%" }}>
                    <div style={{ float: "right" }}>
                        {showColumn ? <Button
                            leftIcon={<BsCartPlus style={{ fontSize: "20px" }} />}
                            onClick={() => createBatch()}
                            variant={"outline"}
                            colorScheme="blue"
                            style={{ margin: "0 8px" }}
                            size="sm"
                        >
                            Create Settlement
                        </Button> : <></>}
                        <Button
                            leftIcon={<BiReset style={{ fontSize: "20px" }} />}
                            onClick={() => handleOnReset()}
                            variant={"outline"}
                            colorScheme="blue"
                            style={{ margin: "0 8px" }}
                            size="sm"
                        >
                            Reset
                        </Button>
                        <Button
                            leftIcon={<BiRefresh style={{ fontSize: "20px" }} />}
                            onClick={() => refreshGrid()}
                            variant={"outline"}
                            colorScheme="blue"
                            style={{ margin: "0 0px" }}
                            size="sm"
                        >
                            Refresh
                        </Button>
                        <Button
                            leftIcon={<FiFilter style={{ fontSize: "20px" }} />}
                            onClick={onOpen}
                            variant={"outline"}
                            colorScheme="blue"
                            style={{ margin: "0 8px" }}
                            size="sm"
                        >
                            Filter
                        </Button>
                        <Button
                            leftIcon={<DownloadIcon style={{ fontSize: "20px" }} />}
                            onClick={() => downloadReport()}
                            variant={"outline"}
                            colorScheme="blue"
                            style={{ margin: "0 8px" }}
                            size="sm"
                        >
                            Download Report
                        </Button>
                    </div>
                    <br />
                </div>
            )}

            <TransactionFilter
                onClose={onClose}
                isOpen={isOpen}
                countryOptions={countryOptions}
                paymentOptions={paymentOptions}
                formValues={formValues}
                setFormValues={setFormValues}
                filters={filters}
                setFilters={setFilters}
                dateRange={dateRange}
                setDateRange={setDateRange}
                inputData={inputData}
                setInputData={setInputData}
                handleOnReset={handleOnReset}
            ></TransactionFilter>
            <TransfiGrid
                gridName="Transactions"
                ref={gridRef}
                columns={columns}
                dataUrl={"/api/settlement/transactionList"}
                filters={{ impersonatedOrg: selectedOrg, filters }}
                selectRow={showColumn}
                onChangeSelectedRow={selectionCallback}
                initialState={{ selectedRowIds: selectedRows }}
            ></TransfiGrid>
            <ConfirmationModal
                isLoading={loading}
                isOpen={isOpenConfirmationModal}
                onOpen={onOpenConfirmationModal}
                onClose={onCloseConfirmationModal}
                selectedRows={selectedRows}
                refreshGrid={refreshGrid}
                summaryDetails={summaryDetails}
                error={error}
            />
            <SelectedConfirmModal
                isOpen={isOpenSelectedModal}
                onOpen={onOpenSelectedModal}
                onClose={onCloseSelectedModal}
                message={selectedModalMessage}
                handleSettlement={handleSettlement}
                loading={loading}
            />
            <ViewModal
                isOpen={isOpenViewModal}
                onClose={onCloseViewModal}
                selectedRow={viewRecord}
            />
        </Box>
    ) : (
        <Loader type="spinner-cub" bgColor={"#FFFFFF"} title={"Loading..."} color={"cyan.400"} size={100} />
    );
}
