// import { applyAuthTokenInterceptor } from 'axios-jwt';
import axios from 'axios';
import { isEmpty } from 'lodash';
import moment from "moment-timezone";

import { getCustomerId, getOrgId } from '../components/App/useToken';
import { urlMap } from './config';
import { getDataFromSession } from './session-storing-utils';

const methodListBody = ["POST", "PUT"];

// Instance of axios 
let intCallApiWithToken = axios.create({
  baseURL: urlMap[window.location.host].server,
  headers: {
      'Content-Type': 'application/json'
  }
})

let intcallApiWithTokenMultipart  = axios.create({
  baseURL: urlMap[window.location.host].server,
  headers: {
      'Content-Type': 'multipart/form-data'
  }
})

// Request Interceptor for withToken
intCallApiWithToken.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token') === null ? "" : localStorage.getItem('token');
    const refreshToken = localStorage.getItem('refreshToken') === null ? "" : localStorage.getItem('refreshToken');
    config.headers["Authorization"] = `Bearer ${token}`;
    config.headers["x-refresh-token"] = refreshToken;
    config.headers["envType"] = getDataFromSession('connectionData', 'envType');
      return config;
  },
  error => {
      return Promise.reject(error);
  }
);

// Response Interceptor for withToken
intCallApiWithToken.interceptors.response.use(res => {
  let refreshedToken = res.headers['x-refreshed-token'];
  if(!isEmpty(refreshedToken) && refreshedToken !== "false") {
      refreshedToken = refreshedToken?.split(" ")[1];
      localStorage.setItem('token', refreshedToken);
  }
  return res;
}, (err) => {
  if (err?.response?.status === 401) {
      localStorage.clear();
      window.location.reload();
      return err;
  }
  return err;
});

// Request Interceptor for withToken & multipart
intcallApiWithTokenMultipart.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token') === null ? "" : localStorage.getItem('token');
    const refreshToken = localStorage.getItem('refreshToken') === null ? "" : localStorage.getItem('refreshToken');
    config.headers["Authorization"] = `Bearer ${token}`;
    config.headers["x-refresh-token"] = refreshToken;
    config.headers["envType"] = getDataFromSession('connectionData', 'envType');
      return config;
  },
  error => {
      return Promise.reject(error);
  }
);

// Response Interceptor for withToken & multipart
intcallApiWithTokenMultipart.interceptors.response.use(res => {
  let refreshedToken = res.headers['x-refreshed-token'];
  if(!isEmpty(refreshedToken) && refreshedToken !== "false") {
      refreshedToken = refreshedToken?.split(" ")[1];
      localStorage.setItem('token', refreshedToken);
  }
  return res;
}, (err) => {
  if (err?.response?.status === 401) {
      localStorage.clear();
      window.location.reload();
      return err;
  }
  return err;
});

const callApi = async (method, URL, body) => {
  return fetch(`${urlMap[window.location.host].server}${URL}`, {
    method,
    headers: {
      'Content-Type': 'application/json'
    },
    body: body
  }).then(data =>
    data.json()
  );
};

const callApiWithToken = async (method, URL, body = {}) => {
  const timezone = getDataFromSession('connectionData', 'timezone') || moment.tz.guess();  
  const cxId = getCustomerId();
  const formattedBody = { ...body, cxId, preferredTz: timezone, clientTz: moment.tz.guess() }

  try {
    let response;
    if(methodListBody.includes(method)){
      response = await intCallApiWithToken({method, url: URL, data : JSON.stringify(formattedBody)})
    }else{
      response = await intCallApiWithToken({method, url: URL})
    }
    if (response instanceof Error) {
      return response;
    }    
    return response.data;
  } catch (error) {
    alert(error.message)
    return error;
  }
}

let callApiWithTokenV2 = axios.create({
  baseURL: urlMap[window.location.host]?.server,
  headers: {
    'Content-Type': 'application/json'
  }
})

// For List API Call on AG Grid
callApiWithTokenV2.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token') === null ? "" : localStorage.getItem('token');
    const refreshToken = localStorage.getItem('refreshToken') === null ? "" : localStorage.getItem('refreshToken');
    config.headers["Authorization"] = `Bearer ${token}`;
    config.headers["x-refresh-token"] = refreshToken;
    config.headers["envType"] = getDataFromSession('connectionData', 'envType');
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

const callApiWithTokenMultipart = async (method, URL, formData = {}) => {
  try {
    const orgId = getOrgId();

    if (formData && formData instanceof FormData) {
      formData.append("orgId", orgId);
    } else {
      throw "Invalid input";
    }

    let response;
    if(methodListBody.includes(method)){
      response = await intcallApiWithTokenMultipart({method, url: URL, data : JSON.stringify(formData)})
    }else{
      response = await intcallApiWithTokenMultipart({method, url: URL})
    }  
    return response.data;
    
  } catch (error) {
    return error;
  }
}

export {
  callApi,
  callApiWithToken,
  callApiWithTokenMultipart,
  callApiWithTokenV2
};
