import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";

import { Drawer, DrawerBody, DrawerHeader, DrawerOverlay, DrawerContent } from "@chakra-ui/react";
import { DrawerCloseButton, Input, Button } from "@chakra-ui/react";
import { isEmpty, unset } from "lodash";

import DateRangePicker from "../../Common/DateRangePicker";

const UserFilter = (props) => {
    const { t } = useTranslation();
    const { isOpen, onClose, countryOptions, setFilters, setDateRange, dateRange } = props;
    const { inputData, setInputData, setFormValues, formValues, filters, handleOnReset } = props;

    useEffect(() => {
        setFormValues(filters);
    }, [filters]);

    useEffect(() => {
        setFormValues(filters);
    }, []);

    const handleOnChanges = (key, value) => {
        setInputData({
            ...inputData,
            [key]: value,
        });
        let newValue;
        if (Array.isArray(value)) newValue = value.map((data) => data.value);
        else newValue = value.trim();
        setFormValues({
            ...formValues,
            [key]: newValue,
        });
    };

    const onChangeDateRange = (start, end) => {
        setDateRange({ startDate: start, endDate: end });
        setFormValues({
            ...formValues,
            ["dateRange"]: { startDate: start, endDate: end },
        });
    };

    const handleOnClear = () => {
        onClose();
        handleOnReset();
    };

    const handleOnFilter = () => {
        Object.keys(formValues).map((key) => {
            if (isEmpty(formValues[key])) unset(formValues, key);
        });
        setFilters(formValues);
        onClose();
    };

    const statusOptions = [
        { label: "Trial User", value: "trial_user" },
        { label: "KYC Initiated", value: "kyc_initiated" },
        { label: "Address Pending", value: "address_pending" },
        { label: "Sof Pending", value: "sof_pending" },
        { label: "KYC Pending", value: "kyc_pending" },
        { label: "KYC Manual Review", value: "kyc_manual_review" },
        { label: "KYC Success", value: "kyc_success" },
        { label: "Active", value: "active" },
        { label: "Blocked", value: "blocked" },
        { label: "Inactive", value: "inactive" },
        { label: "KYC Failed", value: "kyc_failed" },
    ];

    const orgId = sessionStorage.getItem('orgId');
    const grpOrgId = sessionStorage.getItem('grpOrgId');

    return (
        <>
            <Drawer onClose={onClose} isOpen={isOpen} size={"sm"}>
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader style={{ color: "#3182ce", fontFamily: "arial" }}>Filters</DrawerHeader>
                    <DrawerBody>
                        <div style={{ margin: "12px 0" }}>
                            <DateRangePicker
                                width={"100%"}
                                onChangeDateRange={onChangeDateRange}
                                start={dateRange.startDate}
                                end={dateRange.endDate}
                            />
                        </div>
                        <div style={{ margin: "12px 0" }}>
                            <Input
                                placeholder={t("users:user_id")}
                                style={{ border: "1px solid hsl(0, 0%, 80%)" }}
                                type="text"
                                value={formValues.userId}
                                onChange={(e) => handleOnChanges("userId", e.target.value)}
                            />
                        </div>
                        <div style={{ margin: "12px 0" }}>
                            <Input
                                placeholder={t("users:email")}
                                style={{ border: "1px solid hsl(0, 0%, 80%)" }}
                                type="text"
                                value={formValues.email}
                                onChange={(e) => handleOnChanges("email", e.target.value)}
                            />
                        </div>
                        {(orgId === "OG-220923101501950") && (
                            <div style={{ margin: "12px 0" }}>
                                <Input
                                    placeholder={t("users:owner")}
                                    style={{ border: "1px solid hsl(0, 0%, 80%)" }}
                                    type="text"
                                    value={formValues.owner}
                                    onChange={(e) => handleOnChanges("owner", e.target.value)}
                                />
                            </div>
                        )}
                        <div style={{ margin: "12px 0" }}>
                            <Select
                                isMulti
                                isSearchable={false}
                                placeholder={t("users:status")}
                                options={statusOptions}
                                value={inputData.status}
                                onChange={(e) => handleOnChanges("status", e)}
                            />
                        </div>
                        <div style={{ margin: "12px 0" }}>
                            <Select
                                isMulti
                                isSearchable={false}
                                placeholder={t("users:country")}
                                options={countryOptions}
                                value={inputData.country}
                                onChange={(e) => handleOnChanges("country", e)}
                            />
                        </div>

                        <div style={{ marginTop: "30px" }}>
                            <Button onClick={() => handleOnFilter()} variant={"outline"} colorScheme="blue">
                                {t("default:filter")}
                            </Button>
                            <Button
                                onClick={() => handleOnClear()}
                                variant={"outline"}
                                colorScheme="red"
                                style={{ marginLeft: "30px" }}
                            >
                                {t("default:clear")}
                            </Button>
                        </div>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    );
};

export default UserFilter;
