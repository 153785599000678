/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import Select from "react-select";

import {
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Textarea,
    useToast,
    chakra,
    Stack,
    Flex,
    HStack,
    Box,
    VStack,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import * as Yup from "yup";

import { callApiWithToken } from "../../../utils/utils";

const StatusUpdateModal = (props) => {
    const { t } = useTranslation();
    const { isOpen, onClose, isLoading, refreshGrid, details } = props;
    const { batchId, status, email } = details;

    const [loading, setLoading] = useState(false)

    const toast = useToast();

    const closeModal = () => {
        formik.resetForm();
        onClose();
        setLoading(false);
    }

    const statusList = [
        { label: "Processing", value: "settlement_created" },
        { label: "Processed", value: "settlement_completed" },
    ]


    const formik = useFormik({
        initialValues: {
            status: status,
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            status: Yup.object().required("Status is Manadatory")
        }),
        onSubmit: async (formValues) => {
            try {
                setLoading(true);
                const response = await callApiWithToken("POST", "/api/settlement/wpay/update", {
                    field: "status",
                    value: formValues?.status?.value,
                    batchId
                });
                if (response?.message) {
                    toast({
                        title: 'Success',
                        description: response?.message,
                        status: 'success',
                        duration: 5000,
                        isClosable: true,
                        position: 'top-right'
                    })
                }
                refreshGrid();
                closeModal();
            } catch (err) {
                setLoading(false);
                toast({
                    title: 'Error!',
                    description: err?.message || "Failed to fetch order details.",
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                    position: 'top-right'
                })
            }
        },

    });

    return (
        <chakra.form onSubmit={formik.handleSubmit} display="flex" flexGrow={1}>
            <Modal isOpen={isOpen} onClose={() => closeModal()} size={'xl'}>
                <ModalOverlay />
                <ModalContent
                    minWidth="600px"
                    height="fit-content">
                    <ModalHeader>{t("transactions:status_update")}- {email}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>

                        <Stack direction={"column"} justifyContent="space-between" flexGrow={1}>
                            <FormControl
                                isRequired
                                isInvalid={formik.touched.note && formik.errors.note}>
                                <FormLabel htmlFor="name">{t("transactions:select_status")}</FormLabel>
                                <Select
                                    id="email"
                                    isSearchable={true}
                                    options={statusList}
                                    value={formik.values.status}
                                    placeholder={status === "settlement_created" && "Processing"}
                                    onChange={(value) => {
                                        formik.setFieldValue("status", value);
                                    }}
                                />
                                <FormErrorMessage>{formik.errors.note}</FormErrorMessage>
                            </FormControl>
                        </Stack>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            onClick={() => closeModal()}
                            mr={3}
                        >
                            {t("sub-accounts:cancel")}
                        </Button>
                        <Button
                            isLoading={loading}
                            onClick={formik.handleSubmit}
                            colorScheme='blue'
                        >
                            {t("sub-accounts:save")}
                        </Button>
                    </ModalFooter>

                </ModalContent>
            </Modal>
        </chakra.form>
    )
}

export default StatusUpdateModal;