import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";

import { Drawer, DrawerBody, DrawerHeader, DrawerOverlay, DrawerContent, HStack } from "@chakra-ui/react";
import { DrawerCloseButton, Input, Button } from "@chakra-ui/react";
import { isEmpty, unset, split, uniqBy } from "lodash";

import { statusFilterConfigs } from "../../../utils/config";
import { callApiWithToken } from "../../../utils/utils";
import DateRangePicker from "../../Common/DateRangePicker";

const ComplianceOrderFilter = (props) => {
    const { t } = useTranslation();
    const { isOpen, onClose, countryOptions, paymentOptions, setFilters, setDateRange, dateRange, type } = props;
    const { formValues, setFormValues, inputData, setInputData, filters, handleOnReset, fiatCurrency } = props;
    const [cryptoCurrency, setCryptoCurrency] = useState([]);

    const getCryptoCurrency = async () => {
        const response = await callApiWithToken("GET", `/api/widget/configList?name=crypto&module=buy_crypto`);
        const data = response.data[0].config;
        const cryptoList = data.map((crypto) => {
            return { value: crypto.formattedSymbol, label: crypto.formattedSymbol };
        });
        setCryptoCurrency(uniqBy(cryptoList, (obj) => obj.value));
    };

    useEffect(() => {
        setFormValues(filters);
    }, [filters]);

    useEffect(() => {
        getCryptoCurrency();
        setFormValues(filters);
    }, []);

    const handleOnChanges = (key, value) => {
        let newValue;
        setInputData({
            ...inputData,
            [key]: value,
        });
        if (key === "status") {
            setInputData({
                ...inputData,
                status: value
            });
            newValue = value && value.map((values) => statusFilterConfigs[values.value][0]);
        }
        else if (key === "fiatOperator" || key === "usdAmount") newValue = value && value.value || value;
        else if (typeof (value) === "string") newValue = value && (split(value, ',')).map(str => str.trim());
        else if (Array.isArray(value)) newValue = value.map((data) => data.value || data);
        setFormValues({
            ...formValues,
            [key]: newValue,
        });
    };

    const handleOnClear = () => {
        onClose();
        handleOnReset();
    };

    const handleOnFilter = () => {
        Object.keys(formValues).map((key) => {
            if (isEmpty(formValues[key])) unset(formValues, key);
        });
        setFilters(formValues);
        onClose();
    };

    const onChangeDateRange = (start, end) => {
        setDateRange({ startDate: start, endDate: end });
        setFormValues({
            ...formValues,
            ["dateRange"]: { startDate: start, endDate: end },
        });
    };

    const onrampStatusOptions = [
        { label: ("Onramp Initiated"), value: "Onramp Initiated" },
        { label: ("Onramp Manual Review"), value: "Onramp Manual Review" },
        { label: ("Onramp Fiat Processing"), value: "Onramp Fiat Processing" },
        { label: ("Onramp Crypto Initiated"), value: "Onramp Crypto Initiated" },
        { label: ("Onramp Fiat Not Received"), value: "Onramp Fiat Not Received" },
        { label: ("Onramp Successful"), value: "Onramp Successful" },
        { label: ("Onramp Expired"), value: "Onramp Expired" },
    ];
    const offrampStatusOptions = [
        { label: ("Offramp Initiated"), value: "Offramp Initiated" },
        { label: ("Offramp Fiat Initiated"), value: "Offramp Fiat Initiated" },
        { label: ("Crypto Mismatch"), value: "Crypto Mismatch" },
        { label: ("Offramp Successful"), value: "Offramp Successful" },
        { label: ("Offramp Crypto Refunded"), value: "Offramp Crypto Refunded" },
        { label: ("Offramp Expired"), value: "Offramp Expired" },
        { label: ("Offramp Cancelled"), value: "Offramp Cancelled" }
    ];

    const otcPayinStatusOptions = [
        { label: ("Onramp Initiated"), value: "Onramp Initiated" },
        { label: ("Onramp Fiat Received"), value: "Onramp Fiat Received" },
        { label: ("Onramp Successful"), value: "Onramp Successful" },
        { label: ("Onramp Approved"), value: "Onramp Approved" },
        { label: ("Onramp Rejected"), value: "Onramp Rejected" },
    ];

    const otcPayoutStatusOptions = [
        { label: ("Offramp Initiated"), value: "Offramp Initiated" },
        { label: ("Offramp Crypto Recieved"), value: "Offramp Crypto Recieved" },
        { label: ("Offramp Successful"), value: "Offramp Successful" },
        { label: ("Offramp Approved"), value: "Offramp Approved" },
        { label: ("Offramp Rejected"), value: "Offramp Rejected" },
    ];

    const productBuyOptions = [
        { label: "Buy", value: "buy" },
        { label: "Payin", value: "payin" },
        { label: "OTC Payin", value: "otc_payin" }
    ];

    const productSellOptions = [
        { label: "Sell", value: "sell" },
        { label: "Payout", value: "payout" },
        { label: "OTC Payout", value: "otc_payout" }
    ];

    const fiatOperatorOptions = [
        { label: "Greater than", value: "gte" },
        { label: "Less than", value: "lte" },
        { label: "Equal", value: "eq" }
    ];

    let statusOptions = [];
    if (type === 'buy') statusOptions = onrampStatusOptions
    if (type === 'sell') statusOptions = offrampStatusOptions;
    if (type === 'otc_payin') statusOptions = otcPayinStatusOptions;
    if (type === 'otc_payout') statusOptions = otcPayoutStatusOptions;

    return (
        <>
            <Drawer onClose={onClose} isOpen={isOpen} size={"sm"}>
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader style={{ color: "#3182ce", fontFamily: "arial" }}>Filters</DrawerHeader>
                    <DrawerBody>
                        <div style={{ margin: "12px 0" }}>
                            <DateRangePicker
                                width={"100%"}
                                onChangeDateRange={onChangeDateRange}
                                start={dateRange.startDate}
                                end={dateRange.endDate}
                            />
                        </div>
                        <div style={{ margin: "12px 0" }}>
                            <Input
                                placeholder={t("transactions:order_id")}
                                style={{ border: "1px solid hsl(0, 0%, 80%)" }}
                                type="text"
                                value={formValues.orderId}
                                onChange={(e) => handleOnChanges("orderId", e.target.value)}
                            />
                        </div>
                        <div style={{ margin: "12px 0" }}>
                            <Input
                                placeholder={t("default:email_id")}
                                style={{ border: "1px solid hsl(0, 0%, 80%)" }}
                                type="text"
                                value={formValues.email}
                                onChange={(e) => handleOnChanges("email", e.target.value)}
                            />
                        </div>
                        <div style={{ margin: "12px 0" }}>
                            <Input
                                placeholder={t("sub-accounts:api_key")}
                                style={{ border: "1px solid hsl(0, 0%, 80%)" }}
                                type="text"
                                value={formValues.apiKey}
                                onChange={(e) => handleOnChanges("apiKey", e.target.value)}
                            />
                        </div>
                        <div style={{ margin: "12px 0" }}>
                            <Select
                                isMulti
                                isSearchable={true}
                                placeholder="Product"
                                options={type === "buy" ? productBuyOptions : productSellOptions}
                                value={inputData.type}
                                onChange={(e) => handleOnChanges("type", e)}
                            />
                        </div>
                        <div style={{ margin: "12px 0" }}>
                            <Select
                                isMulti
                                isSearchable={true}
                                placeholder={t("transactions:status")}
                                options={statusOptions}
                                value={inputData.status}
                                onChange={(e) => handleOnChanges("status", e)}
                            />
                        </div>
                        <div style={{ margin: "12px 0" }}>
                            <HStack direction="row" gap={"2"}>
                                <Select
                                    styles={{
                                        control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            width: '180px',
                                        }),
                                    }}
                                    placeholder="Select Operator"
                                    isSearchable={true}
                                    options={fiatOperatorOptions}
                                    value={inputData.fiatOperator}
                                    onChange={(e) => handleOnChanges("fiatOperator", e)}
                                />
                                <Input
                                    type="number"
                                    placeholder="Enter an amount" width="50%"
                                    style={{ border: "1px solid hsl(0, 0%, 80%)" }}
                                    value={formValues.usdAmount}
                                    onChange={(e) => handleOnChanges("usdAmount", e.target.value)} />
                            </HStack>
                        </div>
                        <div style={{ margin: "12px 0" }}>
                            <Select
                                isMulti
                                isSearchable={true}
                                placeholder="Country"
                                options={countryOptions}
                                value={inputData.userCountry}
                                onChange={(e) => handleOnChanges("userCountry", e)}
                            />
                        </div>
                        <div style={{ margin: "12px 0" }}>
                            <Select
                                isMulti
                                isSearchable={true}
                                placeholder={t("transactions:fiat_currency")}
                                options={fiatCurrency}
                                value={inputData.fiatTicker}
                                onChange={(e) => handleOnChanges("fiatTicker", e)}
                            />
                        </div>
                        <div style={{ margin: "12px 0" }}>
                            <Select
                                isMulti
                                isSearchable={true}
                                placeholder={t("transactions:crypto_currency")}
                                options={cryptoCurrency}
                                value={inputData.cryptoTicker}
                                onChange={(e) => handleOnChanges("cryptoTicker", e)}
                            />
                        </div>
                        <div style={{ margin: "12px 0" }}>
                            <Select
                                isMulti
                                isSearchable={true}
                                placeholder={t("transactions:payment_name")}
                                options={paymentOptions}
                                value={inputData.paymentName}
                                onChange={(e) => handleOnChanges("paymentName", e)}
                            />
                        </div>
                        <div style={{ margin: "12px 0" }}>
                            <Input
                                placeholder={t("transactions:account_number")}
                                style={{ border: "1px solid hsl(0, 0%, 80%)" }}
                                type="text"
                                value={formValues.paymentAccountNumber}
                                onChange={(e) => handleOnChanges("paymentAccountNumber", e.target.value)}
                            />
                        </div>
                        <div style={{ marginTop: "30px" }}>
                            <Button onClick={() => handleOnFilter()} variant={"outline"} colorScheme="blue">
                                {t("default:filter")}
                            </Button>
                            <Button
                                onClick={() => handleOnClear()}
                                variant={"outline"}
                                colorScheme="red"
                                style={{ marginLeft: "30px" }}
                            >
                                {t("default:clear")}
                            </Button>
                        </div>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    );
};

export default ComplianceOrderFilter;
