/* eslint-disable no-unused-expressions */
import React, { useState,useEffect } from 'react';

import {
    Button,
    FormLabel,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Input,
    Stack,
    Grid,
    FormControl,
    InputGroup,
    useClipboard,
    InputRightElement,
    useToast,
    Heading,
    Divider,
    Link
} from '@chakra-ui/react';
import { startCase,get,find, isEmpty } from "lodash";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { BiCopy } from "react-icons/bi";

import { callApiWithToken } from '../../../utils/utils';
import { getCxRoles } from "../../App/useToken";

const ViewOtcPayoutModal = (props) => {
    const { isOpen, onClose, selectedRow, refreshGrid } = props;

    const { metaData = {}, orderId, customer = {}, fiat = {}, crypto = {}, complianceDetails = {}, cryptoAmount,status,userId=''} = selectedRow;
    const { formattedName = '', customerName = '' } = customer;
    const { txnIdForCryptoReceived, sendersWalletAddress } = metaData;
    const { type = '' } = complianceDetails;
    const { currency: fiatTicker, conversionRate,orderAmount } = fiat;
    const { currency: cryptoTicker, partner, conversionAmount: usdtAmount, conversionRate: cryptoConversionRate, orderId: cryptoOrderId } = crypto;
    const walletAddress = sendersWalletAddress[0];
    const txnHash = txnIdForCryptoReceived[0];
    const sumsubDetails = metaData?.monitoringResults?.crypto || {};
    const { reviewAnswer = "" } = sumsubDetails

    const { onCopy, hasCopied } = useClipboard(walletAddress);

    const { onCopy: onCopyTxnHash, hasCopied: hasCopiedTxnHash } = useClipboard(txnHash);
    const [loading, setLoading] = useState(false)
    const [loadingReject, setLoadingReject] = useState(false);
    const [sumsubId,setSumsubId] = useState("");
    const [cxSumsubId,setCxSumsubId] = useState("");

    let reviewStatus = "Rejected"
    if(reviewAnswer === "GREEN"){
        reviewStatus = "Approved"
    }
    if(reviewAnswer === "Error"){
        reviewStatus = "Rejected"
    }

    const toast = useToast();
    const cxRoles = getCxRoles();

    useEffect(() => {
        getIndividualList();
        getOrganizationList();
    });

     const getIndividualList = async () => {
        const response = await callApiWithToken(
            "GET",
            `/api/orders/usersList`
        );
        const coList = response?.data;
       const userDetails = coList.find((data) => data.userId === userId);
       const id = get(find(userDetails?.partners, { vendor: "Sumsub" }), "id");
       setSumsubId(id);
    };

    const getOrganizationList = async () => {
        const response = await callApiWithToken(
            "GET",
            `/api/orders/orgList`
        );
        const coList = response?.data;
        const orgDetails = coList.find((data) => data.formattedName === formattedName);
        const id = get(find(orgDetails?.partners, { vendor: "Sumsub" }), "id");
        setSumsubId(id);
        if(!isEmpty(customerName)){
            const id = get(find(orgDetails?.partners, { vendor: "Sumsub" }), "id");
            setCxSumsubId(id);
        }
    };

    const onClickApprove = async () => {
        try {
            setLoading(true);
            await callApiWithToken("POST", "/api/orders/otc/buy/approve", {
                orderId,
                status:"fund_processing"
            });

            toast({
                title: "Approved Successfully",
                status: "success",
                isClosable: true,
                position: "top-right",
                duration: 5000,
            });
            setLoading(false);
            await refreshGrid();
            onClose();
        } catch (err) {
            toast({
                title: `Updation Failed :  ${err.message}`,
                status: "failed",
                isClosable: true,
                position: "top-right",
                duration: 5000,
            });
            setLoading(false);
            onClose();
        }
    };

    const onClickReject = async () => {
        try {
            setLoadingReject(true);
            await callApiWithToken("POST", "/api/orders/otc/buy/approve", {
                orderId,
                status:"fund_failed"
            });

            toast({
                title: "Rejected Successfully",
                status: "success",
                isClosable: true,
                position: "top-right",
                duration: 5000,
            });
            setLoadingReject(false);
            refreshGrid();
            onClose();
        } catch (err) {
            toast({
                title: `Updation Failed :  ${err.message}`,
                status: "failed",
                isClosable: true,
                position: "top-right",
                duration: 5000,
            });
            setLoadingReject(false);
            onClose();
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'5xl'}>
            <ModalOverlay />

            <ModalContent>
                <ModalHeader>View Details: {orderId}</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    <Stack direction={"column"} justifyContent="space-between" flexGrow={1}>
                        <Grid templateColumns='repeat(3, 1fr)' gap={3}>
                        <FormControl >
                                <FormLabel htmlFor="name">Type</FormLabel>
                                <InputGroup >
                                    <Input type='text' value={startCase(type)} isDisabled background='gray.200' color={'black'} />
                                </InputGroup>
                            </FormControl>
                            <FormControl >
                                <FormLabel htmlFor="name">Name</FormLabel>
                                <InputGroup >
                                    <Input type='text' value={startCase(formattedName)} isDisabled background='gray.200' color={'black'} />
                                    <Link  href={`https://cockpit.sumsub.com/checkus#/applicant/${sumsubId}/basicInfo?clientId=transfi.com_45136`}>
                                    <InputRightElement cursor='pointer' color={'blue'} children={<AiOutlineInfoCircle fontSize={"20px"} />} />
                                    </Link>
                                </InputGroup>
                            </FormControl>
                            {type === "business-customer" ? <>
                                <FormControl >
                                    <FormLabel htmlFor="name">Customer Name</FormLabel>
                                    <InputGroup >
                                        <Input type='text' value={startCase(customerName)} isDisabled background='gray.200' color={'black'} />
                                        <Link  href={`https://cockpit.sumsub.com/checkus#/applicant/${cxSumsubId}/basicInfo?clientId=transfi.com_45136`}>
                                    <InputRightElement cursor='pointer' color={'blue'} children={<AiOutlineInfoCircle fontSize={"20px"} />} />
                                    </Link>
                                    </InputGroup>
                                </FormControl>
                            </> : <></>}
                            </Grid>
                            <Divider opacity={1} />
                            {/* <Heading size={"sm"} marginTop="6" >Fiat Details:</Heading> */}
                            <Grid templateColumns='repeat(4, 1fr)' gap={3}>
                            <FormControl >
                                <FormLabel htmlFor="name">Crypto Currency</FormLabel>
                                <InputGroup >
                                    <Input type='text' value={cryptoTicker} isDisabled background='gray.200' color={'black'} />
                                </InputGroup>
                            </FormControl><FormControl >
                                <FormLabel htmlFor="name">Crypto Amount</FormLabel>
                                <InputGroup >
                                    <Input type='text' value={cryptoAmount} isDisabled background='gray.200' color={'black'} />
                                </InputGroup>
                            </FormControl><FormControl >
                                <FormLabel htmlFor="name">USDC/USDT Amount</FormLabel>
                                <InputGroup >
                                    <Input type='text' value={usdtAmount} isDisabled background='gray.200' color={'black'} />
                                </InputGroup>
                            </FormControl><FormControl >
                                <FormLabel htmlFor="name">USDT Conversion Rate</FormLabel>
                                <InputGroup >
                                    <Input type='text' value={cryptoConversionRate} isDisabled background='gray.200' color={'black'} />
                                </InputGroup>
                            </FormControl>
                            <FormControl >
                                <FormLabel htmlFor="name">Crypto Partner</FormLabel>
                                <InputGroup >
                                    <Input type='text' value={startCase(partner)} isDisabled background='gray.200' color={'black'} />
                                </InputGroup>
                            </FormControl>
                            <FormControl >
                                <FormLabel htmlFor="name">Crypto Partner Order ID</FormLabel>
                                <InputGroup >
                                    <Input type='text' value={cryptoOrderId} isDisabled background='gray.200' color={'black'} />
                                </InputGroup>
                            </FormControl>
                            <FormControl >
                                <FormLabel htmlFor="name">Sender's Wallet Address</FormLabel>
                                <InputGroup >
                                    <Input type='text' value={walletAddress} isDisabled background='gray.200' color={'black'} />
                                    <InputRightElement cursor='pointer' color={hasCopied ? 'green' : 'blue'} onClick={onCopy} children={<BiCopy fontSize={"20px"} />} />
                                </InputGroup>
                            </FormControl>
                            <FormControl >
                                <FormLabel htmlFor="name">Txn Hash</FormLabel>
                                <InputGroup >
                                    <Input type='text' value={txnHash} isDisabled background='gray.200' color={'black'} />
                                    <InputRightElement cursor='pointer' color={hasCopiedTxnHash ? 'green' : 'blue'} onClick={onCopyTxnHash} children={<BiCopy fontSize={"20px"} />} />
                                </InputGroup>
                            </FormControl>
                            </Grid>
                            <Divider opacity={1} />
                            {/* <Heading size={"sm"} marginTop="6" >Crypto Details:</Heading> */}
                            <Grid templateColumns='repeat(4, 1fr)' gap={3}>
                            <FormControl >
                                <FormLabel htmlFor="name">Fiat Currency</FormLabel>
                                <InputGroup >
                                    <Input type='text' value={fiatTicker} isDisabled background='gray.200' color={'black'} />
                                </InputGroup>
                            </FormControl>
                            <FormControl >
                                <FormLabel htmlFor="name">Fiat Amount</FormLabel>
                                <InputGroup >
                                    <Input type='text' value={orderAmount} isDisabled background='gray.200' color={'black'} />
                                </InputGroup>
                            </FormControl>
                            <FormControl >
                                <FormLabel htmlFor="name">Conversion Rate (USD)</FormLabel>
                                <InputGroup >
                                    <Input type='text' value={conversionRate} isDisabled background='gray.200' color={'black'} />
                                </InputGroup>
                            </FormControl>
                            <FormControl >
                                <FormLabel htmlFor="name">Crypto Monitoring Status</FormLabel>
                                <InputGroup >
                                    <Input type='text' value={reviewStatus} isDisabled background='gray.200' color={'black'} />
                                    {
                                        type === "bussiness_customer" ? <>
                                         <Link  href={`https://cockpit.sumsub.com/checkus#/applicant/${cxSumsubId}/applicantActions?clientId=transfi.com_45136`}>
                                    <InputRightElement cursor='pointer' color={'blue'} children={<AiOutlineInfoCircle fontSize={"20px"} />} />
                                    </Link>
                                        </> : <>
                                        <Link  href={`https://cockpit.sumsub.com/checkus#/applicant/${sumsubId}/applicantActions?clientId=transfi.com_45136`}>
                                    <InputRightElement cursor='pointer' color={'blue'} children={<AiOutlineInfoCircle fontSize={"20px"} />} />
                                    </Link>
                                        </>
                                    }
                                </InputGroup>
                            </FormControl>
                        </Grid>
                    </Stack>
                </ModalBody>
                <ModalFooter>
                {status === "asset_settled" && cxRoles.includes("treasury_admin") && 
                    <>
                    <Button
                        isLoading={loading}
                        onClick={onClickApprove}
                        colorScheme='blue' mr={3}
                    >
                        Approve
                    </Button>
                    <Button
                    isLoading={loadingReject}
                        onClick={onClickReject}
                        colorScheme='red' mr={3}
                    >
                        Rejected
                    </Button>
                    </>
                    }
                    { status !== "asset_settled" && cxRoles.includes("treasury_admin") && <>
                    <Button
                     onClick={onClose}
                    >Close</Button>
                    </> }
                    { !cxRoles.includes("treasury_admin") && <>
                    <Button
                     onClick={onClose}
                    >Close</Button>
                    </>}
                </ModalFooter>

            </ModalContent>
        </Modal>

    )
}

export default ViewOtcPayoutModal;