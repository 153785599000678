import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Box, Center, HStack, Button, Stack, useToast } from "@chakra-ui/react";
import { Divider } from "antd";
import ace from 'brace';
import { JsonEditor } from "jsoneditor-react";

import Loader from "../../../utils/Loader";
import { callApiWithToken } from "../../../utils/utils";

import 'brace/mode/json';
import "brace/theme/github";
import 'jsoneditor-react/es/editor.min.css';

export default function AddConfigurations() {
    const [editData, setEditData] = useState({
        "name": "<NAME>",
        "module": "<MODULE>",
        "version": "<VERSION>",
        "config": []
    });
    const [isValidJson, setIsValidJson] = useState(true);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const toast = useToast();

    const onAddConfig = async () => {
        try {
            if (editData?.name === "<NAME>" || editData?.module === "<MODULE>" || editData?.version === "<VERSION>") {
                throw new Error("Please update the name, module and version");
            }
            setLoading(true);
            let body = {
                record: editData
            }

            const response = await callApiWithToken("POST", "/api/admin/addConfigurations", body);
            const { success } = response;
            if (success) {
                toast({
                    title: "Success",
                    description: "Configurations Added successfully",
                    status: "success",
                    duration: 2000,
                    isClosable: true,
                });
                navigate(`/admin/configs`);
            } else {
                throw new Error("Something went wrong");
            }
        } catch (error) {
            toast({
                title: "Error",
                description: error?.message,
                status: "error",
                duration: 4000,
                isClosable: true,
            });
        }
    };

    return (
        !loading ?
            <Center py={5} bg="white.100">
                <Stack spacing={2} w="90%">
                    <Divider />
                    <Box bg="white" p={4}>
                        <JsonEditor
                            value={editData}
                            onChange={(e) => setEditData(e)}
                            ace={ace}
                            mode="code"
                            htmlElementProps={{ style: { height: '70vh' } }}
                            onError={(e) => console.log('error', e)}
                            theme={"ace/theme/github"}
                        />
                    </Box>
                    <HStack spacing={2} w="100%" justifyContent="flex-end" paddingRight={'0.7rem'}>
                        <Button
                            variant="outline"
                            colorScheme="blue"
                            onClick={() => {
                                navigate(`/admin/configs`);
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="solid"
                            colorScheme="blue"
                            onClick={() => {
                                onAddConfig();
                            }}
                            disabled={!isValidJson}
                        >
                            Add
                        </Button>
                    </HStack>
                </Stack>
            </Center >
            : <Loader />
    );
}
