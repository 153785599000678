/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';
import { useTranslation } from "react-i18next";

import {
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Input,
    useToast,
    Select
} from '@chakra-ui/react';

import { callApiWithToken } from '../../utils/utils';
import { validateEmail } from '../../utils/validations';

export default function InviteModal(props) {
    const { t } = useTranslation();
    const { isOpen, onClose, isLoading, refreshGrid } = props;

    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [emailMessage, setEmailMessage] = useState('');
    const [role, setRole] = useState("");
    const [name, setName] = useState("");

    const toast = useToast();

    const onClickHandler = async () => {
        if (!validateEmail(email)) {
            setEmailMessage('Please enter valid email')
            return;
        }

        try {
            setLoading(true);
            const response = await callApiWithToken(
                "POST",
                '/api/customers/invite',
                { email, role, name }
            );

            if (response?.status === false) {
                setEmailMessage(response?.message);
                return;
            }

            toast({
                title: 'Invitation Sent!',
                status: 'success',
                isClosable: true,
                position: 'bottom',
                duration: 5000,
            });
            setLoading(false);
            await refreshGrid();
            onClose();
        }
        catch (err) {
            toast({
                title: `Invitation Failed :  ${err.message}`,
                status: 'success',
                isClosable: true,
                position: 'bottom',
                duration: 5000,
            });
            setLoading(false);
            onClose();
        }

    }

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />

            <ModalContent>
                <ModalHeader>{t("users:invite_members")}</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    <FormControl mt={4} >
                        <FormLabel>{t("users:enter_name")}</FormLabel>
                        <Input
                            placeholder={t("users:enter_name")}
                            size="md"
                            onChange={(event) => setName(event.target.value)}
                        />
                    </FormControl>

                    <FormControl mt={4} isInvalid={emailMessage}>
                        <FormLabel>{t("users:enter_email")}</FormLabel>
                        <Input
                            placeholder={t("users:enter_email")}
                            size="md"
                            onChange={(event) => setEmail(event.target.value)}
                        />
                        <FormErrorMessage>{emailMessage}</FormErrorMessage>
                    </FormControl>

                    <FormControl mt={4}>
                        <FormLabel>{t("users:select_role")}</FormLabel>
                        <Select
                            placeholder={t("users:select_role")}
                            onChange={(e) => setRole(e.target.value)}
                        >
                            <option value='developer'>Developer</option>
                            <option value='admin'>Admin</option>
                            <option value='operations'>Operations</option>
                        </Select>
                    </FormControl>
                </ModalBody>
                <ModalFooter>
                    <Button
                        isLoading={isLoading}
                        isDisabled={!email}
                        onClick={onClickHandler}
                        colorScheme='blue' mr={3}
                    >
                        {t("sub-accounts:add")}
                    </Button>
                    <Button
                        onClick={onClose}
                    >
                        {t("sub-accounts:cancel")}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}