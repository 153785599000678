import React, { useState } from "react";

import moment from "moment-timezone";

import { saveDataToSession, getDataFromSession } from "../utils/session-storing-utils";

export const TimezoneContext = React.createContext();

export function TimezoneProvider({ children }) {
    const [defaultTimezone, setDefaultTimezone] = useState(sessionStorage.getItem('defaultTimezone'));
    const [timezone, setTimezone] = useState(defaultTimezone || "America/Detroit");

    const onTimezoneChange = (val) => {
        saveDataToSession("connectionData", "timezone", val);
        setTimezone(val);
    }

    const store = { timezone, onTimezoneChange, defaultTimezone, setDefaultTimezone };
    return (
        <TimezoneContext.Provider value={store}>{children}</TimezoneContext.Provider>
    );
}
