import React, { useState, useContext, useRef, useEffect } from "react";
import Loader from "react-js-loader";
import { useParams, Link } from "react-router-dom";

import { Badge, useDisclosure, Button, HStack } from "@chakra-ui/react";
import { Box } from "@chakra-ui/react";
import { startCase, uniqBy, isEmpty, get, round } from "lodash";
import moment from "moment";
import { BiRefresh, BiReset } from "react-icons/bi";
import { BiEdit, BiShow } from "react-icons/bi";
import { BsCartPlus } from "react-icons/bs";
import { FiFilter } from "react-icons/fi";

import { NavBarContext } from "../../context/NavBarContext";
import { TimezoneContext } from "../../context/TimezoneContext";
import SplitView from "../../utils/SplitView";
import TimezoneFormatter from "../../utils/TimezoneFormatter";
import { statusMap, colorMap } from "../../utils/config";
import { getCountryData } from "../../utils/getCountries";
import { callApiWithToken } from "../../utils/utils";
import { getCxRoles, getOrgRoles, getEnv } from "../App/useToken";
import OrderSpliViewContainer from "./OrderSpliViewContainer";
import EditOtcPayoutModal from "./helper/EditOtcPayoutModal";
import OrderFilter from "./helper/OrderFilter";
import OtcPayoutOrdersModal from "./helper/OtcPayoutOrdersModal";
import ViewOtcPayoutModal from "./helper/ViewOtcPayoutModal";

export default function OtcPayoutOrders() {
    const { orderId } = useParams();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const { isOpen, onOpen, onClose } = useDisclosure();
    const {
        isOpen: isOpenViewDetailsModal,
        onOpen: onOpenViewDetailsModal,
        onClose: onCloseViewDetailsModal,
    } = useDisclosure({ defaultIsOpen: false });
    const {
        isOpen: isOpenEditModal,
        onOpen: onOpenEditModal,
        onClose: onCloseEditModal,
    } = useDisclosure({ defaultIsOpen: false });
    const { isOpen: isOpenOtcOrders, onOpen: onOpenOtcOrders, onClose: onCloseOtcOrders } = useDisclosure({ defaultIsOpen: false });
    const [countryOptions, setCountryOptions] = useState([]);
    const [fiatCurrency, setFiatCurrency] = useState([]);
    const [paymentOptions, setPaymentOptions] = useState([]);
    const [formValues, setFormValues] = useState({});
    const [inputData, setInputData] = useState({});
    const defaultDate = { startDate: moment().subtract(2, "months"), endDate: moment().format("YYYY-MM-DD 23:59:59") };
    const [filters, setFilters] = useState({ dateRange: defaultDate });
    const [dateRange, setDateRange] = useState(defaultDate);
    const [viewDetails, setViewDetails] = useState("");
    const [details, setDetails] = useState("");

    const env = getEnv();
    const cxRoles = getCxRoles();

    const { selectedOrg } = useContext(NavBarContext);
    const { timezone } = useContext(TimezoneContext);
    const gridRef = useRef();

    useEffect(() => {
        getCountryList();
        getPaymentList();
    }, []);

    useEffect(() => {
        refreshGrid();
    }, [timezone]);

    const refreshGrid = () => {
        gridRef.current && gridRef.current.refreshGrid();
    };

    const getCountryList = async () => {
        const { countryList, fiatList } = await getCountryData();
        setCountryOptions(countryList);
        setFiatCurrency(fiatList);
    };

    const getPaymentList = async () => {
        const response = await callApiWithToken("GET", `/api/orders/getPaymentMethods`);
        const data = response.data;
        let list = data.map((country) => {
            return { value: country.name, label: country.name };
        });
        setPaymentOptions(uniqBy(list, (obj) => obj.value));
    };

    const numberFormatter = (value) => {
        const number = round(value, 2).toFixed(2)
        const result = parseFloat(number).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        })
        return result === "NaN" ? "0.00" : result;
    };

    const handleOnClickView = (value) => {
        onOpenViewDetailsModal();
        setViewDetails(value);
    };

    const handleOnClickEdit = (value) => {
        onOpenEditModal();
        setDetails(value);
    };

    const columns = [
        {
            Header: "Order Id",
            accessor: "orderId",
            width: "200",
            Cell: ({ value }) => {
                return (
                    <Link style={{ color: "#4ACDED", fontWeight: "bold" }} to={`/otc-payout-orders/${value}`}>
                        {value}
                    </Link>
                );
            },
            zIndex: 1,
            position: 'sticky',
            textAlign: 'center',
        },
        {
            Header: "Customer Name",
            accessor: "customer.formattedName",
            width: "200",
            Cell: ({ value }) => {
                return value || "N/A"
            }
        },
        {
            Header: "Crypto Recieved From",
            accessor: "customer.customerName",
            width: "200",
            Cell: ({ value }) => {
                return value || "N/A"
            }
        },
        {
            Header: "Crypto Wallet From Where Received",
            accessor: "metaData.sendersWalletAddress[0]",
            width: "200",
            Cell: ({ value }) => {
                return value || "N/A"
            }
        },
        {
            Header: "Transaction hash",
            accessor: "metaData.txnIdForCryptoReceived[0]",
            width: "200",
            Cell: ({ value }) => {
                return value || "N/A"
            }
        },
        {
            Header: "Created By",
            accessor: "createdBy",
            width: "230",
            Cell: ({ value }) => {
                return value || "N/A"
            }
        },
        {
            Header: "Status",
            accessor: "status",
            width: "130",
            Cell: ({ row }) => {
                const { status, type } = row?.original;
                const newStatus = statusMap[type][status] || status;
                const color = colorMap[type][status] || "blue";
                return <Badge colorScheme={color}> {newStatus} </Badge>;
            },
        },
        {
            Header: "Action",
            accessor: "",
            width: 100,
            Cell: ({ row }) => {
                return (
                    <HStack>
                        <BiShow
                            style={{
                                fontSize: "22px",
                                color: "#2b6cb0",
                                cursor: "pointer",
                            }}
                            onClick={() => handleOnClickView(row?.original)}
                        ></BiShow>
                        {
                            cxRoles.includes("treasury_user") && row?.original?.status === 'fund_processing' ? <BiEdit
                                style={{
                                    fontSize: "22px",
                                    color: "#2b6cb0",
                                    cursor: "pointer",
                                }}
                                onClick={() => handleOnClickEdit(row?.original)}
                            ></BiEdit> : <></>}
                    </HStack>
                )
            }
        },
        {
            Header: "Date Order Initiated",
            width: "120",
            Cell: ({ row }) => {
                return <TimezoneFormatter date={row?.original.createdAt} format={"MM/DD/YYYY"} />
            },
            color: "info",
        },
        {
            Header: "Time Order Initiated",
            width: "120",
            Cell: ({ row }) => {
                return <TimezoneFormatter date={row?.original.createdAt} format={'LT'} />
            },
            color: "info",
        },
        {
            Header: "Date Completed",
            width: "100",
            Cell: ({ row }) => {
                if (!isEmpty(row?.original?.timestamps)) {
                    if (row?.original?.status === "asset_settled") {
                        return <TimezoneFormatter date={row?.original?.timestamps?.assetSettledAt} format={"MM/DD/YYYY"} />
                    }
                    else {
                        return "N/A"
                    }
                } else {
                    return "N/A"
                }
            },
            color: "info",
        },
        {
            Header: "Time Completed",
            width: "100",
            Cell: ({ row }) => {
                if (!isEmpty(row?.original?.timestamps)) {
                    if (row?.original?.status === "asset_settled") {
                        return <TimezoneFormatter date={row?.original?.timestamps?.assetSettledAt} format={"LT"} />
                    }
                    else {
                        return "N/A"
                    }
                }
                else {
                    return 'N/A'
                }
            },
            color: "info",
        },
        {
            Header: "Crypto Payment Partner",
            width: "150",
            color: "warning",
            accessor: "crypto.partner",
            Cell: ({ value }) => startCase(value) || "N/A"
        },
        {
            Header: "Crypto Partner Order ID",
            accessor: "crypto.orderId",
            width: "150",
            color: "warning",
            Cell: ({ value }) => value || "-",
        },
        {
            Header: "Crypto Currency Currency",
            width: "120",
            color: "warning",
            Cell: ({ row }) => {
                if (!isEmpty(row?.original?.crypto?.currency)) {
                    return row?.original?.crypto?.currency;
                } else {
                    return row?.original?.cryptoTicker;
                }
            },
        },
        {
            Header: "Crypto Amount Received By TransFi",
            width: "120",
            color: "warning",
            Cell: ({ row }) => {
                if (!isEmpty(row?.original?.crypto?.orderAmount)) {
                    return numberFormatter(row?.original?.crypto?.orderAmount);
                } else {
                    return numberFormatter(row?.original?.cryptoAmount);
                }
            },
        },
        {
            Header: "Crypto Conversion Rate USDC / Crypto",
            width: "120",
            color: "warning",
            Cell: ({ row }) => {
                if (row?.original?.crypto?.conversionRate) {
                    return numberFormatter(row?.original?.crypto?.conversionRate);
                } else {
                    return row?.original?.cryptoUnitPrice ? numberFormatter(row?.original?.cryptoUnitPrice) : "0.00";
                }
            },
        },
        {
            Header: "USDC/USDT Purchased (Crypto Account)",
            width: "120",
            color: "warning",
            accessor: "crypto.conversionAmount",
            Cell: ({ value }) => numberFormatter(value) || "0.00"
        },
        {
            Header: "Fiat Payment Partner",
            width: "120",
            color: "info",
            Cell: ({ row }) => {
                if (!isEmpty(row?.original?.fiat?.partner)) {
                    return row?.original?.fiat?.partner
                } else {
                    return row?.original?.paymentGateway
                }
            }
        },
        {
            Header: "Fiat Payment Partner Order ID",
            width: "120",
            color: "info",
            accessor: "fiat.orderId",
            Cell: ({ value }) => value || "-"
        },
        {
            Header: "Fiat Payment Method",
            width: "120",
            color: "info",
            Cell: ({ row }) => {
                if (!isEmpty(row?.original?.fiat?.paymentName)) {
                    return row?.original?.fiat?.paymentName;
                } else {

                    return row?.original?.paymentName;
                }
            }
        },
        {
            Header: "Fiat Currency",
            width: "120",
            color: "info",
            Cell: ({ row }) => {
                if (!isEmpty(row?.original?.fiat?.currency)) {
                    return row?.original?.fiat?.currency;
                } else {

                    return row?.original?.fiatTicker;
                }
            }
        },
        {
            Header: "Conversion Rate to USD",
            width: "120",
            color: "info",
            Cell: ({ row }) => {
                if (isEmpty(row?.original?.fiat?.conversionRate)) {
                    return "0.00";
                } else {
                    return numberFormatter(1 / row?.original?.fiat?.conversionRate);
                }
            },
        },
        {
            Header: "Gross Txn Amount On Fiat",
            width: "120",
            color: "info",
            Cell: ({ row }) => {
                if (!isEmpty(row?.original?.fiat?.orderAmount)) {
                    return numberFormatter(row?.original?.fiat?.orderAmount)
                } else {
                    return numberFormatter(row?.original?.fiatAmount)
                }
            }
        },
        {
            Header: "Processing Fees %",
            width: "120",
            color: "info",
            Cell: ({ row }) => {
                const processingRate = get(row?.original, "fiat.processingRate", 0)
                const tfFee = Number(get(row.original, "fiat.tfFee", 0))
                const cxFee = Number(get(row.original, "fiat.cxFee", 0))
                const fiatAmount = row?.original?.fiat?.orderAmount ? Number(row?.original?.fiat?.orderAmount || 0) : row?.original?.fiatAmount;
                if (processingRate > 0) {
                    return numberFormatter(processingRate) + "%";
                }
                if (tfFee >= 0 && cxFee >= 0 && isEmpty(processingRate)) {
                    const totalFees = tfFee + cxFee;
                    return numberFormatter((totalFees / fiatAmount) * 100) + "%";
                }
                else {
                    return "N/A"
                }

            },
        },
        {
            Header: "Processing Fees Amt.",
            width: "120",
            color: "info",
            Cell: ({ row }) => {
                if (isEmpty(row?.original?.fiat?.processingFee)) {
                    const tfFee = Number(get(row.original, "fiat.tfFee", 0))
                    const cxFee = Number(get(row.original, "fiat.cxFee", 0))
                    const processingFee = (tfFee + cxFee).toFixed(2)
                    return numberFormatter(processingFee)
                } else {
                    const processingFee = Number(row?.original?.fiat?.processingFee).toFixed(2)
                    return numberFormatter(processingFee)
                }
            },
        },
        {
            Header: "Fiat To Be Delivered To User",
            width: "120",
            color: "info",
            Cell: ({ row }) => {
                if (isEmpty(row?.original?.fiat?.processingFee)) {
                    const tfFee = Number(get(row.original, "fiat.tfFee", 0))
                    const cxFee = Number(get(row.original, "fiat.cxFee", 0))
                    const fiatAmount = row?.original?.fiat?.orderAmount ? Number(row?.original?.fiat?.orderAmount || 0) : row?.original?.fiatAmount;
                    return numberFormatter(fiatAmount - (tfFee + cxFee));
                } else {
                    const fiatAmount = row?.original?.fiat?.orderAmount ? Number(row?.original?.fiat?.orderAmount || 0) : row?.original?.fiatAmount;
                    const processFees = Number(get(row.original, "fiat.processingFee", 0))
                    return numberFormatter(fiatAmount - processFees);
                }
            }
        },
        {
            Header: "Payment Partner Fees",
            width: "120",
            color: "info",
            Cell: ({ row }) => {
                if (row?.original?.fiat?.partnerFee) {
                    if (row?.original?.fiat?.partnerFee === 0 || row?.original?.fiat?.partnerFee === "NaN") {
                        return "0.00";
                    } else {
                        return numberFormatter(row?.original?.fiat?.partnerFee);
                    }
                } else {
                    return "0.00";
                }

            }
        },
        {
            Header: "Amount Deducted From Sub Account",
            width: "120",
            color: "info",
            Cell: ({ row }) => {
                const fiatAmount = row?.original?.fiat?.orderAmount ? Number(row?.original?.fiat?.orderAmount || 0) : row?.original?.fiatAmount;
                const processFees = Number(get(row.original, "fiat.processingFee", 0))
                const buyRate = Number(get(row.original, "fiat.partnerFee", 0))
                const fiatDelivered = Number(fiatAmount) - Number(processFees);
                const amtDeducted = Number(fiatDelivered) + Number(buyRate);
                return numberFormatter(amtDeducted);
            },
        },
        {
            Header: "Fees Charged For Conversion By Crypto Exchange",
            width: "120",
            color: "info",
            accessor: "fiat.feeCharged",
            Cell: ({ value }) => numberFormatter(value) || "0.00"
        },
        {
            Header: "Sub-Account Delivery Date",
            width: "120",
            color: "info",
            Cell: ({ row }) => {
                if (!isEmpty(row?.original?.fiat?.settlementTime)) {
                    return <TimezoneFormatter date={row?.original?.fiat?.settlementTime} format={"MM/DD/YYYY"} />

                } else {
                    return "N/A"
                }
            }
        },
        {
            Header: "Gross Profit On Transaction",
            width: "120",
            color: "info",
            Cell: ({ row }) => {
                const fiatAmount = row?.original?.fiat?.orderAmount ? Number(row?.original?.fiat?.orderAmount || 0) : Number(row?.original?.fiatAmount || 0);
                const buyRate = Number(get(row.original, "fiat.partnerFee", 0));
                const processFees = Number(get(row.original, "fiat.processingFee", 0))
                const feeCharged = Number(get(row.original, "fiat.feeCharged", 0));
                const fiatDelivered = Number(fiatAmount) - Number(processFees);
                const subAccount = Number(fiatDelivered) + Number(buyRate);
                const profit = Number(fiatAmount) - Number(subAccount) - Number(feeCharged)
                return numberFormatter(profit);
            }
        },
        {
            Header: "Gross Profit On Transaction (USD)",
            width: "120",
            color: "info",
            Cell: ({ row }) => {
                const fiatAmount = row?.original?.fiat?.orderAmount ? Number(row?.original?.fiat?.orderAmount || 0) : Number(row?.original?.fiatAmount || 0);
                const buyRate = Number(get(row.original, "fiat.partnerFee", 0));
                const processFees = Number(get(row.original, "fiat.processingFee", 0))
                const feeCharged = Number(get(row.original, "fiat.feeCharged", 0));
                const fiatDelivered = Number(fiatAmount) - Number(processFees);
                const subAccount = Number(fiatDelivered) + Number(buyRate);
                const conversionRate = Number(get(row.original, "fiat.conversionRate", 0))
                const profit = Number(fiatAmount) - Number(subAccount) - Number(feeCharged)
                const usdProfit = Number(profit) * Number(conversionRate)
                return numberFormatter(usdProfit);
            }
        },
    ];

    const condensedColumns = {
        Header: "Order Id",
        accessor: "orderId",
        Cell: ({ row, value }) => {
            const { status, userId, type } = row || {};
            const newStatus = statusMap[type][status] || status;
            const color = colorMap[type][status] || "blue";
            return (
                <>
                    <div>
                        <span color="#4ACDED">{value}</span>
                        <Badge style={{ marginLeft: "20px" }} colorScheme={color}>
                            {" "}
                            {startCase(newStatus)}{" "}
                        </Badge>
                    </div>
                    <div style={{ fontSize: "13px" }}>{userId}</div>
                </>
            );
        },
    };

    const handleOnReset = () => {
        setDateRange(defaultDate);
        setFilters({ dateRange: defaultDate });
        setFormValues({});
        setInputData({});
    };

    return !loading ? (
        <Box style={{ background: "white" }} heading="Orders" whiteSpace="nowrap">
            {orderId ? (
                <></>
            ) : (
                <div style={{ marginTop: "2%" }}>
                    <div style={{ float: "right" }}>
                        {env !== "production" && <Button
                            leftIcon={<BsCartPlus style={{ fontSize: "20px" }} />}
                            onClick={onOpenOtcOrders}
                            variant={"outline"}
                            colorScheme="blue"
                            style={{ margin: "0 8px" }}
                            size="sm"
                        >
                            Create OTC Orders
                        </Button>}
                        <Button
                            leftIcon={<BiReset style={{ fontSize: "20px" }} />}
                            onClick={() => handleOnReset()}
                            variant={"outline"}
                            colorScheme="blue"
                            style={{ margin: "0 8px" }}
                            size="sm"
                        >
                            Reset
                        </Button>
                        <Button
                            leftIcon={<BiRefresh style={{ fontSize: "20px" }} />}
                            onClick={() => refreshGrid()}
                            variant={"outline"}
                            colorScheme="blue"
                            style={{ margin: "0 0px" }}
                            size="sm"
                        >
                            Refresh
                        </Button>
                        <Button
                            leftIcon={<FiFilter style={{ fontSize: "20px" }} />}
                            onClick={onOpen}
                            variant={"outline"}
                            colorScheme="blue"
                            style={{ margin: "0 8px" }}
                            size="sm"
                        >
                            Filter
                        </Button>
                    </div>
                    <br />
                </div>
            )}
            <SplitView
                ref={gridRef}
                columns={columns}
                condensed={orderId}
                condensedColumns={condensedColumns}
                dataUrl={"/api/orders/list"}
                condensedUrl={"/otc-payin-orders"}
                filters={{ impersonatedOrg: selectedOrg, filters, type: "otc_payout" }}
                selectRow={false}
            // onChangeSelectedRow={selectionCallback}
            // initialState={{ selectedRowIds: selectedRows }}
            >
                <OrderSpliViewContainer orderId={orderId} />
            </SplitView>

            <OrderFilter
                onClose={onClose}
                isOpen={isOpen}
                countryOptions={countryOptions}
                fiatCurrency={fiatCurrency}
                paymentOptions={paymentOptions}
                formValues={formValues}
                setFormValues={setFormValues}
                filters={filters}
                setFilters={setFilters}
                dateRange={dateRange}
                setDateRange={setDateRange}
                inputData={inputData}
                setInputData={setInputData}
                handleOnReset={handleOnReset}
                type={"otc_payout"}
            ></OrderFilter>
            <OtcPayoutOrdersModal
                isLoading={loading}
                isOpen={isOpenOtcOrders}
                onOpen={onOpenOtcOrders}
                onClose={onCloseOtcOrders}
                refreshGrid={refreshGrid}
            />
            {viewDetails && <ViewOtcPayoutModal
                isOpen={isOpenViewDetailsModal}
                onOpen={onOpenViewDetailsModal}
                onClose={onCloseViewDetailsModal}
                selectedRow={viewDetails}
                refreshGrid={refreshGrid}
            />}
            {cxRoles.includes("treasury_user") && details && <EditOtcPayoutModal
                isOpen={isOpenEditModal}
                onOpen={onOpenEditModal}
                onClose={onCloseEditModal}
                selectedRow={details}
                refreshGrid={refreshGrid}
            />}
        </Box>
    ) : (
        <Loader type="spinner-cub" bgColor={"#FFFFFF"} title={"Loading..."} color={"cyan.400"} size={100} />
    );
}