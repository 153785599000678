export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
};

export const validateIsNullOrUndefined = (value) => {
  return value === null || typeof value === "undefined";
};

export const validateIsNegativeNumber = (num) => {
  return typeof num !== "number" || num < 0;
};

export const validateStringNotEmpty = (str) => {
  return !str || !str.length;
};

export function areCurrenciesSame(array) {
  if (array.length === 0) return true;

  const firstCurrency = array[0].depositCurrency;
  for (let i = 1; i < array.length; i++) {
    if (array[i].depositCurrency !== firstCurrency) {
      return false;
    }
  }
  return true;
}

export function isStatusSettled(array) {
  if (array.length === 0) return false;

  for (let i = 0; i < array.length; i++) {
    if (array[i]?.settlementStatus === "settled") {
      return true;
    }
  }
  return false;
}

export function isPaymentNotReceived(array) {
  if (array.length === 0) return false;

  for (let i = 0; i < array.length; i++) {
    console.log(array[i]?.status);
    if (array[i]?.status !== "fund_settled") {
      return true;
    }
  }
  return false;
}

export function areOrgSame(array) {
  if (array.length === 0) return true;

  const firstOrgId = array[0].orgId;
  for (let i = 1; i < array.length; i++) {
    if (array[i].orgId !== firstOrgId) {
      return false;
    }
  }
  return true;
}
