import React, { useState, useEffect } from 'react';
import Loader from "react-js-loader";

import {
    Stat, StatLabel, StatNumber, Accordion, AccordionItem, Text, Center,
    AccordionButton, Box, AccordionIcon, AccordionPanel, Badge
} from '@chakra-ui/react';
import { isEmpty, startCase, get } from 'lodash';

import TimezoneFormatter from "../../../utils/TimezoneFormatter";
import { callApiWithToken } from '../../../utils/utils';

export default function CryptoMonitoringDetails(props) {
    const { orderId } = props || {};
    const [loading, setLoading] = useState(false);
    const [cryptoMonitoringData, setCryptoMonitoringData] = useState({});

    useEffect(() => {
        getCryptoMonitoringDetails();
    }, [orderId]);

    const getCryptoMonitoringDetails = async () => {
        setLoading(true);
        const response = await callApiWithToken("GET", `/api/orders/getCryptoMonitoringDetails?orderId=${orderId}`) || {};
        const { data = {} } = response;
        setCryptoMonitoringData(data);
        setLoading(false);
    }

    console.log(cryptoMonitoringData);
    const { paymentChecks = [], reviewResult = {}, input = {}, } = cryptoMonitoringData || {};
    const { cryptoTxnRoskScoreInfo = {} } = paymentChecks?.[0] || [];
    const signals = cryptoTxnRoskScoreInfo?.txnMonitorData?.signals || cryptoMonitoringData?.signals || {};

    const rowComponent = (label, value) => {
        return <>
            <div style={{ display: 'inline-flex', width: '100%' }}>
                <div style={{ float: 'left', width: '50%' }}>
                    <Stat float={'left'}>
                        <StatLabel>{label}</StatLabel>
                    </Stat>
                </div>
                <div style={{ float: 'right', width: '50%', marginRight: '10px' }}>
                    <Stat float={'right'}>
                        <StatLabel>{value === null || value === undefined ? 'NA' : value}</StatLabel>
                    </Stat>
                </div>
            </div>
        </>
    }

    return !loading ? <>
        <div style={{ width: '100%' }}>
            <Stat float={'left'} marginBottom={'20px'}>
                <StatNumber fontSize={'md'}>Crypto Transaction Monitoring Details</StatNumber>
            </Stat>
            {rowComponent('Result', <Badge colorScheme={reviewResult?.reviewAnswer === "GREEN" ? "green" : "red"}> {reviewResult?.reviewAnswer} </Badge>)}
            {rowComponent('Date', cryptoMonitoringData?.createdAt ? <TimezoneFormatter date={cryptoMonitoringData?.createdAt} format={"lll"} /> : "N/A")}
            {rowComponent('Applicant Id', cryptoMonitoringData?.applicantId)}
            {rowComponent('Type of Transaction', startCase(cryptoMonitoringData?.type))}
            {rowComponent('Direction', startCase(input?.cryptoTxnInfo?.direction))}
            {rowComponent('Txn ID', input?.cryptoTxnInfo?.txn)}
            {rowComponent('Wallet Address', input?.cryptoTxnInfo?.address)}
            {rowComponent('Risk Score', (cryptoTxnRoskScoreInfo?.riskScore))}
            <br />
            <br />
            <Accordion borderRadius={12} borderColor="transparent" size={'xl'} boxShadow="0 0 5px 0 #1F29371F" allowToggle>
                <AccordionItem>
                    <h2>
                        <AccordionButton _expanded={{ bg: 'rgb(74, 205, 237)', color: 'white' }}>
                            <Box as="span" flex='1' textAlign='left'>
                                Crypto Monitoring Symbols
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel py={4}>
                        {!isEmpty(signals) ? Object.keys(signals).map(signal => {
                            return rowComponent(startCase(signal), get(cryptoMonitoringData?.signals, signal, 'N/A'))
                        })
                            : <Text>No data available.</Text>}
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
        </div>
    </> :
        <Loader type="spinner-cub" bgColor={"#FFFFFF"} title={"Loading..."} color={'#FFFFFF'} size={100} />
}
