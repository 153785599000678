import React, { useState, useEffect } from "react";
import Loader from "react-js-loader";

import {
  Box,
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useToast
} from "@chakra-ui/react";
import axios from "axios";
import moment from "moment";

import BadgeCheckIcon from "../../../icons/badge-check.svg";
import { callApiWithToken } from "../../../utils/utils";

import "./CounterPartyStatus.css";

const CounterPartyStatus = () => {
  const [data, setData] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const toast = useToast();

  useEffect(() => {
    fetchCounterPartyStatus();
  }, []);

  const fetchCounterPartyStatus = async () => {
    setLoading(true);
    try {
      const responseData = await callApiWithToken(
        "GET",
        "/api/admin/finance/counter-party-status"
      );
      console.log("Fetched data:", responseData);
      if (Array.isArray(responseData)) {
        setData(responseData);
      } else {
        console.error("Unexpected data format:", responseData);
      }
    } catch (error) {
      console.error("Error fetching counterparty status:", error);
    }
    finally {
      setLoading(false); // <-- Added this line
    }
  };

  const handleApproveClick = (item) => {
    setSelectedItem(item);
    setIsModalOpen(true);
  };
  const handleConfirmApprove = async () => {
    try {
      await callApiWithToken(
        "POST",
        `/api/admin/finance/update-counter-party-status/${selectedItem.paId}`,
        {
          paId: selectedItem.paId,
          status: "success",
        }
      );
      setData((prevData) =>
        prevData.filter((item) => item.paId !== selectedItem.paId)
      );
      setIsModalOpen(false);
      toast({ 
        title: "Account marked as approved",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    } catch (error) {
      console.error("Error updating counterparty status:", error);
    }
  };
  const handleCancelApprove = () => {
    setIsModalOpen(false);
  };
  const getTimeColor = (createdAt) => {
    const minutesAgo = createdAt;
    if (minutesAgo < 60) {
      return "#2291FF"; // Less than 1 hour ago
    } else if (minutesAgo < 240) {
      return "#FFBF33"; // Between 1 and 4 hours ago
    } else {
      return "#E90000"; // 4 hours ago or more
    }
  };
  const renderTimeAgo = (createdAt) => {
    const minutesAgo = createdAt;
    if (minutesAgo >= 1440) {
      const days = Math.floor(minutesAgo / 1440);
      const hours = Math.floor((minutesAgo % 1440) / 60);
      const minutes = minutesAgo % 60;
      return `${days} day${days > 1 ? "s" : ""} ${hours} hr${
        hours > 1 ? "s" : ""
      } ${minutes} min${minutes > 1 ? "s" : ""} ago`;
    } else if (minutesAgo >= 60) {
      const hours = Math.floor(minutesAgo / 60);
      const minutes = minutesAgo % 60;
      return `${hours} hr${hours > 1 ? "s" : ""} ${minutes} min${
        minutes > 1 ? "s" : ""
      } ago`;
    } else {
      return `${minutesAgo} min${minutesAgo > 1 ? "s" : ""} ago`;
    }
  };
  return (
    <Box w="100%" p={4}>
      <Text
        style={{
          color: "#000",
          fontFamily: "DM Sans",
          fontSize: "20px",
          fontStyle: "normal",
          fontWeight: "bold",
          lineHeight: "normal",
        }}
      >
        {" "}
        Client's Account{" "}
      </Text>
      {loading ? ( 
         <Loader
         type="spinner-cub"
         bgColor={"#FFFFFF"}
         title={"Loading..."}
         color={"cyan.400"}
         size={100}
     />
      ) :(
      <div className="table-container">
        <table className="styled-table">
          <thead style={{ fontWeight: "bold", fontSize: "16px" }}>
            <tr>
              <th>Client</th>
              <th>Contact</th>
              <th>Account Number</th>
              <th>Account Created</th>
              <th>Invoice</th>
              <th>Status</th>
            </tr>
          </thead>
          <tr className="table-divider"></tr>
          <tbody>
            {data.map((item, index) => (
              <tr
                key={index}
                style={{
                  fontFamily: "DM Sans",
                  fontSize: "15px",
                  fontWeight: 400,
                }}
              >
                <td>{item.client}</td>
                <td>{item.contact}</td>
                <td>{item.accountNumber}</td>
                <td
                  style={{
                    color: getTimeColor(item.accountCreated),
                    fontWeight: "bold",
                  }}
                >
                  {renderTimeAgo(item.accountCreated)}
                </td>
                <td>
                  {item.invoiceLink ? (
                    <a
                      href={item.invoiceLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View
                    </a>
                  ) : (
                    "_"
                  )}
                </td>
                <td>
                  <button
                    className="approve-button"
                    onClick={() => handleApproveClick(item)}
                  >
                    <span>Approve</span>
                    <img
                      src={BadgeCheckIcon}
                      alt="Approve icon"
                      className="badge-check-icon"
                    />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      )}

      {/* Modal for confirmation */}
      <Modal
        isOpen={isModalOpen}
        onClose={handleCancelApprove}
        style={{
          display: "flex",
          width: "530px",
          padding: "24px",
          alignItems: "flex-start",
          alignContent: "flex-start",
          gap: "23px 35px",
          flexWrap: "wrap",
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            Are you sure you want to mark
            <br />
            <br />
            Account: {selectedItem?.contact}
            <br />
            Account Number: {selectedItem?.accountNumber}
            <br />
            <br />
            As Approved?
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={handleConfirmApprove}
              style={{
                borderRadius: "5px",
                background: "#3182CE",
                width: "90px",
                height: "37px",
                flexShrink: 0,
              }}
            >
              Yes
            </Button>
            <Button
              variant="ghost"
              onClick={handleCancelApprove}
              style={{
                borderRadius: "5px",
                background: "#EDF2F7",
                width: "90px",
                height: "37px",
                flexShrink: 0,
              }}
            >
              No
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};
export default CounterPartyStatus;