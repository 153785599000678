import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Box, Center, HStack, Button, Stack, Text, useToast } from "@chakra-ui/react";
import { Divider } from "antd";
import ace from 'brace';
import { JsonEditor } from "jsoneditor-react";
import { startCase } from "lodash";
import queryString from "query-string";

import Loader from "../../../utils/Loader";
import { callApiWithToken } from "../../../utils/utils";

import 'brace/mode/json';
import "brace/theme/github";
import 'jsoneditor-react/es/editor.min.css';

export default function EditConfigurations() {
    const qParams = queryString.parse(window.location.search);
    const { module, name, version } = qParams;
    const [jsonData, setJsonData] = useState({});
    const [editData, setEditData] = useState({});
    const [isValidJson, setIsValidJson] = useState(true);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const toast = useToast();

    useEffect(() => {
        fetchConfig();
    }, [module, name, version]);

    const fetchConfig = async () => {
        const qParams = queryString.parse(window.location.search);
        setLoading(true);
        let body = {
            filters: {
                module: qParams.module,
                name: qParams.name,
                version: qParams.version,
            }
        }

        const response = await callApiWithToken("POST", "/api/admin/listConfigurations", body);
        const { data = [] } = response;
        setJsonData(data[0]?.config);
        setEditData(data[0]?.config);
        setLoading(false);
    };

    const onUpdateConfig = async () => {
        try {
            const qParams = queryString.parse(window.location.search);
            setLoading(true);
            let body = {
                module: qParams.module,
                name: qParams.name,
                version: qParams.version,
                config: editData
            }

            const response = await callApiWithToken("POST", "/api/admin/updateConfigurations", body);
            const { success } = response;
            if (success) {
                toast({
                    title: "Success",
                    description: "Configurations updated successfully",
                    status: "success",
                    duration: 2000,
                    isClosable: true,
                });
                navigate(`/admin/configs`);
            } else {
                throw new Error("Something went wrong");
            }
        } catch (error) {
            toast({
                title: "Error",
                description: error?.message,
                status: "error",
                duration: 4000,
                isClosable: true,
            });
        }
    };

    return (
        !loading ?
            <Center py={5} bg="white.100">
                <Stack spacing={2} w="90%">
                    <Text fontWeight={700} marginLeft={'15px'}>{`Module - ${startCase(module)}, Name - ${startCase(name)}, Version - ${version}`}</Text>
                    <Divider />
                    <Box bg="white" p={4}>
                        <JsonEditor
                            value={jsonData}
                            onChange={(e) => setEditData(e)}
                            ace={ace}
                            mode="code"
                            htmlElementProps={{ style: { height: '70vh' } }}
                            onError={(e) => console.log('error', e)}
                            theme={"ace/theme/github"}
                        />
                    </Box>
                    <HStack spacing={2} w="100%" justifyContent="flex-end" paddingRight={'0.7rem'}>
                        <Button
                            variant="outline"
                            colorScheme="blue"
                            onClick={() => {
                                navigate(`/admin/configs`);
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="solid"
                            colorScheme="blue"
                            onClick={() => {
                                onUpdateConfig();
                            }}
                            disabled={!isValidJson}
                        >
                            Update
                        </Button>
                    </HStack>
                </Stack>
            </Center >
            : <Loader />
    );
}
