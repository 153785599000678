/* eslint-disable no-unused-expressions */
import React, { useEffect, useState, useContext } from 'react';
import Select from "react-select";

import {
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Input,
    useToast,
    chakra,
    Stack,
    Flex,
    Grid
} from '@chakra-ui/react';
import { useClipboard } from '@chakra-ui/react'
import { useFormik } from 'formik';
import * as Yup from "yup";

import { getOrgId } from '../../components/App/useToken';
import { NavBarContext } from "../../context/NavBarContext";
import { callApiWithToken } from "../../utils/utils";

const NewPreFundModal = (props) => {
    const { isOpen, onClose, isLoading, refreshGrid } = props;

    const [currency, setCurrency] = useState("");

    const { value, setValue, hasCopied: hasCopiedWalletAddress, onCopy: onCopyWalletAddress } = useClipboard("");
    const toast = useToast();

    const currencyOptions = [{ label: "USDT", value: "USDT" }];
    const receiveCurrencyOptions = [{ label: "PHP", value: "PHP" }, { label: "IDR", value: "IDR" }, { label: "GHS", value: "GHS" }, { label: "TZS", value: "TZS" }, { label: "ZMW", value: "ZMW" }, { label: "UGX", value: "UGX" }];
    const walletNameOptions = [
        { label: "TransFi USDT", value: "usdt_wallet", address: "0x2d244022359f432f2c1b6b64f90b5f318ad9f817" }
    ];
    const { selectedOrg } = useContext(NavBarContext);

    const formik = useFormik({
        initialValues: {
            amount: '',
            walletName: '',
            walletAddress: '',
            receiveCurrency: ''
        },
        validationSchema: Yup.object({
            amount: Yup.string().required("Please enter the amount")
                .matches(/^\d+([.]\d+)?$/, "Please enter the valid amount"),
            walletName: Yup.object().required("Please select the wallet name"),
            receiveCurrency: Yup.object().required("Please select the receive currency"),
        }),
        onSubmit: async (formValues) => {
            try {
                const response = await callApiWithToken("POST", "/api/prefund/create", {
                    sendCurrency: "USDT",
                    sendAmount: formValues?.amount,
                    walletName: formValues?.walletName?.value,
                    walletAddress: formValues?.walletAddress,
                    convertedCurrency: formValues?.receiveCurrency?.value,
                    orgName:selectedOrg
                });
                if (response?.message) {
                    toast({
                        title: 'Success',
                        description: response?.message,
                        status: 'success',
                        duration: 5000,
                        isClosable: true,
                        position: 'top-right'
                    })
                }
                refreshGrid();
                closeModal();
            } catch (err) {
                toast({
                    title: 'Error!',
                    description: err?.message || "Failed to fetch order details.",
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                    position: 'top-right'
                })
            }
            formik.setValues({
                amount: '',
                walletName: '',
                walletAddress: '',
            });
        },
    });

    const onWalletNameChange = (event) => {
        formik.setFieldValue("walletName", event);
        formik.setFieldValue("walletAddress", event?.address);
    };

    useEffect(() => {
        setValue(formik.values.walletAddress)
    }, [formik.values.walletAddress])

    const closeModal = () => {
        formik.resetForm();
        onClose();
    }

    return (
        <chakra.form onSubmit={formik.handleSubmit} display="flex" flexGrow={1}>
            <Modal isOpen={isOpen} onClose={() => closeModal()}>
                <ModalOverlay />

                <ModalContent>
                    <ModalHeader>Initiate Prefund</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>

                        <Stack direction={"column"} justifyContent="space-between" flexGrow={1}>
                            <Grid templateColumns={{ base: "1fr", md: "1fr 1fr" }} gap={4}>
                                <FormControl>
                                    <FormLabel>Transfer Currency</FormLabel>
                                    <Select
                                        id="currency"
                                        isSearchable={false}
                                        value={{ label: "USDT", value: "USDT" }}
                                        isDisabled
                                        placeholder='Select Currency'
                                        options={currencyOptions}
                                        onChange={(value) => {
                                            setCurrency(value);
                                        }}
                                    />
                                </FormControl>
                                <FormControl
                                    isRequired
                                    isInvalid={formik.touched.amount && formik.errors.amount}
                                >
                                    <FormLabel htmlFor="name">Amount</FormLabel>
                                    <Input
                                        id="amount"
                                        type="number"
                                        placeholder='Enter the amount'
                                        value={formik.values.amount}
                                        onChange={formik.handleChange}
                                    />
                                    <FormErrorMessage>{formik.errors.amount}</FormErrorMessage>
                                </FormControl>
                            </Grid>
                            <FormControl
                                isRequired
                                isInvalid={formik.touched.walletName && formik.errors.walletName}
                            >
                                <FormLabel>TransFi Wallet</FormLabel>
                                <Select
                                    id="walletName"
                                    type="text"
                                    isSearchable={false}
                                    placeholder='Select Destination Wallet'
                                    options={walletNameOptions}
                                    onChange={(e) => onWalletNameChange(e)}
                                    value={formik.values.walletName}
                                />
                                <FormErrorMessage>{formik.errors.walletName}</FormErrorMessage>
                            </FormControl>

                            <FormControl>
                                <FormLabel htmlFor="name">TransFi Wallet Address</FormLabel>
                                <Flex>
                                    <Input
                                        id="walletAddress"
                                        type="text"
                                        value={formik.values.walletAddress}
                                        disabled
                                    />
                                    <Button marginLeft={1} onClick={onCopyWalletAddress}>{hasCopiedWalletAddress ? "Copied!" : "Copy"}</Button>
                                </Flex>
                            </FormControl>
                            <FormControl
                                isRequired
                                isInvalid={formik.touched.receiveCurrency && formik.errors.receiveCurrency}>
                                <FormLabel>Deposit Currency</FormLabel>
                                <Select
                                    id="receiveCurrency"
                                    isSearchable={false}
                                    value={formik.values.receiveCurrency}
                                    placeholder='Select Currency'
                                    options={receiveCurrencyOptions}
                                    onChange={(e) => formik.setFieldValue("receiveCurrency", e)}
                                />
                                <FormErrorMessage>{formik.errors.receiveCurrency}</FormErrorMessage>
                            </FormControl>
                        </Stack>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            isLoading={isLoading}
                            onClick={formik.handleSubmit}
                            colorScheme='blue' mr={3}
                        >
                            Create
                        </Button>
                        <Button
                            onClick={() => closeModal()}
                        >
                            Cancel
                        </Button>
                    </ModalFooter>

                </ModalContent>
            </Modal>
        </chakra.form>
    )
}

export default NewPreFundModal;