import React, { useState, useContext, useRef, useEffect } from "react";
import Loader from "react-js-loader";
import Select from "react-select";

import {
    Box,
    Flex,
    FormControl,
    FormLabel,
    Button,
    Input,
    useToast
} from "@chakra-ui/react";
import { isEmpty, uniqBy } from "lodash";

import { callApiWithToken } from "../../utils/utils";

export default function ChainalysisPreScreening() {
    const [loading, setLoading] = useState(false);
    const [selectedCrypto, setSelectedCrypto] = useState({ value: "BTC", label: "BTC" });
    const [cryptoOptions, setCryptoOptions] = useState([]);
    const [walletAddress, setWalletAddress] = useState("");
    const [amount, setAmount] = useState("");
    const [userId, setUserId] = useState("");
    const [alerts, setAlerts] = useState("");
    const [alertResult, setAlertResult] = useState(false);
    const toast = useToast();

    useEffect(() => {
        getCryptoCurrency();
    }, []);

    const getCryptoCurrency = async () => {
        const response = await callApiWithToken("GET", `/api/widget/configList?name=crypto&module=buy_crypto`);
        const data = response.data[0].config;
        const cryptoList = data.map((crypto) => {
            return { value: crypto.formattedSymbol, label: crypto.formattedSymbol };
        });
        setCryptoOptions(uniqBy(cryptoList, (obj) => obj.value));
    };

    const onSubmit = async () => {
        try {
            setAlerts([]);
            setAlertResult(false);
            setLoading(true);

            if (walletAddress === "" || amount === "" || userId === "") {
                alert("All fields are mandatory");
                setLoading(false);
                return;
            }

            const data = {
                asset: selectedCrypto.value,
                walletAddress: walletAddress,
                amount: amount,
                chainalysisId: userId,
            };

            const response = await callApiWithToken("POST", "/api/compliance/preScreening", data);
            console.log(response);
            if (response?.success) {
                setAlerts(response?.data);
                if (isEmpty(response?.data)) {
                    setAlertResult(true);
                }
                toast({
                    title: "Success",
                    description: "Pre Screening Successfull",
                    status: "success",
                    duration: 2000,
                    isClosable: true,
                });
            } else {
                toast({
                    title: "Error",
                    description: "Pre Screening Failed",
                    status: "error",
                    duration: 2000,
                    isClosable: true,
                });
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log("Error in Pre Screening", error);
            toast({
                title: "Error",
                description: "Pre Screening Failed",
                status: "error",
                duration: 2000,
                isClosable: true,
            });
        }
    }

    const onClear = () => {
        setWalletAddress("");
        setAmount("");
        setUserId("");
        setAlerts("");
    }

    return !loading ? (
        <Box style={{ background: "white" }} heading="Pre Screening of Wallets">
            <Flex
                justifyContent={"space-evenly"}
                alignItems={"flex-end"}
                gap="5"
                p={5}
                width="100%"
            >
                <FormControl>
                    <FormLabel>Asset:</FormLabel>
                    <Select
                        options={cryptoOptions}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,

                            }),
                        }}
                        value={selectedCrypto}
                        onChange={value => setSelectedCrypto(value)}
                    />
                </FormControl>
                <FormControl>
                    <FormLabel>Wallet Address:</FormLabel>
                    <Input
                        type="text"
                        value={walletAddress}
                        onChange={(e) => setWalletAddress(e.target.value)}
                    />
                </FormControl>
            </Flex>
            <Flex
                justifyContent={"space-evenly"}
                alignItems={"flex-end"}
                gap="5"
                p={5}
                width="100%"
            >
                <FormControl>
                    <FormLabel>Amount:</FormLabel>

                    <Input
                        type="number"
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                    />
                </FormControl>
                <FormControl>
                    <FormLabel>User Id or Customer Name:</FormLabel>

                    <Input
                        type="text"
                        value={userId}
                        onChange={(e) => setUserId(e.target.value)}
                    />
                </FormControl>
            </Flex>
            <Box textAlign={"right"} paddingRight="20px">
                <Button
                    onClick={e => onClear()} colorScheme="red"
                    marginRight={"10px"}
                >
                    Clear
                </Button>
                <Button
                    onClick={e => onSubmit()} colorScheme="blue"
                >
                    Submit
                </Button>
            </Box>
            <div className="table-container">
                <table className="styled-table">
                    <thead style={{ fontWeight: "bold", fontSize: "16px" }}>
                        <tr>
                            <th>Alert Amount</th>
                            <th>Alert Level</th>
                            <th>Category</th>
                            <th>Category Id</th>
                            <th>Exposure Type</th>
                        </tr>
                    </thead>
                    <tr className="table-divider"></tr>
                    <tbody>
                        {isEmpty(alerts) ? (
                            <tr>
                                <td colSpan="5" style={{ textAlign: "center" }}>
                                    {alertResult ? "No Alerts for this Address" : "Run Screening to see the alerts" }
                                </td>
                            </tr>
                        ) :
                            alerts.map((item, index) => (
                                <tr
                                    key={index}
                                    style={{
                                        fontFamily: "DM Sans",
                                        fontSize: "15px",
                                        fontWeight: 400,
                                    }}
                                >
                                    <td>{item.alertAmount}</td>
                                    <td>{item.alertLevel}</td>
                                    <td>{item.category}</td>
                                    <td>{item.categoryId}</td>
                                    <td>{item.exposureType}</td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>

        </Box>
    ) : (
        <Loader type="spinner-cub" bgColor={"#FFFFFF"} title={"Loading..."} color={"cyan.400"} size={100} />
    );
}
